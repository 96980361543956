import React from 'react';
import { Link } from 'react-router-dom';
import bottomBg from 'assets/imgs/background/girl.png';
import Button from 'components/button/Button';
import tableImg1 from 'assets/imgs/topik/table1.png'
import tableImg2 from 'assets/imgs/topik/table2.png'

type LinkDataProps = {
  url: string;
  text: string;
};

const Topik = () => {
  const linkData: LinkDataProps[] = [
    {
      url: '/topik/category/1',
      text: 'TOPIK I',
    },
    {
      url: '/topik/category/2',
      text: 'TOPIK II',
    },
  ];
  return (
    <div
      className='flex justify-center'>
      <div className='w-[360px] pt-4 flex-col items-center px-2 pb-10 relative min-h-screen'>
        <div className="flex flex-wrap gap-3 justify-center">
          {linkData.map((ele) => (
            <Link to={ele.url} className="max-h-[100px]">
              <Button className='bg-pr3 text-pr2'>{ele.text}</Button >
            </Link>
          ))}
        </div>
        <div className='w-[330px] m-auto'>
          <h2 className='mt-6 text-center text-[24px]'><a target='_blank' href='https://www.topik.go.kr/' className='text-pr2 underline'>TOPIK</a>とは？</h2>
          <p className='mt-3 text-[12px] leading-6'>TOPIK韓国語能力試験はTOPIK1とTOPIK2で構成されている。<br />
            TOPIK1は聞き取りと読解、。<br />TOPIK2は聞き取り、書き取り、読解領域に区分されている。
            GKS奨学生はTOPIK21~20点以上（3級）を受けなければ、。<br />大学課程と大学院課程に進学できない。<br />韓国語研修期間中、約3回の試験を受けることになる。<br />
            2番の試験受験料は支援される。<br />通常は団体受付で行われるが、<br />状況によって個別進行に変更されることがある。</p>
          <h3 className='text-center text-[18px] text-pr2 mt-10 mb-5 font-bold'>TOPIK 級数</h3>
          <p className='text-[12px] leading-6'>大学院入学2~3ヶ月前には3級を取得しなければならない。<br />未取得の場合、奨学資格が喪失する。 ただし、<br />
            TOPIK3級合格点の70%(84点)以上を受け、<br />韓国語研修機関長の入学許可を受けた奨学生は、<br />国立国際教育院長の承認を得て、<br />韓国語研修課程の履修期間を<br />1回に限り6ヶ月延長することができる。<br />この場合、研修費の50%は奨学生が負担。</p>
          <img src={tableImg1} className='mt-5 w-full' />
          <p className=' text-[10px] font-light text-gray'>*韓国能力優秀者(大学院進学後、TOPIK5級以上は月10万ウォン支給)</p>
          <h3 className='text-center text-[18px] text-pr2 mt-10 mb-5 font-bold'>TOPIK 試験内容</h3>
          <img src={tableImg1} className='mt-5 w-full' />
        </div>
      </div>
    </div >
  );
};

export default Topik;
