import { TopikReadingType } from "types/topik";

export const Reading2Quizlist2: TopikReadingType[] = [
    {
        q: '다음 글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '이 서비스는 일 년 내내 운영된다.',
                isAnswer: false
            },
            {
                text: '올해 처음으로 이 센터가 운영되기 시작했다.',
                isAnswer: false
            },
            {
                text: '주말에는 인주공원에서 수리를 받을 수 있다.',
                isAnswer: true
            },
            {
                text: '이 수리 센터에서는 무료로 부품을 교체해 준다.',
                isAnswer: false
            },
        ],
        question:
            `인주시가 작년에 이어 올해도 10월 한 달간
'찾아가는 자전거 수리 센터'를 운영한다.
평일에는 여러 지역을 직접 방문해 고쳐 주고 주말에는 인주공원에서 수리 서비스를 제공한다.
전문 수리 기사가 자전거를 무료로 고쳐 주고 부품 교체가 필요한 경우에는 저렴한 가격으로 바꿔 준다.
시는 시민들의 자전거 타기 생활화를 위해 이 수리 센터를 운영하는 것이라고 밝혔다.`
    },
    {
        q: '다음 글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '고객은 주문한 피자를 취소하겠다고 말했다.',
                isAnswer: false
            },
            {
                text: '사고가 생겨서 배달 중이던 피자가 망가졌다.',
                isAnswer: true
            },
            {
                text: '오토바이가 빗길에 미끄러져서 김 씨가 다쳤다.',
                isAnswer: false
            },
            {
                text: '김 씨는 피자를 시킨 고객에게 연락하다 사고가 났다.',
                isAnswer: false
            },
        ],
        question:
            `피자 가게를 운영하는 김민수 씨의 사연이 잔잔한 감동을 주고 있다.
최근 김 씨는 피자를 배달하러 가다가 오토바이가 빗길에 미끄러지는 사고를 당했다.
다행히 사람이 다치는 피해는 없었지마 피자가 망가져서 배달에 문제가 생겼다.
배달이 늦어지는 것에 대해 김 씨가 고객에게 연락하자 고객은 "천천히 오셔도 돼요. 몸은 괜찮나요?"라며 따뜻한 말부터 전했다고 한다.`
    },
    {
        q: '다음을 순서에 맞게 배열한 것을 고르십시오.',
        answer: [
            {
                text: '(가) - (다) - (라) - (나)',
                isAnswer: false
            },
            {
                text: '(나) - (가) - (다) - (라)',
                isAnswer: true
            },
            {
                text: '(가) - (라) - (나) - (다)',
                isAnswer: false
            },
            {
                text: '(나) - (라) - (다) - (가)',
                isAnswer: false
            },
        ],
        question:
            `(가) 짧게는 하루, 길게는 한 달 정도 반짝 운영하는 임시 매장이다.
(나) 사람이 붐비는 장소에서 짧은 기간 동안 운영하는 매장이 유행이다.
(다) 기업은 이 임시 매장을 이용해 주로 특정 제품을 팔거나 홍보를 한다.
(라) 또 기업 이미지를 친숙하게 만들기 위해 재미있는 행사를 열기도 한다.`,
    },
    {
        q: '다음을 순서에 맞게 배열한 것을 고르십시오.',
        answer: [
            {
                text: '(나) - (다) - (라) - (가)',
                isAnswer: false
            },
            {
                text: '(나) - (라) - (가) - (다)',
                isAnswer: false
            },
            {
                text: '(다) - (나) - (가) - (라)',
                isAnswer: true
            },
            {
                text: '(다) - (나) - (라) - (가)',
                isAnswer: false
            },
        ],
        question:
            `(가) 그런데 전화를 끊고 뒤늦게 미안한 마음이 들었다.
(나) 친구는 나의 합격 소식을 듣고 자신의 일처럼 기뻐했다.
(다) 얼마 전 나는 회사 합격 소식을 듣고 친한 친구에게 전화했다.
(라) 아직 취업 못 한 친구를 내가 배려하지 못했다는 생각 때문이었다.`,
    },
    {
        q: '다음을 순서에 맞게 배열한 것을 고르십시오.',
        answer: [
            {
                text: '(가) - (나) - (라) - (다)',
                isAnswer: false
            },
            {
                text: '(다) - (가) - (나) - (라)',
                isAnswer: true
            },
            {
                text: '(가) - (다) - (라) - (나)',
                isAnswer: false
            },
            {
                text: '(다) - (라) - (나) - (가)',
                isAnswer: false
            },
        ],
        question:
            `(가) 빵이나 고기를 구울 때 갈색으로 바뀌는 것이 그 예이다.
(나) 갈색으로 바뀌면서 빵이나 고기의 맛도 더 깊어지고 풍부해진다.
(다) 식품에 들어 있는 당과 단백질이 열을 만나면 색깔이 바뀌게 된다.
(라) 만약 열이 너무 가해져서 검게 타게 되면 그런 맛을 낼 수가 없다.`,
    },
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '적의 움직임을 알기',
                isAnswer: true
            },
            {
                text: '먹이를 빨리 구하기',
                isAnswer: false
            },
            {
                text: '머리를 깨끗이 씻기',
                isAnswer: false
            },
            {
                text: '다른 타조를 공격하기',
                isAnswer: false
            },
        ],
        question:
            `타조는 멀리서 적이 다가와도 머리를 모래에 파묻고 있다.
그래서 위험이나 위기를 모르는 척 피하는 사람을 타조에 비유하곤 한다.
그런데 사실 타조는 (      ) 위해 이런 행동을 한다.
시각보다 청각이 발달한 타조는 땅에서 울리는 발소리를 듣고 적이 오는지를 파악하려는 것이다.`,
    },
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '향기를 없애기',
                isAnswer: false
            },
            {
                text: '색깔을 변형시키기',
                isAnswer: false
            },
            {
                text: '흡수력을 떨어뜨리기',
                isAnswer: true
            },
            {
                text: '촉감을 거칠게 만들기',
                isAnswer: false
            },
        ],
        question:
            `섬유 유연제는 섬유에 막을 씌워 옷감을 부드럽게 하고 향기도 남긴다.
그래서 세탁할 때 유연제를 넣는 사람들이 많다.
그런데 수건처럼 물기를 빠르게 흡수해야 하는 소재에는 유연제를 넣는 것이 좋은 것만은 아니다.
유연제가 만든 막이 오히려 섬유의 (      ) 때문이다.`,
    },
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '뇌를 덜 사용한다고',
                isAnswer: true
            },
            {
                text: '기억력이 좋아졌다고',
                isAnswer: false
            },
            {
                text: '저장해야 할 정보가 많다고',
                isAnswer: false
            },
            {
                text: '디지털 기기를 많이 쓴다고',
                isAnswer: false
            },
        ],
        question:
            `디지털 기기에 대한 의존도가 높아지면서 사람들이 무언가 직접 기억해야 할 일은 줄었다.
그래서 이전보다 (      ) 생각할 수 있다.
그런데 뇌를 사용하는 방식이 이전과 달라졌을 뿐 사용량이 줄어든 것이 아니다.
과거에는 단순히 정보를 기억하는 데 뇌를 썼다면 이제는 과거보다 압도적으로 많아진 정보를 처리하는 데 사용하고 있는 것이다.`,
    },
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '혹시',
                isAnswer: true
            },
            {
                text: '또는',
                isAnswer: false
            },
            {
                text: '비록',
                isAnswer: false
            },
            {
                text: '만약',
                isAnswer: false
            },
        ],
        question:
            `매서운 남극의 겨울, 황제펭귄들은 겹겹이 붙어 서로의 체온으로 추위를 견딘다.
무리 전체가 돌면서 바깥쪽과 안쪽에 있는 펭귄들이 계속 서로의 위치를 바꾼다.
안에서 몸을 데운 펭귄은 밖으로 나가고 밖에서 추위에 떨던 펭귄은 안으로 들어오는 것이다.
(      ) 그 움직임은 아주 느리지만 쉬지 않고 이루어져 한 마리의 펭귄이 줄곧 찬바람을 맞고 서 있는 일이 없다.
그렇게 쉼 없이 둥글게 돌며서 펭귄들은 다 함께 살아남는다.`,
    },
    {
        q: '윗글의 주제로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '황제펭귄은 서로 도우면서 추위에 맞서 생존해 왔다.',
                isAnswer: true
            },
            {
                text: '황제펭귄은 둥글게 돌면서 날씨에 대한 정보를 알린다.',
                isAnswer: false
            },
            {
                text: '황제펭귄은 추위에서 살아남기 위해 움직임이 느려졌다.',
                isAnswer: false
            },
            {
                text: '황제펭귄은 무리 생활을 통해 경쟁에서 이기는 법을 배운다.',
                isAnswer: false
            },
        ],
        question:
            `매서운 남극의 겨울, 황제펭귄들은 겹겹이 붙어 서로의 체온으로 추위를 견딘다.
무리 전체가 돌면서 바깥쪽과 안쪽에 있는 펭귄들이 계속 서로의 위치를 바꾼다.
안에서 몸을 데운 펭귄은 밖으로 나가고 밖에서 추위에 떨던 펭귄은 안으로 들어오는 것이다.
(      ) 그 움직임은 아주 느리지만 쉬지 않고 이루어져 한 마리의 펭귄이 줄곧 찬바람을 맞고 서 있는 일이 없다.
그렇게 쉼 없이 둥글게 돌며서 펭귄들은 다 함께 살아남는다.`,
    },
]