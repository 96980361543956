import React from 'react'

type Props = {
    src: string;
    width?: number;
    height?: number;
    isSvg?: boolean;
    name?: string;
    className?: string;
    onClick?: () => void;
}

const Icon = ({ src, width = 24, height = 24, isSvg, className, name, onClick }: Props) => {
    return (
        <span
            className={"inline-block cursor-pointer " + className}
            style={{ width: width, height: height }}
            onClick={onClick}
        >
            <img src={src} alt={name} style={{ width: width, height: height }} />
        </span>
    )
}

export default Icon