export const word1_1 = [
    { "kr": "가게", "mean": "店" },
    { "kr": "가격", "mean": "価格" },
    { "kr": "가구", "mean": "家具" },
    { "kr": "가깝다", "mean": "近い" },
    { "kr": "가끔", "mean": "時々" },
    { "kr": "가다", "mean": "行く" },
    { "kr": "가르치다", "mean": "教える" },
    { "kr": "가방", "mean": "鞄" },
    { "kr": "가볍다", "mean": "軽い" },
    { "kr": "가수", "mean": "歌手" },
    { "kr": "가슴", "mean": "胸" },
    { "kr": "가요", "mean": "歌謡" },
    { "kr": "가운데", "mean": "間、中間" },
    { "kr": "가위", "mean": "はさみ" },
    { "kr": "가을", "mean": "秋" },
    { "kr": "가장", "mean": "最も、一番" },
    { "kr": "가져가다", "mean": "持って行く" },
    { "kr": "가져오다", "mean": "持って来る" },
    { "kr": "가족", "mean": "家族" },
    { "kr": "가지", "mean": "種類" },
    { "kr": "가지다", "mean": "持つ" },
    { "kr": "각", "mean": "各、おのおの" },
    { "kr": "간", "mean": "間" },
    { "kr": "간단하다", "mean": "簡単だ" },
    { "kr": "간단히", "mean": "簡単に" },
    { "kr": "간식", "mean": "間食、おやつ" },
    { "kr": "간장", "mean": "醤油" },
    { "kr": "간호사", "mean": "看護士" },
    { "kr": "갈비", "mean": "カルビ" },
    { "kr": "갈비탕", "mean": "カルビタン" },
    { "kr": "갈색", "mean": "褐色、茶色" },
    { "kr": "갈아타다", "mean": "乗り換える" },
    { "kr": "감", "mean": "柿" },
    { "kr": "감기", "mean": "風邪" },
    { "kr": "감기약", "mean": "風邪薬" },
    { "kr": "감다", "mean": "（目を）閉じる" },
    { "kr": "감동", "mean": "感動" },
    { "kr": "감동하다", "mean": "感動する" },
    { "kr": "감사", "mean": "感謝" },
    { "kr": "감사드립니다", "mean": "ありがとうございます" },
    { "kr": "감사하다", "mean": "感謝する" },
    { "kr": "감사합니다", "mean": "ありがとうございます" },
    { "kr": "감자", "mean": "じゃがいも" },
    { "kr": "감자탕", "mean": "カムジャタン" },
    { "kr": "갑자기", "mean": "急に" },
    { "kr": "값", "mean": "値段、価値" },
    { "kr": "강", "mean": "川" },
    { "kr": "강아지", "mean": "子犬" },
    { "kr": "강좌", "mean": "講座" },
    { "kr": "갖다", "mean": "持つ" },
    { "kr": "같다", "mean": "同じだ" },
    { "kr": "같이", "mean": "一緒に" },
    { "kr": "개", "mean": "～個、犬" },
    { "kr": "개나리", "mean": "レンギョウ" },
    { "kr": "개인", "mean": "個人" },
    { "kr": "거기", "mean": "そこ" },
    { "kr": "거리", "mean": "通り、街" },
    { "kr": "거실", "mean": "居室、居間" },
    { "kr": "거울", "mean": "鏡" },
    { "kr": "거의", "mean": "ほとんど" },
    { "kr": "거짓말", "mean": "嘘" },
    { "kr": "걱정", "mean": "心配" },
    { "kr": "걱정하다", "mean": "心配する" },
    { "kr": "건강", "mean": "健康" },
    { "kr": "건강하다", "mean": "健康だ" },
    { "kr": "건너가다", "mean": "渡って行く、渡る、横切る" },
    { "kr": "건너다", "mean": "渡る" },
    { "kr": "건너편", "mean": "向こう側、向かい" },
    { "kr": "건물", "mean": "建物" },
    { "kr": "건배", "mean": "乾杯" },
    { "kr": "걷다", "mean": "歩く" },
    { "kr": "걸다", "mean": "かける" },
    { "kr": "걸리다", "mean": "かかる" },
    { "kr": "걸어가다", "mean": "歩いて行く" },
    { "kr": "걸어오다", "mean": "歩いて来る" },
    { "kr": "검은색", "mean": "黒、黒色" },
    { "kr": "것", "mean": "もの、こと" },
    { "kr": "게임", "mean": "ゲーム、遊び" },
    { "kr": "겨울", "mean": "冬" },
    { "kr": "겨울방학", "mean": "冬休み" },
    { "kr": "결果", "mean": "結果" },
    { "kr": "결정", "mean": "決定" },
    { "kr": "결정하다", "mean": "決定する" },
    { "kr": "결혼", "mean": "結婚" },
    { "kr": "결婚式", "mean": "結婚式" },
    { "kr": "결婚하다", "mean": "結婚する" },
    { "kr": "경기", "mean": "競技、試合" },
    { "kr": "경기장", "mean": "競技場" },
    { "kr": "경복궁", "mean": "景福宮" },
    { "kr": "경주", "mean": "慶州" },
    { "kr": "경찰", "mean": "警察" },
    { "kr": "경찰관", "mean": "警察官" },
    { "kr": "경찰署", "mean": "警察署" },
    { "kr": "경치", "mean": "景色、風景" },
    { "kr": "경험", "mean": "経験" },
    { "kr": "계단", "mean": "階段" },
    { "kr": "계란", "mean": "鶏卵、たまご" },
    { "kr": "계산하다", "mean": "計算する" },
    { "kr": "계속", "mean": "続けて" },
    { "kr": "계시다", "mean": "いらっしゃる" }
]


export const word1_2 = [
    { "kr": "계절", "mean": "季節" },
    { "kr": "계획", "mean": "計画" },
    { "kr": "고기", "mean": "肉" },
    { "kr": "고등학교", "mean": "高等学校" },
    { "kr": "고등학생", "mean": "高校生" },
    { "kr": "고르다", "mean": "選ぶ" },
    { "kr": "고마웠습니다", "mean": "ありがとうございました" },
    { "kr": "고맙다", "mean": "ありがたい" },
    { "kr": "고맙습니다", "mean": "ありがとうございます" },
    { "kr": "고모", "mean": "（父方の）おば" },
    { "kr": "고모부", "mean": "（父方の）おばの夫、おじ" },
    { "kr": "고속버스", "mean": "高速バス" },
    { "kr": "고양이", "mean": "猫" },
    { "kr": "고장", "mean": "故障" },
    { "kr": "고추", "mean": "唐辛子" },
    { "kr": "고추장", "mean": "コチュジャン" },
    { "kr": "고치다", "mean": "直す" },
    { "kr": "고프다", "mean": "（お腹が）すいている、空腹だ" },
    { "kr": "고향", "mean": "故郷、ふるさと" },
    { "kr": "곧", "mean": "すぐ" },
    { "kr": "골목", "mean": "路地、裏通り" },
    { "kr": "골프", "mean": "ゴルフ" },
    { "kr": "곱다", "mean": "美しい" },
    { "kr": "곳", "mean": "ところ" },
    { "kr": "공", "mean": "ボール、玉" },
    { "kr": "공간", "mean": "空間" },
    { "kr": "공기", "mean": "空気" },
    { "kr": "공무원", "mean": "公務員" },
    { "kr": "공부", "mean": "勉強" },
    { "kr": "공부하다", "mean": "勉強する" },
    { "kr": "공연", "mean": "公演" },
    { "kr": "공원", "mean": "公園" },
    { "kr": "공중전화", "mean": "公衆電話" },
    { "kr": "공짜", "mean": "ただ、無料" },
    { "kr": "공책", "mean": "ノート" },
    { "kr": "공항", "mean": "空港" },
    { "kr": "공휴일", "mean": "祝日" },
    { "kr": "과", "mean": "課" },
    { "kr": "과거", "mean": "過去" },
    { "kr": "과일", "mean": "果物" },
    { "kr": "과자", "mean": "菓子" },
    { "kr": "과학", "mean": "科学" },
    { "kr": "관계", "mean": "関係" },
    { "kr": "관광하다", "mean": "観光する" },
    { "kr": "관심", "mean": "関心、興味" },
    { "kr": "광고", "mean": "広告" },
    { "kr": "괜찮다", "mean": "大丈夫だ、構わない" },
    { "kr": "괜찮습니다", "mean": "大丈夫です、結構です" },
    { "kr": "교과서", "mean": "教科書" },
    { "kr": "교수", "mean": "教授" },
    { "kr": "교실", "mean": "教室" },
    { "kr": "교체", "mean": "交代" },
    { "kr": "교통", "mean": "交通" },
    { "kr": "교통사고", "mean": "交通事故" },
    { "kr": "교회", "mean": "教会" },
    { "kr": "구", "mean": "9" },
    { "kr": "구경하다", "mean": "見物する" },
    { "kr": "구두", "mean": "靴" },
    { "kr": "구름", "mean": "雲" },
    { "kr": "구십", "mean": "90" },
    { "kr": "구월", "mean": "9月" },
    { "kr": "구하다", "mean": "求める、手に入れる" },
    { "kr": "국", "mean": "スープ、汁" },
    { "kr": "국내", "mean": "国内" },
    { "kr": "국립", "mean": "国立" },
    { "kr": "국수", "mean": "麺、麺類" },
    { "kr": "국어", "mean": "国語" },
    { "kr": "국적", "mean": "国籍" },
    { "kr": "국제", "mean": "国際" },
    { "kr": "군인", "mean": "軍人" },
    { "kr": "굽다", "mean": "焼く" },
    { "kr": "권", "mean": "～巻" },
    { "kr": "귀", "mean": "耳" },
    { "kr": "귀엽다", "mean": "かわいい" },
    { "kr": "규칙", "mean": "規則" },
    { "kr": "귤", "mean": "みかん" },
    { "kr": "그", "mean": "その" },
    { "kr": "그거", "mean": "それ" },
    { "kr": "그것", "mean": "それ" },
    { "kr": "그곳", "mean": "そこ" },
    { "kr": "그날", "mean": "その日" },
    { "kr": "그냥", "mean": "そのまま、ただ" },
    { "kr": "그동안", "mean": "その間、これまで" },
    { "kr": "그들", "mean": "彼ら" },
    { "kr": "그때", "mean": "そのとき、当時" },
    { "kr": "그래", "mean": "うん、そう（相づち）" },
    { "kr": "그래서", "mean": "それで、そうして" },
    { "kr": "그램", "mean": "グラム" },
    { "kr": "그러나", "mean": "しかし、けれど" },
    { "kr": "그러니까", "mean": "だから、そうだから" },
    { "kr": "그러면", "mean": "そうなら、それでは" },
    { "kr": "그런", "mean": "そんな" },
    { "kr": "그런데", "mean": "ところで、ところが" },
    { "kr": "그럼", "mean": "では" },
    { "kr": "그렇게", "mean": "そのように" },
    { "kr": "그렇구나", "mean": "なるほど、そうなんだ" },
    { "kr": "그렇다", "mean": "そうだ" },
    { "kr": "그렇습니다", "mean": "そうです" },
    { "kr": "그렇지만", "mean": "そうだけれど、しかし" },
    { "kr": "그릇", "mean": "器、容器、食器" }
]

export const word1_3 = [
    { "kr": "그리고", "mean": "そして" },
    { "kr": "그리다", "mean": "（絵を）描く" },
    { "kr": "그림", "mean": "絵" },
    { "kr": "그만", "mean": "それくらいで、それで" },
    { "kr": "그분", "mean": "そのかた" },
    { "kr": "그저께", "mean": "おととい" },
    { "kr": "그중", "mean": "その中" },
    { "kr": "그쪽", "mean": "そちら、そのかた" },
    { "kr": "그치다", "mean": "終わる、止む" },
    { "kr": "극장", "mean": "劇場、映画館" },
    { "kr": "근처", "mean": "近所" },
    { "kr": "글", "mean": "文章、文字" },
    { "kr": "글쎄요", "mean": "そうですね、どうでしょうね" },
    { "kr": "금방", "mean": "すぐ、さっき" },
    { "kr": "금연", "mean": "禁煙" },
    { "kr": "금요일", "mean": "金曜日" },
    { "kr": "금주", "mean": "禁酒" },
    { "kr": "급", "mean": "級" },
    { "kr": "급하다", "mean": "急だ、緊急だ、急がれる" },
    { "kr": "기간", "mean": "期間" },
    { "kr": "기다리다", "mean": "待つ" },
    { "kr": "기르다", "mean": "育てる、養う" },
    { "kr": "기름", "mean": "油、ガソリン" },
    { "kr": "기분", "mean": "気分" },
    { "kr": "기뻐하다", "mean": "嬉しがる" },
    { "kr": "기쁘다", "mean": "嬉しい" },
    { "kr": "기사", "mean": "運転手、技士、記事" },
    { "kr": "기숙사", "mean": "寄宿舎、寮" },
    { "kr": "기억하다", "mean": "記憶する、憶える" },
    { "kr": "기온", "mean": "気温" },
    { "kr": "기자", "mean": "記者" },
    { "kr": "기차", "mean": "汽車" },
    { "kr": "기침", "mean": "咳" },
    { "kr": "기타", "mean": "その他" },
    { "kr": "긴장되다", "mean": "緊張する" },
    { "kr": "길", "mean": "道" },
    { "kr": "길다", "mean": "長い" },
    { "kr": "김", "mean": "海苔" },
    { "kr": "김밥", "mean": "のり巻き" },
    { "kr": "김치", "mean": "キムチ" },
    { "kr": "김치찌개", "mean": "キムチ鍋" },
    { "kr": "김포공항", "mean": "金浦空港" },
    { "kr": "까만색", "mean": "黒、黒色" },
    { "kr": "까맣다", "mean": "黒い" },
    { "kr": "깎다", "mean": "削る、そぐ" },
    { "kr": "깜짝", "mean": "はっと（驚く）" },
    { "kr": "깨끗하다", "mean": "清潔だ、きれいだ" },
    { "kr": "깨다", "mean": "割る、壊す" },
    { "kr": "깨지다", "mean": "割れる、壊れる" },
    { "kr": "꺼내다", "mean": "取り出す" },
    { "kr": "껌", "mean": "ガム" },
    { "kr": "꼭", "mean": "必ず、絶対に" },
    { "kr": "꽃", "mean": "花" },
    { "kr": "꽃집", "mean": "花屋" },
    { "kr": "꾸다", "mean": "（夢を）見る、夢見る" },
    { "kr": "꿈", "mean": "夢" },
    { "kr": "끄다", "mean": "消す、止める" },
    { "kr": "끓이다", "mean": "沸かす、煮る" },
    { "kr": "끝", "mean": "終わり" },
    { "kr": "끝나다", "mean": "終わる" },
    { "kr": "끝내다", "mean": "終える、済ませる" },
    { "kr": "끼다", "mean": "霧が立ち込める、差し込む" }
]
