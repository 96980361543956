import Progress from 'components/bar/Progress';
import ArrowButton from 'components/button/ArrowButton';
import Button from 'components/button/Button';
import { list } from 'data/hiragana';
import { hanguelResult } from 'data/result';
import Answer from 'pages/topik/Answer';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { DataType } from 'types/item';

import { getRandomValuesFromArray, shuffleTwoArrays, getRandomValueFromArray } from 'utils/array';
import { getPointPerGrade } from 'utils/number';

const Question = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [showOption, setShowOption] = useState<boolean>(false);
  const [qlist, setQlist] = useState<{ q: DataType; a: DataType[] }[]>([]);
  const [userAnswer, setUserAnswer] = useState<
    {
      idx: number;
      answer: boolean;
    }[]
  >([]);
  const [reset, setReset] = useState<boolean>(false);

  useEffect(() => {
    let result = [];

    let except = list;
    for (let i = 0; i < 10; i++) {
      let target = getRandomValueFromArray(except);
      except = except.filter((ele) => ele !== target);

      result.push({
        q: target,
        a: shuffleTwoArrays(getRandomValuesFromArray(except, 3), [target]),
      });
    }
    setQlist(result);
  }, [reset]);

  const handleClickAnswer = (ko: string, step: number, idx: number) => () => {
    let clone = [...userAnswer];
    clone[step] = { idx: idx, answer: ko === qlist[step]?.q.ko };
    setUserAnswer(clone);
    if (step === 9) {
      return;
    }
  };

  const handleClickPrev = () => {
    setStep(step - 1);
  };
  const handleClickNext = () => {
    setStep(step + 1);
  };
  const handleClickShowResult = () => {
    setShowOption(!showOption);
  };

  const handleReset = () => {
    setStep(0);
    setReset(!reset);
    setShowOption(false);
    setUserAnswer([]);
  };

  return (
    <div className="pt-10 flex-center flex-col">
      {!showOption ? (
        <>
          <Progress total={10} current={step} />
          <div className="mb-6 rounded-full text-white bg-pr2 w-[30px] h-[30px] line-normal font-semibold flex-center">{step + 1}</div>
          <div>
            <div className="flex-center">
              <h2 className="text-[30px] font-semibold h-20 flex-center"> {qlist[step]?.q.ko}</h2>
            </div>
            <div className="flex gap-[10px] justify-center mt-6">
              {qlist[step]?.a.map((ele, idx) => (
                <button
                  onClick={handleClickAnswer(ele.ko, step, idx)}
                  className={`jp-font text-[18px] rounded-[100px] inline-block w-[70px] h-[50px] text-center font-bold ${idx === userAnswer[step]?.idx ? 'bg-pr2 text-white' : 'text-black bg-pr5'
                    }`}
                  key={uuid()}
                >
                  {ele.jp}
                </button>
              ))}
            </div>
            <div className="flex gap-5 justify-between mt-[60px]">
              <ArrowButton onClick={handleClickPrev} disabled={step === 0} way="left" />
              {step === 9 ? (
                <button
                  className={`w-[150px] rounded-[20px] border-pr4 border-[1px] text-pr2 h-[70px] text-[20px] font-semibold flex-center ${!userAnswer[step] ? 'bg-gray2' : 'bg-pr3'
                    }`}
                  disabled={!userAnswer[step]}
                  onClick={handleClickShowResult}
                >
                  提出
                </button>
              ) : (
                <ArrowButton onClick={handleClickNext} disabled={!(userAnswer[step] && 9 !== step)} way="right" />
              )}
            </div>
          </div>
        </>
      ) : (
        <Answer
          title={`10個のうち ${userAnswer.filter((ele) => ele.answer).length}を当てた!`}
          desc={hanguelResult[getPointPerGrade(userAnswer.filter((ele) => ele.answer).length, 10)]}
          onReset={handleReset}
          onSelectOther={() => navigate(-1)}
          selectOtherText="後ろへ"
        />
      )}
    </div>
  );
};

export default Question;
