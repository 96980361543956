export const word_2_7_1 = [
    { "kr": "사건", "mean": "事件" },
    { "kr": "사고방식", "mean": "考え方" },
    { "kr": "사교적", "mean": "社交的" },
    { "kr": "사귀다", "mean": "付き合う、交わる" },
    { "kr": "사라지다", "mean": "消える" },
    { "kr": "사로잡다", "mean": "捕える" },
    { "kr": "사막", "mean": "砂漠" },
    { "kr": "사망", "mean": "死亡" },
    { "kr": "사망자", "mean": "死亡者、死者" },
    { "kr": "사모님", "mean": "奥様" },
    { "kr": "사무소", "mean": "事務所" },
    { "kr": "사생활", "mean": "私生活、プライベート" },
    { "kr": "사슴", "mean": "鹿" },
    { "kr": "사실", "mean": "事実" },
    { "kr": "사업", "mean": "事業" },
    { "kr": "사용료", "mean": "使用料" },
    { "kr": "사용법", "mean": "使用法" },
    { "kr": "사우나", "mean": "サウナ" },
    { "kr": "사은품", "mean": "景品、おまけ" },
    { "kr": "사이버", "mean": "サイバー" },
    { "kr": "사이좋다", "mean": "仲が良い" },
    { "kr": "사이트", "mean": "サイト" },
    { "kr": "사정", "mean": "事情" },
    { "kr": "사진관", "mean": "写真館" },
    { "kr": "사진첩", "mean": "写真アルバム" },
    { "kr": "사춘기", "mean": "思春期" },
    { "kr": "사투리", "mean": "訛り、方言" },
    { "kr": "사표", "mean": "辞表" },
    { "kr": "사회", "mean": "社会" },
    { "kr": "사회생활", "mean": "社会生活" },
    { "kr": "사회자", "mean": "司会者" },
    { "kr": "사회적", "mean": "社会的" },
    { "kr": "산꼭대기", "mean": "山頂" },
    { "kr": "산더미", "mean": "山積み、山盛り" },
    { "kr": "산부인과", "mean": "産婦人科" },
    { "kr": "산불", "mean": "山火事" },
    { "kr": "산업", "mean": "産業" },
    { "kr": "산책로", "mean": "散策路" },
    { "kr": "살리다", "mean": "生かす" },
    { "kr": "살림", "mean": "暮らし" },
    { "kr": "살아가다", "mean": "生きていく" },
    { "kr": "살아나다", "mean": "助かる" },
    { "kr": "살아남다", "mean": "生き延びる" },
    { "kr": "살아오다", "mean": "生きてくる" },
    { "kr": "살인", "mean": "殺人" },
    { "kr": "살짝", "mean": "こっそり、そっと、静かに" },
    { "kr": "살찌다", "mean": "太る、肉が付く" },
    { "kr": "살펴보다", "mean": "探る、観察する、注意してみる" },
    { "kr": "살피다", "mean": "調べる、探る" },
    { "kr": "삶", "mean": "暮し、人生、生" },
    { "kr": "삶다", "mean": "ゆでる" },
    { "kr": "삼겹살", "mean": "サムギョプサル" },
    { "kr": "삼일절", "mean": "3.1節" },
    { "kr": "삼키다", "mean": "飲み込む" },
    { "kr": "상", "mean": "相" },
    { "kr": "상가", "mean": "商店街" },
    { "kr": "상관없다", "mean": "気にしない" },
    { "kr": "상관없이", "mean": "関係なく" },
    { "kr": "상금", "mean": "賞金" },
    { "kr": "상담", "mean": "相談" },
    { "kr": "상담실", "mean": "相談室" },
    { "kr": "상담원", "mean": "エージェント、相談員" },
    { "kr": "상담자", "mean": "相談者" },
    { "kr": "상당히", "mean": "かなり" },
    { "kr": "상대", "mean": "相手" },
    { "kr": "상대방", "mean": "相手" },
    { "kr": "상대편", "mean": "相手方" },
    { "kr": "상상", "mean": "想像" },
    { "kr": "상상력", "mean": "想像力" },
    { "kr": "상승", "mean": "上昇" },
    { "kr": "상식", "mean": "常識" },
    { "kr": "상업", "mean": "商業" },
    { "kr": "상업적", "mean": "商業的" },
    { "kr": "상인", "mean": "商人" },
    { "kr": "상점", "mean": "商店" },
    { "kr": "상징", "mean": "象徴" },
    { "kr": "상쾌하다", "mean": "さわやかだ、爽快だ" },
    { "kr": "상태", "mean": "状態" },
    { "kr": "상표", "mean": "商標、ラベル" },
    { "kr": "상품권", "mean": "商品券" },
    { "kr": "상하다", "mean": "傷む、（食べ物などが）腐る" },
    { "kr": "상황", "mean": "状況" },
    { "kr": "새다", "mean": "夜が明ける、夜明かしをする" },
    { "kr": "새롭다", "mean": "新しい" },
    { "kr": "새어머니", "mean": "継母" },
    { "kr": "새우다", "mean": "夜を明かす" },
    { "kr": "새치기", "mean": "割り込み" },
    { "kr": "새해", "mean": "新年" },
    { "kr": "색다르다", "mean": "風変りだ" },
    { "kr": "색상", "mean": "色相" },
    { "kr": "생각나다", "mean": "思い出す" },
    { "kr": "생강차", "mean": "生姜茶" },
    { "kr": "생겨나다", "mean": "生じる" },
    { "kr": "생김새", "mean": "見かけ" },
    { "kr": "생략", "mean": "省略" },
    { "kr": "생머리", "mean": "ストレート" },
    { "kr": "생명", "mean": "命" },
    { "kr": "생산", "mean": "生産" },
    { "kr": "생생하다", "mean": "新鮮だ、生々しい" },
    { "kr": "생중계", "mean": "生放送" },
    { "kr": "생필품", "mean": "生活必需品" }
]

export const word_2_7_2 = [
    { "kr": "생활비", "mean": "生活費" },
    { "kr": "생활수준", "mean": "生活水準" },
    { "kr": "서너", "mean": "三・四" },
    { "kr": "서두르다", "mean": "急ぐ、焦る" },
    { "kr": "서랍", "mean": "引き出し" },
    { "kr": "서론", "mean": "序論" },
    { "kr": "서명", "mean": "署名" },
    { "kr": "서부", "mean": "西部" },
    { "kr": "서빙", "mean": "サービング" },
    { "kr": "서서히", "mean": "徐々に" },
    { "kr": "서운하다", "mean": "名残惜しい" },
    { "kr": "서투르다", "mean": "下手だ" },
    { "kr": "석사", "mean": "修士" },
    { "kr": "석식", "mean": "夕食" },
    { "kr": "석유", "mean": "石油" },
    { "kr": "석탄", "mean": "石炭" },
    { "kr": "섞다", "mean": "混ぜる" },
    { "kr": "선", "mean": "線" },
    { "kr": "선글라스", "mean": "サングラス" },
    { "kr": "선선하다", "mean": "涼しい" },
    { "kr": "선진국", "mean": "先進国" },
    { "kr": "선착순", "mean": "先着順" },
    { "kr": "설", "mean": "説" },
    { "kr": "설득", "mean": "説得" },
    { "kr": "설마", "mean": "まさか" },
    { "kr": "설명서", "mean": "マニュアル、説明書" },
    { "kr": "설문조사", "mean": "アンケート" },
    { "kr": "설문지", "mean": "アンケート" },
    { "kr": "설사", "mean": "例え、下痢" },
    { "kr": "섭섭하다", "mean": "名残惜しい、寂しい" },
    { "kr": "성공", "mean": "成功" },
    { "kr": "성능", "mean": "性能" },
    { "kr": "성당", "mean": "聖堂" },
    { "kr": "성명", "mean": "声明" },
    { "kr": "성별", "mean": "性別" },
    { "kr": "성실", "mean": "誠実" },
    { "kr": "성인", "mean": "成人" },
    { "kr": "성인병", "mean": "成人病" },
    { "kr": "성적", "mean": "成績" },
    { "kr": "성적표", "mean": "成績表" },
    { "kr": "성형", "mean": "整形" },
    { "kr": "세계적", "mean": "世界的" },
    { "kr": "세금", "mean": "税金" },
    { "kr": "세기", "mean": "世紀" },
    { "kr": "세다", "mean": "数える" },
    { "kr": "세대", "mean": "世代" },
    { "kr": "세련", "mean": "洗練" },
    { "kr": "세면대", "mean": "洗面台" },
    { "kr": "세면도구", "mean": "洗面道具" },
    { "kr": "세미나", "mean": "セミナー" },
    { "kr": "세배", "mean": "トリプル、3倍" },
    { "kr": "세뱃돈", "mean": "お年玉" },
    { "kr": "세상", "mean": "世界" },
    { "kr": "세월", "mean": "歳月" },
    { "kr": "세제", "mean": "洗剤" },
    { "kr": "세탁물", "mean": "洗濯物" },
    { "kr": "셔츠", "mean": "シャツ" },
    { "kr": "셔틀버스", "mean": "シャトルバス" },
    { "kr": "소감", "mean": "所感、感想" },
    { "kr": "소개팅", "mean": "合コン" },
    { "kr": "소규모", "mean": "小規模" },
    { "kr": "소극장", "mean": "小劇場" },
    { "kr": "소극적", "mean": "消極的" },
    { "kr": "소나기", "mean": "夕立" },
    { "kr": "소년", "mean": "少年" },
    { "kr": "소득", "mean": "所得" },
    { "kr": "소망", "mean": "希望" },
    { "kr": "소매", "mean": "袖" },
    { "kr": "소매치기", "mean": "すり" },
    { "kr": "소문", "mean": "うわさ、評判" },
    { "kr": "소방관", "mean": "消防官" },
    { "kr": "소방서", "mean": "消防署" },
    { "kr": "소방차", "mean": "消防車" },
    { "kr": "소비", "mean": "消費" },
    { "kr": "소비자", "mean": "消費者" },
    { "kr": "소설책", "mean": "小説" },
    { "kr": "소수", "mean": "少数" },
    { "kr": "소스", "mean": "ソース" },
    { "kr": "소심하다", "mean": "小心である" },
    { "kr": "소용", "mean": "使い道" },
    { "kr": "소용없다", "mean": "無駄だ、役に立たない" },
    { "kr": "소원", "mean": "念願、願い" },
    { "kr": "소음", "mean": "騒音" },
    { "kr": "소주", "mean": "焼酎" },
    { "kr": "소중하다", "mean": "大事だ" },
    { "kr": "소중히", "mean": "大切に" },
    { "kr": "소화", "mean": "消化" },
    { "kr": "속다", "mean": "だまされる" },
    { "kr": "속담", "mean": "ことわざ" },
    { "kr": "속도", "mean": "速度" },
    { "kr": "속도위반", "mean": "速度違反" },
    { "kr": "속력", "mean": "スピード、速力" },
    { "kr": "속상하다", "mean": "しゃくにさわる、気に障る" },
    { "kr": "속옷", "mean": "下着" },
    { "kr": "속이다", "mean": "あざむく、だます" },
    { "kr": "손바닥", "mean": "手のひら" },
    { "kr": "손발", "mean": "手足" },
    { "kr": "손쉽다", "mean": "たやすい" },
    { "kr": "손자", "mean": "男の孫" },
    { "kr": "손잡이", "mean": "手すり、取っ手" },
    { "kr": "손질", "mean": "手入れ" }
]

export const word_2_7_3 = [
    { "kr": "솔직하다", "mean": "率直である" },
    { "kr": "솔직히", "mean": "率直に言って" },
    { "kr": "솜씨", "mean": "手並み、手際" },
    { "kr": "송금", "mean": "送金" },
    { "kr": "쇠", "mean": "鉄、金物、錠" },
    { "kr": "쇠고기", "mean": "牛肉" },
    { "kr": "쇼", "mean": "ショー" },
    { "kr": "쇼핑몰", "mean": "ショッピングモール" },
    { "kr": "쇼핑백", "mean": "ショッピングバッグ" },
    { "kr": "쇼핑센터", "mean": "ショッピングセンター" },
    { "kr": "수거", "mean": "回収" },
    { "kr": "수고", "mean": "苦労" },
    { "kr": "수단", "mean": "手段" },
    { "kr": "수도꼭지", "mean": "水道の蛇口" },
    { "kr": "수량", "mean": "数量" },
    { "kr": "수료식", "mean": "修了式" },
    { "kr": "수리", "mean": "受理、修理" },
    { "kr": "수리비", "mean": "修理費" },
    { "kr": "수면", "mean": "睡眠" },
    { "kr": "수면제", "mean": "睡眠薬" },
    { "kr": "수명", "mean": "寿命" },
    { "kr": "수산물", "mean": "水産物" },
    { "kr": "수상", "mean": "首相" },
    { "kr": "수선", "mean": "修繕" },
    { "kr": "수선하다", "mean": "修理する、修繕する" },
    { "kr": "수수료", "mean": "手数料" },
    { "kr": "수입", "mean": "収入、輸入" },
    { "kr": "수정", "mean": "修正、水晶" },
    { "kr": "수준", "mean": "レベル" },
    { "kr": "수집", "mean": "収集、コレクション" },
    { "kr": "수출", "mean": "輸出" },
    { "kr": "수표", "mean": "小切手" },
    { "kr": "수학여행", "mean": "修学旅行" },
    { "kr": "숙녀복", "mean": "婦人服" },
    { "kr": "숙박", "mean": "宿泊" },
    { "kr": "숙박비", "mean": "宿泊費" },
    { "kr": "숙소", "mean": "宿" },
    { "kr": "숙이다", "mean": "下げる、うなだれる" },
    { "kr": "순간", "mean": "瞬間" },
    { "kr": "순두부찌개", "mean": "スンドゥブチゲ" },
    { "kr": "술자리", "mean": "飲み会" },
    { "kr": "술잔", "mean": "盃" },
    { "kr": "술집", "mean": "バー" },
    { "kr": "숨기다", "mean": "隠す" },
    { "kr": "숨다", "mean": "隠れる" },
    { "kr": "숫자", "mean": "数字" },
    { "kr": "숲", "mean": "森、林" },
    { "kr": "스님", "mean": "お坊さん" },
    { "kr": "스스로", "mean": "自ら、自分で、自然と" },
    { "kr": "스캐너", "mean": "スキャナー" },
    { "kr": "스타일", "mean": "スタイル" },
    { "kr": "스티커", "mean": "シール、ステッカー" },
    { "kr": "스프", "mean": "スープ" },
    { "kr": "스피커", "mean": "スピーカー" },
    { "kr": "슬리퍼", "mean": "スリッパ" },
    { "kr": "슬픔", "mean": "悲しみ" },
    { "kr": "습기", "mean": "湿気" },
    { "kr": "습도", "mean": "湿度" },
    { "kr": "승강기", "mean": "エレベーター" },
    { "kr": "승객", "mean": "乗客" },
    { "kr": "승리", "mean": "勝利" },
    { "kr": "승용차", "mean": "乗用車" },
    { "kr": "승진", "mean": "昇進" },
    { "kr": "승차", "mean": "乗車" },
    { "kr": "시각", "mean": "視覚" },
    { "kr": "시대", "mean": "時代" },
    { "kr": "시도", "mean": "試み" },
    { "kr": "시력", "mean": "視力" },
    { "kr": "시리다", "mean": "ひりひりする、しびれるほど冷たい" },
    { "kr": "시부모님", "mean": "義理の両親" },
    { "kr": "시스템", "mean": "システム" },
    { "kr": "시아버지", "mean": "舅、夫の父" },
    { "kr": "시어머니", "mean": "姑、夫の母" },
    { "kr": "시외버스", "mean": "市外バス" },
    { "kr": "시인", "mean": "詩人" },
    { "kr": "시집", "mean": "詩集、嫁入り先" },
    { "kr": "시집가다", "mean": "嫁に行く、（女性が）結婚する" },
    { "kr": "시청각실", "mean": "視聴覚室" },
    { "kr": "시청자", "mean": "視聴者" },
    { "kr": "시합", "mean": "試合" },
    { "kr": "시험공부", "mean": "試験勉強" },
    { "kr": "식", "mean": "式、やり方、方法" },
    { "kr": "식구", "mean": "家族" },
    { "kr": "식료품", "mean": "食料品" },
    { "kr": "식물", "mean": "植物" },
    { "kr": "식물원", "mean": "植物園" },
    { "kr": "식비", "mean": "食費" },
    { "kr": "식생활", "mean": "食生活" },
    { "kr": "식습관", "mean": "食習慣" },
    { "kr": "식욕", "mean": "食欲" },
    { "kr": "식용유", "mean": "食用油" },
    { "kr": "식초", "mean": "酢" },
    { "kr": "식품", "mean": "食品" },
    { "kr": "식후", "mean": "食後" },
    { "kr": "신고", "mean": "通報" },
    { "kr": "신기다", "mean": "履かせる" },
    { "kr": "신기술", "mean": "新技術" },
    { "kr": "신기하다", "mean": "不思議だ、珍しい" },
    { "kr": "신나다", "mean": "浮かれる、興がのる" },
    { "kr": "신도시", "mean": "新都市" },
    { "kr": "신랑", "mean": "新郎" }
]

export const word_2_7_4 = [
    { "kr": "신랑감", "mean": "新郎候補" },
    { "kr": "신문사", "mean": "新聞社" },
    { "kr": "신문지", "mean": "新聞紙" },
    { "kr": "신부", "mean": "神父、新婦" },
    { "kr": "신분증", "mean": "身分証明書" },
    { "kr": "신상품", "mean": "新商品" },
    { "kr": "신선하다", "mean": "新鮮だ" },
    { "kr": "신세대", "mean": "新世代" },
    { "kr": "신용", "mean": "信用" },
    { "kr": "신입생", "mean": "新入生" },
    { "kr": "신제품", "mean": "新製品" },
    { "kr": "신청자", "mean": "申請者" },
    { "kr": "신체", "mean": "身体、体" },
    { "kr": "신형", "mean": "新型" },
    { "kr": "신호", "mean": "信号" },
    { "kr": "신혼", "mean": "新婚" },
    { "kr": "신혼부부", "mean": "新婚夫婦" },
    { "kr": "신혼여행", "mean": "新婚旅行" },
    { "kr": "싣다", "mean": "乗せる、積む" },
    { "kr": "실", "mean": "糸" },
    { "kr": "실내", "mean": "室内" },
    { "kr": "실력", "mean": "実力" },
    { "kr": "실망", "mean": "失望" },
    { "kr": "실시", "mean": "実施" },
    { "kr": "실용적", "mean": "実用的" },
    { "kr": "실은", "mean": "実は" },
    { "kr": "실제", "mean": "実際" },
    { "kr": "실제로", "mean": "実際に" },
    { "kr": "실종", "mean": "失踪" },
    { "kr": "실종자", "mean": "失踪者、行方不明者" },
    { "kr": "실천", "mean": "実践" },
    { "kr": "실컷", "mean": "思う存分、飽きるほど" },
    { "kr": "실크", "mean": "シルク" },
    { "kr": "실패", "mean": "失敗" },
    { "kr": "실험", "mean": "実験" },
    { "kr": "실험실", "mean": "実験室" },
    { "kr": "싫증", "mean": "飽き" },
    { "kr": "심각하다", "mean": "深刻だ" },
    { "kr": "심다", "mean": "植える" },
    { "kr": "심리학", "mean": "心理学" },
    { "kr": "심리학자", "mean": "心理学者" },
    { "kr": "심부름", "mean": "お使い" },
    { "kr": "심심하다", "mean": "退屈だ" },
    { "kr": "심장", "mean": "心臓" },
    { "kr": "심장병", "mean": "心臓病" },
    { "kr": "싱싱하다", "mean": "みずみずしい、生き生きしている" },
    { "kr": "싱크대", "mean": "シンク台" },
    { "kr": "싸구려", "mean": "安物" },
    { "kr": "싸움", "mean": "喧嘩" },
    { "kr": "쌀쌀하다", "mean": "肌寒い" },
    { "kr": "쌍둥이", "mean": "双子" },
    { "kr": "쌓다", "mean": "積む、築く" },
    { "kr": "쏘다", "mean": "射る、撃つ" },
    { "kr": "쏟다", "mean": "こぼす" },
    { "kr": "쏟아지다", "mean": "降り注ぐ、こぼれる、あふれる" },
    { "kr": "쑥스럽다", "mean": "照れくさい" },
    { "kr": "쓰러지다", "mean": "倒れる、床につく、滅びる" },
    { "kr": "쓰레기통", "mean": "ごみ箱" },
    { "kr": "쓸데없다", "mean": "無用だ、使い道がない" },
    { "kr": "쓸데없이", "mean": "やたら" },
    { "kr": "쓸쓸하다", "mean": "わびしい" },
    { "kr": "씌우다", "mean": "かぶせる、覆う" },
    { "kr": "씨름", "mean": "シルム（朝鮮の相撲）" },
    { "kr": "씩씩하다", "mean": "りりしい、たくましい" }
]
