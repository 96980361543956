export const word_2_13_1 = [
    { "kr": "파다", "mean": "掘る、彫る、掘り下げる、えぐる" },
    { "kr": "파마", "mean": "パーマ" },
    { "kr": "파일", "mean": "ファイル" },
    { "kr": "파전", "mean": "チヂミ" },
    { "kr": "판매", "mean": "販売" },
    { "kr": "판매자", "mean": "販売者" },
    { "kr": "팥빙수", "mean": "パッピンス(韓国風かき氷)" },
    { "kr": "패션쇼", "mean": "ファッションショー" },
    { "kr": "패스트푸드", "mean": "ファーストフード" },
    { "kr": "팩스", "mean": "ファックス" },
    { "kr": "팬", "mean": "ファン" },
    { "kr": "퍼지다", "mean": "広がる、行き渡る" },
    { "kr": "편리", "mean": "便利" },
    { "kr": "편식", "mean": "偏食" },
    { "kr": "편안하다", "mean": "楽だ" },
    { "kr": "편의점", "mean": "コンビニ、便宜店" },
    { "kr": "편히", "mean": "楽に" },
    { "kr": "펼치다", "mean": "広げる" },
    { "kr": "평가", "mean": "評価" },
    { "kr": "평균", "mean": "平均" },
    { "kr": "평등", "mean": "平等" },
    { "kr": "평범하다", "mean": "平凡だ" },
    { "kr": "평생", "mean": "一生" },
    { "kr": "평소", "mean": "普段、日頃" },
    { "kr": "평화", "mean": "平和" },
    { "kr": "포근하다", "mean": "暖かい、穏やかだ" },
    { "kr": "포기", "mean": "放棄" },
    { "kr": "포도주", "mean": "ワイン、葡萄酒" },
    { "kr": "포인트", "mean": "ポイント" },
    { "kr": "포장지", "mean": "包装紙" },
    { "kr": "폭발", "mean": "爆発" },
    { "kr": "폭설", "mean": "どか雪、爆雪" },
    { "kr": "폭식", "mean": "暴食、やけ食い" },
    { "kr": "폭우", "mean": "暴雨、豪雨" },
    { "kr": "폭포", "mean": "滝" },
    { "kr": "표시", "mean": "標示" },
    { "kr": "표정", "mean": "表情" },
    { "kr": "표지판", "mean": "表示板" },
    { "kr": "푸르다", "mean": "青い" },
    { "kr": "풀리다", "mean": "解ける、ほぐれる、和らぐ" },
    { "kr": "풀이", "mean": "解釈" },
    { "kr": "품질", "mean": "品質" },
    { "kr": "풍경", "mean": "風景" },
    { "kr": "풍부하다", "mean": "豊富だ、豊かだ" },
    { "kr": "풍선", "mean": "風船" },
    { "kr": "프라이팬", "mean": "フライパン" },
    { "kr": "프로그래머", "mean": "プログラマー" },
    { "kr": "프린터", "mean": "プリンター" },
    { "kr": "플라스틱", "mean": "プラスティック" },
    { "kr": "플러그", "mean": "プラグ" },
    { "kr": "피로", "mean": "疲労" },
    { "kr": "피부", "mean": "皮膚、肌" },
    { "kr": "피부과", "mean": "皮膚科" },
    { "kr": "피부병", "mean": "皮膚病" },
    { "kr": "피서", "mean": "避暑" },
    { "kr": "피아ニ스트", "mean": "ピアニスト" },
    { "kr": "피하다", "mean": "避ける" },
    { "kr": "피해", "mean": "被害" },
    { "kr": "피해者", "mean": "피해者" },
    { "kr": "필수", "mean": "必須" },
    { "kr": "필수品", "mean": "必需品" },
    { "kr": "필요성", "mean": "必要性" },
    { "kr": "핑계", "mean": "言い訳" }
]
