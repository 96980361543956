export const getPointPerGrade = (point: number, total: number): 'A' | 'B' | 'C' | 'D' => {
    // 퍼센트 계산
    const percentage = (point / total) * 100;

    // 등급 구간에 따른 반환 값 설정
    if (percentage === 100) {
        return 'A';
    } else if (percentage >= 67) {
        return 'B';
    } else if (percentage >= 34) {
        return 'C';
    } else {
        return 'D';
    }
};