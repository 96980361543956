import React, { useState } from 'react'
import logo from 'assets/d-icon/logohigh.png'

type OXCardProps = {
    title: string;
    quiz: string;
    quiz2: string;
    answer: 'O' | 'X';
    onClickO?: () => void;
    onClickX?: () => void;
}

const OXCard = ({ title, quiz, quiz2, answer, onClickO, onClickX }: OXCardProps) => {
    const [result, setResult] = useState<boolean>(false)

    const handleClickO = () => {
        onClickO && onClickO()
        setResult(true)
    }

    const handleClickX = () => {
        onClickX && onClickX()
        setResult(true)
    }

    return (
        <div className='w-[320px] relative h-[380px] bg-white rounded-[20px] px-5 pb-20 pt-10'>
            <img src={logo} className='absolute w-[60px] top-[-30px] rounded-full left-[120px]' />

            <h2 className='mb-5 text-[18px] font-semibold text-center'>OX {title}</h2>
            <div className='bg-pr5 w-[280px] h-[220px] px-5 pb-5 pt-[28px] text-center'>
                <p className='text-[22px] font-semibold '>{quiz}</p>
                <p className='text-[22px] font-semibold text-pr2'>{quiz2}</p>
                <div className='flex gap-5 mt-7  text-[40px] font-semibold'>
                    <button className={`w-[110px] h-20 rounded-[15px] ${(result && answer !== 'O') ? 'bg-gray2 text-gray' : 'bg-pr3 text-pr2 border-[1px] border-pr4 '
                        }`}
                        onClick={answer === 'O' ? handleClickO : handleClickX}
                    >O</button>
                    <button className={`w-[110px] h-20 rounded-[15px]  ${(result && answer !== 'X') ? 'bg-gray2 text-gray' : 'bg-pr3 text-pr2 border-pr4 border-[1px]'
                        }`}
                        onClick={answer === 'X' ? handleClickO : handleClickX}
                    >X</button>
                </div>
            </div>
        </div>
    )
}

export default OXCard