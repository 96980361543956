import React, { useState } from 'react';

import Landing1 from 'pages/landing/Landing1';
import Landing2 from 'pages/landing/Landing2';
import Landing3 from 'pages/landing/Landing3';
import Landing4 from 'pages/landing/Landing4';
import xl from 'assets/imgs/background/xl.png'
import Landing5 from 'pages/landing/Landing5';
import Landing6 from 'pages/landing/Landing6';
import Footer from 'components/layouts/Footer';

function App() {

  return (
    <div className='flex-center flex-col bg-bg1'>
      <Landing1 />
      <div
        className='bg-illust-contain-repeat min-h-[500px] flex-center flex-col bg-bg4'
        style={{ backgroundImage: `url(${xl})`, }}
      >
        <Landing2 />
      </div>
      <div className='w-full flex-center flex-col bg-bg4 pb-15'>
        <Landing3 />
        <Landing4 />
      </div>
      <div
        className='bg-illust-contain-repeat min-h-[500px] flex-center flex-col bg-bg4'
        style={{ backgroundImage: `url(${xl})`, }}
      >
        <Landing5 />
      </div>
      <Landing6 />
      <Footer />
    </div>
  );
}

export default App;
