import { TopikReadingType } from "types/topik";

export const Reading2Quizlist4: TopikReadingType[] = [{
    q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '염색하기 편하게',
            isAnswer: false
        },
        {
            text: '환자와 구분이 되도록',
            isAnswer: false
        },
        {
            text: '오염이 쉽게 눈에 띄도록',
            isAnswer: true
        },
        {
            text: '밝은 이미지를 줄 수 있게',
            isAnswer: false
        },
    ],
    question:
        `의사의 모습을 생각하면 우리는 보통 흰색 가운을 떠올리게 된다.
그런데 의사 가운이 처음부터 흰색이었던 것은 아니다.
중세 시대에는 성직자가 의사를 겸하는 경우가 많아 성직자용 검은색 가운을 입고 진료를 했다.
그런데 검은색 옷은 세균으로 오염된 얼룩이 잘 보이지 않았다.
이후 세균 감염의 위험성에 대한 인식이 높아지면서(      ) 가운의 색이 흰색으로 바뀌었다.`,
},
{
    q: '다음을 읽고 글의 내용과 같은 것을 고르십시오.',
    answer: [
        {
            text: '살리초는 해충에 취약하다는 단점이 있다.',
            isAnswer: false
        },
        {
            text: '살리초의 열매는 크고 영양 성분이 풍부하다.',
            isAnswer: false
        },
        {
            text: '살리초는 생육 기간이 길어 관심을 받지 못하고 있다.',
            isAnswer: false
        },
        {
            text: '살리초의 잎에는 혈당을 떨어뜨리는 성분이 들어 있다.',
            isAnswer: true
        },
    ],
    question:
        `당뇨 환자들을 위해 고추 품종인 '살리초'가 개발되었다.
고추는 보통 열매를 얻기 위해 재배하지만 살리초는 열매가 없고 잎을 먹는 품종이다.
일반 고추에 비해 혈당을 낮추는 성분이 10배 이상 많이 함유돼 있어 당뇨병, 비만증 등 성인병의 예방과 치료에 이용될 수 있다.
또한 살리초에는 칼슘, 비타민 등 각종 영양 성분이 풍부하다.
병해충에도 강하고 생육 기간도 짧아서 농가의 고소득 작물로 주목받고 있다.`,
},
{
    q: '다음을 읽고 글의 내용과 같은 것을 고르십시오.',
    answer: [
        {
            text: '이 책은 각 분야의 전문가가 모여 만든 책이다.',
            isAnswer: false
        },
        {
            text: '서유구는 집필하면서 참고한 문헌을 이 책에 기록해 놓았다.',
            isAnswer: true
        },
        {
            text: '서유구는 객관적인 책을 쓰기 위해 자신의 의견은 배제했다.',
            isAnswer: false
        },
        {
            text: '이 책은 내용이 특정 분야에 한정되어 서지학적 가치는 크지 않다.',
            isAnswer: false
        },
    ],
    question:
        `『임원경제지』는 조선 시대 서유구가 쓴 백과사전이다.
선비가 시골에서 살아가는 데 필요한 지식을 탐색한 것으로 농업, 건축, 의학 등 16개 분야를 정리했다.
서유구는 이 책을 쓸 때 조선의 생활상을 직접 관찰하고 여러 서적들을 참고했으며 거기에 자신의 논평을 첨부했다.
이 과정에서 인용한 책들을 밝혀 놓아 서지학적 가치도 크다.
총 52권의 책을 편찬했는데 개인이 완성했다고 보기 어려울 만큼 방대하고 전문적인 지식이 담겨 있다.`
},
{
    q: '다음을 읽고 글의 내용과 같은 것을 고르십시오.',
    answer: [
        {
            text: '다양한 동물들이 유칼립투스 잎을 차지하기 위해 경쟁한다.',
            isAnswer: false
        },
        {
            text: '어미 코알라는 유칼립투스의 독성을 분해하는 효소를 가지고 있다.',
            isAnswer: true
        },
        {
            text: '코알라는 태어난 직후부터 많은 양의 유칼립투스 나뭇잎을 먹는다.',
            isAnswer: false
        },
        {
            text: '새끼 코알라는 유칼립투스의 독성 때문에 어미의 배설물을 먹지 않는다.',
            isAnswer: false
        },
    ],
    question:
        `코알라는 유칼립투스라는 나뭇잎을 먹고 산다.
이 나뭇잎은 독성이 강해서 일반적인 동물들은 먹을 수 없다.
하지만 코알라는 유칼립투스의 독을 해독하는 효소가 있어서 다른 동물들과 경쟁할 필요 없이 유칼립투스잎을 충분히 먹을 수 있다.
다만 새끼 코알라는 독성을 분해하는 효소가 없어서 유칼립투스 잎 대신 어미의 배설물을 먹는다.
이를 먹다 보면 새끼 코알라의 몸속에도 유칼립투스의 독을 해독하는 효소가 생기게 된다.`
},
{
    q: '다음을 읽고 글의 주제로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '태양계에는 아직 발견되지 않은 소행성이 많이 존재한다.',
            isAnswer: false
        },
        {
            text: '소행성에서 자원을 가져오는 것은 실현 가능성이 희박하다.',
            isAnswer: false
        },
        {
            text: '소행성 탐사를 성공시키기 위해 국제 협력을 확대해야 한다.',
            isAnswer: false
        },
        {
            text: '미래 자원 확보를 위해 소행성 탐사에 대한 노력을 기울여야 한다.',
            isAnswer: true
        },
    ],
    question:
        `태양계에 존재하는 수많은 소행성에는 천문학적인 가치를 지닌 광물이 있는 것으로 예상된다.
그래서 많은 나라들이 이에 관심을 가지고 있다.
실제로 최근 몇몇 국가에서는 소행성에 있는 광물 시료를 채취하는 데 성공하기도 했다.
하지만 대부분의 나라들에서는 소행성 탐사가 아직 논의 단계에 머무르고 있다.
미래 자원을 확보하기 위해서 적극적으로 소행성 탐사에 대한 투자와 기술 개발에 나설 필요가 있다.`,
},
{
    q: '다음을 읽고 글의 주제로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '불경기에는 비합리적인 소비로 인해 근로자들의 저축이 줄어든다.',
            isAnswer: false
        },
        {
            text: '경기가 침체하면 개인의 소득이 줄어들지 않도록 투자를 늘려야 한다.',
            isAnswer: false
        },
        {
            text: '경제 상황을 고려해서 생산과 고용을 적절히 조절하는 것이 중요하다.',
            isAnswer: false
        },
        {
            text: '경기 침체 시에는 저축이 국가 경제 전체에 부정적 영향을 줄 수 있다',
            isAnswer: true
        },
    ],
    question:
        `경기 침체에 빠지면 사람들은 소비보다는 저축을 늘리려고 한다.
그런데 저축이 언제나 긍정적인 것만은 아니다.
소비가 줄면 기업의 매출이 감소해서 생산과 고용이 줄 수밖에 없고 결국 근로자들의 소득도 줄어들게 된다.
그러면 미래가 불확실해진 사람들이 더욱 소비를 줄여 전체 경기가 다시 침체에 빠지는 악순환이 되풀이된다.
개인 차원에서는 저축이 합리적인 행동이지만 경제 전체적으로는 비합리적인 상황을 초래하게 되는 것이다.`,
},
{
    q: '다음을 읽고 글의 주제로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '국가별 상황에 따라 참치 쿼터제를 현실에 맞게 수정해야 한다.',
            isAnswer: true
        },
        {
            text: '참치의 무분별한 포획을 방지하기 위해 쿼터제를 도입해야 한다.',
            isAnswer: false
        },
        {
            text: '수온 상승으로부터 참치를 보호하기 위한 대책을 마련해야 한다.',
            isAnswer: false
        },
        {
            text: '참치의 유입량을 늘리기 위한 실질적인 연구가 이루어져야 한다.',
            isAnswer: false
        },
    ],
    question:
        `참치 쿼터제는 참치의 무분별한 포획을 막기 위해 어획량을 국가별로 정해 놓은 제도이다.
한국은 참치 어획량이 매우 적게 배정돼 있는데 이는 한국 연안에서는 참치가 거의 잡히지 않았기 때문이다.
최근 수온이 상승함에 따라 한국 연안에 유입되는 참치가 급격히 늘어나고 있지만 쿼터제 때문에 그물에 걸린 참치를 대부분 바다에 버리고 있다.
쿼터제의 필요성은 인정하지만 국가별 개체 수 증감을 반영한 융통성 있는 조정이 필요하다.`,
},
{
    q: '다음을 읽고 글의 주제로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '고객들의 무리한 요구 때문에 기술 혁신에 어려움을 겪게 된다.',
            isAnswer: false
        },
        {
            text: '혁신적인 기업은 고객들에게 신기술의 사용 방법을 잘 설명해 준다.',
            isAnswer: false
        },
        {
            text: '기술 혁신에 실패한 기업은 자연스럽게 고객들의 외면을 받게 된다.',
            isAnswer: false
        },
        {
            text: '기술 혁신은 고객이 쉽게 받아들일 수 있을 때 성공할 가능성이 높다.',
            isAnswer: true
        },
    ],
    question:
        `기업들은 기술 혁신을 통해 고객에게 편의와 혜택을 제공하려고 한다.
하지만 아무리 획기적인 혁신이라도 고객에게 낯설고 커다란 행동 변화를 요구한다면 외면당하기 쉽다.
예를 들어 초창기 전기 자동차는 전기로 오래 충전을 해야 하는 것이 낯설고 불편해서 큰 관심을 끌지 못했다.
반대로 전자 칠판과 전자 펜처럼 대단한 혁신은 아니지만 사용하는 방법이 고객에게 익숙하고 간단해서 환영받는 경우도 있다.`,
},
{
    q: `주어진 문장이 들어갈 곳으로 가장 알맞은 것을 고르십시오.
"그런데 심판이 아무리 위치 선정을 잘해도 필연적으로 선수의 몸에 가려서 볼 수 없는 사각지대가 생긴다."
`,

    answer: [
        {
            text: 'ㄱ',
            isAnswer: true
        },
        {
            text: 'ㄴ',
            isAnswer: false
        },
        {
            text: 'ㄷ',
            isAnswer: false
        },
        {
            text: 'ㄹ',
            isAnswer: false
        },
    ],
    question:
        `스포츠 경기를 진행할 때 득점이나 반칙 등의 판정은 심판에 의해서 이루어진다.
(  ㄱ  ) 이렇게 눈에 보이지 않는 곳에서 벌어진 상황에 대해서는 심판도 정확한 판단을 하기 어렵다.
(  ㄴ  ) 비디오 판독은 이런 스포츠 경기에서 초고속 카메라를 판정의 근거로 사용하는 기술이다.
(  ㄷ  ) 이처럼 비디오 판독을 활용하면 심판이 판단하기 어려운 부분을 객관적으로 확인할 수 있어 판정에 대한 신뢰도를 높일 수 있다.
(  ㄹ  )`,
},
{
    q: `주어진 문장이 들어갈 곳으로 가장 알맞은 것을 고르십시오.
"그 증거로 지중해 전역에서 발견되고 있는 소금 퇴적층을 들 수 있다."`,
    answer: [
        {
            text: 'ㄱ',
            isAnswer: false
        },
        {
            text: 'ㄴ',
            isAnswer: false
        },
        {
            text: 'ㄷ',
            isAnswer: true
        },
        {
            text: 'ㄹ',
            isAnswer: false
        },
    ],
    question:
        `유럽과 아시아, 아프리카 대륙으로 둘러싸인 바다를 지중해라고 한다.  (  ㄱ  )  오늘날 지중해 연안은 기후가 온화해서 살기 좋은 곳으로 손꼽힌다.
(  ㄴ  )  그런데 지중해는 오래전 사막이었던 적이 있었다.  (  ㄷ  )  이 소금 퇴적층은 바닷물이 증발되고 남은 소금이 쌓여 만들어진 것으로 지중해가 이전에는 사막이었음을 보여 준다.
(  ㄹ  )  사막이었던 지중해에 이후 큰 홍수가 발생하면서 다시 오늘날과 같은 바다가 되었다.`,
},
]