import React from 'react';
import foot from 'assets/d-icon/foot.svg';

type Props = {
  text: string;
};

const ContentButton = (props: Props) => {
  return (
    <button className="bg-pr3 rounded-full w-[100px] h-[100px] flex flex-col items-center justify-center border-[1px] border-pr4 border-solid link">
      <img src={foot} className="link-icon" />
      <p className="text-pr2 font-semibold whitespace-pre-wrap">{props.text}</p>
    </button>
  );
};

export default ContentButton;
