import { TopikListeningType } from "types/topik";
import t1_91_1 from 'assets/audio/t91_2/t2_91_11.mp3';
import t1_91_2 from 'assets/audio/t91_2/t2_91_12.mp3';
import t1_91_3 from 'assets/audio/t91_2/t2_91_13.mp3';
import t1_91_4 from 'assets/audio/t91_2/t2_91_14.mp3';
import t1_91_5 from 'assets/audio/t91_2/t2_91_15.mp3';
import t1_91_6 from 'assets/audio/t91_2/t2_91_16.mp3';
import t1_91_7 from 'assets/audio/t91_2/t2_91_17.mp3';
import t1_91_8 from 'assets/audio/t91_2/t2_91_18.mp3';
import t1_91_9 from 'assets/audio/t91_2/t2_91_19.mp3';
import t1_91_10 from 'assets/audio/t91_2/t2_91_20.mp3';

// 토픽 91 II 듣기 20
export const listening2Quizlist2: TopikListeningType[] = [
    {
        q: '다음을 듣고 여자가 이어서 할 행동으로 가장 알맞은 것을 고르십시오. (11~12)',
        audio: t1_91_1,
        answer: [
            {
                text: '물감을 찾아본다.',
                isAnswer: false
            },
            {
                text: '미술 연필을 고른다.',
                isAnswer: false
            },
            {
                text: '직원에게 물어본다.',
                isAnswer: true
            },
            {
                text: '남자에게 제품 이름을 말한다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 여자가 이어서 할 행동으로 가장 알맞은 것을 고르십시오. (11~12)',
        audio: t1_91_2,
        answer: [
            {
                text: '촬영팀에게 연락한다.',
                isAnswer: false
            },
            {
                text: '진행 순서를 설명한다.',
                isAnswer: false
            },
            {
                text: '공연 무대를 점검한다.',
                isAnswer: false
            },
            {
                text: '공연팀을 데리러 간다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_3,
        answer: [
            {
                text: '길이 예전에 비해 좁아졌다.',
                isAnswer: false
            },
            {
                text: '여자는 이곳에 처음 와 봤다.',
                isAnswer: false
            },
            {
                text: '남자는 인주서점에 간 적이 없다.',
                isAnswer: false
            },
            {
                text: '인주서점은 지금까지 같은 자리에 있다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_4,
        answer: [
            {
                text: '분실물은 구두 매장에 있었다.',
                isAnswer: true
            },
            {
                text: '오후 8시에 지갑이 발견되었다.',
                isAnswer: false
            },
            {
                text: '고객 센터는 구두 매장 옆에 있다.',
                isAnswer: false
            },
            {
                text: '지갑을 잃어버린 사람은 1층으로 가면 된다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_5,
        answer: [
            {
                text: '이 사고는 어젯밤에 발생했다.',
                isAnswer: false
            },
            {
                text: '이 사고로 인해 사람들이 크게 다쳤다.',
                isAnswer: false
            },
            {
                text: '경찰은 사고 원인에 대해 조사하고 있다.',
                isAnswer: true
            },
            {
                text: '낚싯배가 다른 배와 충돌하는 사고가 났다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_6,
        answer: [
            {
                text: '여자는 수술 과정에 참여한다.',
                isAnswer: true
            },
            {
                text: '여자는 보호자의 집에서 동물을 돌본다.',
                isAnswer: false
            },
            {
                text: '여자의 직업은 사람들에게 잘 알려져 있다.',
                isAnswer: false
            },
            {
                text: '동물의 상태를 관찰하는 일은 여자의 업무가 아니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 남자의 중심 생각으로 가장 알맞은 것을 고르십시오. (17~20)',
        audio: t1_91_7,
        answer: [
            {
                text: '저녁 식사를 미리 준비해 두어야 한다.',
                isAnswer: false
            },
            {
                text: '음식을 한꺼번에 많이 먹지 않는 것이 좋다.',
                isAnswer: false
            },
            {
                text: '건강을 위해서 식사를 규칙적으로 해야 한다.',
                isAnswer: false
            },
            {
                text: '피곤할 때는 저녁을 밖에서 사 먹는 것이 좋다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 남자의 중심 생각으로 가장 알맞은 것을 고르십시오. (17~20)',
        audio: t1_91_8,
        answer: [
            {
                text: '친구가 힘들 때는 옆에 있어 줘야 한다.',
                isAnswer: true
            },
            {
                text: '여러 사람과 잘 지내기 위해 노력할 필요가 있다.',
                isAnswer: false
            },
            {
                text: '다른 사람과 함께 있을 때는 말을 조심해야 한다.',
                isAnswer: false
            },
            {
                text: '친구와 문제가 생겼을 때는 빨리 해결하는 것이 좋다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 남자의 중심 생각으로 가장 알맞은 것을 고르십시오. (17~20)',
        audio: t1_91_9,
        answer: [
            {
                text: '여행은 여유롭게 하는 것이 좋다.',
                isAnswer: true
            },
            {
                text: '여행을 자주 가는 것은 좋지 않다.',
                isAnswer: false
            },
            {
                text: '여행에서 다양한 경험을 해 봐야 한다.',
                isAnswer: false
            },
            {
                text: '여행에 필요한 예싼을 정해 놓을 필요가 있다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 남자의 중심 생각으로 가장 알맞은 것을 고르십시오. (17~20)',
        audio: t1_91_10,
        answer: [
            {
                text: '광고를 짧게 만드는 것이 효과적이다.',
                isAnswer: false
            },
            {
                text: '광고는 대중의 호기심을 자극해야 한다.',
                isAnswer: true
            },
            {
                text: '광고는 제품의 정보를 사실대로 전달해야 한다.',
                isAnswer: false
            },
            {
                text: '광고는 보는 사람ㅁ이 쉽게 이해할 수 있어야 한다.',
                isAnswer: false
            },
        ],
    },
]
