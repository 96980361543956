export const templete = [
  [
    {
      ko: '와',
      jp: 'わ',
    },
    {
      ko: '라',
      jp: 'ら',
    },
    {
      ko: '야',
      jp: 'や',
    },
    {
      ko: '마',
      jp: 'ま',
    },
    {
      ko: '하',
      jp: 'は',
    },
    {
      ko: '나',
      jp: 'な',
    },
    {
      ko: '타',
      jp: 'た',
    },
    {
      ko: '사',
      jp: 'さ',
    },
    {
      ko: '카',
      jp: 'か',
    },
    {
      ko: '아',
      jp: 'あ',
    },
  ],
  [
    {
      ko: '',
      jp: "",
    },
    {
      ko: '리',
      jp: 'り',
    },
    {
      ko: '',
      jp: "",
    },
    {
      ko: '미',
      jp: 'み',
    },
    {
      ko: '히',
      jp: 'ひ',
    },
    {
      ko: '니',
      jp: 'に',
    },
    {
      ko: '치',
      jp: 'ち',
    },
    {
      ko: '시',
      jp: 'し',
    },
    {
      ko: '키',
      jp: 'き',
    },
    {
      ko: '이',
      jp: 'い',
    },
  ],
  [
    {
      ko: '오',
      jp: 'お',
    },
    {
      ko: '루',
      jp: 'る',
    },
    {
      ko: '유',
      jp: 'ゆ',
    },
    {
      ko: '무',
      jp: 'む',
    },
    {
      ko: '후',
      jp: 'ふ',
    },
    {
      ko: '누',
      jp: 'ぬ',
    },
    {
      ko: '츠',
      jp: 'つ',
    },
    {
      ko: '스',
      jp: 'す',
    },
    {
      ko: '쿠',
      jp: 'く',
    },
    {
      ko: '우',
      jp: 'う',
    },
  ],
  [
    {
      ko: '',
      jp: "",
    },
    {
      ko: '레',
      jp: 'れ',
    },
    {
      ko: '',
      jp: "",
    },
    {
      ko: '메',
      jp: 'め',
    },
    {
      ko: '헤',
      jp: 'へ',
    },
    {
      ko: '네',
      jp: 'ね',
    },
    {
      ko: '테',
      jp: 'て',
    },
    {
      ko: '세',
      jp: 'せ',
    },
    {
      ko: '케',
      jp: 'け',
    },
    {
      ko: '에',
      jp: 'え',
    },
  ],
  [
    {
      ko: 'ㄴ',
      jp: "ん",
    },
    {
      ko: '로',
      jp: 'ろ',
    },
    {
      ko: '요',
      jp: 'よ',
    },
    {
      ko: '모',
      jp: 'も',
    },
    {
      ko: '호',
      jp: 'ほ',
    },
    {
      ko: '노',
      jp: 'の',
    },
    {
      ko: '토',
      jp: 'と',
    },
    {
      ko: '소',
      jp: 'そ',
    },
    {
      ko: '코',
      jp: 'こ',
    },
    {
      ko: '오',
      jp: 'お',
    },
  ]
]


export const list = [
  {
    ko: '와',
    jp: 'わ',
  },
  {
    ko: '라',
    jp: 'ら',
  },
  {
    ko: '야',
    jp: 'や',
  },
  {
    ko: '마',
    jp: 'ま',
  },
  {
    ko: '하',
    jp: 'は',
  },
  {
    ko: '나',
    jp: 'な',
  },
  {
    ko: '타',
    jp: 'た',
  },
  {
    ko: '사',
    jp: 'さ',
  },
  {
    ko: '카',
    jp: 'か',
  },
  {
    ko: '아',
    jp: 'あ',
  },
  {
    ko: '리',
    jp: 'り',
  },
  {
    ko: '미',
    jp: 'み',
  },
  {
    ko: '히',
    jp: 'ひ',
  },
  {
    ko: '니',
    jp: 'に',
  },
  {
    ko: '치',
    jp: 'ち',
  },
  {
    ko: '시',
    jp: 'し',
  },
  {
    ko: '키',
    jp: 'き',
  },
  {
    ko: '이',
    jp: 'い',
  },
  {
    ko: '오',
    jp: 'お',
  },
  {
    ko: '루',
    jp: 'る',
  },
  {
    ko: '유',
    jp: 'ゆ',
  },
  {
    ko: '무',
    jp: 'む',
  },
  {
    ko: '후',
    jp: 'ふ',
  },
  {
    ko: '누',
    jp: 'ぬ',
  },
  {
    ko: '츠',
    jp: 'つ',
  },
  {
    ko: '스',
    jp: 'す',
  },
  {
    ko: '쿠',
    jp: 'く',
  },
  {
    ko: '우',
    jp: 'う',
  },
  {
    ko: '레',
    jp: 'れ',
  },
  {
    ko: '메',
    jp: 'め',
  },
  {
    ko: '헤',
    jp: 'へ',
  },
  {
    ko: '네',
    jp: 'ね',
  },
  {
    ko: '테',
    jp: 'て',
  },
  {
    ko: '세',
    jp: 'せ',
  },
  {
    ko: '케',
    jp: 'け',
  },
  {
    ko: '에',
    jp: 'え',
  },
  {
    ko: '로',
    jp: 'ろ',
  },
  {
    ko: '요',
    jp: 'よ',
  },
  {
    ko: '모',
    jp: 'も',
  },
  {
    ko: '호',
    jp: 'ほ',
  },
  {
    ko: '노',
    jp: 'の',
  },
  {
    ko: '토',
    jp: 'と',
  },
  {
    ko: '소',
    jp: 'そ',
  },
  {
    ko: '코',
    jp: 'こ',
  },
  {
    ko: '오',
    jp: 'お',
  }
]
