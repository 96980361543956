export const word5_1 = [
    { "kr": "마늘", "mean": "にんにく" },
    { "kr": "마르다", "mean": "乾く" },
    { "kr": "마리", "mean": "～匹、～頭" },
    { "kr": "마시다", "mean": "飲む" },
    { "kr": "마음", "mean": "心、気持ち" },
    { "kr": "마지막", "mean": "最後" },
    { "kr": "마치다", "mean": "終わる、終える" },
    { "kr": "마흔", "mean": "40" },
    { "kr": "막히다", "mean": "詰まる、つかえる" },
    { "kr": "만", "mean": "万" },
    { "kr": "만나다", "mean": "会う" },
    { "kr": "만두", "mean": "ぎょうざ" },
    { "kr": "만들다", "mean": "作る" },
    { "kr": "만일", "mean": "万一" },
    { "kr": "만지다", "mean": "触る、触れる" },
    { "kr": "만화", "mean": "漫画" },
    { "kr": "많다", "mean": "多い" },
    { "kr": "많이", "mean": "多く、たくさん" },
    { "kr": "말", "mean": "言葉、話" },
    { "kr": "말씀", "mean": "お言葉" },
    { "kr": "말씀하다", "mean": "お話する、おっしゃる" },
    { "kr": "말하기", "mean": "しゃべること、話、会話" },
    { "kr": "말하다", "mean": "話す、しゃべる" },
    { "kr": "맑다", "mean": "清い、澄んでいる" },
    { "kr": "맛", "mean": "味" },
    { "kr": "맛없다", "mean": "おいしくない、不味い" },
    { "kr": "맛있다", "mean": "おいしい" },
    { "kr": "맞다", "mean": "合う、合っている、正しい" },
    { "kr": "맞습니다", "mean": "そうです" },
    { "kr": "맞아요", "mean": "その通りです" },
    { "kr": "맞은편", "mean": "向こう側、向かい" },
    { "kr": "매다", "mean": "結ぶ、締める" },
    { "kr": "매우", "mean": "とても、非常に" },
    { "kr": "매일", "mean": "毎日" },
    { "kr": "매주", "mean": "毎週" },
    { "kr": "맥주", "mean": "ビール" },
    { "kr": "맵다", "mean": "辛い" },
    { "kr": "머리", "mean": "頭、髪" },
    { "kr": "먹다", "mean": "食べる" },
    { "kr": "먼저", "mean": "まず、先に" },
    { "kr": "멀다", "mean": "遠い" },
    { "kr": "멋있다", "mean": "素敵だ、格好いい" },
    { "kr": "메뉴", "mean": "メニュー" },
    { "kr": "메다", "mean": "担ぐ、背負う" },
    { "kr": "메모", "mean": "メモ" },
    { "kr": "메시지", "mean": "メッセージ" },
    { "kr": "멕시코", "mean": "メキシコ" },
    { "kr": "며칠", "mean": "何日" },
    { "kr": "면도", "mean": "かみそり、ひげそり" },
    { "kr": "명", "mean": "～名" },
    { "kr": "명절", "mean": "年中行事" },
    { "kr": "몇", "mean": "いくつの～、どんな～" },
    { "kr": "모두", "mean": "すべて、みな" },
    { "kr": "모레", "mean": "あさって" },
    { "kr": "모르겠습니다", "mean": "知りません、わかりません" },
    { "kr": "모르다", "mean": "知らない" },
    { "kr": "모시다", "mean": "お連れする、お仕えする" },
    { "kr": "모양", "mean": "形、形状、格好、スタイル" },
    { "kr": "모으다", "mean": "集める" },
    { "kr": "모이다", "mean": "集まる" },
    { "kr": "모임", "mean": "集まり、集会" },
    { "kr": "모자", "mean": "帽子" },
    { "kr": "목", "mean": "首、喉" },
    { "kr": "목걸이", "mean": "ネックレス、首飾り" },
    { "kr": "목소리", "mean": "声" },
    { "kr": "목요일", "mean": "木曜日" },
    { "kr": "목욕", "mean": "沐浴、入浴" },
    { "kr": "목욕하다", "mean": "入浴する" },
    { "kr": "목적", "mean": "目的" },
    { "kr": "몸", "mean": "体、身体" },
    { "kr": "몸살", "mean": "疲労からくる病気" },
    { "kr": "못하다", "mean": "できない" },
    { "kr": "몽골", "mean": "モンゴル" },
    { "kr": "무", "mean": "大根" },
    { "kr": "무겁다", "mean": "重い" },
    { "kr": "무궁화", "mean": "むくげ" },
    { "kr": "무료", "mean": "無料" },
    { "kr": "무릎", "mean": "ひざ" },
    { "kr": "무리", "mean": "無理" },
    { "kr": "무섭다", "mean": "怖い" },
    { "kr": "무슨", "mean": "何の～、どんな～" },
    { "kr": "무엇", "mean": "何" },
    { "kr": "무엇이", "mean": "何が" },
    { "kr": "무역", "mean": "貿易" },
    { "kr": "무용", "mean": "舞踊、踊り、ダンス" },
    { "kr": "무척", "mean": "とても、非常に" },
    { "kr": "문", "mean": "門、戸、ドア" },
    { "kr": "문구점", "mean": "文具店" },
    { "kr": "문장", "mean": "文章" },
    { "kr": "문제", "mean": "問題" },
    { "kr": "문화", "mean": "文化" },
    { "kr": "묻다", "mean": "尋ねる、聞く" },
    { "kr": "물", "mean": "水" },
    { "kr": "물건", "mean": "物、物品" },
    { "kr": "물론", "mean": "もちろん" },
    { "kr": "물어보다", "mean": "尋ねてみる、聞いてみる、聞く" },
    { "kr": "뭐", "mean": "何" },
    { "kr": "미국", "mean": "米国、アメリカ" },
    { "kr": "미래", "mean": "未来" },
    { "kr": "미리", "mean": "前もって、あらかじめ" },
    { "kr": "미술", "mean": "美術" }
]


export const word5_2 = [
    { "kr": "미술관", "mean": "美術館" },
    { "kr": "미안하다", "mean": "すまない" },
    { "kr": "미안합니다", "mean": "すみません" },
    { "kr": "미용실", "mean": "美容室" },
    { "kr": "미터", "mean": "メートル" },
    { "kr": "민속촌", "mean": "民俗村" },
    { "kr": "밀가루", "mean": "小麦粉" },
    { "kr": "밀리다", "mean": "滞る" },
    { "kr": "밑", "mean": "下、底" }
]
