export const word13_1 = [
    { "kr": "파", "mean": "ねぎ" },
    { "kr": "파란색", "mean": "青色、青" },
    { "kr": "파랗다", "mean": "青い" },
    { "kr": "파티", "mean": "パーティ" },
    { "kr": "팔", "mean": "腕、８" },
    { "kr": "팔다", "mean": "売る" },
    { "kr": "팔리다", "mean": "売れる" },
    { "kr": "팔십", "mean": "80" },
    { "kr": "팔월", "mean": "８月" },
    { "kr": "패션", "mean": "ファッション" },
    { "kr": "퍼센트", "mean": "パーセント" },
    { "kr": "페이지", "mean": "ページ" },
    { "kr": "펜", "mean": "ペン" },
    { "kr": "펴다", "mean": "広げる、伸ばす" },
    { "kr": "편", "mean": "～編（映画など）" },
    { "kr": "편지", "mean": "手紙" },
    { "kr": "편하다", "mean": "楽だ、気楽だ、便利だ" },
    { "kr": "평양", "mean": "ピョンヤン" },
    { "kr": "평일", "mean": "平日" },
    { "kr": "포도", "mean": "ぶどう" },
    { "kr": "포장", "mean": "包装、荷造り" },
    { "kr": "표", "mean": "券・切符類の総称、チケット" },
    { "kr": "표현", "mean": "表現" },
    { "kr": "푹", "mean": "ぐっすり" },
    { "kr": "풀", "mean": "草" },
    { "kr": "풀다", "mean": "解く" },
    { "kr": "프랑스", "mean": "フランス" },
    { "kr": "프로그램", "mean": "プログラム" },
    { "kr": "프린트", "mean": "プリント" },
    { "kr": "피", "mean": "血" },
    { "kr": "피곤하다", "mean": "疲れる" },
    { "kr": "피다", "mean": "咲く" },
    { "kr": "피아노", "mean": "ピアノ" },
    { "kr": "피우다", "mean": "咲かせる" },
    { "kr": "피자", "mean": "ピザ" },
    { "kr": "필름", "mean": "フィルム" },
    { "kr": "필요", "mean": "必要" },
    { "kr": "필요하다", "mean": "必要だ" },
    { "kr": "필통", "mean": "筆箱、筆入れ" }
]
