export const word2_1 = [
    { "kr": "나", "mean": "僕、わたし、～や" },
    { "kr": "나가다", "mean": "出て行く" },
    { "kr": "나누다", "mean": "分ける" },
    { "kr": "나다", "mean": "出る" },
    { "kr": "나라", "mean": "国" },
    { "kr": "나무", "mean": "木" },
    { "kr": "나빠지다", "mean": "悪くなる" },
    { "kr": "나쁘다", "mean": "悪い" },
    { "kr": "나오다", "mean": "出て来る、あらわれる" },
    { "kr": "나이", "mean": "歳、年齢" },
    { "kr": "나중", "mean": "（時間や順序が）後" },
    { "kr": "나타나다", "mean": "あらわれる" },
    { "kr": "나흘", "mean": "４日" },
    { "kr": "낚시", "mean": "釣り" },
    { "kr": "날", "mean": "日" },
    { "kr": "날씨", "mean": "天気" },
    { "kr": "날짜", "mean": "日付" },
    { "kr": "남", "mean": "男" },
    { "kr": "남기다", "mean": "残す" },
    { "kr": "남녀", "mean": "男女" },
    { "kr": "남대문시장", "mean": "南大門市場" },
    { "kr": "남동생", "mean": "弟" },
    { "kr": "남미", "mean": "南米" },
    { "kr": "남북", "mean": "南北" },
    { "kr": "남자", "mean": "男" },
    { "kr": "남쪽", "mean": "南" },
    { "kr": "남편", "mean": "夫" },
    { "kr": "남학생", "mean": "男子学生" },
    { "kr": "낮", "mean": "昼、日中" },
    { "kr": "낮다", "mean": "低い" },
    { "kr": "내", "mean": "私の、僕の、おれの" },
    { "kr": "내가", "mean": "僕が、私が" },
    { "kr": "내과", "mean": "内科" },
    { "kr": "내년", "mean": "来年" },
    { "kr": "내다", "mean": "出す、起こす" },
    { "kr": "내려가다", "mean": "下がって行く、下がる、降りて行く" },
    { "kr": "내려오다", "mean": "下がって来る、降りて来る" },
    { "kr": "내리다", "mean": "下がる、降りる、降る" },
    { "kr": "내용", "mean": "内容" },
    { "kr": "내일", "mean": "明日" },
    { "kr": "냄비", "mean": "鍋" },
    { "kr": "냄새", "mean": "におい" },
    { "kr": "냉면", "mean": "冷麺" },
    { "kr": "냉장고", "mean": "冷蔵庫" },
    { "kr": "너", "mean": "きみ、おまえ" },
    { "kr": "너무", "mean": "あまりにも、とても" },
    { "kr": "넓다", "mean": "広い" },
    { "kr": "넘다", "mean": "越える、過ぎる" },
    { "kr": "넘어지다", "mean": "倒れる" },
    { "kr": "넣다", "mean": "入れる" },
    { "kr": "네", "mean": "はい、４つの～" },
    { "kr": "넥타이", "mean": "ネクタイ" },
    { "kr": "넷", "mean": "4" },
    { "kr": "넷째", "mean": "第４の～、４番目の" },
    { "kr": "년", "mean": "～年" },
    { "kr": "노란색", "mean": "黄色" },
    { "kr": "노랗다", "mean": "黄色い" },
    { "kr": "노래", "mean": "歌" },
    { "kr": "노래방", "mean": "カラオケ店" },
    { "kr": "노래하다", "mean": "歌う" },
    { "kr": "노력하다", "mean": "努力する" },
    { "kr": "노트", "mean": "ノート" },
    { "kr": "녹색", "mean": "緑色" },
    { "kr": "녹차", "mean": "緑茶" },
    { "kr": "놀다", "mean": "遊ぶ" },
    { "kr": "놀라다", "mean": "驚く" },
    { "kr": "농구", "mean": "バスケットボール" },
    { "kr": "높다", "mean": "高い" },
    { "kr": "놓다", "mean": "置く" },
    { "kr": "누가", "mean": "誰が" },
    { "kr": "누구", "mean": "誰" },
    { "kr": "누나", "mean": "姉（弟から見て）" },
    { "kr": "누르다", "mean": "押す" },
    { "kr": "눈", "mean": "目、雪" },
    { "kr": "눈물", "mean": "涙" },
    { "kr": "눈사람", "mean": "雪だるま" },
    { "kr": "눈싸움", "mean": "雪合戦" },
    { "kr": "눕다", "mean": "横になる、寝る" },
    { "kr": "뉴스", "mean": "ニュース" },
    { "kr": "뉴욕", "mean": "ニューヨーク" },
    { "kr": "느끼다", "mean": "感じる" },
    { "kr": "느낌", "mean": "感じ、気持ち" },
    { "kr": "느리다", "mean": "（速度が）遅い" },
    { "kr": "늘", "mean": "いつも、ずっと" },
    { "kr": "늘다", "mean": "増える、増す" },
    { "kr": "능력", "mean": "能力" },
    { "kr": "늦다", "mean": "（時間的に）遅い、遅れる" },
    { "kr": "늦잠", "mean": "寝坊" },
    { "kr": "님", "mean": "～様" }
]
