export const word14_1 = [
    { "kr": "하나", "mean": "1" },
    { "kr": "하늘", "mean": "空" },
    { "kr": "하늘색", "mean": "空色、水色" },
    { "kr": "하다", "mean": "する、言う" },
    { "kr": "하루", "mean": "１日" },
    { "kr": "하숙", "mean": "下宿" },
    { "kr": "하숙집", "mean": "下宿屋、下宿先" },
    { "kr": "하얀색", "mean": "白色、白" },
    { "kr": "하얗다", "mean": "白い" },
    { "kr": "하지만", "mean": "しかし、けれど" },
    { "kr": "학", "mean": "つる" },
    { "kr": "학교", "mean": "学校" },
    { "kr": "학기", "mean": "学期" },
    { "kr": "학년", "mean": "学年" },
    { "kr": "학생", "mean": "学生" },
    { "kr": "학생증", "mean": "学生証" },
    { "kr": "학원", "mean": "塾" },
    { "kr": "한", "mean": "１つの～" },
    { "kr": "한강", "mean": "ハンガン（漢江）" },
    { "kr": "한국", "mean": "韓国" },
    { "kr": "한국드라마", "mean": "韓国ドラマ" },
    { "kr": "한국말", "mean": "韓国語" },
    { "kr": "한국사람", "mean": "韓国人" },
    { "kr": "한국어", "mean": "韓国語" },
    { "kr": "한국요리", "mean": "韓国料理" },
    { "kr": "한글", "mean": "ハングル" },
    { "kr": "한번", "mean": "一回、一度" },
    { "kr": "한복", "mean": "韓服" },
    { "kr": "한식", "mean": "韓国料理" },
    { "kr": "한자", "mean": "漢字" },
    { "kr": "할머니", "mean": "祖母、おばあさん" },
    { "kr": "할아버지", "mean": "祖父、おじいさん" },
    { "kr": "할인", "mean": "割引" },
    { "kr": "함께", "mean": "一緒に、共に" },
    { "kr": "합격", "mean": "合格" },
    { "kr": "항상", "mean": "いつも、常に" },
    { "kr": "해", "mean": "陽、太陽" },
    { "kr": "해외", "mean": "海外" },
    { "kr": "해외여행", "mean": "海外旅行" },
    { "kr": "핸드폰", "mean": "携帯電話" },
    { "kr": "햄버거", "mean": "ハンバーガー" },
    { "kr": "햇빛", "mean": "日光、日差し" },
    { "kr": "행복", "mean": "幸福、幸せ" },
    { "kr": "행복하다", "mean": "幸せだ、幸福だ" },
    { "kr": "행사", "mean": "行事" },
    { "kr": "허리", "mean": "腰" },
    { "kr": "현금", "mean": "現金" },
    { "kr": "현재", "mean": "現在" },
    { "kr": "형", "mean": "（弟から見て）兄" },
    { "kr": "형님", "mean": "お兄様、兄貴" },
    { "kr": "형제", "mean": "兄弟" },
    { "kr": "호", "mean": "～号" },
    { "kr": "호랑이", "mean": "虎" },
    { "kr": "호주", "mean": "オーストラリア、豪州" },
    { "kr": "호텔", "mean": "ホテル" },
    { "kr": "혼자", "mean": "独り" },
    { "kr": "홈페이지", "mean": "ホームページ" },
    { "kr": "홍차", "mean": "紅茶" },
    { "kr": "화", "mean": "怒り" },
    { "kr": "화가", "mean": "画家" },
    { "kr": "화나다", "mean": "怒る、腹が立つ" },
    { "kr": "화내다", "mean": "怒る、腹を立てる" },
    { "kr": "화요일", "mean": "火曜日" },
    { "kr": "화장실", "mean": "トイレ、化粧室" },
    { "kr": "화장품", "mean": "化粧品" },
    { "kr": "화장하다", "mean": "化粧する" },
    { "kr": "확인하다", "mean": "確認する" },
    { "kr": "환영하다", "mean": "歓迎する" },
    { "kr": "환자", "mean": "患者" },
    { "kr": "회사", "mean": "会社" },
    { "kr": "회사원", "mean": "会社員" },
    { "kr": "회색", "mean": "灰色" },
    { "kr": "회의", "mean": "会議" },
    { "kr": "횡단보도", "mean": "横断歩道" },
    { "kr": "후", "mean": "～後" },
    { "kr": "후배", "mean": "後輩" },
    { "kr": "휴가", "mean": "休暇" },
    { "kr": "휴대전화", "mean": "携帯電話" },
    { "kr": "휴일", "mean": "休日" },
    { "kr": "휴지", "mean": "ちり紙、トイレットペーパー" },
    { "kr": "휴지통", "mean": "ゴミ箱" },
    { "kr": "흐리다", "mean": "曇る" },
    { "kr": "흰색", "mean": "白色、白" },
    { "kr": "힘", "mean": "力" }
]
