import App from 'App';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import Layout from 'components/layouts/Layout';
import Quiz from 'pages/quiz/Quiz';
import Words from 'pages/quiz/words/Words';
import Event from 'pages/community/event/Event';
import Notice from 'pages/community/notice/Notice';
import Archive from 'pages/archive/Archive';
import Topik from 'pages/topik/Topik';
import TopikListening from 'pages/topik/TopikListening';
import TopikReading from 'pages/topik/TopikReading';
import TopikWords from 'pages/topik/TopikWords';
import TopikWordsList1 from 'pages/topik/TopikWordsList1';
import TopikWordsList2 from 'pages/topik/TopikWordsList2';
import Community from 'pages/community/community';
import Qna from 'pages/community/qna/Qna';

import Hanguel from 'pages/haguel/Hanguel';
import NotFound from 'pages/NotFound';
import Sentences from 'pages/quiz/sentence/Sentences';
import TopikCategory from 'pages/topik/TopikCategory';



const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Layout>

          <Routes>
            <Route path="/" element={<App />} />

            <Route path="/hanguel" element={<Hanguel />} />

            <Route path="/quiz" element={<Quiz />} />
            <Route path="/quiz/words" element={<Words />} />
            <Route path="/quiz/sentences" element={<Sentences />} />

            <Route path="/topik" element={<Topik />} />
            <Route path="/topik/category/:level" element={<TopikCategory />} />
            <Route path="/topik/listening/:level/:step" element={<TopikListening />} />
            <Route path="/topik/reading/:level/:step" element={<TopikReading />} />
            <Route path="/wordsbook" element={<TopikWords />} />
            <Route path="/wordsbook/I" element={<TopikWordsList1 />} />
            <Route path="/wordsbook/II" element={<TopikWordsList2 />} />

            <Route path="/archive" element={<Archive />} />

            <Route path="/community" element={<Community />} />
            <Route path="/community/event" element={<Event />} />
            <Route path="/community/notice" element={<Notice />} />
            <Route path="/community/qna" element={<Qna />} />


            <Route path="/*" element={<NotFound />} />

          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
};

export default Router;