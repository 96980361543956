import Card from 'components/card/Card'
import MbWrapper from 'components/warpper/MbWrapper'
import React from 'react'
import { Link } from 'react-router-dom'

const Quiz = () => {

    return (
        <MbWrapper bg='lg'>
            <div className='flex flex-col gap-6 mt-4 pb-5'>
                <Card>
                    <div className='py-6 text-white flex flex-col items-center justify-between px-6 gap-4'>
                        <h2 className='font-semibold text-[24px]'>単語クイズ</h2>
                        <p className='whitespace-pre-wrap text-[14px] text-center'>{`単語クイズでは、様々なテーマの単語を\nクイズ形式で解くことができます。`}</p>
                        <p className='font-semibold text-[17px]'>人、食べ物、季節などのテーマ</p>
                        <Link to='/quiz/words'>
                            <button className='bg-pr3 border-pr4 border-[1px] border-solid text-pr2 py-2 px-4 rounded-lg'>クイズスタート</button>
                        </Link>
                    </div>
                </Card>
                <Card>
                    <div className='py-6 text-white flex flex-col items-center justify-between px-6 gap-4'>
                        <h2 className='font-semibold text-[24px]'>文章クイズ</h2>
                        <p className='whitespace-pre-wrap text-[14px] text-center'>{`文章クイズでは、様々なテーマの文章を\nクイズ形式で解くことができます。`}</p>
                        <p className='font-semibold text-[17px]'>日常会話、レストランなどの会話文</p>
                        <Link to='/quiz/sentences'>
                            <button className='bg-pr3 border-pr4 border-[1px] border-solid text-pr2 py-2 px-4 rounded-lg'>クイズスタート</button>
                        </Link>
                    </div>
                </Card>
            </div>
        </MbWrapper>
    )
}

export default Quiz

