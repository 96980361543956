
import hanguelPost from 'assets/imgs/service/hanguel.png';
import sentence from 'assets/imgs/service/sentence.png';
import words from 'assets/imgs/service/words.png';
import hiragana from 'assets/imgs/service/hiragana.png';
import spring from 'assets/imgs/service/1spring.png';
import summer from 'assets/imgs/service/1summer.png';
import fall from 'assets/imgs/service/1fall.png';
import winter from 'assets/imgs/service/1winter.png';
import date from 'assets/imgs/service/date.png';
import day from 'assets/imgs/service/day.png';
import time from 'assets/imgs/service/time.png';
import event from 'assets/imgs/service/event.png';
import weather from 'assets/imgs/service/weather.png';
import icecream from 'assets/imgs/service/icecream.png';

export const slideData = [
    {
        src: hanguelPost,
        title: 'ハングルの勉強',
        // desc: 'ハングルは難しくないです。一つずつ',
        link: "https://www.instagram.com/p/C7viqdVvJxn/?img_index=1"
    },
    {
        src: sentence,
        title: '文の勉強',
        // desc: '日常でよく使われる文章と例を一緒に勉強しましょう',
        link: "https://www.instagram.com/p/C8lVwHUPi7z/?img_index=1"
    },
    {
        src: hiragana,
        title: 'ひらがな',
        // desc: 'ハングルのひらがな表などの資料を勉強しています',
        link: "https://www.instagram.com/p/C8LzCVCvD9B/?img_index=1"
    },
    {
        src: words,
        title: '単語の勉強',
        // desc: '様々なテーマの単語と例を一緒に勉強しましょう',
        link: "https://www.instagram.com/p/C8lVwHUPi7z/?img_index=1"
    },
    {
        src: spring, title: "春の単語",
        link: "https://www.instagram.com/p/C-joWu5S0lO/?img_index=1"
    },
    { src: summer, title: "夏の単語", link: "https://www.instagram.com/p/C-mTlPKyZ2-/?img_index=1" },
    { src: fall, title: "秋の単語", link: "https://www.instagram.com/p/C-o9ke9SRBm/?img_index=1" },
    { src: winter, title: "冬の単語", link: "https://www.instagram.com/p/C-mTlPKyZ2-/?img_index=1" },
    { src: date, title: "今日の単語", link: "https://www.instagram.com/p/C_KqiYJSjDF/?img_index=1" },
    { src: day, title: "曜日の単語", link: "https://www.instagram.com/p/C-9f7PESggK/?img_index=1" },
    { src: time, title: "日の時間帯", link: "https://www.instagram.com/p/C_H3epTSLRh/?img_index=1" },
    { src: event, title: "サイ·コンサート", link: "https://www.instagram.com/p/C-RxFiZS1U_/?img_index=1" },
    { src: weather, title: "天気の単語", link: "https://www.instagram.com/p/C-1wqoQSV3T/?img_index=1" },
    { src: icecream, title: "夏のおやつ", link: "https://www.instagram.com/p/C_Ntk6OSomF/?img_index=1" },
]