import React from 'react'
import EmailImg from 'assets/icon/png/mail.png';
import globalizationImg from 'assets/icon/png/globalization.png';
import instagramImg from 'assets/icon/png/instagram.png';
import tiktokImg from 'assets/icon/png/tiktok.png';
import youtubeImg from 'assets/icon/png/youtube.png';
import logo from 'assets/d-icon/logohigh.png';
import Icon from 'components/icon/Icon';
import uuid from 'react-uuid';
import useAlert from 'hook/useAlert';

const Footer = () => {
    const [showAlert, Alert] = useAlert();
    const iconList = [
        {
            url: "",
            src: youtubeImg
        },
        {
            url: "https://www.instagram.com/yoshi_kr_class",
            src: instagramImg

        },
    ]

    return (
        <footer className='w-full h-[120px]  flex-center flex-col bg-[#213C90] text-[12px] text-white'>
            <div className='flex gap-2 w-[360px] h-[120px] p-5'>
                <div className='flex flex-col gap-4  w-[70%]'>
                    <img src={logo} className='w-[50px]' />
                    <p className='opacity-60'>© Team_Yoshi all rights reserved.</p>
                </div>
                <div className='flex gap-2 justify-end w-[30%]'>
                    {
                        iconList.map(icon => (
                            icon.url === "" ?
                                <Icon src={icon.src} key={uuid()} width={30} height={30} onClick={showAlert} /> :
                                <a href={icon.url} target='_blank'>
                                    <Icon src={icon.src} key={uuid()} width={30} height={30} />
                                </a>
                        ))
                    }
                </div>
            </div>
            <Alert
                message='まだ準備中です！'
                textCenter
            />
        </footer>
    )
}

export default Footer