export const word3_1 = [
    { "kr": "다", "mean": "全部" },
    { "kr": "다녀오다", "mean": "行って来る" },
    { "kr": "다니다", "mean": "通う" },
    { "kr": "다르다", "mean": "違う、異なる" },
    { "kr": "다른", "mean": "別の～、違う～" },
    { "kr": "다리", "mean": "脚、足、橋" },
    { "kr": "다섯", "mean": "5" },
    { "kr": "다시", "mean": "再び、また" },
    { "kr": "다음", "mean": "次、後" },
    { "kr": "다음 달", "mean": "来月、翌月" },
    { "kr": "다음 주", "mean": "来週" },
    { "kr": "다음 해", "mean": "翌年" },
    { "kr": "다이어트", "mean": "ダイエット" },
    { "kr": "다치다", "mean": "けがする、触れる" },
    { "kr": "닦다", "mean": "磨く、拭く" },
    { "kr": "단어", "mean": "単語" },
    { "kr": "단점", "mean": "短所、欠点" },
    { "kr": "닫다", "mean": "閉める" },
    { "kr": "닫히다", "mean": "閉まる" },
    { "kr": "달", "mean": "～ヶ月、月" },
    { "kr": "달걀", "mean": "卵" },
    { "kr": "달다", "mean": "甘い" },
    { "kr": "달러", "mean": "ドル" },
    { "kr": "달력", "mean": "カレンダー、暦" },
    { "kr": "달리다", "mean": "走る" },
    { "kr": "닭", "mean": "にわとり" },
    { "kr": "닭고기", "mean": "鶏肉" },
    { "kr": "닮다", "mean": "似る" },
    { "kr": "담그다", "mean": "漬ける" },
    { "kr": "담배", "mean": "たばこ" },
    { "kr": "답장", "mean": "返信、返事" },
    { "kr": "당근", "mean": "にんじん" },
    { "kr": "당신", "mean": "あなた" },
    { "kr": "대", "mean": "～台" },
    { "kr": "대답", "mean": "答え" },
    { "kr": "대답하다", "mean": "返事する、答える" },
    { "kr": "대부분", "mean": "大部分、ほとんど" },
    { "kr": "대사관", "mean": "大使館" },
    { "kr": "대학", "mean": "大学" },
    { "kr": "대학교", "mean": "大学校" },
    { "kr": "대학생", "mean": "大学生" },
    { "kr": "대학원", "mean": "大学院" },
    { "kr": "대학원생", "mean": "大学院生" },
    { "kr": "대한민국", "mean": "大韓民国" },
    { "kr": "대화", "mean": "対話" },
    { "kr": "대회", "mean": "大会" },
    { "kr": "댁", "mean": "お宅" },
    { "kr": "더", "mean": "もっと、もう" },
    { "kr": "더럽다", "mean": "汚い" },
    { "kr": "더운물", "mean": "お湯" },
    { "kr": "덕분", "mean": "おかげ" },
    { "kr": "덥다", "mean": "暑い" },
    { "kr": "덮다", "mean": "覆う、かぶせる" },
    { "kr": "데리다", "mean": "連れる" },
    { "kr": "데이트", "mean": "デート" },
    { "kr": "도로", "mean": "道路" },
    { "kr": "도서관", "mean": "図書館" },
    { "kr": "도시", "mean": "都市" },
    { "kr": "도와주다", "mean": "助けてあげる、助ける" },
    { "kr": "도움", "mean": "助け" },
    { "kr": "도착", "mean": "到着" },
    { "kr": "도착하다", "mean": "到着する、着く" },
    { "kr": "도쿄", "mean": "東京" },
    { "kr": "독서", "mean": "読書" },
    { "kr": "독서실", "mean": "読書室" },
    { "kr": "독서하다", "mean": "読書する" },
    { "kr": "독일", "mean": "ドイツ" },
    { "kr": "돈", "mean": "お金" },
    { "kr": "돌아가다", "mean": "帰って行く" },
    { "kr": "돌아오다", "mean": "帰って来る" },
    { "kr": "돕다", "mean": "助ける、手伝う" },
    { "kr": "동네", "mean": "町内、ご近所" },
    { "kr": "동대문시장", "mean": "東大門市場" },
    { "kr": "동물", "mean": "動物" },
    { "kr": "동생", "mean": "弟・妹" },
    { "kr": "동아리", "mean": "愛好会、サークル" },
    { "kr": "동안", "mean": "～の間" },
    { "kr": "동양", "mean": "東洋" },
    { "kr": "동전", "mean": "硬貨、小銭" },
    { "kr": "동쪽", "mean": "東、東側" },
    { "kr": "돼지", "mean": "豚" },
    { "kr": "돼지고기", "mean": "豚肉" },
    { "kr": "되다", "mean": "～になる" },
    { "kr": "된장", "mean": "味噌" },
    { "kr": "된장국", "mean": "味噌汁" },
    { "kr": "된장찌개", "mean": "テンジャンチゲ、味噌鍋" },
    { "kr": "두", "mean": "２つの～" },
    { "kr": "두껍다", "mean": "厚い" },
    { "kr": "두다", "mean": "置く" },
    { "kr": "두부", "mean": "豆腐" },
    { "kr": "두부찌개", "mean": "豆腐チゲ" },
    { "kr": "둘", "mean": "2" },
    { "kr": "둘째", "mean": "第２の～、２番目の～" },
    { "kr": "뒤", "mean": "後ろ、裏" },
    { "kr": "뒤쪽", "mean": "後ろ、後ろの方、後側" },
    { "kr": "드라마", "mean": "ドラマ" },
    { "kr": "드리다", "mean": "差し上げる" },
    { "kr": "드시다", "mean": "召し上がる" },
    { "kr": "듣기", "mean": "聞くこと、聞き取り" },
    { "kr": "듣다", "mean": "聞く" },
    { "kr": "들", "mean": "～たち、～ども" }
]

export const word3_2 = [
    { "kr": "들다", "mean": "持つ、入る" },
    { "kr": "들어가다", "mean": "入って行く、入る" },
    { "kr": "들어오다", "mean": "入って来る、入る" },
    { "kr": "등", "mean": "背、など" },
    { "kr": "등산", "mean": "登山" },
    { "kr": "등산복", "mean": "登山服" },
    { "kr": "등산화", "mean": "登山靴" },
    { "kr": "디브이디", "mean": "DVD" },
    { "kr": "디자인", "mean": "デザイン" },
    { "kr": "따뜻하다", "mean": "暖かい" },
    { "kr": "따라가다", "mean": "ついて行く、従う" },
    { "kr": "따라오다", "mean": "ついて来る" },
    { "kr": "따로", "mean": "別に、別途" },
    { "kr": "따르다", "mean": "従う" },
    { "kr": "딸", "mean": "娘" },
    { "kr": "딸기", "mean": "いちご" },
    { "kr": "땀", "mean": "汗" },
    { "kr": "때", "mean": "時" },
    { "kr": "때문", "mean": "～のため、～だから" },
    { "kr": "떠나다", "mean": "発つ、去る" },
    { "kr": "떠들다", "mean": "騒ぐ" },
    { "kr": "떡", "mean": "餅" },
    { "kr": "떡국", "mean": "トック、餅入りスープ" },
    { "kr": "떡볶이", "mean": "トッポギ" },
    { "kr": "떨어지다", "mean": "落ちる" },
    { "kr": "또", "mean": "また、再び" },
    { "kr": "또는", "mean": "または" },
    { "kr": "똑같다", "mean": "全く同じだ、そっくりだ" },
    { "kr": "똑바로", "mean": "まっすぐに、しっかり" },
    { "kr": "뛰다", "mean": "駆ける、走る" },
    { "kr": "뜨겁다", "mean": "熱い" },
    { "kr": "뜨다", "mean": "（目を）開く" },
    { "kr": "뜻", "mean": "意味、こころざし" }
]
