export const word12_1 = [
    { "kr": "타다", "mean": "乗る" },
    { "kr": "타월", "mean": "タオル" },
    { "kr": "탁구", "mean": "卓球、ピンポン" },
    { "kr": "태국", "mean": "タイ" },
    { "kr": "태권도", "mean": "テコンドー" },
    { "kr": "태어나다", "mean": "うまれる" },
    { "kr": "태풍", "mean": "台風" },
    { "kr": "택시", "mean": "タクシー" },
    { "kr": "탤런트", "mean": "タレント、芸能人" },
    { "kr": "터미널", "mean": "ターミナル" },
    { "kr": "테니스", "mean": "テニス" },
    { "kr": "테이블", "mean": "テーブル" },
    { "kr": "텔레비전", "mean": "テレビ" },
    { "kr": "토마토", "mean": "トマト" },
    { "kr": "토요일", "mean": "土曜日" },
    { "kr": "통", "mean": "～通（手紙など）" },
    { "kr": "통장", "mean": "（預金）通帳" },
    { "kr": "통하다", "mean": "通じる" },
    { "kr": "퇴근하다", "mean": "退社する、帰宅する" },
    { "kr": "특별히", "mean": "特別に" },
    { "kr": "특징", "mean": "特徴" },
    { "kr": "특히", "mean": "特に" },
    { "kr": "틀다", "mean": "（スイッチを）入れる、つける" },
    { "kr": "틀리다", "mean": "間違う" },
    { "kr": "티셔츠", "mean": "Ｔシャツ" },
    { "kr": "팀", "mean": "チーム" }
]
