import React from 'react'
import close from 'assets/d-icon/close.svg';

type ModalProps = {
    closeModal: () => void;
    isClosing: boolean;
    children: React.ReactNode;
}

const Modal = ({ closeModal, isClosing, children }: ModalProps) => {
    return (
        <div className={` modal-overlay ${isClosing ? 'modal-hide' : ''}`} onClick={closeModal}>
            <div
                className={`modal-content relative ${isClosing ? 'modal-hide' : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <button onClick={closeModal} className='absolute top-4 right-4'>
                    <img src={close} />
                </button>
                {children}
            </div>
        </div>
    )
}

export default Modal