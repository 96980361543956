import { TopikListeningType } from "types/topik";
import t1_91_1 from 'assets/audio/t91_2/t2_91_31.mp3';
import t1_91_2 from 'assets/audio/t91_2/t2_91_32.mp3';
import t1_91_3 from 'assets/audio/t91_2/t2_91_33.mp3';
import t1_91_4 from 'assets/audio/t91_2/t2_91_34.mp3';
import t1_91_5 from 'assets/audio/t91_2/t2_91_35.mp3';
import t1_91_6 from 'assets/audio/t91_2/t2_91_36.mp3';
import t1_91_7 from 'assets/audio/t91_2/t2_91_37.mp3';
import t1_91_8 from 'assets/audio/t91_2/t2_91_38.mp3';
import t1_91_9 from 'assets/audio/t91_2/t2_91_39.mp3';
import t1_91_10 from 'assets/audio/t91_2/t2_91_40.mp3';

// 토픽 91 II 듣기 40
export const listening2Quizlist4: TopikListeningType[] = [
    {
        q: '다음을 듣고 물음에 답하시오. (31~32)',
        question: "남자의 중심 생각으로 가장 알맞은 것을 고르십시오.",
        audio: t1_91_1,
        answer: [
            {
                text: '시설을 다른 지역으로 옮겨서 설치해야 한다.',
                isAnswer: false
            },
            {
                text: '시설의 설치 효과에 대한 명확한 검증이 필요하다.',
                isAnswer: false
            },
            {
                text: '시설 설치에 대한 반대는 홍보를 통해 해결할 수 있다.',
                isAnswer: true
            },
            {
                text: '시설의 설치 사실을 주민들에게 바로 알리지 않는 것이 좋다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오. (31~32)',
        question: "남자의 태도로 가장 알맞은 것을 고르십시오.",
        audio: t1_91_2,
        answer: [
            {
                text: '앞으로 일어날 문제에 대해 걱정하고 있다.',
                isAnswer: false
            },
            {
                text: '자신의 의견대로 될 것임을 확신하고 있다.',
                isAnswer: true
            },
            {
                text: '상대방에게 객관적인 근거를 요구하고 있다.',
                isAnswer: false
            },
            {
                text: '상황이 심각해진 것에 대한 책임을 묻고 있다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (33~34)',
        question: '무엇에 대한 내용인지 알맞은 것을 고르십시오.',
        audio: t1_91_3,
        answer: [
            {
                text: '음악을 감상하는 올바른 방법',
                isAnswer: false
            },
            {
                text: '음악적 재능이 발달하는 결정적 시기',
                isAnswer: false
            },
            {
                text: '노인을 대상으로 하는 연구에서 주의할 점',
                isAnswer: false
            },
            {
                text: '음악 활동이 노인의 인지 기능에 미치는 영향',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (33~34)',
        question: '들을 내용과 같은 고르십시오.',
        audio: t1_91_4,
        answer: [
            {
                text: '이 연구를 위한 실험은 1년 동안 진행됬다.',
                isAnswer: false
            },
            {
                text: '이 실험의 결과는 연구팀의 예상과 같았다.',
                isAnswer: true
            },
            {
                text: '이 실험에서 두 집단은 동일한 결과를 보였다.',
                isAnswer: false
            },
            {
                text: '이 실험은 음악을 전공한 사람들을 대상으로 했다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (35~36)',
        question: '남자가 무엇을 하고 있는지 고르십시오.',
        audio: t1_91_5,
        answer: [
            {
                text: '이 편지가 가지는 가치와 의의를 알리고 있다.',
                isAnswer: true
            },
            {
                text: '이 편지의 보존 상태에 대해 아쉬움을 표하고 있다.',
                isAnswer: false
            },
            {
                text: '이 편지가 발견된 장소와 발굴 과정을 보고하고 있다.',
                isAnswer: false
            },
            {
                text: '이 편지를 연구하는 데 도움을 준 사람들에게 감사하고 있다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (35~36)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_6,
        answer: [
            {
                text: '이 편지는 곧 박물관에 전시될 것이다.',
                isAnswer: false
            },
            {
                text: '이 편지는 군인인 남편이 아내에게 쓴 것이다.',
                isAnswer: true
            },
            {
                text: '이 편지가 어느 시대의 것인지 밝혀지지 않았다.',
                isAnswer: false
            },
            {
                text: '이 편지를 보물로 지정하기 위한 논의가 진행 중이다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (37~38)',
        question: '여자의 중심 생각으로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_7,
        answer: [
            {
                text: '목재를 수출하기 위한 경로를 확보해야 한다.',
                isAnswer: false
            },
            {
                text: '산림 순환 경영을 통해 좋은 효과를 얻을 수 있다.',
                isAnswer: true
            },
            {
                text: '좋은 목재를 얻을 수 있는 나무를 심는 것이 좋다.',
                isAnswer: false
            },
            {
                text: '산림 순환 경영이 정착될 때까지 시간이 필요하다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (37~38)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_8,
        answer: [
            {
                text: '한국은 목재 수입 비중이 낮은 편이다.',
                isAnswer: false
            },
            {
                text: '한국은 산림 순환 경영을 시행하고 있다.',
                isAnswer: false
            },
            {
                text: '나무가 30년 이상 되면 탄소 흡수 능력이 떨어진다.',
                isAnswer: true
            },
            {
                text: '한국의 산에는 심은 지 얼마 안 된 어린나무가 많다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (39~40)',
        question: '이 대화 전의 내용으로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_9,
        answer: [
            {
                text: '인공위성을 관측하는 신기술이 개발되었다.',
                isAnswer: false
            },
            {
                text: '대기권에서 인공위성이 폭발하는 사고가 있었다.',
                isAnswer: true
            },
            {
                text: '우주 조약을 개정하기 위해 여러 나라 사람들이 모였다.',
                isAnswer: false
            },
            {
                text: '우주 탐사를 위한 인력을 양성하는 것은 쉬운 일이 아니다.',
                isAnswer: false

            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (39~40)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_10,
        answer: [
            {
                text: '얼마 전에 국제 우주법이 제정되었다.',
                isAnswer: false
            },
            {
                text: '우주 조약은 구체적인 내용으로 구성되어 있다.',
                isAnswer: false
            },
            {
                text: '우주 조약은 강제적인 효력을 가지고 있지 않다.',
                isAnswer: true
            },
            {
                text: '인공위성으로 인한 전파 방해 문제는 일어나지 않을 것이다.',
                isAnswer: false
            },
        ],
    },
]
