import React, { useEffect, useState } from 'react';
import illust1 from 'assets/imgs/background/landing1.png';
import OXCard from 'components/card/OXCard';
import { motion } from 'framer-motion';
import logo from 'assets/d-icon/blue-logo.svg';

const Landing1 = () => {
  const randomQuizList = [];
  const [answer, setAnswer] = useState<null | Boolean>(null);
  const [showLink, setShowLink] = useState<Boolean>(false);

  const [current, setCurrent] = useState<{
    title: string;
    quiz: string;
    quiz2: string;
    answer: 'O' | 'X';
  }>({
    title: '韓国語クイズ👀',
    quiz: '안녕하세요!',
    quiz2: 'おはよう',
    answer: 'O',
  });

  useEffect(() => {
    if (answer !== null && typeof answer === 'boolean') {
      setShowLink(true);
    }
  }, [answer]);

  const handleClickO = () => {
    setAnswer(current.answer === 'O');
  };
  const handleClickX = () => {
    setAnswer(current.answer === 'X');
  };
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center relative">
        <a className='slow-bounce text-[24px] mt-20 font-bold text-white' href='https://forms.gle/3PxgcCypA2v55NcT7' target='_blank'>アンケートに参加 🎁</a>
        <div className="h-[530px] min-h-[530px]  bg-illust w-full max-w-[540px]" style={{ backgroundImage: `url(${illust1})` }}>
          {/* #80beff */}

        </div>
      </div>
      <div className="relative bg-bg3 flex justify-center">
        <div className="absolute h-[380px] w-full  bg-bg1 flex-center">
          <div className="absolute h-[380px] w-full  max-w-[531px] border-x-[2px] border-black bg-bg2 flex-center" />
        </div>

        {!showLink && (
          <motion.div
            className="pt-[50px] flex-center"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.3,
              delay: 0.5,
            }}
          >
            <OXCard
              title={current.title}
              quiz={current.quiz}
              quiz2={current.quiz2}
              answer={current.answer}
              onClickO={handleClickO}
              onClickX={handleClickX}
            />
          </motion.div>
        )}
        {showLink && (
          <motion.div
            className="pt-[50px] flex-center"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.3,
            }}
          >
            <div className="w-[320px] relative h-[380px] bg-white rounded-[20px] px-5 pb-20 pt-10">
              <img src={logo} className="absolute w-[60px] top-[-30px] rounded-full left-[128px]" />

              <h2 className="mb-5 text-[16px] font-semibold text-center">
                {answer ? '正解！もっと多くの問題を解こう！' : 'ブー！勉強しに行ってみましょうか？'}
              </h2>
              <div className="w-[280px] h-[220px] px-5 pb-5 pt-[28px] text-center">
                <p className="text-[20px] font-semibold flex flex-col gap-4 ">
                  <button className="flex-center w-full h-[60px] bg-pr2 rounded-[14px]">
                    <a href={'/quiz/words'} className="text-center  text-white  w-full">
                      単語クイズ
                    </a>
                  </button>
                  <button className="flex-center w-full h-[60px] bg-pr2 rounded-[14px]">
                    <a href={'/quiz/sentences'} className="text-center  text-white  w-full">
                      文章クイズ
                    </a>
                  </button>
                  <button className="flex-center w-full h-[60px] bg-pr2 rounded-[14px]">
                    <a href={'/topik'} className="text-center  text-white  w-full">
                      TOPIKの勉強
                    </a>
                  </button>
                </p>
              </div>
            </div>
          </motion.div>
        )}
        {/* #80beff */}
      </div>
    </div>
  );
};

export default Landing1;
