import { ReadingQuizlist3 } from "./topik91_3";
import { ReadingQuizlist } from "./topik91_1";
import { ReadingQuizlist2 } from "./topik91_2";
import { ReadingQuizlist4 } from "./topik91_4";


export const readingI93 = [
    ReadingQuizlist,
    ReadingQuizlist2,
    ReadingQuizlist3,
    ReadingQuizlist4
]