import { TopikListeningType } from "types/topik";
import t1_91_1 from 'assets/audio/t91_2/t2_91_41.mp3';
import t1_91_2 from 'assets/audio/t91_2/t2_91_42.mp3';
import t1_91_3 from 'assets/audio/t91_2/t2_91_43.mp3';
import t1_91_4 from 'assets/audio/t91_2/t2_91_44.mp3';
import t1_91_5 from 'assets/audio/t91_2/t2_91_45.mp3';
import t1_91_6 from 'assets/audio/t91_2/t2_91_46.mp3';
import t1_91_7 from 'assets/audio/t91_2/t2_91_47.mp3';
import t1_91_8 from 'assets/audio/t91_2/t2_91_48.mp3';
import t1_91_9 from 'assets/audio/t91_2/t2_91_49.mp3';
import t1_91_10 from 'assets/audio/t91_2/t2_91_50.mp3';

// 토픽 91 II 듣기 50
export const listening2Quizlist5: TopikListeningType[] = [
    {
        q: '다음을 듣고 물음에 답하시오. (41~42)',
        question: "이 강연의 중심 내용으로 가장 알맞은 것을 고르십시오.",
        audio: t1_91_1,
        answer: [
            {
                text: '제품의 품질은 부품의 질에 의해 결정된다.',
                isAnswer: false
            },
            {
                text: '제조업은 일자리 창출에 크게 기여하고 있다.',
                isAnswer: false
            },
            {
                text: '제품에 대한 고객의 의견을 반영하는 것이 중요하다.',
                isAnswer: false
            },
            {
                text: '제조업은 사업 영역 확장을 통해 발전을 도모하고 있다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오. (41~42)',
        question: "들은 내용과 같은 것을 고르십시오.",
        audio: t1_91_2,
        answer: [
            {
                text: '이 업체는 엔진 판매에 어려움을 겪고 있다.',
                isAnswer: false
            },
            {
                text: '조선업이 경제적으로 힘든 상황에 놓여 있다.',
                isAnswer: false
            },
            {
                text: '이 업체는 안정적인 수입을 얻지 못하고 있다.',
                isAnswer: false
            },
            {
                text: '이 업체는 실시간 엔진 점검 서비스 시스템을 마련했다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (43~44)',
        question: '무엇에 대한 내용인지 알맞은 것을 고르십시오.',
        audio: t1_91_3,
        answer: [
            {
                text: '종묘 정전의 건축미',
                isAnswer: true
            },
            {
                text: '종묘 정전의 건립 과정',
                isAnswer: false
            },
            {
                text: '종묘 정전의 관리 방법',
                isAnswer: false
            },
            {
                text: '종묘 정전의 현대적 기능',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (43~44)',
        question: '종묘 정전에 대한 설명으로 맞는 것을 고르십시오.',
        audio: t1_91_4,
        answer: [
            {
                text: '지붕이 곡선 형태로 휘어 있다.',
                isAnswer: false
            },
            {
                text: '기둥에 화려한 색이 사용되었다.',
                isAnswer: false
            },
            {
                text: '도심에서 멀리 떨어진 곳에 있다.',
                isAnswer: false
            },
            {
                text: '왕과 왕비의 제사를 지내는 공간이다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (45~46)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_5,
        answer: [
            {
                text: '충수에는 염증이 잘 생기지 않는다.',
                isAnswer: false
            },
            {
                text: '충수는 소화가 잘되도록 돕는 일을 한다.',
                isAnswer: false
            },
            {
                text: '충수에는 유익한 미생물이 서식하고 있다.',
                isAnswer: true
            },
            {
                text: '충수를 제거하는 수술이 최근에 가능해졌다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (45~46)',
        question: '여자가 말하는 방식으로 알맞은 것을 고르십시오.',
        audio: t1_91_6,
        answer: [
            {
                text: '유사한 사례들을 묶어 비교 분석하고 있다.',
                isAnswer: false
            },
            {
                text: '대상이 가진 문제의 주요 원인을 유추하고 있다.',
                isAnswer: false
            },
            {
                text: '대상의 역할에 대해 밝혀진 사실을 설명하고 있다.',
                isAnswer: true
            },
            {
                text: '다양한 연구를 바탕으로 자신만의 기준을 제시하고 있다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (47~48)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_7,
        answer: [
            {
                text: '인건비와 임대료는 고정 비용에 포함되지 않는다.',
                isAnswer: false
            },
            {
                text: '소상공인의 경영난은 일률적 방식으로 해결할 수 있다.',
                isAnswer: false
            },
            {
                text: '대출 의존도가 높은 경우 채무를 조정해 주면 안 된다.',
                isAnswer: false
            },
            {
                text: '영업 비용 때문에 빚을 지는 소상공인이 많아지고 있다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (47~48)',
        question: '남자의 태도로 알맞은 것을 고르십시오.',
        audio: t1_91_8,
        answer: [
            {
                text: '소상공업계의 태도 변화를 기대하고 있다.',
                isAnswer: false
            },
            {
                text: '경제 위기 극복을 위한 소상공인의 노력에 감탄하고 있다.',
                isAnswer: false
            },
            {
                text: '소상공인에 대한 실효성 있는 지원 방안을 제안하고 있다.',
                isAnswer: true
            },
            {
                text: '소솽공업의 활성화가 가져올 경제적 효과를 낙관하고 있다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (49~50)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_9,
        answer: [
            {
                text: '이 철학자는 도덕성에 대해 일반적인 견해를 따르고 있다.',
                isAnswer: false
            },
            {
                text: '이 철학자는 도덕성을 평가하는 기준에서 감정을 배제했다.',
                isAnswer: true
            },
            {
                text: '이 철학자에 따르면 출근 시간을 지키는 것은 도덕적 의무가 아니다.',
                isAnswer: false
            },
            {
                text: '이 철학자에 따르면 어떤 상황에서도 다른 사람을 돕는 것이 최우선이다.',
                isAnswer: false

            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (49~50)',
        question: '남자의 태도로 알맞은 것을 고르십시오.',
        audio: t1_91_10,
        answer: [
            {
                text: '도덕적 행위의 당위성을 강조하고 있다.',
                isAnswer: false
            },
            {
                text: '도덕적 행위의 가치를 과대평가하고 있다.',
                isAnswer: false
            },
            {
                text: '도덕성에 대한 섣부른 판단을 경계하고 있다.',
                isAnswer: true
            },
            {
                text: '도덕성에 대한 연구의 필요성을 제기하고 있다.',
                isAnswer: false
            },
        ],
    },
]
