export const word_2_8_1 = [
    { "kr": "아깝다", "mean": "もったいない、惜しい" },
    { "kr": "아끼다", "mean": "節約する、惜しむ" },
    { "kr": "아나운서", "mean": "アナウンサー" },
    { "kr": "아니", "mean": "いや" },
    { "kr": "아동", "mean": "児童" },
    { "kr": "아랫사람", "mean": "目下の人" },
    { "kr": "아르바이트", "mean": "アルバイト" },
    { "kr": "아리랑", "mean": "アリラン" },
    { "kr": "아무것", "mean": "何" },
    { "kr": "아무래도", "mean": "どうにも、いずれにしても" },
    { "kr": "아무런", "mean": "いかなる、何の" },
    { "kr": "아무리", "mean": "いくら（～しても）" },
    { "kr": "아무튼", "mean": "とにかく" },
    { "kr": "아쉬움", "mean": "心残り" },
    { "kr": "아쉽다", "mean": "物足りない、心残りだ、望ましい" },
    { "kr": "아이디", "mean": "ユーザー名、ID" },
    { "kr": "아이디어", "mean": "アイデア" },
    { "kr": "아침저녁", "mean": "朝晩" },
    { "kr": "아픔", "mean": "痛み" },
    { "kr": "악몽", "mean": "悪夢" },
    { "kr": "악수", "mean": "握手" },
    { "kr": "안개", "mean": "霧" },
    { "kr": "안과", "mean": "眼科" },
    { "kr": "안내문", "mean": "案内文" },
    { "kr": "안내서", "mean": "案内書" },
    { "kr": "안내소", "mean": "案内所" },
    { "kr": "안되다", "mean": "うまく行かない" },
    { "kr": "안방", "mean": "居間" },
    { "kr": "안부", "mean": "安否" },
    { "kr": "안색", "mean": "顔色" },
    { "kr": "안심", "mean": "安心" },
    { "kr": "안약", "mean": "目薬" },
    { "kr": "안전선", "mean": "安全線" },
    { "kr": "안주", "mean": "おつまみ" },
    { "kr": "안쪽", "mean": "内側、奥" },
    { "kr": "안타깝다", "mean": "気の毒だ、ふびんだ、もどかしい" },
    { "kr": "앉히다", "mean": "座らせる" },
    { "kr": "알", "mean": "卵、玉" },
    { "kr": "알레르기", "mean": "アレルギー" },
    { "kr": "알아듣다", "mean": "理解する" },
    { "kr": "알아보다", "mean": "調べる、探る、見分ける、認める" },
    { "kr": "알아주다", "mean": "認める" },
    { "kr": "알약", "mean": "錠剤" },
    { "kr": "알코올", "mean": "アルコール" },
    { "kr": "암", "mean": "がん" },
    { "kr": "앞날", "mean": "将来" },
    { "kr": "앞두다", "mean": "控える、前にする" },
    { "kr": "앞뒤", "mean": "前後" },
    { "kr": "앞머리", "mean": "前髪" },
    { "kr": "애기", "mean": "赤ちゃん" },
    { "kr": "애완견", "mean": "愛犬、ペットの犬" },
    { "kr": "애완동물", "mean": "ペット" },
    { "kr": "애인", "mean": "恋人" },
    { "kr": "애정", "mean": "愛情" },
    { "kr": "애프터서비스", "mean": "アフターサービス" },
    { "kr": "액자", "mean": "額縁" },
    { "kr": "앨범", "mean": "アルバム" },
    { "kr": "야간", "mean": "夜間" },
    { "kr": "야경", "mean": "夜景" },
    { "kr": "야근", "mean": "残業、夜勤" },
    { "kr": "야단맞다", "mean": "叱られる" },
    { "kr": "야단치다", "mean": "叱りつける" },
    { "kr": "야외", "mean": "野外" },
    { "kr": "야유회", "mean": "ピクニック" },
    { "kr": "약하다", "mean": "弱い" },
    { "kr": "약혼", "mean": "婚約" },
    { "kr": "약혼자", "mean": "婚約者、フィアンセ" },
    { "kr": "얌전하다", "mean": "おとなしい" },
    { "kr": "양념", "mean": "薬味だれ" },
    { "kr": "양로원", "mean": "老人ホーム" },
    { "kr": "양반", "mean": "ヤンバン(朝鮮時代の官僚)" },
    { "kr": "양보", "mean": "譲歩" },
    { "kr": "양쪽", "mean": "両方" },
    { "kr": "얘", "mean": "この子" },
    { "kr": "어기다", "mean": "破る、違反する" },
    { "kr": "어느새", "mean": "いつのまにか" },
    { "kr": "어떠하다", "mean": "どういうふうだ" },
    { "kr": "어떡하다", "mean": "どうする" },
    { "kr": "어려움", "mean": "困難" },
    { "kr": "어린아이", "mean": "子供" },
    { "kr": "어린이날", "mean": "子供の日" },
    { "kr": "어버이날", "mean": "父母の日" },
    { "kr": "어색하다", "mean": "ぎこちない" },
    { "kr": "어쨌든", "mean": "とにかく、いずれにせよ" },
    { "kr": "어쩌다가", "mean": "偶然に、ときどき" },
    { "kr": "어쩌면", "mean": "どうすれば、どうして" },
    { "kr": "어쩐지", "mean": "なんか、やっぱり" },
    { "kr": "어차피", "mean": "どうせ、どっちみち、結局" },
    { "kr": "어학", "mean": "語学" },
    { "kr": "어학연수", "mean": "語学研修" },
    { "kr": "어휘", "mean": "語彙" },
    { "kr": "억", "mean": "億" },
    { "kr": "억양", "mean": "アクセント" },
    { "kr": "억지로", "mean": "無理やり" },
    { "kr": "언론", "mean": "言論" },
    { "kr": "언론인", "mean": "ジャーナリスト" },
    { "kr": "언어", "mean": "言語" },
    { "kr": "언젠가", "mean": "いつか" },
    { "kr": "얻다", "mean": "得る" },
    { "kr": "얻어먹다", "mean": "おごってもらう" },
    { "kr": "얼다", "mean": "凍る" }
]

export const word_2_8_2 = [
    { "kr": "얼룩", "mean": "染み、汚れ" },
    { "kr": "얼른", "mean": "早く、すぐ、すばやく" },
    { "kr": "엄격하다", "mean": "厳しい、厳格だ" },
    { "kr": "엄청나다", "mean": "途方もない、とんでもない" },
    { "kr": "업무", "mean": "業務" },
    { "kr": "없애다", "mean": "なくす、処分する" },
    { "kr": "엉덩이", "mean": "お尻" },
    { "kr": "엉뚱하다", "mean": "とんでもない、突拍子もない" },
    { "kr": "엉망", "mean": "めちゃくちゃ" },
    { "kr": "엊그제", "mean": "一昨日" },
    { "kr": "에너지", "mean": "エネルギー" },
    { "kr": "에이즈", "mean": "エイズ" },
    { "kr": "엔진", "mean": "エンジン" },
    { "kr": "엠티", "mean": "メンバーシップトレーニング" },
    { "kr": "여가", "mean": "余暇" },
    { "kr": "여고생", "mean": "女子高生" },
    { "kr": "여관", "mean": "旅館" },
    { "kr": "여드름", "mean": "ニキビ" },
    { "kr": "여럿", "mean": "多数" },
    { "kr": "여름날", "mean": "夏の日" },
    { "kr": "여성", "mean": "女性" },
    { "kr": "여성복", "mean": "女性服" },
    { "kr": "여유", "mean": "余裕" },
    { "kr": "여전히", "mean": "依然として、相変わらず" },
    { "kr": "여쭈다", "mean": "（目上の人に）申し上げる、伺う" },
    { "kr": "여행비", "mean": "旅費" },
    { "kr": "여행자", "mean": "旅行者" },
    { "kr": "여행지", "mean": "旅行先" },
    { "kr": "역사적", "mean": "歴史的" },
    { "kr": "역시", "mean": "やはり、やっぱり" },
    { "kr": "역할", "mean": "役割" },
    { "kr": "연결", "mean": "連結" },
    { "kr": "연구", "mean": "研究" },
    { "kr": "연구소", "mean": "研究所" },
    { "kr": "연구실", "mean": "研究室" },
    { "kr": "연극", "mean": "演劇" },
    { "kr": "연기", "mean": "延期、演技" },
    { "kr": "연도", "mean": "年度" },
    { "kr": "연락", "mean": "連絡" },
    { "kr": "연말", "mean": "年末" },
    { "kr": "연말연시", "mean": "年末年始" },
    { "kr": "연상", "mean": "年上" },
    { "kr": "연속", "mean": "連続" },
    { "kr": "연속극", "mean": "連続ドラマ" },
    { "kr": "연수", "mean": "研修" },
    { "kr": "연애", "mean": "恋愛" },
    { "kr": "연애결혼", "mean": "恋愛結婚" },
    { "kr": "연인", "mean": "恋人" },
    { "kr": "연장", "mean": "延長" },
    { "kr": "연주", "mean": "演奏" },
    { "kr": "연주회", "mean": "コンサート" },
    { "kr": "연하", "mean": "年下" },
    { "kr": "연하다", "mean": "軟らかい、薄い" },
    { "kr": "연회비", "mean": "年会費" },
    { "kr": "열대야", "mean": "熱帯夜" },
    { "kr": "열람실", "mean": "閲覧室" },
    { "kr": "열흘", "mean": "十日" },
    { "kr": "염색", "mean": "カラーリング、髪を染めること" },
    { "kr": "엿", "mean": "飴" },
    { "kr": "영수증", "mean": "領収書" },
    { "kr": "영양", "mean": "栄養" },
    { "kr": "영양가", "mean": "栄養価" },
    { "kr": "영양분", "mean": "栄養分" },
    { "kr": "영양소", "mean": "栄養素" },
    { "kr": "영양제", "mean": "栄養剤" },
    { "kr": "영업", "mean": "営業" },
    { "kr": "영업부", "mean": "営業部" },
    { "kr": "영역", "mean": "エリア" },
    { "kr": "영원히", "mean": "永遠に" },
    { "kr": "영향", "mean": "影響" },
    { "kr": "영화감독", "mean": "映画監督" },
    { "kr": "영화제", "mean": "映画祭" },
    { "kr": "옆집", "mean": "隣の家" },
    { "kr": "예금", "mean": "預金" },
    { "kr": "예매", "mean": "前売り、事前購入、予約" },
    { "kr": "예방", "mean": "予防" },
    { "kr": "예보", "mean": "予報" },
    { "kr": "예상", "mean": "予想" },
    { "kr": "예술", "mean": "芸術" },
    { "kr": "예습", "mean": "予習" },
    { "kr": "예식장", "mean": "結婚式場" },
    { "kr": "예의", "mean": "礼儀" },
    { "kr": "예전", "mean": "昔、ずっと前" },
    { "kr": "예절", "mean": "礼節、行儀" },
    { "kr": "예정", "mean": "予定" },
    { "kr": "예측", "mean": "予測" },
    { "kr": "옛", "mean": "昔" },
    { "kr": "옛말", "mean": "古語、昔話" },
    { "kr": "오가다", "mean": "行き来する" },
    { "kr": "오늘날", "mean": "今日" },
    { "kr": "오락", "mean": "娯楽" },
    { "kr": "오래되다", "mean": "古い、久しい、長い時間が経つ" },
    { "kr": "오랜", "mean": "長い間" },
    { "kr": "오르내리다", "mean": "上がり下がりする" },
    { "kr": "오른손", "mean": "右手" },
    { "kr": "오리엔테이션", "mean": "オリエンテーション" },
    { "kr": "오염", "mean": "汚染" },
    { "kr": "오토바이", "mean": "バイク" },
    { "kr": "오페라", "mean": "オペラ" },
    { "kr": "오피스텔", "mean": "オフィステル" },
    { "kr": "오해", "mean": "誤解" }
]


export const word_2_8_3 = [
    { "kr": "오히려", "mean": "むしろ" },
    { "kr": "온", "mean": "全～、すべての" },
    { "kr": "온갖", "mean": "いろいろな種類の" },
    { "kr": "온도", "mean": "温度" },
    { "kr": "온돌", "mean": "オンドル（暖房装置）" },
    { "kr": "온돌방", "mean": "オンドル部屋" },
    { "kr": "온라인", "mean": "オンライン" },
    { "kr": "온몸", "mean": "全身" },
    { "kr": "온천", "mean": "温泉" },
    { "kr": "온천욕", "mean": "温泉浴" },
    { "kr": "온통", "mean": "すべて、全部" },
    { "kr": "올리다", "mean": "上げる、載せる" },
    { "kr": "올림픽", "mean": "オリンピック" },
    { "kr": "올바르다", "mean": "正しい" },
    { "kr": "옳다", "mean": "正しい" },
    { "kr": "옷차림", "mean": "身なり" },
    { "kr": "와인", "mean": "ワイン" },
    { "kr": "완성", "mean": "完成" },
    { "kr": "완전히", "mean": "完全に" },
    { "kr": "왕", "mean": "王" },
    { "kr": "왕복", "mean": "往復" },
    { "kr": "왕비", "mean": "王妃" },
    { "kr": "왠지", "mean": "なぜだか" },
    { "kr": "외가", "mean": "母方" },
    { "kr": "외과", "mean": "外科" },
    { "kr": "외교관", "mean": "外交官" },
    { "kr": "외동딸", "mean": "一人娘" },
    { "kr": "외동아들", "mean": "一人息子" },
    { "kr": "외래어", "mean": "外来語" },
    { "kr": "외로움", "mean": "孤独" },
    { "kr": "외모", "mean": "外見" },
    { "kr": "외식", "mean": "外食" },
    { "kr": "외아들", "mean": "一人息子" },
    { "kr": "외우다", "mean": "覚える" },
    { "kr": "외치다", "mean": "叫ぶ、わめく" },
    { "kr": "요가", "mean": "ヨガ" },
    { "kr": "요구", "mean": "要求" },
    { "kr": "요구르트", "mean": "ヨーグルト" },
    { "kr": "요리법", "mean": "レシピ" },
    { "kr": "요리사", "mean": "シェフ" },
    { "kr": "요새", "mean": "近頃、最近" },
    { "kr": "요약", "mean": "要約" },
    { "kr": "요청", "mean": "要請" },
    { "kr": "욕", "mean": "悪口" },
    { "kr": "욕실", "mean": "風呂場" },
    { "kr": "욕심", "mean": "欲" },
    { "kr": "욕심쟁이", "mean": "欲ばり" },
    { "kr": "용", "mean": "竜" },
    { "kr": "용기", "mean": "勇気" },
    { "kr": "용돈", "mean": "小遣い" },
    { "kr": "용서", "mean": "容赦、許すこと" },
    { "kr": "우등상", "mean": "優等賞" },
    { "kr": "우리말", "mean": "韓国語" },
    { "kr": "우물", "mean": "井戸" },
    { "kr": "우수성", "mean": "優秀性" },
    { "kr": "우승", "mean": "優勝" },
    { "kr": "우아하다", "mean": "優雅だ" },
    { "kr": "우연", "mean": "偶然" },
    { "kr": "우연히", "mean": "偶然に" },
    { "kr": "우울증", "mean": "うつ病" },
    { "kr": "우울하다", "mean": "憂うつだ" },
    { "kr": "우정", "mean": "友情" },
    { "kr": "우주", "mean": "宇宙" },
    { "kr": "우주여행", "mean": "宇宙旅行" },
    { "kr": "우편", "mean": "郵便" },
    { "kr": "우편물", "mean": "郵便物" },
    { "kr": "우편배달", "mean": "郵便配達" },
    { "kr": "운", "mean": "運" },
    { "kr": "운명", "mean": "運命" },
    { "kr": "운전기사", "mean": "運転手" },
    { "kr": "운전면허", "mean": "運転免許証" },
    { "kr": "운전자", "mean": "ドライバー" },
    { "kr": "울보", "mean": "泣き虫" },
    { "kr": "울음", "mean": "泣くこと" },
    { "kr": "웃어른", "mean": "目上の人" },
    { "kr": "웃음", "mean": "笑い" },
    { "kr": "워낙", "mean": "あまりにも、何しろ" },
    { "kr": "원고", "mean": "原稿" },
    { "kr": "원래", "mean": "元々" },
    { "kr": "원룸", "mean": "ワンルーム" },
    { "kr": "원리", "mean": "原理" },
    { "kr": "원서", "mean": "願書" },
    { "kr": "원숭이", "mean": "サル" },
    { "kr": "원인", "mean": "原因" },
    { "kr": "월말", "mean": "月末" },
    { "kr": "웨딩드레스", "mean": "ウェディングドレス" },
    { "kr": "위대하다", "mean": "偉大だ" },
    { "kr": "위로", "mean": "慰労" },
    { "kr": "위반", "mean": "違反" },
    { "kr": "윗사람", "mean": "目上の人" },
    { "kr": "유난히", "mean": "ひときわ、際立って、とりわけ" },
    { "kr": "유니폼", "mean": "ユニフォーム" },
    { "kr": "유람선", "mean": "遊覧船" },
    { "kr": "유료", "mean": "有料" },
    { "kr": "유리창", "mean": "窓ガラス" },
    { "kr": "유머", "mean": "ユーモア" },
    { "kr": "유명", "mean": "有名" },
    { "kr": "유적지", "mean": "遺跡地" },
    { "kr": "유창하다", "mean": "流暢だ" },
    { "kr": "유치원", "mean": "幼稚園" },
    { "kr": "유쾌하다", "mean": "愉快だ" }
]


export const word_2_8_4 = [
    { "kr": "유행어", "mean": "流行語" },
    { "kr": "육식", "mean": "肉食" },
    { "kr": "육아", "mean": "育児" },
    { "kr": "윷놀이", "mean": "韓国のすごろく" },
    { "kr": "은", "mean": "銀" },
    { "kr": "은혜", "mean": "恩恵、恵み" },
    { "kr": "음력", "mean": "陰暦" },
    { "kr": "음료", "mean": "飲料、飲み物" },
    { "kr": "음성", "mean": "音声" },
    { "kr": "음식물", "mean": "食べ物、飲食物" },
    { "kr": "음식점", "mean": "レストラン、食堂" },
    { "kr": "음악회", "mean": "音楽会" },
    { "kr": "음주", "mean": "飲酒" },
    { "kr": "응급실", "mean": "応急室" },
    { "kr": "응답", "mean": "応答、回答" },
    { "kr": "응답자", "mean": "回答者、応答者" },
    { "kr": "응시자", "mean": "受験者" },
    { "kr": "응원", "mean": "応援" },
    { "kr": "의견", "mean": "意見" },
    { "kr": "의논", "mean": "相談、議論" },
    { "kr": "의류", "mean": "衣類" },
    { "kr": "의무", "mean": "義務" },
    { "kr": "의문", "mean": "疑問" },
    { "kr": "의사소통", "mean": "意思疎通" },
    { "kr": "의상", "mean": "衣装" },
    { "kr": "의식주", "mean": "衣食住" },
    { "kr": "의심", "mean": "疑心、疑い" },
    { "kr": "의외로", "mean": "意外に" },
    { "kr": "의욕", "mean": "意欲" },
    { "kr": "의의", "mean": "意義" },
    { "kr": "의지", "mean": "意志" },
    { "kr": "의하다", "mean": "依る、基づく" },
    { "kr": "의학", "mean": "医学" },
    { "kr": "의학적", "mean": "医学的" },
    { "kr": "이것저것", "mean": "あれこれ" },
    { "kr": "이국적", "mean": "異国的" },
    { "kr": "이기다", "mean": "勝つ" },
    { "kr": "이기적", "mean": "利己的" },
    { "kr": "이끌다", "mean": "引く、導く" },
    { "kr": "이내", "mean": "すぐ" },
    { "kr": "이다", "mean": "～だ、である" },
    { "kr": "이동", "mean": "移動" },
    { "kr": "이따", "mean": "のちほど" },
    { "kr": "이때", "mean": "この時" },
    { "kr": "이러하다", "mean": "こうだ" },
    { "kr": "이런저런", "mean": "あれこれ" },
    { "kr": "이루다", "mean": "成す、作り上げる" },
    { "kr": "이루어지다", "mean": "構成される、形成される" },
    { "kr": "이름나다", "mean": "有名になる" },
    { "kr": "이름표", "mean": "ネームタグ" },
    { "kr": "이리", "mean": "こちらへ" },
    { "kr": "이리저리", "mean": "あっちこっち" },
    { "kr": "이마", "mean": "額" },
    { "kr": "이만", "mean": "これぐらいの、この程度の" },
    { "kr": "이만큼", "mean": "これくらい" },
    { "kr": "이미", "mean": "すでに" },
    { "kr": "이미지", "mean": "画像、イメージ" },
    { "kr": "이민", "mean": "移民" },
    { "kr": "이별", "mean": "別れ" },
    { "kr": "이불", "mean": "布団" },
    { "kr": "이비인후과", "mean": "耳鼻咽喉科" },
    { "kr": "이삿짐", "mean": "引っ越しの荷物" },
    { "kr": "이상형", "mean": "好きなタイプ、理想のタイプ、理想の人" },
    { "kr": "이성", "mean": "理性" },
    { "kr": "이어지다", "mean": "相次ぐ、続く、繋がる" },
    { "kr": "이어폰", "mean": "イヤホン" },
    { "kr": "이외", "mean": "以外" },
    { "kr": "이용료", "mean": "利用料" },
    { "kr": "이용자", "mean": "利用者" },
    { "kr": "이웃", "mean": "隣、隣の家" },
    { "kr": "이웃집", "mean": "お隣" },
    { "kr": "이익", "mean": "利益" },
    { "kr": "이자", "mean": "利子" },
    { "kr": "이전", "mean": "以前" },
    { "kr": "이제야", "mean": "今やっと" },
    { "kr": "이하", "mean": "以下" },
    { "kr": "이해력", "mean": "理解力" },
    { "kr": "이해심", "mean": "思いやり" },
    { "kr": "이혼", "mean": "離婚" },
    { "kr": "이후", "mean": "以後" },
    { "kr": "익숙하다", "mean": "よく知られている、慣れている" },
    { "kr": "익히다", "mean": "煮る、身につける、習う" },
    { "kr": "인간", "mean": "人間" },
    { "kr": "인간관계", "mean": "人間関係" },
    { "kr": "인간성", "mean": "人間性" },
    { "kr": "인간적", "mean": "人間的" },
    { "kr": "인류", "mean": "人類" },
    { "kr": "인사말", "mean": "ご挨拶" },
    { "kr": "인상적", "mean": "印象的" },
    { "kr": "인생", "mean": "人生" },
    { "kr": "인쇄", "mean": "印刷" },
    { "kr": "인쇄물", "mean": "印刷物" },
    { "kr": "인스턴트", "mean": "インスタント" },
    { "kr": "인원", "mean": "人員" },
    { "kr": "인정받다", "mean": "認定されている、認められる" },
    { "kr": "인출기", "mean": "ATM" },
    { "kr": "인턴사원", "mean": "インターン" },
    { "kr": "인하다", "mean": "基づく、原因による" },
    { "kr": "일기장", "mean": "日記帳" },
    { "kr": "일단", "mean": "まず、一旦" },
    { "kr": "일등", "mean": "一等" }
]


export const word_2_8_5 = [
    { "kr": "일반", "mean": "一般" },
    { "kr": "일반인", "mean": "一般人" },
    { "kr": "일반적", "mean": "一般的" },
    { "kr": "일부", "mean": "一部" },
    { "kr": "일부러", "mean": "わざと、わざわざ" },
    { "kr": "일상", "mean": "日常" },
    { "kr": "일상적", "mean": "日常的" },
    { "kr": "일생", "mean": "一生" },
    { "kr": "일시", "mean": "一時" },
    { "kr": "일시불", "mean": "一括払い" },
    { "kr": "일으키다", "mean": "起こす、興す" },
    { "kr": "일자리", "mean": "働き口、職場" },
    { "kr": "일정", "mean": "日程" },
    { "kr": "일정하다", "mean": "一定だ" },
    { "kr": "일출", "mean": "日の出" },
    { "kr": "일회용품", "mean": "使い捨て" },
    { "kr": "읽히다", "mean": "読まれる" },
    { "kr": "임산부", "mean": "妊婦" },
    { "kr": "임시", "mean": "臨時" },
    { "kr": "임신", "mean": "妊娠" },
    { "kr": "입금", "mean": "入金" },
    { "kr": "입맛", "mean": "食欲" },
    { "kr": "입술", "mean": "唇" },
    { "kr": "입시", "mean": "入試、入学試験" },
    { "kr": "입장", "mean": "入場" },
    { "kr": "입장권", "mean": "入場券" },
    { "kr": "입장료", "mean": "入場料" },
    { "kr": "입학시험", "mean": "入学試験" },
    { "kr": "입히다", "mean": "着せる" },
    { "kr": "잇다", "mean": "繋ぐ、結ぶ、続ける" },
    { "kr": "잎", "mean": "葉" }
]
