export const word_2_14_1 = [
    { "kr": "하도", "mean": "あまりにも" },
    { "kr": "하루빨리", "mean": "一日でも早く" },
    { "kr": "하루하루", "mean": "一日一日" },
    { "kr": "하룻밤", "mean": "一晩" },
    { "kr": "하마터면", "mean": "危うく" },
    { "kr": "하품", "mean": "あくび" },
    { "kr": "학과", "mean": "学科" },
    { "kr": "학력", "mean": "学歴" },
    { "kr": "학문", "mean": "学問" },
    { "kr": "학비", "mean": "学費" },
    { "kr": "학습", "mean": "学習" },
    { "kr": "학자", "mean": "学者" },
    { "kr": "학점", "mean": "単位" },
    { "kr": "한가하다", "mean": "暇だ" },
    { "kr": "한국학", "mean": "韓国学" },
    { "kr": "한글날", "mean": "ハングルの日" },
    { "kr": "한꺼번에", "mean": "一度に、一緒に" },
    { "kr": "한낮", "mean": "真昼" },
    { "kr": "한눈", "mean": "脇目" },
    { "kr": "한동안", "mean": "一時、しばらく" },
    { "kr": "한두", "mean": "一・二" },
    { "kr": "한마디", "mean": "一言" },
    { "kr": "한순간", "mean": "一瞬" },
    { "kr": "한숨", "mean": "一息、一休み、溜息" },
    { "kr": "한약", "mean": "漢方薬、韓方薬" },
    { "kr": "한옥", "mean": "韓屋(伝統的な朝鮮の建築様式を使用した家屋)" },
    { "kr": "한우", "mean": "韓牛、韓国の牛" },
    { "kr": "한의사", "mean": "漢方医" },
    { "kr": "한정식", "mean": "韓定食" },
    { "kr": "한지", "mean": "伝統韓紙" },
    { "kr": "한참", "mean": "しばらく、はるかに" },
    { "kr": "한창", "mean": "とても、さかんに" },
    { "kr": "한편", "mean": "一方" },
    { "kr": "할부", "mean": "分割払い" },
    { "kr": "할인점", "mean": "ディスカウントストア" },
    { "kr": "함부로", "mean": "むやみに、やたらに" },
    { "kr": "합격자", "mean": "合格者" },
    { "kr": "합치다", "mean": "合わせる" },
    { "kr": "합하다", "mean": "足す" },
    { "kr": "항공", "mean": "航空" },
    { "kr": "항공권", "mean": "航空券" },
    { "kr": "항공기", "mean": "航空機" },
    { "kr": "항공료", "mean": "航空料" },
    { "kr": "항공사", "mean": "航空会社" },
    { "kr": "항공편", "mean": "航空便" },
    { "kr": "해결", "mean": "解決" },
    { "kr": "해결책", "mean": "解決策" },
    { "kr": "해고", "mean": "解雇" },
    { "kr": "해내다", "mean": "やりとげる" },
    { "kr": "해돋이", "mean": "日の出" },
    { "kr": "해롭다", "mean": "有害である、害になる" },
    { "kr": "해마다", "mean": "毎年" },
    { "kr": "해물", "mean": "海産物" },
    { "kr": "해보다", "mean": "やってみる" },
    { "kr": "해산물", "mean": "海産物" },
    { "kr": "해소", "mean": "解消" },
    { "kr": "해소법", "mean": "解消法" },
    { "kr": "해수욕", "mean": "海水浴" },
    { "kr": "해수욕장", "mean": "海水浴場" },
    { "kr": "해열제", "mean": "解熱剤" },
    { "kr": "핸드백", "mean": "ハンドバック" },
    { "kr": "햇곡식", "mean": "新穀" },
    { "kr": "햇볕", "mean": "日差し、日光" },
    { "kr": "행동", "mean": "行動" },
    { "kr": "행사장", "mean": "催事場" },
    { "kr": "행운", "mean": "幸運" },
    { "kr": "향", "mean": "香、香り" },
    { "kr": "향기", "mean": "香り" },
    { "kr": "향수", "mean": "香水、郷愁" },
    { "kr": "향하다", "mean": "向かう" },
    { "kr": "허락", "mean": "許諾、許し" },
    { "kr": "허리띠", "mean": "ベルト、腰巻" },
    { "kr": "험하다", "mean": "険しい" },
    { "kr": "헤매다", "mean": "さまよう、迷う" },
    { "kr": "헤어스타일", "mean": "ヘアスタイル" },
    { "kr": "헤어지다", "mean": "別れる、離れる" },
    { "kr": "헤엄치다", "mean": "泳ぐ" },
    { "kr": "헬멧", "mean": "ヘルメット" },
    { "kr": "헬스클럽", "mean": "スポーツクラブ、ジム" },
    { "kr": "현관", "mean": "玄関" },
    { "kr": "현관문", "mean": "玄関ドア" },
    { "kr": "현대", "mean": "現代" },
    { "kr": "현대식", "mean": "現代式" },
    { "kr": "현대인", "mean": "現代人" },
    { "kr": "현대적", "mean": "現代的" },
    { "kr": "현모양처", "mean": "良妻賢母" },
    { "kr": "현실", "mean": "現実" },
    { "kr": "현실적", "mean": "現実的" },
    { "kr": "현장", "mean": "現場" },
    { "kr": "형용사", "mean": "形容詞" },
    { "kr": "형태", "mean": "形" },
    { "kr": "형편", "mean": "状況、事情" },
    { "kr": "혜택", "mean": "恵み、恩恵" },
    { "kr": "호감", "mean": "好感" },
    { "kr": "호기심", "mean": "好奇心" },
    { "kr": "호수", "mean": "湖" },
    { "kr": "호칭", "mean": "呼称" },
    { "kr": "혹시", "mean": "もしかして、ひょっとして" },
    { "kr": "혹은", "mean": "あるいは" },
    { "kr": "혼나다", "mean": "怒られる" },
    { "kr": "혼내다", "mean": "叱る、こらしめる" }
]


export const word_2_14_2 = [
    { "kr": "혼잡", "mean": "混雑" },
    { "kr": "홀수", "mean": "奇数" },
    { "kr": "홍보", "mean": "広報" },
    { "kr": "홍보물", "mean": "広報物" },
    { "kr": "홍수", "mean": "洪水" },
    { "kr": "화려하다", "mean": "華麗だ、華やかだ" },
    { "kr": "화면", "mean": "画面" },
    { "kr": "화물", "mean": "貨物" },
    { "kr": "화분", "mean": "植木鉢" },
    { "kr": "화산", "mean": "火山" },
    { "kr": "화상", "mean": "やけど" },
    { "kr": "화장지", "mean": "トイレットペーパー" },
    { "kr": "화재", "mean": "火災、火事" },
    { "kr": "화창하다", "mean": "のどかだ" },
    { "kr": "화해", "mean": "和解" },
    { "kr": "확실하다", "mean": "確実だ" },
    { "kr": "환경", "mean": "環境" },
    { "kr": "환기", "mean": "換気" },
    { "kr": "환불", "mean": "払い戻し" },
    { "kr": "환상적", "mean": "幻想的" },
    { "kr": "환송회", "mean": "歓送会" },
    { "kr": "환영회", "mean": "歓迎会" },
    { "kr": "환율", "mean": "為替レート" },
    { "kr": "환전", "mean": "両替" },
    { "kr": "환절기", "mean": "季節の変わり目" },
    { "kr": "활동", "mean": "活動" },
    { "kr": "활동적", "mean": "活動的" },
    { "kr": "활발하다", "mean": "活発だ" },
    { "kr": "활용", "mean": "活用" },
    { "kr": "활짝", "mean": "ぱあっと、満開に" },
    { "kr": "황당하다", "mean": "とてつもない、とんでもない" },
    { "kr": "황사", "mean": "黄砂" },
    { "kr": "～회", "mean": "～回" },
    { "kr": "회갑", "mean": "還暦" },
    { "kr": "회복", "mean": "回復" },
    { "kr": "회비", "mean": "会費" },
    { "kr": "회식", "mean": "会食、飲み会" },
    { "kr": "회원", "mean": "会員" },
    { "kr": "회의장", "mean": "会議場" },
    { "kr": "회장", "mean": "会長" },
    { "kr": "횟수", "mean": "回数" },
    { "kr": "효과", "mean": "効果、効き目" },
    { "kr": "효과적", "mean": "効果的" },
    { "kr": "효녀", "mean": "孝行娘" },
    { "kr": "효율적", "mean": "効率的" },
    { "kr": "후문", "mean": "裏門" },
    { "kr": "후반", "mean": "後半" },
    { "kr": "후반전", "mean": "後半戦" },
    { "kr": "후보", "mean": "候補" },
    { "kr": "후식", "mean": "デザート" },
    { "kr": "후회", "mean": "後悔" },
    { "kr": "훈련", "mean": "訓練" },
    { "kr": "훌륭하다", "mean": "立派だ、すばらしい" },
    { "kr": "훔치다", "mean": "盗む" },
    { "kr": "훨씬", "mean": "ずっと、はるかに" },
    { "kr": "휘발유", "mean": "ガソリン、揮発油" },
    { "kr": "휴가철", "mean": "休暇の季節" },
    { "kr": "휴게실", "mean": "休憩室" },
    { "kr": "휴대", "mean": "携帯" },
    { "kr": "휴대폰", "mean": "携帯電話" },
    { "kr": "휴식", "mean": "休息" },
    { "kr": "휴양지", "mean": "リゾート" },
    { "kr": "휴학", "mean": "休学" },
    { "kr": "흐르다", "mean": "流れる" },
    { "kr": "흔들다", "mean": "振る、揺らす" },
    { "kr": "흔들리다", "mean": "揺れる、そよぐ、ぐらつく" },
    { "kr": "흔하다", "mean": "ありふれている" },
    { "kr": "흔히", "mean": "よく、多く" },
    { "kr": "흘리다", "mean": "流す、こぼす、紛失する" },
    { "kr": "흙", "mean": "土" },
    { "kr": "흡연", "mean": "喫煙" },
    { "kr": "흥미", "mean": "興味" },
    { "kr": "흥분", "mean": "興奮" },
    { "kr": "희다", "mean": "白い" },
    { "kr": "희망", "mean": "希望" },
    { "kr": "희망자", "mean": "希望者" },
    { "kr": "희생자", "mean": "犠牲者" },
    { "kr": "힘껏", "mean": "力の限り、精一杯" }
]
