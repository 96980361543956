import React, { useState } from 'react'

export type TabProps = {
  list: {
    value: number;
    text: string;
  }[];
  longTitle?: boolean;
  onClick: (value: number) => void;
}

const Tab = ({ list, longTitle, onClick }: TabProps) => {
  const [current, setCurrent] = useState<number>(1)
  const handleClickTab = (value: number) => () => {
    setCurrent(value)
    onClick(value)
  }

  return (
    <div className='flex '>
      <ul className='flex flex-wrap gap-[10px]'>
        {list.map(ele => (
          <li className={`cursor-pointer flex justify-center items-center ${longTitle ? 'p-1 rounded-[10px]' : 'min-w-10 px-2 h-10 rounded-full border-solid border-[1px]'} ` +
            ` ${current === ele.value ? 'bg-pr3 border-pr4 text-pr2 font-semibold' : 'border-gray text-gray'}`}
            onClick={handleClickTab(ele.value)}>{ele.text}</li>
        ))}
      </ul>
    </div>
  )
}

export default Tab