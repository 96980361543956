import React, { useEffect, useState } from 'react'
import face from 'assets/d-icon/face.svg'

type ProgressProps = {
    total: number;  // 전체 길이
    current: number; // 현재 값
};


const Progress = ({ total, current }: ProgressProps) => {
    const [progressWidth, setProgressWidth] = useState(0);

    useEffect(() => {
        // current 값에 따라 프로그래스 바의 너비를 애니메이션으로 설정
        const progress = (current / (total - 1)) * 90;
        setProgressWidth(progress);
    }, [current, total]);
    return (
        <div className='relative  w-[320px] h-[50px]'>
            <div className='absolute bg-pr5 h-5 w-full rounded-[10px]' />
            <div className='absolute bg-pr2 h-5 rounded-[10px] z-10 transition-all '
                style={{ width: `${progressWidth}%` }}
            />
            <img className='absolute z-20 top-[-20px] transition-all ' src={face}
                style={{ left: `calc(${progressWidth}% - 15px)` }} // 아이콘이 progress에 따라 이동
            />
            {/* left -30px */}
        </div>
    )
}

export default Progress