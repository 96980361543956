import React, { useEffect } from 'react';
import logo from 'assets/d-icon/logohigh.png';
import uuid from 'react-uuid';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Hamburger from 'components/menu/Hamburger';

type UrlToTitleType = {
    [key: string]: {
        root: string;
        [subKey: string]: string;
    };
};

const Header = () => {
    const location = useLocation().pathname.replace('/', '')
    const navigate = useNavigate();
    const { level } = useParams<{ level: string }>(); // id는 URL에서 추출된 값
    const lastChar = useLocation().pathname.charAt(useLocation().pathname.length - 1);
    const urlToTitle: UrlToTitleType = {
        topik: {
            root: 'TOPIK',
            category: `TOPIK${lastChar === "1" ? " I" : " II"}`,
            reading: '読み',
            listening: '聞き取り'
        },
        wordsbook: {
            root: '単語帳',
            I: '単語帳',
            II: "単語帳"
        },
        quiz: {
            root: 'クイズ',
            sentences: '紋章',
            words: '単語'
        },
        hanguel: {
            root: 'ハングル'
        },
        archive: {
            root: 'ダウンロード'
        },
        community: {
            root: "コミュニティー",
            notice: "お知らせ",
            event: "イベント",
            qna: "Q&A"

        }
        // 필요한 다른 경로와 제목을 추가할 수 있습니다.
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [useLocation().pathname])

    const getTitleFromLocation = (location: string) => {
        // '/'로 주소를 나누어 배열로 변환하고 빈 문자열 제거
        const segments = location.split('/').filter(Boolean);

        // 첫 번째 경로 부분 (예: 'topik', 'quiz')
        const baseKey = segments[0];

        // 기본 객체를 찾음
        const baseObject = urlToTitle[baseKey];

        if (!baseObject) {
            return ''; // 기본 객체가 없는 경우
        }

        // 두 번째 경로 부분이 있을 경우
        const subKey = segments[1];

        // 서브 경로가 없는 경우에는 기본 제목 반환
        if (!subKey) {
            return baseObject.root;
        }

        // 서브 키가 있는 경우 해당 제목을 반환
        return baseObject[subKey] || ''; // 서브 키가 없는 경우 기본 제목 반환
    };

    return (
        <header className={`w-full flex-center h-[64px] pt-2 text-[12px] z-50 fixed top-0 bg-white`}>
            {/*  ${location === '' && ' bg-bg1'} */}
            <div className="fixed left-0 top-[-60px] w-screen h-[100px] bg-white"
                style={{ zIndex: 50 }}
            ></div>
            <div className='flex items-center justify-between w-[360px] px-5 py-[10px]'
                style={{ zIndex: 100 }}
            >
                {/* border-solid border-white border-4 rounded-lg */}
                <img src={logo} onClick={() => {
                    navigate('/'); navigate(0);
                }} className='cursor-pointer w-10 h-10' />
                {location && <h1 className='text-center text-[18px] font-semibold'>{getTitleFromLocation(location)}</h1>}
                <Hamburger />

            </div>
        </header>
    )
}

export default Header