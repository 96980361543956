export const word8_1 = [
    { "kr": "아가씨", "mean": "お嬢さん" },
    { "kr": "아기", "mean": "赤ちゃん、幼児" },
    { "kr": "아까", "mean": "さっき" },
    { "kr": "아내", "mean": "妻" },
    { "kr": "아뇨", "mean": "いいえ" },
    { "kr": "아니다", "mean": "～でない" },
    { "kr": "아니오", "mean": "いいえ" },
    { "kr": "아니요", "mean": "いいえ" },
    { "kr": "아들", "mean": "息子" },
    { "kr": "아랍어", "mean": "アラビア語" },
    { "kr": "아래", "mean": "下" },
    { "kr": "아름답다", "mean": "美しい" },
    { "kr": "아마", "mean": "多分、おそらく" },
    { "kr": "아무", "mean": "何（もない）、誰（もいない）" },
    { "kr": "아버님", "mean": "お父様" },
    { "kr": "아버지", "mean": "父" },
    { "kr": "아빠", "mean": "パパ" },
    { "kr": "아시아", "mean": "アジア" },
    { "kr": "아이", "mean": "子供" },
    { "kr": "아이스크림", "mean": "アイスクリーム" },
    { "kr": "아저씨", "mean": "おじさん" },
    { "kr": "아주", "mean": "とても、完全に" },
    { "kr": "아주머니", "mean": "おばさん" },
    { "kr": "아줌마", "mean": "おばさん" },
    { "kr": "아직", "mean": "まだ、いまだに" },
    { "kr": "아침", "mean": "朝、朝食" },
    { "kr": "아침밥", "mean": "朝ご飯" },
    { "kr": "아침식사", "mean": "朝食" },
    { "kr": "아파트", "mean": "アパート、マンション" },
    { "kr": "아프다", "mean": "痛い、病気だ" },
    { "kr": "아프리카", "mean": "アフリカ" },
    { "kr": "아홉", "mean": "9" },
    { "kr": "아흔", "mean": "90" },
    { "kr": "악기", "mean": "楽器" },
    { "kr": "안", "mean": "～しない、中" },
    { "kr": "안경", "mean": "眼鏡" },
    { "kr": "안내하다", "mean": "案内する" },
    { "kr": "안녕하세요", "mean": "こんにちは" },
    { "kr": "안녕하십니까", "mean": "こんにちは" },
    { "kr": "안다", "mean": "抱く" },
    { "kr": "안전하다", "mean": "安全だ" },
    { "kr": "앉다", "mean": "座る" },
    { "kr": "알다", "mean": "知る、分かる" },
    { "kr": "알리다", "mean": "知らせる" },
    { "kr": "알맞다", "mean": "ふさわしい、適当だ" },
    { "kr": "알았습니다", "mean": "わかりました" },
    { "kr": "앞", "mean": "前" },
    { "kr": "애", "mean": "子供" },
    { "kr": "액세서리", "mean": "アクセサリ" },
    { "kr": "야구", "mean": "野球" },
    { "kr": "야채", "mean": "野菜" },
    { "kr": "약", "mean": "約～、薬" },
    { "kr": "약간", "mean": "若干、少し" },
    { "kr": "약국", "mean": "薬局" },
    { "kr": "약사", "mean": "薬剤師" },
    { "kr": "약속", "mean": "約束" },
    { "kr": "얇다", "mean": "薄い" },
    { "kr": "양", "mean": "量" },
    { "kr": "양말", "mean": "靴下" },
    { "kr": "양복", "mean": "スーツ、背広" },
    { "kr": "양식", "mean": "洋食" },
    { "kr": "양파", "mean": "たまねぎ" },
    { "kr": "얘기하다", "mean": "話す" },
    { "kr": "어깨", "mean": "肩" },
    { "kr": "어느", "mean": "どの～" },
    { "kr": "어둡다", "mean": "暗い" },
    { "kr": "어디", "mean": "どこ" },
    { "kr": "어떤", "mean": "どんな" },
    { "kr": "어떻게", "mean": "どのように" },
    { "kr": "어떻다", "mean": "どうだ" },
    { "kr": "어떻습니까?", "mean": "どうですか" },
    { "kr": "어렵다", "mean": "難しい" },
    { "kr": "어른", "mean": "大人" },
    { "kr": "어리다", "mean": "幼い" },
    { "kr": "어린이", "mean": "子供" },
    { "kr": "어머", "mean": "まあ、おや（女性が使う）" },
    { "kr": "어머니", "mean": "母" },
    { "kr": "어머님", "mean": "お母様" },
    { "kr": "어서", "mean": "はやく" },
    { "kr": "어울리다", "mean": "似合う、仲良く付き合う" },
    { "kr": "어저께", "mean": "昨日" },
    { "kr": "어제", "mean": "きのう" },
    { "kr": "어젯밤", "mean": "昨夜" },
    { "kr": "언니", "mean": "（妹から見て）姉" },
    { "kr": "언제", "mean": "いつ" },
    { "kr": "언제나", "mean": "いつも、いつでも" },
    { "kr": "얼굴", "mean": "顔" },
    { "kr": "얼마나", "mean": "いくらほど、どのくらい" },
    { "kr": "얼음", "mean": "氷" },
    { "kr": "엄마", "mean": "ママ" },
    { "kr": "없다", "mean": "ない、いない" },
    { "kr": "없이", "mean": "～なしで" },
    { "kr": "에어컨", "mean": "エアコン、クーラー" },
    { "kr": "엔", "mean": "円" },
    { "kr": "엘리베이터", "mean": "エレベーター" },
    { "kr": "여권", "mean": "旅券、パスポート" },
    { "kr": "여기", "mean": "ここ" },
    { "kr": "여기저기", "mean": "あちこち" },
    { "kr": "여덟", "mean": "8" },
    { "kr": "여동생", "mean": "妹" }
]

export const word8_2 = [
    { "kr": "여든", "mean": "80" },
    { "kr": "여러", "mean": "多くの" },
    { "kr": "여러가지", "mean": "いろいろ" },
    { "kr": "여러분", "mean": "皆様" },
    { "kr": "여름", "mean": "夏" },
    { "kr": "여보세요", "mean": "もしもし" },
    { "kr": "여섯", "mean": "6" },
    { "kr": "여자", "mean": "女性" },
    { "kr": "여학생", "mean": "女子学生" },
    { "kr": "여행", "mean": "旅行" },
    { "kr": "여행사", "mean": "旅行社" },
    { "kr": "역", "mean": "駅" },
    { "kr": "역사", "mean": "歴史" },
    { "kr": "연락처", "mean": "連絡先" },
    { "kr": "연세", "mean": "お歳（「年齢」の尊敬語）" },
    { "kr": "연습", "mean": "練習" },
    { "kr": "연습하다", "mean": "練習する" },
    { "kr": "연예인", "mean": "芸能人" },
    { "kr": "연필", "mean": "鉛筆" },
    { "kr": "연휴", "mean": "連休" },
    { "kr": "열", "mean": "熱、10" },
    { "kr": "열다", "mean": "開く（あく）、開ける、（物事を）始める" },
    { "kr": "열리다", "mean": "開く（ひらく）、開かれる、（物事が）始まる" },
    { "kr": "열쇠", "mean": "鍵" },
    { "kr": "열심히", "mean": "熱心に、懸命に" },
    { "kr": "열차", "mean": "列車" },
    { "kr": "엽서", "mean": "はがき" },
    { "kr": "영", "mean": "ゼロ" },
    { "kr": "영국", "mean": "英国、イギリス" },
    { "kr": "영상", "mean": "映像" },
    { "kr": "영어", "mean": "英語" },
    { "kr": "영어회화", "mean": "英会話" },
    { "kr": "영하", "mean": "零下、氷点下" },
    { "kr": "영화", "mean": "映画" },
    { "kr": "영화관", "mean": "映画館" },
    { "kr": "영화배우", "mean": "映画俳優" },
    { "kr": "영화표", "mean": "映画のチケット" },
    { "kr": "옆", "mean": "横" },
    { "kr": "예", "mean": "はい" },
    { "kr": "예문", "mean": "例文" },
    { "kr": "예쁘다", "mean": "きれいだ" },
    { "kr": "예순", "mean": "60" },
    { "kr": "예약", "mean": "予約" },
    { "kr": "옛날", "mean": "昔" },
    { "kr": "오", "mean": "5" },
    { "kr": "오늘", "mean": "きょう" },
    { "kr": "오다", "mean": "来る" },
    { "kr": "오래", "mean": "長く、ずっと" },
    { "kr": "오래간만", "mean": "久しぶり" },
    { "kr": "오래간만입니다", "mean": "お久しぶりです" },
    { "kr": "오랜만", "mean": "久しぶり" },
    { "kr": "오랜만에", "mean": "久しぶりに" },
    { "kr": "오랫동안", "mean": "長い間" },
    { "kr": "오렌지", "mean": "オレンジ" },
    { "kr": "오르다", "mean": "上る、登る" },
    { "kr": "오른쪽", "mean": "右、右側" },
    { "kr": "오리", "mean": "あひる、鴨" },
    { "kr": "오빠", "mean": "（妹から見て）兄" },
    { "kr": "오십", "mean": "50" },
    { "kr": "오월", "mean": "５月" },
    { "kr": "오이", "mean": "きゅうり" },
    { "kr": "오전", "mean": "午前" },
    { "kr": "오징어", "mean": "いか（烏賊）" },
    { "kr": "오후", "mean": "午後" },
    { "kr": "올라가다", "mean": "上って行く" },
    { "kr": "올라오다", "mean": "上って来る" },
    { "kr": "올려놓다", "mean": "乗せる" },
    { "kr": "올림픽", "mean": "オリンピック" },
    { "kr": "올해", "mean": "今年" },
    { "kr": "옮기다", "mean": "移す、翻訳する" },
    { "kr": "옷", "mean": "服" },
    { "kr": "옷가게", "mean": "服屋、衣服店、洋服店" },
    { "kr": "옷장", "mean": "洋服だんす" },
    { "kr": "와이셔츠", "mean": "ワイシャツ" },
    { "kr": "왜", "mean": "なぜ、どうして" },
    { "kr": "왜냐하면", "mean": "なぜかというと、なぜならば" },
    { "kr": "외국", "mean": "外国" },
    { "kr": "외국어", "mean": "外国語" },
    { "kr": "외국인", "mean": "外国人" },
    { "kr": "외롭다", "mean": "（一人ぼっちで）さびしい、心細い" },
    { "kr": "외삼촌", "mean": "（母方の）おじ" },
    { "kr": "외숙모", "mean": "（母方の）おば" },
    { "kr": "외출하다", "mean": "外出する" },
    { "kr": "외할머니", "mean": "（母方の）祖母" },
    { "kr": "외할아버지", "mean": "（母方の）祖父" },
    { "kr": "왼쪽", "mean": "左、左側" },
    { "kr": "요금", "mean": "料金" },
    { "kr": "요르단", "mean": "ヨルダン" },
    { "kr": "요리", "mean": "料理" },
    { "kr": "요일", "mean": "曜日" },
    { "kr": "요즘", "mean": "この頃、近ごろ" },
    { "kr": "우리", "mean": "我々" },
    { "kr": "우리나라", "mean": "韓国、わが国" },
    { "kr": "우산", "mean": "雨傘、傘" },
    { "kr": "우선", "mean": "まず、最初に" },
    { "kr": "우유", "mean": "牛乳" },
    { "kr": "우체국", "mean": "郵便局" },
    { "kr": "우표", "mean": "切手" },
    { "kr": "운동", "mean": "運動" },
    { "kr": "운동복", "mean": "体操服、スポーツウェア" }
]

export const word8_3 = [
    { "kr": "운동선수", "mean": "スポーツ選手" },
    { "kr": "운동장", "mean": "運動場" },
    { "kr": "운동하다", "mean": "運動する、スポーツする" },
    { "kr": "운동화", "mean": "運動靴" },
    { "kr": "운전하다", "mean": "運転する" },
    { "kr": "울다", "mean": "（人が）泣く、（動物が）鳴く、（鐘などが）鳴る" },
    { "kr": "울리다", "mean": "泣かせる、鳴かせる、鳴らす" },
    { "kr": "움직이다", "mean": "動く" },
    { "kr": "웃기다", "mean": "笑わせる" },
    { "kr": "웃다", "mean": "笑う" },
    { "kr": "원", "mean": "ウォン（韓国の貨幣単位）" },
    { "kr": "원피스", "mean": "ワンピース" },
    { "kr": "원하다", "mean": "願う、望む" },
    { "kr": "월", "mean": "～（ヶ）月" },
    { "kr": "월급", "mean": "月給、給料" },
    { "kr": "월드컵", "mean": "ワールドカップ" },
    { "kr": "월세", "mean": "（月払いの）家賃、賃料" },
    { "kr": "월요일", "mean": "月曜日" },
    { "kr": "웬일", "mean": "何事、どうしたこと" },
    { "kr": "위", "mean": "上" },
    { "kr": "위치", "mean": "位置" },
    { "kr": "위하다", "mean": "（～のために）する" },
    { "kr": "위험하다", "mean": "危険だ" },
    { "kr": "유럽", "mean": "ヨーロッパ" },
    { "kr": "유리", "mean": "ガラス" },
    { "kr": "유명하다", "mean": "有名だ" },
    { "kr": "유월", "mean": "６月" },
    { "kr": "유학", "mean": "留学" },
    { "kr": "유학생", "mean": "留学生" },
    { "kr": "유행", "mean": "流行" },
    { "kr": "육", "mean": "6" },
    { "kr": "육십", "mean": "60" },
    { "kr": "은행", "mean": "銀行" },
    { "kr": "은행원", "mean": "銀行員" },
    { "kr": "음료수", "mean": "飲料水、清涼飲料水、ジュース" },
    { "kr": "음반", "mean": "音盤" },
    { "kr": "음식", "mean": "食べ物、料理" },
    { "kr": "음악", "mean": "音楽" },
    { "kr": "음악가", "mean": "音楽家" },
    { "kr": "응", "mean": "うん（返事）" },
    { "kr": "의미", "mean": "意味" },
    { "kr": "의사", "mean": "医者" },
    { "kr": "의자", "mean": "いす" },
    { "kr": "이", "mean": "歯、この、２" },
    { "kr": "이거", "mean": "これ" },
    { "kr": "이것", "mean": "これ" },
    { "kr": "이곳", "mean": "ここ" },
    { "kr": "이따가", "mean": "後で、後ほど" },
    { "kr": "이런", "mean": "こんな～" },
    { "kr": "이렇게", "mean": "こんなに、このように" },
    { "kr": "이렇다", "mean": "こうだ" },
    { "kr": "이름", "mean": "名前" },
    { "kr": "이메일", "mean": "ｅメール、電子メール" },
    { "kr": "이모", "mean": "（母方の）おば" },
    { "kr": "이모부", "mean": "（母方の）おじ" },
    { "kr": "이번", "mean": "今回、こんど" },
    { "kr": "이분", "mean": "この方" },
    { "kr": "이사", "mean": "引っ越し" },
    { "kr": "이상", "mean": "以上" },
    { "kr": "이상하다", "mean": "おかしい、変だ" },
    { "kr": "이십", "mean": "20" },
    { "kr": "이야기", "mean": "話、物語" },
    { "kr": "이야기하다", "mean": "話す" },
    { "kr": "이용", "mean": "利用" },
    { "kr": "이용하다", "mean": "利用する" },
    { "kr": "이월", "mean": "２月" },
    { "kr": "이유", "mean": "理由" },
    { "kr": "이제", "mean": "いまや、もう" },
    { "kr": "이집트", "mean": "エジプト" },
    { "kr": "이쪽", "mean": "こちら、この方" },
    { "kr": "이틀", "mean": "２日、２日間" },
    { "kr": "이해", "mean": "理解" },
    { "kr": "이해하다", "mean": "理解する" },
    { "kr": "인", "mean": "～人" },
    { "kr": "인구", "mean": "人口" },
    { "kr": "인기", "mean": "人気" },
    { "kr": "인기스타", "mean": "人気スター" },
    { "kr": "인도", "mean": "インド" },
    { "kr": "인사", "mean": "挨拶" },
    { "kr": "인사하다", "mean": "挨拶する" },
    { "kr": "인삼", "mean": "人参、高麗人参" },
    { "kr": "인상", "mean": "印象" },
    { "kr": "인천", "mean": "インチョン（仁川）" },
    { "kr": "인터넷", "mean": "インターネット" },
    { "kr": "인터뷰하다", "mean": "インタビューする" },
    { "kr": "인형", "mean": "人形" },
    { "kr": "일", "mean": "1、日" },
    { "kr": "일곱", "mean": "7" },
    { "kr": "일기", "mean": "日記" },
    { "kr": "일기예보", "mean": "天気予報" },
    { "kr": "일본", "mean": "日本" },
    { "kr": "일본드라마", "mean": "日本のドラマ" },
    { "kr": "일본말", "mean": "日本語" },
    { "kr": "일본사람", "mean": "日本人" },
    { "kr": "일본어", "mean": "日本語" },
    { "kr": "일본요리", "mean": "日本料理" },
    { "kr": "일상생활", "mean": "日常生活" },
    { "kr": "일식", "mean": "日本料理" },
    { "kr": "일어나다", "mean": "起きる、立つ、起こる" },
    { "kr": "일어서다", "mean": "立ち上がる" }
]


export const word8_4 = [
    { "kr": "일요일", "mean": "日曜日" },
    { "kr": "일월", "mean": "１月" },
    { "kr": "일주일", "mean": "１週間" },
    { "kr": "일찍", "mean": "（時間的に）はやく" },
    { "kr": "일하다", "mean": "働く" },
    { "kr": "일흔", "mean": "70" },
    { "kr": "읽기", "mean": "読むこと、読解" },
    { "kr": "읽다", "mean": "読む" },
    { "kr": "잃다", "mean": "失う" },
    { "kr": "잃어버리다", "mean": "失くしてしまう" },
    { "kr": "입", "mean": "口" },
    { "kr": "입구", "mean": "入り口" },
    { "kr": "입다", "mean": "着る" },
    { "kr": "입원하다", "mean": "入院する" },
    { "kr": "입학", "mean": "入学" },
    { "kr": "있다", "mean": "ある、いる" },
    { "kr": "잊다", "mean": "忘れる" },
    { "kr": "잊어버리다", "mean": "忘れる、忘れてしまう" }
]
