import React from 'react'
import leftArr from 'assets/d-icon/left.svg'
import rightArr from 'assets/d-icon/right.svg'
leftArr:

interface ArrowButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    way: 'left' | 'right'
}

const ArrowButton = (props: ArrowButtonProps) => {
    return (
        <button {...props} className={`w-[150px] rounded-[20px] border-pr4 border-[1px] h-[70px] flex-center ${props.disabled ? 'bg-gray2' : 'bg-pr3'}`}  >
            <img src={props.way === 'left' ? leftArr : rightArr} />
        </button>
    )
}


export default ArrowButton