export const word6_1 = [
    { "kr": "바꾸다", "mean": "変える" },
    { "kr": "바뀌다", "mean": "変わる" },
    { "kr": "바나나", "mean": "バナナ" },
    { "kr": "바다", "mean": "海" },
    { "kr": "바닷가", "mean": "海辺、浜辺" },
    { "kr": "바라다", "mean": "願う" },
    { "kr": "바람", "mean": "風" },
    { "kr": "바로", "mean": "すぐに、まさに" },
    { "kr": "바르다", "mean": "塗る、つける" },
    { "kr": "바쁘다", "mean": "忙しい" },
    { "kr": "바이올린", "mean": "バイオリン" },
    { "kr": "바지", "mean": "ズボン" },
    { "kr": "박물관", "mean": "博物館" },
    { "kr": "박수", "mean": "拍手" },
    { "kr": "밖", "mean": "外" },
    { "kr": "반", "mean": "半分、班" },
    { "kr": "반갑다", "mean": "嬉しい" },
    { "kr": "반갑습니다", "mean": "お会いできて嬉しいです" },
    { "kr": "반년", "mean": "半年" },
    { "kr": "반달", "mean": "半月" },
    { "kr": "반바지", "mean": "半ズボン" },
    { "kr": "반지", "mean": "指輪" },
    { "kr": "반찬", "mean": "おかず" },
    { "kr": "받다", "mean": "受ける、受け取る" },
    { "kr": "발", "mean": "足" },
    { "kr": "발가락", "mean": "足の指" },
    { "kr": "발음", "mean": "発音" },
    { "kr": "발전", "mean": "発展" },
    { "kr": "발표", "mean": "発表" },
    { "kr": "밝다", "mean": "明るい" },
    { "kr": "밤", "mean": "夜" },
    { "kr": "밥", "mean": "ご飯、食事" },
    { "kr": "방", "mean": "部屋" },
    { "kr": "방법", "mean": "方法" },
    { "kr": "방송", "mean": "放送" },
    { "kr": "방송국", "mean": "放送局" },
    { "kr": "방학", "mean": "（夏休み、冬休みなど、学校の長期の）休み" },
    { "kr": "배", "mean": "お腹、船、梨" },
    { "kr": "배고프다", "mean": "空腹だ" },
    { "kr": "배구", "mean": "バレーボール" },
    { "kr": "배달", "mean": "配達、出前" },
    { "kr": "배부르다", "mean": "お腹いっぱいだ、満腹だ" },
    { "kr": "배우", "mean": "俳優" },
    { "kr": "배우다", "mean": "習う、教わる" },
    { "kr": "배탈", "mean": "腹痛" },
    { "kr": "백", "mean": "100" },
    { "kr": "백화점", "mean": "百貨店、デパート" },
    { "kr": "버리다", "mean": "捨てる" },
    { "kr": "버스", "mean": "バス" },
    { "kr": "번", "mean": "～回" },
    { "kr": "번호", "mean": "番号" },
    { "kr": "벌다", "mean": "（お金を）稼ぐ、儲ける" },
    { "kr": "벌써", "mean": "すでに、もう" },
    { "kr": "벗다", "mean": "脱ぐ" },
    { "kr": "벚꽃", "mean": "桜" },
    { "kr": "베이징", "mean": "北京" },
    { "kr": "베트남", "mean": "ベトナム" },
    { "kr": "벽", "mean": "壁" },
    { "kr": "변호사", "mean": "弁護士" },
    { "kr": "별", "mean": "星" },
    { "kr": "별로", "mean": "別に" },
    { "kr": "병", "mean": "（瓶）～本、病気" },
    { "kr": "병원", "mean": "病院" },
    { "kr": "보내다", "mean": "送る、過ごす" },
    { "kr": "보다", "mean": "見る、より" },
    { "kr": "보도", "mean": "報道" },
    { "kr": "보이다", "mean": "見える、見せる" },
    { "kr": "보통", "mean": "ふつう、一般的に" },
    { "kr": "복숭아", "mean": "桃" },
    { "kr": "복잡하다", "mean": "複雑だ、（道が）混雑している" },
    { "kr": "볶다", "mean": "炒める、煎る" },
    { "kr": "볶음밥", "mean": "チャーハン" },
    { "kr": "볼펜", "mean": "ボールペン" },
    { "kr": "봄", "mean": "春" },
    { "kr": "봉지", "mean": "袋" },
    { "kr": "봉투", "mean": "封筒" },
    { "kr": "뵙다", "mean": "お目にかかる" },
    { "kr": "부동산", "mean": "不動産" },
    { "kr": "부드럽다", "mean": "柔らかい、やさしい" },
    { "kr": "부르다", "mean": "人を呼ぶ、歌を歌う" },
    { "kr": "부모", "mean": "父母" },
    { "kr": "부부", "mean": "夫婦" },
    { "kr": "부산", "mean": "釜山" },
    { "kr": "부업", "mean": "副業" },
    { "kr": "부엌", "mean": "台所" },
    { "kr": "부인", "mean": "夫人" },
    { "kr": "부장", "mean": "部長" },
    { "kr": "부지런하다", "mean": "勤勉だ、まめだ" },
    { "kr": "부치다", "mean": "（郵便物を）送る、（チヂミ、プッチムゲなどを）焼く" },
    { "kr": "부탁", "mean": "お願い" },
    { "kr": "부탁하다", "mean": "依頼する" },
    { "kr": "북쪽", "mean": "北、北側" },
    { "kr": "분", "mean": "～名（目上の人を数える）、～分（時間）" },
    { "kr": "분위기", "mean": "雰囲気" },
    { "kr": "불", "mean": "火、明かり、電気" },
    { "kr": "불고기", "mean": "プルコギ、焼き肉" },
    { "kr": "불다", "mean": "（風が）吹く、（口笛、楽器などを）吹く" },
    { "kr": "불편하다", "mean": "不便だ、体調が悪い" },
    { "kr": "붓다", "mean": "（液体を）注ぐ" },
    { "kr": "붙다", "mean": "くっつく、（試験に）受かる" },
    { "kr": "붙이다", "mean": "つける、貼る" }
]



export const word6_2 = [
    { "kr": "브라질", "mean": "ブラジル" },
    { "kr": "블라우스", "mean": "ブラウス" },
    { "kr": "비", "mean": "雨" },
    { "kr": "비누", "mean": "石鹸" },
    { "kr": "비디오", "mean": "ビデオ" },
    { "kr": "비밀", "mean": "秘密" },
    { "kr": "비빔밥", "mean": "ビビンバ" },
    { "kr": "비슷하다", "mean": "似ている" },
    { "kr": "비싸다", "mean": "（値段が）高い" },
    { "kr": "비행기", "mean": "飛行機" },
    { "kr": "비행장", "mean": "飛行場" },
    { "kr": "빌딩", "mean": "ビル" },
    { "kr": "빌리다", "mean": "借りる" },
    { "kr": "빠르다", "mean": "（速度が）速い" },
    { "kr": "빨간색", "mean": "赤色、赤" },
    { "kr": "빨갛다", "mean": "赤い" },
    { "kr": "빨다", "mean": "洗濯する" },
    { "kr": "빨래", "mean": "洗濯" },
    { "kr": "빨리", "mean": "はやく" },
    { "kr": "빵", "mean": "パン" },
    { "kr": "빵집", "mean": "パン屋、ベーカリー" },
    { "kr": "빼다", "mean": "のける、引く、抜く" },
    { "kr": "뿐", "mean": "だけ、のみ、ばかり" }
]
