import React from 'react'
import xl from 'assets/imgs/background/xl.png'
import ex1 from 'assets/imgs/background/ex1.png'
import ex2 from 'assets/imgs/background/ex2.png'
import ex3 from 'assets/imgs/background/ex3.png'
import AnimatedSection from 'components/animation/AnimationSection'
import face from 'assets/d-icon/face.svg'


const Landing5 = () => {
    return (
        <div className='w-[360px] '>
            <div className='flex-center flex-col'>
                <div className='relative text-center rounded-[100px] p-[10px] my-8 bg-bg2 w-[320px]'>
                    <img src={face} className='absolute top-[-36px] right-3' />
                    <p className='text-white'>問題を解きながら勉強してみましょう？</p>
                </div>
                <AnimatedSection
                    className='mr-20'
                    inViewAni={{ opacity: 1 }}
                    initAni={{ opacity: 0 }}
                    duration={0.5}
                    delay={0.2}
                    threshold={0.2}
                >
                    <img src={ex1} />
                </AnimatedSection>
                <AnimatedSection
                    className='ml-20 mt-[-30px]'
                    inViewAni={{ opacity: 1 }}
                    initAni={{ opacity: 0 }}
                    duration={0.5}
                    delay={0.2}
                    threshold={0.2}
                >
                    <img src={ex2} />
                </AnimatedSection>
                <AnimatedSection
                    className='mr-20 mt-[-60px]'
                    inViewAni={{ opacity: 1 }}
                    initAni={{ opacity: 0 }}
                    duration={0.5}
                    delay={0.2}
                    threshold={0.2}
                >
                    <img src={ex3} />
                </AnimatedSection>
            </div>

        </div >
    )
}

export default Landing5