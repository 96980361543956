import React from 'react'
import backgroundImage from 'assets/imgs/background/blue.png'

type Props = {
    children?: React.ReactNode
}

const Card = (props: Props) => {
    return (
        <div
            className='flex justify-center w-[320px] h-[320px]'
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            {props.children}
        </div>
    )
}

export default Card