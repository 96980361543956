export const word_2_9_1 = [
    { "kr": "자격", "mean": "資格" },
    { "kr": "자격증", "mean": "資格証" },
    { "kr": "자극적", "mean": "刺激的" },
    { "kr": "자꾸", "mean": "しきりに" },
    { "kr": "자녀", "mean": "子女" },
    { "kr": "자동", "mean": "自動" },
    { "kr": "자라다", "mean": "成長する" },
    { "kr": "자랑", "mean": "自慢" },
    { "kr": "자료실", "mean": "アーカイブ、資料室" },
    { "kr": "자막", "mean": "字幕" },
    { "kr": "자매", "mean": "姉妹" },
    { "kr": "자살", "mean": "自殺" },
    { "kr": "자세하다", "mean": "詳しい、細かい" },
    { "kr": "자세히", "mean": "詳しく" },
    { "kr": "자식", "mean": "息子や娘、子息" },
    { "kr": "자신감", "mean": "自信、自信感" },
    { "kr": "자연적", "mean": "自然的" },
    { "kr": "자외선", "mean": "紫外線" },
    { "kr": "자원", "mean": "資源" },
    { "kr": "자음", "mean": "子音" },
    { "kr": "자존심", "mean": "自尊心、プライド" },
    { "kr": "자취", "mean": "自炊" },
    { "kr": "자판기", "mean": "自販機" },
    { "kr": "작가", "mean": "作家" },
    { "kr": "작곡", "mean": "作曲" },
    { "kr": "작문", "mean": "作文" },
    { "kr": "작사", "mean": "作詞" },
    { "kr": "작아지다", "mean": "小さくなる" },
    { "kr": "작업", "mean": "作業" },
    { "kr": "작품", "mean": "作品" },
    { "kr": "잔돈", "mean": "小銭" },
    { "kr": "잔디", "mean": "芝生" },
    { "kr": "잔디밭", "mean": "芝生" },
    { "kr": "잔뜩", "mean": "いっぱい" },
    { "kr": "잔소리", "mean": "小言" },
    { "kr": "잔액", "mean": "残額" },
    { "kr": "잠그다", "mean": "（鍵を）かける" },
    { "kr": "잠기다", "mean": "掛かる、浸かる" },
    { "kr": "잠들다", "mean": "眠る" },
    { "kr": "잠옷", "mean": "パジャマ、寝間着" },
    { "kr": "잠자리", "mean": "トンボ" },
    { "kr": "잡수다", "mean": "召し上がる" },
    { "kr": "잡아먹다", "mean": "捕って食べる" },
    { "kr": "잡채", "mean": "チャプチェ" },
    { "kr": "잡히다", "mean": "捕まる" },
    { "kr": "장가", "mean": "男が結婚すること" },
    { "kr": "장가가다", "mean": "(男性が)結婚する" },
    { "kr": "장기적", "mean": "長期的" },
    { "kr": "장난", "mean": "いたずら、わるふざけ" },
    { "kr": "장난감", "mean": "おもちゃ" },
    { "kr": "장난꾸러기", "mean": "いたずらっ子" },
    { "kr": "장남", "mean": "長男" },
    { "kr": "장녀", "mean": "長女" },
    { "kr": "장단점", "mean": "長所と短所、一長一短、メリットとデメリット" },
    { "kr": "장래", "mean": "将来" },
    { "kr": "장례식", "mean": "葬式" },
    { "kr": "장례식장", "mean": "葬儀場" },
    { "kr": "장바구니", "mean": "買い物かご" },
    { "kr": "장사", "mean": "商売" },
    { "kr": "장애", "mean": "障害" },
    { "kr": "장애인", "mean": "障害者" },
    { "kr": "장학", "mean": "奨学" },
    { "kr": "장학금", "mean": "奨学金" },
    { "kr": "재발급", "mean": "再発給" },
    { "kr": "재산", "mean": "財産" },
    { "kr": "재작년", "mean": "一昨年" },
    { "kr": "재주", "mean": "才能、素質" },
    { "kr": "재즈", "mean": "ジャズ" },
    { "kr": "재테크", "mean": "財テク" },
    { "kr": "재학", "mean": "在学" },
    { "kr": "재해", "mean": "災害" },
    { "kr": "재혼", "mean": "再婚" },
    { "kr": "재활용", "mean": "リサイクル" },
    { "kr": "재활용품", "mean": "リサイクル用品" },
    { "kr": "쟤", "mean": "あの子" },
    { "kr": "저금", "mean": "貯金" },
    { "kr": "저금통", "mean": "貯金箱" },
    { "kr": "저렴하다", "mean": "安い" },
    { "kr": "저울", "mean": "秤（はかり）" },
    { "kr": "저장", "mean": "貯蔵、保存" },
    { "kr": "저절로", "mean": "自然に、自ずと" },
    { "kr": "저축", "mean": "貯蓄" },
    { "kr": "적극적", "mean": "積極的" },
    { "kr": "적금", "mean": "積み立て、積立預金" },
    { "kr": "적당하다", "mean": "適当だ、丁度良い" },
    { "kr": "적당히", "mean": "適当に" },
    { "kr": "적성", "mean": "適正" },
    { "kr": "적어도", "mean": "少なくとも" },
    { "kr": "적어지다", "mean": "少なくなる" },
    { "kr": "적응", "mean": "適応" },
    { "kr": "적절하다", "mean": "適切だ" },
    { "kr": "전구", "mean": "電球" },
    { "kr": "전국", "mean": "全国" },
    { "kr": "전국적", "mean": "全国的" },
    { "kr": "전기", "mean": "電気" },
    { "kr": "전날", "mean": "前の日" },
    { "kr": "전달", "mean": "伝達" },
    { "kr": "전동차", "mean": "電車" },
    { "kr": "전등", "mean": "電灯" },
    { "kr": "전망", "mean": "展望" },
    { "kr": "전문가", "mean": "専門家" }
]

export const word_2_9_2 = [
    { "kr": "전문적", "mean": "専門的" },
    { "kr": "전문점", "mean": "専門店" },
    { "kr": "전반전", "mean": "前半戦" },
    { "kr": "전부", "mean": "全部" },
    { "kr": "전산실", "mean": "電算室" },
    { "kr": "전세", "mean": "保証金制度" },
    { "kr": "전셋집", "mean": "借家" },
    { "kr": "전시", "mean": "展示" },
    { "kr": "전시회", "mean": "展示会" },
    { "kr": "전원", "mean": "電源" },
    { "kr": "전자", "mean": "電子" },
    { "kr": "전자레인지", "mean": "電子レンジ" },
    { "kr": "전쟁", "mean": "戦争" },
    { "kr": "전철", "mean": "地下鉄、電車" },
    { "kr": "전체", "mean": "全体" },
    { "kr": "전체적", "mean": "全体的" },
    { "kr": "전통", "mean": "伝統" },
    { "kr": "전통문화", "mean": "伝統文化" },
    { "kr": "전통적", "mean": "伝統的" },
    { "kr": "전화벨", "mean": "電話のベル" },
    { "kr": "절대로", "mean": "絶対に" },
    { "kr": "절반", "mean": "半分、折半" },
    { "kr": "절약", "mean": "節約" },
    { "kr": "젊은이", "mean": "若者" },
    { "kr": "젊음", "mean": "若さ" },
    { "kr": "점잖다", "mean": "礼儀正しい、温厚だ" },
    { "kr": "점점", "mean": "だんだんと、徐々に" },
    { "kr": "점차", "mean": "徐々に" },
    { "kr": "접다", "mean": "折る、畳む" },
    { "kr": "접수", "mean": "受付" },
    { "kr": "접하다", "mean": "接する" },
    { "kr": "정", "mean": "情" },
    { "kr": "정기적", "mean": "定期的" },
    { "kr": "정답", "mean": "正解" },
    { "kr": "정말로", "mean": "本当に" },
    { "kr": "정보화", "mean": "情報化" },
    { "kr": "정부", "mean": "政府" },
    { "kr": "정상", "mean": "正常" },
    { "kr": "정상적", "mean": "正常的" },
    { "kr": "정성", "mean": "誠意、真心" },
    { "kr": "정성껏", "mean": "心をこめて" },
    { "kr": "정신", "mean": "精神" },
    { "kr": "정신없다", "mean": "忙しくて気が抜ける" },
    { "kr": "정신없이", "mean": "我を忘れて、無我夢中で" },
    { "kr": "정신적", "mean": "精神的" },
    { "kr": "정의", "mean": "正義" },
    { "kr": "정장", "mean": "フォーマル服、スーツ" },
    { "kr": "정전", "mean": "停電" },
    { "kr": "정지", "mean": "停止" },
    { "kr": "정직하다", "mean": "正直だ" },
    { "kr": "정차", "mean": "停車" },
    { "kr": "정체", "mean": "正体" },
    { "kr": "정치", "mean": "政治" },
    { "kr": "정치가", "mean": "政治家" },
    { "kr": "정치인", "mean": "政治家" },
    { "kr": "정치학", "mean": "政治学" },
    { "kr": "정확하다", "mean": "正確だ" },
    { "kr": "정확히", "mean": "正確に" },
    { "kr": "젖다", "mean": "浸る（ひたる）、ぬれる" },
    { "kr": "제공", "mean": "提供" },
    { "kr": "제대로", "mean": "きちんと、満足に" },
    { "kr": "제도", "mean": "制度" },
    { "kr": "제때", "mean": "適時、定時" },
    { "kr": "제발", "mean": "どうか、頼むから" },
    { "kr": "제비", "mean": "くじ、抽選、燕" },
    { "kr": "제사", "mean": "法事、祭祀" },
    { "kr": "제시간", "mean": "定時" },
    { "kr": "제안", "mean": "提案" },
    { "kr": "제자", "mean": "弟子" },
    { "kr": "제자리", "mean": "もとの場所、自分の位置" },
    { "kr": "제작", "mean": "製作" },
    { "kr": "제출", "mean": "提出" },
    { "kr": "제품", "mean": "製品" },
    { "kr": "제한", "mean": "制限" },
    { "kr": "조개", "mean": "貝" },
    { "kr": "조건", "mean": "条件" },
    { "kr": "조교", "mean": "助手" },
    { "kr": "조깅", "mean": "ジョギング" },
    { "kr": "조르다", "mean": "せがむ、締める" },
    { "kr": "조리", "mean": "調理" },
    { "kr": "조리법", "mean": "調理法" },
    { "kr": "조림", "mean": "煮つけ、煮物" },
    { "kr": "조만간", "mean": "遅かれ早かれ" },
    { "kr": "조명", "mean": "照明" },
    { "kr": "조미료", "mean": "調味料" },
    { "kr": "조상", "mean": "先祖" },
    { "kr": "조심", "mean": "用心すること" },
    { "kr": "조절", "mean": "調節" },
    { "kr": "족발", "mean": "豚足" },
    { "kr": "존경", "mean": "尊敬" },
    { "kr": "졸다", "mean": "居眠りする" },
    { "kr": "졸리다", "mean": "眠い" },
    { "kr": "졸업식", "mean": "卒業式" },
    { "kr": "졸음", "mean": "眠気" },
    { "kr": "좀처럼", "mean": "なかなか～しない" },
    { "kr": "좁히다", "mean": "狭める" },
    { "kr": "종교", "mean": "宗教" },
    { "kr": "종이컵", "mean": "紙コップ" },
    { "kr": "종종", "mean": "ときどき、しばしば" },
    { "kr": "종합", "mean": "総合" },
    { "kr": "좌석", "mean": "座席" }
]


export const word_2_9_3 = [
    { "kr": "좌우", "mean": "左右" },
    { "kr": "좌회전", "mean": "左折" },
    { "kr": "주거", "mean": "住居" },
    { "kr": "주고받다", "mean": "やり取りする" },
    { "kr": "주년", "mean": "～周年" },
    { "kr": "주름", "mean": "しわ" },
    { "kr": "주먹", "mean": "こぶし、げんこつ" },
    { "kr": "주문서", "mean": "注文書" },
    { "kr": "주민", "mean": "住民" },
    { "kr": "주방", "mean": "厨房、キッチン" },
    { "kr": "주식회사", "mean": "株式会社" },
    { "kr": "주어지다", "mean": "与えられる" },
    { "kr": "주유소", "mean": "ガソリンスタンド、給油所" },
    { "kr": "주의", "mean": "注意" },
    { "kr": "주인공", "mean": "主人公" },
    { "kr": "주장", "mean": "主張" },
    { "kr": "주제", "mean": "主題、テーマ" },
    { "kr": "주택", "mean": "住宅" },
    { "kr": "주택가", "mean": "住宅街" },
    { "kr": "죽음", "mean": "死" },
    { "kr": "죽이다", "mean": "殺す、かっこいい、すごい" },
    { "kr": "준비물", "mean": "持ち物" },
    { "kr": "줄거리", "mean": "ストーリー、あらすじ" },
    { "kr": "줄무늬", "mean": "縞模様(しまもよう)" },
    { "kr": "줄어들다", "mean": "減る" },
    { "kr": "줄이다", "mean": "減らす、縮める" },
    { "kr": "줍다", "mean": "拾う" },
    { "kr": "중간", "mean": "中間" },
    { "kr": "중개소", "mean": "仲介所" },
    { "kr": "중고", "mean": "中古" },
    { "kr": "중고차", "mean": "中古車" },
    { "kr": "중고품", "mean": "中古品" },
    { "kr": "중급", "mean": "中級" },
    { "kr": "중단", "mean": "中断" },
    { "kr": "중부", "mean": "中部" },
    { "kr": "중소기업", "mean": "中小企業" },
    { "kr": "중심지", "mean": "中心地" },
    { "kr": "중앙", "mean": "中央" },
    { "kr": "중요성", "mean": "重要性" },
    { "kr": "쥐", "mean": "ネズミ" },
    { "kr": "즉", "mean": "すなわち、つまり" },
    { "kr": "즐거움", "mean": "楽しみ" },
    { "kr": "증가", "mean": "増加" },
    { "kr": "증명서", "mean": "証明書" },
    { "kr": "증상", "mean": "症状" },
    { "kr": "지겹다", "mean": "うんざりする、飽きる" },
    { "kr": "지구", "mean": "地球" },
    { "kr": "지나가다", "mean": "通り過ぎる" },
    { "kr": "지나치다", "mean": "度が過ぎる" },
    { "kr": "지난해", "mean": "去年" },
    { "kr": "지루하다", "mean": "飽き飽きする" },
    { "kr": "지붕", "mean": "屋根" },
    { "kr": "지식", "mean": "知識" },
    { "kr": "지역", "mean": "地域" },
    { "kr": "지옥", "mean": "地獄" },
    { "kr": "지우다", "mean": "なくす、消す、落とす" },
    { "kr": "지원", "mean": "サポート" },
    { "kr": "지원자", "mean": "支援者" },
    { "kr": "지위", "mean": "地位" },
    { "kr": "지저분하다", "mean": "汚らしい、散らかっている" },
    { "kr": "지점", "mean": "地点、支店" },
    { "kr": "지진", "mean": "地震" },
    { "kr": "지출", "mean": "支出" },
    { "kr": "지치다", "mean": "疲れる、くたびれる" },
    { "kr": "지켜보다", "mean": "見守る、世話する" },
    { "kr": "지퍼", "mean": "ファスナー" },
    { "kr": "지휘자", "mean": "指揮者" },
    { "kr": "직선", "mean": "直線" },
    { "kr": "직장인", "mean": "会社員" },
    { "kr": "직접적", "mean": "直接的" },
    { "kr": "직진", "mean": "ストレート" },
    { "kr": "직후", "mean": "直後" },
    { "kr": "진실", "mean": "真実" },
    { "kr": "진심", "mean": "真心、本気" },
    { "kr": "진짜", "mean": "本当、マジ" },
    { "kr": "진찰", "mean": "診察" },
    { "kr": "진통제", "mean": "鎮痛剤" },
    { "kr": "진하다", "mean": "濃い" },
    { "kr": "진학", "mean": "進学" },
    { "kr": "진행", "mean": "進行" },
    { "kr": "진행자", "mean": "司会者" },
    { "kr": "질병", "mean": "疾病" },
    { "kr": "질서", "mean": "秩序" },
    { "kr": "질투", "mean": "嫉妬" },
    { "kr": "질환", "mean": "疾患" },
    { "kr": "짐승", "mean": "獣" },
    { "kr": "집값", "mean": "住宅価格" },
    { "kr": "집다", "mean": "つかむ" },
    { "kr": "집들이", "mean": "引っ越しパーティー" },
    { "kr": "집세", "mean": "家賃" },
    { "kr": "집안", "mean": "家柄" },
    { "kr": "집안일", "mean": "家事" },
    { "kr": "집주인", "mean": "大家" },
    { "kr": "집중", "mean": "集中" },
    { "kr": "집중력", "mean": "集中力" },
    { "kr": "짙다", "mean": "濃い、深い" },
    { "kr": "짜증", "mean": "イライラ" },
    { "kr": "짝", "mean": "片方、ペア" },
    { "kr": "짝사랑", "mean": "片思い" },
    { "kr": "짝수", "mean": "偶数" },
    { "kr": "쫓기다", "mean": "追われる" }
]


export const word_2_9_4 = [
    { "kr": "쫓다", "mean": "追う" },
    { "kr": "쫓아내다", "mean": "追い出す" },
    { "kr": "쫓아다니다", "mean": "追い回す" },
    { "kr": "쭉", "mean": "ずらりと、ずっと" },
    { "kr": "찌다", "mean": "（肉が）つく、太る" },
    { "kr": "찜질", "mean": "チムジル、温療法" },
    { "kr": "찜통더위", "mean": "蒸し暑さ" },
    { "kr": "찢다", "mean": "破る、裂く" },
    { "kr": "찢어지다", "mean": "破れる" }
]
