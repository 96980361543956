export const word_2_11_1 = [
    { "kr": "카네이션", "mean": "カーネーション" },
    { "kr": "카세트", "mean": "カセット" },
    { "kr": "카센터", "mean": "カーセンター" },
    { "kr": "카페인", "mean": "カフェイン" },
    { "kr": "카펫", "mean": "カーペット" },
    { "kr": "칸", "mean": "空欄、欄" },
    { "kr": "칼국수", "mean": "カルグクス、韓国式うどん" },
    { "kr": "캄캄하다", "mean": "真っ暗だ" },
    { "kr": "캠퍼스", "mean": "キャンパス" },
    { "kr": "캠프", "mean": "キャンプ" },
    { "kr": "커다랗다", "mean": "非常に大きい" },
    { "kr": "커지다", "mean": "大きくなる" },
    { "kr": "커튼", "mean": "カーテン" },
    { "kr": "커플", "mean": "カップル" },
    { "kr": "컴퓨터실", "mean": "コンピューター室" },
    { "kr": "켜지다", "mean": "（火・電灯が）つく" },
    { "kr": "코끼리", "mean": "象" },
    { "kr": "코너", "mean": "コーナー" },
    { "kr": "코미디", "mean": "コメディー" },
    { "kr": "코미디언", "mean": "コメディアン" },
    { "kr": "콘서트", "mean": "コンサート" },
    { "kr": "콘セン트", "mean": "コンセント" },
    { "kr": "콩나물", "mean": "豆もやし" },
    { "kr": "퀴즈", "mean": "クイズ" },
    { "kr": "큰길", "mean": "大通り" },
    { "kr": "큰돈", "mean": "大金" },
    { "kr": "큰소리", "mean": "大きな声、大声" },
    { "kr": "큰일", "mean": "大ごと、重大なこと、大事" },
    { "kr": "클래식", "mean": "クラシック" },
    { "kr": "클릭", "mean": "クリック" },
    { "kr": "키우다", "mean": "大きくする、育てる" }
]
