import { TopikReadingType } from "types/topik";
import img11 from 'assets/imgs/topik/topik1/r91_1_11.png'
import img12 from 'assets/imgs/topik/topik1/r91_1_12.png'

export const ReadingQuizlist2: TopikReadingType[] = [
    {
        q: '다음을 읽고 맞지 않는 것을 고르십시오.',
        answer: [
            {
                text: '주차장은 공원 안에 있습니다.',
                isAnswer: true
            },
            {
                text: '카페 옆에 화장실이 있습니다.',
                isAnswer: false
            },
            {
                text: '공원에 입구가 한 개 있습니다.',
                isAnswer: false
            },
            {
                text: '이곳에서 농구를 할 수 있습니다.',
                isAnswer: false
            },
        ],
        isImgQ: true,
        question: img11,
    },

    {
        q: '다음을 읽고 맞지 않는 것을 고르십시오.',
        answer: [
            {
                text: '수미 씨는 오늘 바빴습니다.',
                isAnswer: false
            },
            {
                text: '수미 씨는 메시지를 보냈습니다.',
                isAnswer: false
            },
            {
                text: '수미 씨는 지금 회사에 있습니다.',
                isAnswer: false
            },
            {
                text: '수미 씨는 십 분 후에 출발할 겁니다.',
                isAnswer: true
            },
        ],
        isImgQ: true,
        question: img12,
    },

    {
        q: '다음을 읽고 내용이 같은 것을 고르십시오.',
        answer: [
            {
                text: '친구는 저와 같이 삽니다.',
                isAnswer: true
            },
            {
                text: '저는 금요일에 수업이 없습니다.',
                isAnswer: false
            },
            {
                text: '우리는 한국 노래를 가끔 듣습니다.',
                isAnswer: false
            },
            {
                text: '친구는 금요일에 혼자 노래방에 갑니다.',
                isAnswer: false
            },
        ],
        question:
            `저는 친구와 함께 삽니다.
우리는 집에서 한국 가수의 노래를 매일 듣습니다.
금요일에는 수업이 끝나고 같이 노래방에 갑니다`,
    },

    {
        q: '다음을 읽고 내용이 같은 것을 고르십시오.',
        answer: [
            {
                text: '이 축제는 가을에 합니다.',
                isAnswer: false
            },
            {
                text: '이 축제에서 춤 공연을 봤습니다.',
                isAnswer: true
            },
            {
                text: '저는 작년에 이 축제에 갔습니다.',
                isAnswer: false
            },
            {
                text: '저는 이 축제에서 치킨을 못 먹었습니다.',
                isAnswer: false
            },
        ],
        question:
            `매년 여름에 한강에서 축제를 합니다.
저는 올해 한강 축제에 처음 갔습니다.
축제에서 춤 공연도 구경하고 맛있는 치킨도 먹었습니다.`,
    },

    {
        q: '다음을 읽고 내용이 같은 것을 고르십시오.',
        answer: [
            {
                text: '저는 자전거가 없습니다.',
                isAnswer: false
            },
            {
                text: '저는 지금 자전거를 잘 탑니다.',
                isAnswer: false
            },
            {
                text: '저는 주말에 민수 씨를 만납니다.',
                isAnswer: true
            },
            {
                text: '저는 요즘 민수 씨와 자전거 여행을 합니다.',
                isAnswer: false
            },
        ],
        question:
            `저는 자전거가 있지만 잘 못 탑니다. 
그래서 저는 주말마다 민수 씨를 만나서 자전거를 배웁니다.
나중에 민수 씨와 자전거 여행을 하고 싶습니다.`,
    },

    {
        q: '다음을 읽고 중심 내용을 고르십시오.',
        answer: [
            {
                text: '저는 제주도에서 등산할 겁니다.',
                isAnswer: true
            },
            {
                text: '저는 등산을 하는 것이 힘듭니다.',
                isAnswer: false
            },
            {
                text: '저는 비가 오는 날을 좋아합니다.',
                isAnswer: false
            },
            {
                text: '저는 제주도에 가서 살려고 합니다.',
                isAnswer: false
            },
        ],
        question:
            `저는 등산을 하러 제주도에 왔습니다. 
그런데 오늘 비가 와서 등산을 못 했습니다.
내일은 꼭 산에 가고 싶습니다.`,
    },

    {
        q: '다음을 읽고 중심 내용을 고르십시오.',
        answer: [
            {
                text: '저는 모자가 마음에 듭니다.',
                isAnswer: true
            },
            {
                text: '저는 예쁜 모자가 필요합니다.',
                isAnswer: false
            },
            {
                text: '저는 친구에게 모자를 줄 겁니다.',
                isAnswer: false
            },
            {
                text: '저는 백화점에서 일하면 좋겠습니다.',
                isAnswer: false
            },
        ],
        question:
            `저는 백화점에서 모자를 하나 샀습니다.
그 모자는 가볍고 예쁩니다.
저는 요즘 밖에 나갈 때 그 모자를 자주 씁니다.`,
    },

    {
        q: '다음을 읽고 중심 내용을 고르십시오.',
        answer: [
            {
                text: '저는 선생님이 될 겁니다.',
                isAnswer: false
            },
            {
                text: '저는 김 선생님이 보고 싶습니다.',
                isAnswer: true
            },
            {
                text: '저는 고등학교를 졸업해야 합니다.',
                isAnswer: false
            },
            {
                text: '저는 학교에 다니는 것이 즐겁습니다.',
                isAnswer: false
            },
        ],
        question:
            `전 고등학교 졸업 후에 김 선생님을 못 만났습니다.
저는 이번 주말에 김 선생님을 만나러 갈 겁니다.
빨리 주말이 오면 좋겠습니다.`,
    },

    {
        q: '(  ㄱ  )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '구경하고',
                isAnswer: true
            },
            {
                text: '구경하러',
                isAnswer: false
            },
            {
                text: '구경하는',
                isAnswer: false
            },
            {
                text: '구경해도',
                isAnswer: false
            },
        ],
        question:
            `지영 씨는 제 친한 친구인데 지난달에 결혼했습니다. 저와 제 남편은 지영 씨의 결혼식에 갔습니다.
그래서 오늘 지영 씨 부부가 저와 남편을 집을 초대했습니다. 우리는 지영 씨의 집을(   ㄱ   ) 지영 씨 
부부가 만든 음식을 맛있게 먹었습니다.
그리고 결혼식 사진도 함께 봤습니다.`,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '저는 오늘 지영 씨를 집에 초대했습니다.',
                isAnswer: false
            },
            {
                text: '지영 씨는 오늘 제 남편을 못 만났습니다.',
                isAnswer: false
            },
            {
                text: '저는 오늘 지영 씨의 결혼식 사진을 봤습니다.',
                isAnswer: true
            },
            {
                text: '지영 씨는 오늘 저와 식당에서 밥을 먹었습니다.',
                isAnswer: false
            },
        ],
        question:
            `지영 씨는 제 친한 친구인데 지난달에 결혼했습니다. 저와 제 남편은 지영 씨의 결혼식에 갔습니다.
그래서 오늘 지영 씨 부부가 저와 남편을 집을 초대했습니다. 우리는 지영 씨의 집을(   ㄱ   ) 지영 씨 
부부가 만든 음식을 맛있게 먹었습니다.
그리고 결혼식 사진도 함께 봤습니다.`,
    },

]
