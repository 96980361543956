export const word_2_12_1 = [
    { "kr": "타입", "mean": "タイプ" },
    { "kr": "탈", "mean": "事故、故障、仮面" },
    { "kr": "탈의실", "mean": "脱衣室、試着室" },
    { "kr": "탈춤", "mean": "仮面舞踊、タルチュム" },
    { "kr": "탑승구", "mean": "搭乗口" },
    { "kr": "탕", "mean": "スープ、汁" },
    { "kr": "태도", "mean": "態度" },
    { "kr": "태양", "mean": "太陽" },
    { "kr": "태양열", "mean": "太陽熱" },
    { "kr": "태우다", "mean": "焼く、燃やす" },
    { "kr": "택배", "mean": "宅配、宅配便" },
    { "kr": "택배비", "mean": "宅配費" },
    { "kr": "터지다", "mean": "突然起こる、裂ける、ドッと出る" },
    { "kr": "털다", "mean": "はたく" },
    { "kr": "테이プ", "mean": "テープ" },
    { "kr": "텐트", "mean": "テント" },
    { "kr": "토끼", "mean": "ウサギ" },
    { "kr": "토론", "mean": "討論" },
    { "kr": "토하다", "mean": "吐く" },
    { "kr": "통계", "mean": "統計" },
    { "kr": "통신", "mean": "通信" },
    { "kr": "통역", "mean": "通訳" },
    { "kr": "統一", "mean": "통일" },
    { "kr": "통증", "mean": "痛み" },
    { "kr": "통지서", "mean": "通知書" },
    { "kr": "통화", "mean": "通話" },
    { "kr": "퇴근길", "mean": "仕事帰り" },
    { "kr": "퇴원", "mean": "退院" },
    { "kr": "퇴직", "mean": "退職" },
    { "kr": "투표", "mean": "投票" },
    { "kr": "튀기다", "mean": "揚げる、はじく、跳ね飛ばす" },
    { "kr": "트ラック", "mean": "トラック、貨物自動車" },
    { "kr": "特技", "mean": "특기" },
    { "kr": "특별", "mean": "特別" },
    { "kr": "특별하다", "mean": "特別だ" },
    { "kr": "特産物", "mean": "特産物" },
    { "kr": "튼튼하다", "mean": "丈夫だ、頑丈だ" },
    { "kr": "틀림없다", "mean": "間違いない、決まってる、確かだ" },
    { "kr": "틀림없이", "mean": "間違いなく" },
    { "kr": "틈", "mean": "隙、暇" },
    { "kr": "티켓", "mean": "チケット" },
    { "kr": "팀장", "mean": "チーム長" }
]
