import React, { useEffect, useState } from 'react'
import { GoogleDriveFolderHasChildrenType, GoogleDriveFolderType } from 'types/google';
import { sortByLeadingNumber } from 'utils/array';
import folder from 'assets/d-icon/file.svg';
import useAlert from 'hook/useAlert';
import Tab from 'components/tab/Tab';
import Spinner from 'components/spinner/Spinner';


const Archive = () => {
    const [items, setItems] = useState<GoogleDriveFolderHasChildrenType[]>([]);
    const [currentData, setCurrentData] = useState<GoogleDriveFolderHasChildrenType>();

    const API_KEY = process.env.REACT_APP_GDRIVE_API_KEY // Google Cloud Console에서 생성한 API 키
    const folderId = process.env.REACT_APP_FORDER_ID
    // const API_KEY = 'AIzaSyBQIRVpOIUpTirJyYt0hILcrBxYNWo7fmA'; // Google Cloud Console에서 생성한 API 키
    // const folderId = '1PdIsDsl_0zDzFpm9nQDB_tuDB2K0_p0s'
    const [showAlert, Alert] = useAlert();
    const [showErrorAlert, ErrorAlert] = useAlert();

    const [current, setCurrent] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)

    const handleClickTab = (value: number) => {
        setCurrent(value)
        setCurrentData(items[value - 1])
    }




    useEffect(() => {
        const fetchAllFilesAndFolders = async () => {
            let result
            setLoading(true)
            try {
                const url = `https://www.googleapis.com/drive/v3/files?q='${folderId}' in parents&key=${API_KEY}`;
                const response = await fetch(url);
                const data = await response.json();
                // folder list get 단어
                result = await Promise.all(data.files.map(async (ele: GoogleDriveFolderType) => {
                    const dataUrl = `https://www.googleapis.com/drive/v3/files?q='${ele.id}' in parents&key=${API_KEY}`;
                    const res = await fetch(dataUrl);
                    const itemData = await res.json();
                    // folder list inner item get    
                    return {
                        ...ele, children: itemData.files
                    }
                }))

                setItems(result as GoogleDriveFolderHasChildrenType[]);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching files and folders:", error);
                setLoading(false)
            }
        };

        fetchAllFilesAndFolders();
    }, []);

    const handleDownloadContent = (fileId: string, fileName: string, mimeType: string) => () => {
        showAlert().then(async () => {
            let downloadUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${API_KEY}`;
            setLoading(true)
            try {
                if (mimeType === 'application/vnd.google-apps.spreadsheet') {
                    // Google Sheets를 XLSX 형식으로 내보내기
                    downloadUrl = `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet&key=${API_KEY}`;
                } else if (mimeType === 'application/pdf' || mimeType === 'image/png') {
                    // PDF 및 PNG 파일의 경우 직접 다운로드
                    downloadUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${API_KEY}`;
                }

                const response = await fetch(downloadUrl);
                if (!response.ok) throw new Error("File download failed");

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                setLoading(false)
            } catch (error) {
                console.error("Error downloading the file:", error);
                showErrorAlert();
                setLoading(false)
            }
        })

    }

    useEffect(() => {
        // items가 업데이트된 후 첫 번째 폴더 데이터를 currentData로 설정
        if (items.length > 0) {
            setCurrentData(items[0]);
        }
    }, [items]); // items가 변경될 때마다 실행

    return (
        <div className='flex flex-col items-center'>
            {loading && <Spinner />}
            <div className='w-[360px] pt-[10px] px-5'>
                <div>
                    <Tab list={items.map((ele, idx) => {
                        return {
                            value: idx + 1,
                            text: ele.name
                        }
                    })} onClick={handleClickTab} />
                    <p className='pt-6 text-[12px]  font-semibold'>💡 アクセル学習資料を<b className='text-pr2'>ダウンロード</b>してみてください！</p>
                    <div className='flex flex-wrap'>
                        {currentData && sortByLeadingNumber(currentData.children).map(ele => (
                            <div className='flex gap-5 items-center h-20 border-b-[0.5px] border-[#D4DBE4] w-full'>
                                <div className='w-10 h-10 rounded-full bg-pr3 flex justify-center items-center'>
                                    <img src={folder} className='w-[30px] h-[30px]' />
                                </div>
                                <p className='font-bold cursor-pointer' onClick={handleDownloadContent(ele.id, ele.name, ele.mimeType)}>{ele.name.split('.')[1]}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <Alert
                    message={`ファイルをダウンロードします。`}
                    confirm={'ダウン'}
                    reject={'閉じる'}
                    textCenter
                />
                <ErrorAlert
                    message={`エラーTTしばらくしてからもう一度お試しください。`}
                    confirm={'閉じる'}
                    textCenter
                />
            </div>
        </div>
    )
}

export default Archive