import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from 'assets/imgs/background/lg.png';
import ContentButton from 'components/button/ContentButton';
import MbWrapper from 'components/warpper/MbWrapper';
import bottomBg from 'assets/imgs/background/girl.png';
import { useParams } from 'react-router-dom';
import Card from 'components/card/Card';

type LinkDataProps = {
  url: string;
  text: string;
};

const TopikCategory = () => {
  const { level } = useParams(); // id는 URL에서 추출된 값
  const linkData: LinkDataProps[] =
    Number(level) === 1 ?
      [
        {
          url: `/topik/listening/1/1`,
          text: `91回_1\n聞き取り`,
        },
        {
          url: '/topik/listening/1/2',
          text: `91回_2\n聞き取り`,
        },
        {
          url: '/topik/listening/1/3',
          text: `91回_3\n聞き取り`,
        },
        {
          url: '/topik/reading/1/1',
          text: `91回_1\n読み`,
        },
        {
          url: '/topik/reading/1/2',
          text: `91回_2\n読み`,
        },
        {
          url: '/topik/reading/1/3',
          text: `91回_3\n読み`,
        },
        {
          url: '/topik/reading/1/4',
          text: `91回_4\n読み`,
        },
      ]
      : [
        {
          url: `/topik/listening/2/1`,
          text: `91回_1\n聞き取り`,
        },
        {
          url: '/topik/listening/2/2',
          text: `91回_2\n聞き取り`,
        },
        {
          url: '/topik/listening/2/3',
          text: `91回_3\n聞き取り`,
        },
        {
          url: '/topik/listening/2/4',
          text: `91回_4\n聞き取り`,
        },
        {
          url: '/topik/listening/2/5',
          text: `91回_5\n聞き取り`,
        },
        {
          url: '/topik/reading/2/1',
          text: `91回_1\n読み`,
        },
        {
          url: '/topik/reading/2/2',
          text: `91回_2\n読み`,
        },
        {
          url: '/topik/reading/2/3',
          text: `91回_3\n読み`,
        },
        {
          url: '/topik/reading/2/4',
          text: `91回_4\n読み`,
        },
        {
          url: '/topik/reading/2/5',
          text: `91回_5\n読み`,
        },
      ];

  return (
    <MbWrapper bg="lg">
      <div className="flex-col flex-center gap-5">
        <div className="flex gap-x-[20px] w-[340px] gap-y-5 justify-start flex-wrap">
          {linkData.map((ele) => (
            <Link to={ele.url} className="max-h-[100px]">
              <ContentButton text={ele.text} />
            </Link>
          ))}
        </div>
      </div>
      <img src={bottomBg} alt="이미지" className="absolute bottom-0 -z-10" />
    </MbWrapper>
  );
};

export default TopikCategory;
