import React, { useState } from 'react'
import { TabProps } from './Tab'


const Nav = ({ list, onClick }: TabProps) => {
    const [current, setCurrent] = useState<number>(1)
    const handleClickTab = (value: number) => () => {
        setCurrent(value)
        onClick(value)
    }

    return (
        <ul className='flex flex-wrap  gap-[26px]'>
            {list.map(ele => (
                <li className={'cursor-pointer flex items-center text-[20px] font-bold ' +
                    ` ${current === ele.value ? 'text-pr2 pb-2 border-b-[2px] border-pr2' : 'border-b-white text-gray pb-2 border-b-[2px]'}`}
                    onClick={handleClickTab(ele.value)}>{ele.text}</li>
            ))}
        </ul>
    )
}

export default Nav