import { TopikReadingType } from "types/topik";

export const Reading2Quizlist3: TopikReadingType[] = [
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '입맛에 맞을',
                isAnswer: false
            },
            {
                text: '가슴을 울릴',
                isAnswer: false
            },
            {
                text: '발목을 잡을',
                isAnswer: true
            },
            {
                text: '손을 맞잡을',
                isAnswer: false
            },
        ],
        question:
            `최근 미술 전시회 수요가 증가하면서 돈벌이에 급급한 전시회가 늘고 있다.
일부 전시 관계자들이 전시 포스터 등 홍보 자료에 복제품 전시임을 밝히지 않고 입자료 수입만을 챙기는 것이다.
전시장을 찾아와서야 이런 사실을 알게 된 관람객을은 불만을 쏟아 내고 있다.
전문가들은 이런 전시 행태가 미술계 발전의(      ) 수 있다고 우려한다.
전시회에 실망한 사람들이 미술에 대한 관심을 아예 끊을 수 있기 때문이다.`,
    },
    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '복제품 전시회는 입장료를 받지 않는다.',
                isAnswer: false
            },
            {
                text: '최근 미술 전시회를 찾는 사람들이 많지 않다.',
                isAnswer: false
            },
            {
                text: '전시회에서 복제품을 전시하는 일이 점점 줄고 있다.',
                isAnswer: false
            },
            {
                text: '복제품 전시회인지 모르고 전시회를 방문한 관람객들이 있다.',
                isAnswer: true
            },
        ],
        question:
            `최근 미술 전시회 수요가 증가하면서 돈벌이에 급급한 전시회가 늘고 있다.
일부 전시 관계자들이 전시 포스터 등 홍보 자료에 복제품 전시임을 밝히지 않고 입자료 수입만을 챙기는 것이다.
전시장을 찾아와서야 이런 사실을 알게 된 관람객을은 불만을 쏟아 내고 있다.
전문가들은 이런 전시 행태가 미술계 발전의(      ) 수 있다고 우려한다.
전시회에 실망한 사람들이 미술에 대한 관심을 아예 끊을 수 있기 때문이다.`,
    },
    {
        q: `밑줄 친 부분에 나타난 '나'의 심정으로 가장 알맞은 것을 고르십시오.`,
        answer: [
            {
                text: '의심스럽다',
                isAnswer: true
            },
            {
                text: '고통스럽다',
                isAnswer: false
            },
            {
                text: '조심스럽다',
                isAnswer: false
            },
            {
                text: '부담스럽다',
                isAnswer: false
            },
        ],
        question:
            `꽃집을 지나다가 꽃말에 이끌려 금잔화 꽃씨를 샀다. 
화분에 심어 사무실의 내 책상 위에 두었더니 어느 날 싹이 텄다. 
때맞춰 물도 주며 나는 수시로 들여다보았다. 
신기했다. 작고 여린 싹은 눈에 띄게 쑥쑥 자랐다. 
그런데 내가 상상한 모습이 아니었다. 도대체 여기서 어떻게 꽃이 핀다는 건지.
무순처럼 길쭉하게 위로만 자라는 것이었다. 
하루는 출근해 보니 금잔화가 쓰러져 있었다. 그럼 그렇지. 
내가 무슨 식물을 키우나. 그날 나는 화분을 창가로 옮겨 놓았다. 
죽을 것 같은 모습을 눈앞에서 보고 싶지 않았다. 
그런데 어느 날부턴가 점점 줄기가 굵어지더니 잎도 제법 풍성해지기 시작했다.
어느 날에는 꽃망울도 올라와 있었다. 
금잔화는 창문으로 들어오는 풍성한 햇볕 속에서 스스로 튼튼해졌다. 
금잔화에게는 햇빛이 더 많이 필요했었나 보다. 
사람도 식물도 사랑하려면 그 대상을 제대로 알아야 하는 건 똑같구나 싶었다. 
씩씩하게 꽃피운 금잔화의 꽃말은 ‘반드시 올 행복'이다.`,
    },
    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '내 책상 위에 둔 금잔화는 금방 말라 죽었다.',
                isAnswer: false
            },
            {
                text: '나는 금잔화 화분에 물을 제대로 주지 못했다.',
                isAnswer: false
            },
            {
                text: '나는 꽃집에서 금잔화가 피어 있는 화분을 샀다.',
                isAnswer: false
            },
            {
                text: '내가 금잔화 화분을 옮긴 곳은 햇볕이 잘 들었다.',
                isAnswer: true
            },
        ],
        question:
            `꽃집을 지나다가 꽃말에 이끌려 금잔화 꽃씨를 샀다. 
화분에 심어 사무실의 내 책상 위에 두었더니 어느 날 싹이 텄다. 
때맞춰 물도 주며 나는 수시로 들여다보았다. 
신기했다. 작고 여린 싹은 눈에 띄게 쑥쑥 자랐다. 
그런데 내가 상상한 모습이 아니었다. 도대체 여기서 어떻게 꽃이 핀다는 건지.
무순처럼 길쭉하게 위로만 자라는 것이었다. 
하루는 출근해 보니 금잔화가 쓰러져 있었다. 그럼 그렇지. 
내가 무슨 식물을 키우나. 그날 나는 화분을 창가로 옮겨 놓았다. 
죽을 것 같은 모습을 눈앞에서 보고 싶지 않았다. 
그런데 어느 날부턴가 점점 줄기가 굵어지더니 잎도 제법 풍성해지기 시작했다.
어느 날에는 꽃망울도 올라와 있었다. 
금잔화는 창문으로 들어오는 풍성한 햇볕 속에서 스스로 튼튼해졌다. 
금잔화에게는 햇빛이 더 많이 필요했었나 보다. 
사람도 식물도 사랑하려면 그 대상을 제대로 알아야 하는 건 똑같구나 싶었다. 
씩씩하게 꽃피운 금잔화의 꽃말은 ‘반드시 올 행복'이다.`,
    },
    {
        q: '다음 신문 기사의 제목을 가장 잘 설명한 것을 고르십시오.',
        answer: [
            {
                text: '가수 진영이 3년 만에 콘서트를 열자 수많은 관중이 몰렸다.',
                isAnswer: true
            },
            {
                text: '가수 진영이 3년 내에 다시 콘서트를 열기로 관중에게 약속했다.',
                isAnswer: false
            },
            {
                text: '가수 진영이 3년간 준비한 콘서트를 열었으나 관중의 호응이 적었다.',
                isAnswer: false
            },
            {
                text: '가수 진영이 3년 만에 하는 콘서트에 많은 관중을 모으려고 홍보했다.',
                isAnswer: false
            },
        ],
        question:
            `가수 진영,
3년 만의 콘서트에 구름 관중`,
    },
    {
        q: '다음 신문 기사의 제목을 가장 잘 설명한 것을 고르십시오.',
        answer: [
            {
                text: '올해 국제 탁구 대회에서 한국 대표 김수미의 금메달 가능성이 높아졌다.',
                isAnswer: false
            },
            {
                text: '한국 탁구계가 김수미를 내세워 올해 국제 대회의 금메달을 노리고 있다.',
                isAnswer: false
            },
            {
                text: '한국의 대표 탁구 선수 김수미가 올해 국제 대회에서 모두 금메달을 땄다.',
                isAnswer: true
            },
            {
                text: '한국 탁구 선수 김수미가 올해 국제 대회에서 금메달을 따고자 훈련 중이다.',
                isAnswer: false
            },
        ],
        question:
            `한국 탁구의 간판 김수미,
올해 국제 대회 금메달 싹쓸이`,
    },
    {
        q: '다음 신문 기사의 제목을 가장 잘 설명한 것을 고르십시오.',
        answer: [
            {
                text: '대출 금리가 떨어지면서 부동산 시장이 살아나기 시작했다.',
                isAnswer: true
            },
            {
                text: '부동산 시장에 대한 규제가 대출 금리 하락에 영향을 미쳤다.',
                isAnswer: false
            },
            {
                text: '대출 금리가 하락했지만 부동산 시장의 거래는 줄어들고 있다.',
                isAnswer: false
            },
            {
                text: '부동산 시장을 활성화하려고 대출 금리 안정화 대책이 논의되고 있다.',
                isAnswer: false
            },
        ],
        question:
            `대출 금리 하락세, 부동산 시장 기지개`,
    },
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '렌즈를 바꿔 주는',
                isAnswer: true
            },
            {
                text: '렌즈를 꼼꼼히 잘 닦는',
                isAnswer: false
            },
            {
                text: '렌즈의 색을 어둡게 하는',
                isAnswer: false
            },
            {
                text: '렌즈의 엷은 막을 벗겨 내는',
                isAnswer: false
            },
        ],
        question:
            `선글라스는 보통 햇빛이 강할 때 눈 건강을 위해 쓴다.
그런데 선글라스의 렌즈는 사용 기한이 있다.
선글라스의 렌즈에는 자외선 차단을 위한 엷은 막이 입혀져 있는데 열에 계속 노출되면 자외선 차단 기능이 점점 떨어지게 된다.
자외선 차단 기능이 약해진 선글라스로는 눈을 
보호하기 어려우므로 5년 정도 쓰고 나면 (      ) 것이 좋다.`,
    },
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '동적인 효과를 살리기',
                isAnswer: false
            },
            {
                text: '안정적인 느낌을 주기',
                isAnswer: true
            },
            {
                text: '풍경을 중앙에 배치하기',
                isAnswer: false
            },
            {
                text: '긴장된 분위기를 연출하기',
                isAnswer: false
            },
        ],
        question:
            `사진에서 구도는 전체적인 분위기를 살리는 중요한 요소이다.
보통 동적인 느낌을 주고 싶을 때는 대각선 구도를 쓰는데 도로나 폭포 등의 풍경 사진 등에 쓰여 생동감을 표현한다.
한편 전통적인 가족사진은(      ) 위해서 삼각형 구도를 가장 많이 활용한다.
이 구도는 연장자를 중심으로 가족 구성원이 옆이나 뒤에 배치되어 전체적으로 편안하고 균형 잡힌 분위기를 만들어 낸다.`,
    },
    {
        q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '제조업에 의존하려는',
                isAnswer: false
            },
            {
                text: '자원을 생산하지 못하는',
                isAnswer: false
            },
            {
                text: '경제 발전을 이루지 못하는',
                isAnswer: true
            },
            {
                text: '기술 개발에 무리하게 투자하는',
                isAnswer: false
            },
        ],
        question:
            `풍부한 자원은 경제 성장의 필요조건이지 충분조건은 아니다.
풍부한 천연 자원을 갖추고 있음에도(      ) 경우도 있다.
풍부한 자원에만 의존해 경제 성장의 중심 산업인 제조업이나 서비스업 등에 투자를 하지 않게 되는 것이다.
반면에 천연자원이 빈약해도 새로운 기술 개발이나 교육을 통해 생산 능력을 높이는 나라도 있다.`,
    },
]