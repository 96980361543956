import React, { useEffect, useRef, useState } from 'react';
import { AnswerType } from 'types/quiz';
import MbWrapper from 'components/warpper/MbWrapper';
import Progress from 'components/bar/Progress';
import AnswerButton from 'components/button/AnswerButton';
import ArrowButton from 'components/button/ArrowButton';
import Answer from './Answer';
import { TopikListeningResult } from 'data/result';
import { getPointPerGrade } from 'utils/number';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { readingI93 } from 'data/topik/91/I/reading';
import Spinner from 'components/spinner/Spinner';
import { readingII93 } from 'data/topik/91/II/reading';

const TopikReading = () => {
  const { level } = useParams(); // id는 URL에서 추출된 값
  const location = useLocation();
  const path = location.pathname;
  const match = path.match(/\/(\d+)$/);
  const lastNumber = match ? Number(match[1]) : 1;
  const readingQuizlist = Number(level) === 1 ?
    readingI93[lastNumber - 1] :
    readingII93[lastNumber - 1];
  const navigate = useNavigate();
  const [userAnswer, setUserAnswer] = useState<{ idx: number; answer: boolean; text: string }[]>([]);
  const [answerText, setAnswerText] = useState<{ title: string; desc: string }>({
    title: '',
    desc: '',
  });
  const [showResult, setShowResult] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageLoad = () => {
    setIsLoading(false); // 이미지가 로드되면 로딩 상태를 false로 변경
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    readingQuizlist[current].isImgQ && setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false)
    }, 100)
  }, [current]);

  const handleClickAnswer = (index: number, idx: number, answer: AnswerType) => {
    let clone = [...userAnswer];
    clone[index] = {
      idx,
      answer: answer.isAnswer,
      text: answer.text,
    };
    setUserAnswer(clone);
  };

  const handleClickShowResult = () => {
    const point = userAnswer.filter((ele) => ele.answer).length;
    const total = readingQuizlist.length;
    setAnswerText({
      title: `${total}個のうち ${point}を当てた！`,
      desc: TopikListeningResult[getPointPerGrade(point, total)],
    });
    setShowResult(true);
  };

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };
  const handleClickNext = () => {
    setCurrent(current + 1);
  };

  const handleReset = () => {
    setShowResult(false);
    setUserAnswer([]);
    setCurrent(0);
  };

  return (
    <MbWrapper bg="sm">
      {isLoading && <Spinner />}
      {showResult ? (
        <Answer
          title={answerText.title}
          desc={answerText.desc}
          onReset={handleReset}
          onSelectOther={() => navigate(-1)}
          selectOtherText="後ろへ"
          hasCommentary={userAnswer.map(ele => ele.answer)}
          count={lastNumber - 1}
        />
      ) : (
        <div className="px-5">
          <Progress total={readingQuizlist.length} current={current} />
          <div className='flex-center mb-4'>
            <span className="inline-block w-[24px] leading-[24px] text-[14px] font-semibold h-[24px] text-center bg-pr2 text-white rounded-full">
              {(lastNumber - 1) * 10 + current + 1}
            </span>
          </div>
          <p className="flex-center gap-1 font-medium line-normal whitespace-pre-wrap">
            {readingQuizlist[current].q}
          </p>

          <div className="mt-[30px]">
            <p className="bg-pr5 p-5 w-full max-w-[320px] whitespace-pre-wrap rounded-[15px] leading-[20px] font-semibold">
              {readingQuizlist[current].question && (
                <div className="font-normal text-center text-[14px]">
                  {readingQuizlist[current].isImgQ ? (
                    <img src={readingQuizlist[current].question} onLoad={handleImageLoad} className="w-[320px]" />
                  ) : (
                    <p>{readingQuizlist[current].question}</p>
                  )}
                </div>
              )}
            </p>


            <div className="flex flex-wrap gap-5 mt-5">
              {readingQuizlist[current].answer.map((a, idx) => (
                <AnswerButton
                  disabled={showResult}
                  selected={userAnswer[current]?.idx === idx}
                  onClick={() => handleClickAnswer(current, idx, a)}
                >
                  {idx + 1}. {a.text}
                </AnswerButton>
              ))}
            </div>
          </div>
          <div className="flex gap-5 justify-between mt-[60px]">
            <ArrowButton onClick={handleClickPrev} disabled={current === 0} way="left" />
            {current === readingQuizlist.length - 1 ? (
              <button
                className={`w-[150px] rounded-[20px] border-pr4 border-[1px] text-pr2 h-[70px] text-[20px] font-semibold flex-center ${!userAnswer[current] ? 'bg-gray2' : 'bg-pr3'
                  }`}
                disabled={!userAnswer[current]}
                onClick={handleClickShowResult}
              >
                提出
              </button>
            ) : (
              <ArrowButton
                onClick={handleClickNext}
                disabled={!(userAnswer[current] && readingQuizlist.length - 1 !== current)}
                way="right"
              />
            )}
          </div>

          {/* <div>
                    {isFinish && <button onClick={handleClickShowResult}>제출</button>}
                    {showResult && <div>{userAnswer.map((ele, idx) => (
                        <div>{ele.idx + 1}. {String(ele.answer)} {!ele.answer && '정답은 ' + readingQuizlist[idx].answer.filter(ele => ele.isAnswer)[0].text}</div>
                    ))}
                        <div><button>もう一度</button></div>
                    </div>}
                </div> */}
        </div>
      )}
    </MbWrapper>
  );
};

export default TopikReading;
