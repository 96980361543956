import React, { useState } from 'react';
import left from 'assets/d-icon/left.svg'
import right from 'assets/d-icon/right.svg'

interface PaginationProps {
    totalItems: number;
    itemsPerPage?: number;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

const Pagination = ({ totalItems, itemsPerPage = 6, currentPage, setCurrentPage }: PaginationProps) => {
    // 전체 페이지 수 계산
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // 현재 페이지 상태


    // 현재 페이지를 기준으로 보여줄 페이지 범위 계산
    const getPaginationGroup = () => {
        const start = Math.floor((currentPage - 1) / 4) * 4;
        return new Array(4).fill("").map((_, idx) => start + idx + 1).filter(page => page <= totalPages);
    };

    // 페이지 변경 핸들러
    const goToPage = (page: number) => {
        setCurrentPage(page);
    };

    // 다음 페이지 그룹으로 이동
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    // 이전 페이지 그룹으로 이동
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    return (
        <div className='flex items-center mt-[30px]'>
            {/* 이전 버튼 */}
            <button onClick={prevPage} disabled={currentPage === 1}
                className='w-10 h-10 rounded-full bg-pr3 border-[1px] border-pr4 flex justify-center items-center'
            >
                <img src={left} className='w-[25px]' />
            </button>

            <div className='mx-[25px] flex gap-[10px]'>
                {/* 페이지 번호 */}
                {getPaginationGroup().map(page => (
                    <button
                        key={page}
                        onClick={() => goToPage(page)}

                        className={'w-10 h-10 rounded-full leading-1  border-[1px]  flex justify-center items-center font-semibold' +
                            ` ${currentPage === page ? 'bg-pr2 text-white' : 'border-gray text-gray'}`
                        }
                    >
                        {page}
                    </button>
                ))}
            </div>
            {/* 다음 버튼 */}
            <button onClick={nextPage} disabled={currentPage === totalPages}
                className='w-10 h-10 rounded-full bg-pr3 border-[1px] border-pr4 flex justify-center items-center'
            >
                <img src={right} className='w-[25px]' />
            </button>
        </div>
    );
};

export default Pagination;