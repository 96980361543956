import { Reading2Quizlist } from "./topik91_1";
import { Reading2Quizlist2 } from "./topik91_2";
import { Reading2Quizlist3 } from "./topik91_3";
import { Reading2Quizlist4 } from "./topik91_4";
import { Reading2Quizlist5 } from "./topik91_5";
// import { Reading2Quizlist2 } from "./topik91_2";
// import { Reading2Quizlist3 } from "./topik91_3";
// import { Reading2Quizlist4 } from "./topik91_4";


export const readingII93 = [
    Reading2Quizlist,
    Reading2Quizlist2,
    Reading2Quizlist3,
    Reading2Quizlist4,
    Reading2Quizlist5
    // Reading2Quizlist3,
    // Reading2Quizlist4
]