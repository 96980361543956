import React from 'react'
import textBox from 'assets/d-icon/landing/textbox.png'
import logo from 'assets/d-icon/logo.svg'
import korea from 'assets/d-icon/landing/korea.png'
import study from 'assets/d-icon/landing/study.png'
import hanguel from 'assets/d-icon/landing/hanguel.png'
import AnimatedSection from 'components/animation/AnimationSection'


const Landing3 = () => {


    const contents = [
        {
            src: hanguel,
            text: '様々な韓国語\n勉強コンテンツ'
        },
        {
            src: korea,
            text: '韓国文化\nトレンド共有'
        },
        {
            src: study,
            text: 'オン/オフライン\n講義'
        }
    ]



    return (
        <div className='bg-bg4 w-[360px] min-h-[500px] flex flex-col items-center pt-20'>
            <div className='relative'>
                <img src={textBox} />
                <p className='absolute top-[14px] left-[90px] flex items-center text-[18px] text-white'><img src={logo} className='w-[30px] left-[-32px] top-[-4px] absolute' />ヨシクラスを紹介します</p>
            </div>
            <div className='flex flex-col gap-[30px] mt-12'>
                {contents.map((ele, idx) => (
                    <AnimatedSection
                        className='bg-white rounded-full w-[240px] h-[240px] flex-center flex-col'
                        inViewAni={{ opacity: 1, x: 0 }}
                        initAni={{ opacity: 0, x: idx % 2 === 0 ? -200 : 200 }}
                        duration={0.5}
                        delay={0.2}
                        threshold={0.2}
                    >
                        <img src={ele.src} />
                        <p className='text-[20px] font-bold whitespace-pre-wrap text-center'>{ele.text}</p>
                    </AnimatedSection>
                ))}
            </div>

        </div>
    )
}

export default Landing3