import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface AnimatedSectionProps {
    children: React.ReactNode;
    className?: string;
    style?: {
        [key: string]: string | number;
    }
    inViewAni: {
        [key: string]: string | number;
    }
    initAni: {
        [key: string]: string | number;
    }
    delay?: number;
    duration?: number;
    threshold?: number;
}

const AnimatedSection = ({ children, className, style, inViewAni, initAni, duration, delay, threshold }: AnimatedSectionProps) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true, // 요소가 처음 화면에 나타날 때만 애니메이션 시작
        threshold: threshold ?? 0.1,    // 요소의 10%가 화면에 보일 때 애니메이션 트리거
    });

    React.useEffect(() => {
        if (inView) {
            controls.start({
                ...inViewAni,
                transition: { duration: duration ?? 0, delay: delay ?? 0 },
            });
        } else {
            controls.start({
                ...initAni,
            });
        }
    }, [controls, inView]);

    return (
        <motion.div
            className={className}
            style={style}
            ref={ref}
            initial={initAni}
            animate={controls}
        >
            {children}
        </motion.div>
    );
};

export default AnimatedSection;