import Button from 'components/button/Button'
import { templete } from 'data/hiragana'
import React from 'react'
import uuid from 'react-uuid'

const Hiragana = () => {
    return (
        <div className='pt-3'>
            <div className='flex-center flex-col bg-pr5 px-6 pb-0  rounded-[15px] w-[340px]'>
                <h2 className='text-center text-[18px] pt-2 pb-[2px] text-pr2 border-b-[2px] font-bold border-b-pr2'>ひらがな</h2>
                <div className='pb-10' />
                {templete.map(line => (
                    <div className='flex text-center' key={uuid()}>
                        {line.map(ele => (
                            <div className='relative w-[30px] h-[52px] mb-2 font-extrabold' key={uuid()}>
                                {ele.ko && <span className='jp-font absolute text-pr2  bg-gray2 text-[14px] top-[-20px] right-[6px] rounded-full p-[2px] inline-block w-[20px] h-[20px] line-normal font-black'>{ele.ko}</span>}
                                <span className='text-[14px]'>{ele.jp}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Hiragana