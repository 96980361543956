import { TopikReadingType } from "types/topik";

export const ReadingQuizlist3: TopikReadingType[] = [
    {
        q: '(  ㄱ  )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '그러면',
                isAnswer: true
            },
            {
                text: '그래도',
                isAnswer: false
            },
            {
                text: '그러나',
                isAnswer: false
            },
            {
                text: '그렇지만',
                isAnswer: false
            },
        ],
        question:
            `한국대학교에서는 외국인 학생들에게 한복을 빌려주는 서비스를 시작했습니다.
요즘 외국인 학생들은 명절이나 학교에서 행사가 있을 때 한복을 자주 입기 때문입니다.
한복은 1층에 있는 사무실에서 빌릴 수 있습니다.
마음에 드는 한복을 고르고 이름과 전화번호를 써서 냅니다.
(  ㄱ  ) 3일동안 무료로 한복을 빌릴 수 있습니다.`,
    },

    {
        q: '무엇에 대한 내용인지 맞는 것을 고르십시오.',
        answer: [
            {
                text: '한복을 사는 이유',
                isAnswer: false
            },
            {
                text: '한복을 빌리는 방법',
                isAnswer: true
            },
            {
                text: '한복을 입으면 좋은 점',
                isAnswer: false
            },
            {
                text: '한복을 싸게 살 수 있는 장소',
                isAnswer: false
            },
        ],
        question:
            `한국대학교에서는 외국인 학생들에게 한복을 빌려주는 서비스를 시작했습니다.
요즘 외국인 학생들은 명절이나 학교에서 행사가 있을 때 한복을 자주 입기 때문입니다.
한복은 1층에 있는 사무실에서 빌릴 수 있습니다.
마음에 드는 한복을 고르고 이름과 전화번호를 써서 냅니다.
(  ㄱ  ) 3일동안 무료로 한복을 빌릴 수 있습니다.`,
    },

    {
        q: '(  ㄱ  )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '힘들면',
                isAnswer: false
            },
            {
                text: '힘들게',
                isAnswer: false
            },
            {
                text: '힘들거나',
                isAnswer: false
            },
            {
                text: '힘들지만',
                isAnswer: true
            },
        ],
        question:
            `저는 퇴근 후에 친구들과 매일 배드민턴 모임을 합니다.
우리는 오후 8시에 모여서 배드민턴을 칩니다.
일 끝나고 매일 모이는 것이(  ㄱ  ) 친구들과 같이 하니까 재미있습니다.
처음에는 다음 날 아침에 너무 피곤했는데 지금은 괜찮습니다.
몸도 더 건강해지는 것 같습니다.`,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '저는 퇴근하고 혼자 운동을 합니다.',
                isAnswer: false
            },
            {
                text: '저는 요즘 아침에 계속 피곤합니다.',
                isAnswer: false
            },
            {
                text: '배드민턴 모임은 오후 여덟 시에 합니다.',
                isAnswer: true
            },
            {
                text: '배드민턴 모임은 일주일에 한 번 있습니다.',
                isAnswer: false
            },
        ],
        question:
            `저는 퇴근 후에 친구들과 매일 배드민턴 모임을 합니다.
우리는 오후 8시에 모여서 배드민턴을 칩니다.
일 끝나고 매일 모이는 것이(  ㄱ  ) 친구들과 같이 하니까 재미있습니다.
처음에는 다음 날 아침에 너무 피곤했는데 지금은 괜찮습니다.
몸도 더 건강해지는 것 같습니다.`,
    },

    {
        q: '(  ㄱ  )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '일이 바쁠',
                isAnswer: false
            },
            {
                text: '가게에 갈',
                isAnswer: false
            },
            {
                text: '음식을 만들',
                isAnswer: false
            },
            {
                text: '몸이 안 좋을',
                isAnswer: true
            },
        ],
        question:
            `사람들은 여러 종류의 차를 마십니다. 
그중에서(  ㄱ  ) 때 마시면 좋은 차가 있습니다.
'인삼차'는 배가 아플 때 마시면 도움이 됩니다.
또 잠을 못 잘 때는 '장미차'를 마시면 좋습니다. 
감기에는 '유자차'가 좋습니다.
이런 차들은 맛도 좋고 가게에서 쉽게 사 먹을 수 있어서 편합니다.`,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '유자차는 맛이 안 좋아서 먹기 불편합니다.',
                isAnswer: false
            },
            {
                text: '잠을 자지 못할 때 장미차가 도움이 됩니다.',
                isAnswer: true
            },
            {
                text: '배가 아플 때 인삼차를 마시면 좋지 않습니다.',
                isAnswer: false
            },
            {
                text: '장미차는 팔지 않기 때문에 만들어 먹어야 합니다.',
                isAnswer: false
            },
        ],
        question:
            `사람들은 여러 종류의 차를 마십니다. 
그중에서(  ㄱ  ) 때 마시면 좋은 차가 있습니다.
'인삼차'는 배가 아플 때 마시면 도움이 됩니다.
또 잠을 못 잘 때는 '장미차'를 마시면 좋습니다.
감기에는 '유자차'가 좋습니다.
이런 차들은 맛도 좋고 가게에서 쉽게 사 먹을 수 있어서 편합니다.`,
    },

    {
        q: '다음을 순서에 맞게 배열한 것을 고르십시오.',
        answer: [
            {
                text: '(가) - (나) - (다) - (라)',
                isAnswer: false
            },
            {
                text: '(가) - (라) - (다) - (나)',
                isAnswer: true
            },
            {
                text: '(라) - (가) - (나) - (다)',
                isAnswer: false
            },
            {
                text: '(라) - (나) - (다) - (가)',
                isAnswer: false
            },
        ],
        question:
            `(가) 저는 여행을 가면 기념품을 꼭 삽니다.
(나) 다음에는 산이나 바다 그림이 있는 컵을 사고 싶습니다.
(다) 지금까지는 유명한 건물 그림이 있는 컵을 많이 샀습니다.
(라) 기념품 중에서 여행한 장소의 그림이 있는 컵을 주로 삽니다.`,
    },

    {
        q: '다음을 순서에 맞게 배열한 것을 고르십시오.',
        answer: [
            {
                text: '(나) - (다) - (가) - (라)',
                isAnswer: false
            },
            {
                text: '(나) - (라) - (다) - (가)',
                isAnswer: false
            },
            {
                text: '(다) - (가) - (나) - (라)',
                isAnswer: false
            },
            {
                text: '(다) - (라) - (나) - (가)',
                isAnswer: true
            },
        ],
        question:
            `(가) 그렇게 하면 실수를 줄일 수 있습니다.
(나) 미리 할 일을 써 보는 것도 좋은 방법입니다.
(다) 계획을 세우지 않고 일을 하면 좋지 않습니다.
(라) 일을 할 때 실수를 자주 할 수 있기 때문입니다.`,
    },

    {
        q: '다음 문장이 들어갈 곳으로 가장 알맞은 것을 고르십시오. 잠시 후에 태국 사람이 와서 태국어로 역사를 설명해 줬습니다.',
        answer: [
            {
                text: '( ㄱ )',
                isAnswer: false
            },
            {
                text: '( ㄴ )',
                isAnswer: false
            },
            {
                text: '( ㄷ )',
                isAnswer: false
            },
            {
                text: '( ㄹ )',
                isAnswer: true
            },
        ],
        question:
            `저는 태국 친구하고 인주 역사 박물관에 갔습니다.
(  ㄱ  ) 친구는 한국어를 잘 못합니다.
(  ㄴ  ) 그런데 그 박물관에는 외국어로 설명해주는 서비스가 있었습니다.
(  ㄷ  ) 친구는 태국어 설명 서비스를 신청했습니다.
(  ㄹ  ) 설명이 쉽고 재미있어서 친구가 정말 좋아했습니다.`,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '제 친구는 한국어를 잘합니다.',
                isAnswer: false
            },
            {
                text: '태국어 설명은 조금 어려웠습니다.',
                isAnswer: false
            },
            {
                text: '제 친구는 태국어로 설명을 들었습니다.',
                isAnswer: true
            },
            {
                text: '저는 외국어 설명 서비스를 신청했습니다.',
                isAnswer: false
            },
        ],
        question:
            `저는 태국 친구하고 인주 역사 박물관에 갔습니다. 
(  ㄱ  ) 친구는 한국어를 잘 못합니다.
(  ㄴ  ) 그런데 그 박물관에는 외국어로 설명해주는 서비스가 있었습니다. 
(  ㄷ  ) 친구는 태국어 설명 서비스를 신청했습니다.
(  ㄹ  ) 설명이 쉽고 재미있어서 친구가 정말 좋아했습니다.`,
    }
]