import Icon from 'components/icon/Icon'
import React, { useState } from 'react'
import logo from 'assets/d-icon/logohigh.png'

import EmailImg from 'assets/d-icon/email.png';
import pen from 'assets/d-icon/pen.png'
import painting from 'assets/imgs/background/painting.png';
import useAlert from 'hook/useAlert';
import emailjs from 'emailjs-com';
import { validateEmail } from 'utils/valid';
import AnimatedSection from 'components/animation/AnimationSection';

const Landing6 = () => {
    const [step, setStep] = useState(false)
    const [size, setSize] = useState(40)
    const [formData, setFormData] = useState<{ author: string; content: string; }>({ author: "", content: "" })
    const [showAlert, Alert] = useAlert();
    const [showSuccessAlert, SuccessAlert] = useAlert();
    const [showErrorAlert, ErrorAlert] = useAlert();
    const [showValidAlert, ValidAlert] = useAlert();

    const handleClickIcon = () => {
        if (validateEmail(formData.author)) {
            setStep(true)
            setSize(200)
        } else {
            showValidAlert()
        }
    }

    const handleSendEmailForCotact = () => {
        showAlert().then(() => {
            const templateParams = {
                from_name: formData.author,
                to_name: process.env.REACT_APP_YOSHI_EMAIL ?? "",
                message: formData.content
            };

            if (process.env.REACT_APP_EMAIL_KEY &&
                process.env.REACT_APP_SERVICE_ID &&
                process.env.REACT_APP_TEMPELTE_ID) {
                emailjs.send(

                    process.env.REACT_APP_SERVICE_ID as string,
                    process.env.REACT_APP_TEMPELTE_ID as string,      // EmailJS에서 받은 사용자 ID로 대체합니다.
                    templateParams, // EmailJS에서 만든 템플릿 ID로 대체합니다.
                    process.env.REACT_APP_EMAIL_KEY as string,    // EmailJS에서 만든 서비스 ID로 대체합니다.
                )
                    .then((result) => {
                        showSuccessAlert()
                    }, (error) => {
                        alert(`${error.text}`)
                        showErrorAlert()
                    });

                // 폼 초기화
                setFormData({ author: "", content: "" });
                setStep(false)
                setSize(40)
            }
        })

    }

    const handleChangeForm = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        step ? setFormData({ ...formData, content: e.target.value }) :
            setFormData({ ...formData, author: e.target.value })
    }




    return (
        <div className='py-20 min-h-[700px] bg-bg4 w-full flex justify-center jp-font '>
            <AnimatedSection
                className='w-[360px]  px-2 py-6 h-full rounded-md'
                inViewAni={{ opacity: 1 }}
                initAni={{ opacity: 0 }}
                duration={0.5}
                delay={0.4}
                threshold={0.2}
            >
                <div className='relative flex justify-center mb-4'><img src={painting} />
                    <p className='text-white absolute top-3 font-semibold'>Contact</p>
                </div>
                <div className='flex justify-between items-center px-4' >
                    <div className='flex items-center relative'>
                        <Icon src={logo} width={32} height={32} className='mr-[10px]' />
                        <span className='text-[18px] font-bold'>yoshi_kr_class</span>
                    </div>
                </div>
                <div className=' h-[400px] pt-4 '>
                    <div className='ml-10 relative w-fit'>
                        <p className='text-[16px] rounded-2xl bg-[#f2f5f7] p-3'>
                            お返事を受ける住所と<br />お問い合わせ内容を書いてください！
                            <Icon src={logo} width={32} height={32} className='mr-[10px] absolute bottom-0 left-[-40px]' />
                        </p>
                    </div>
                </div>
                <div className='bg-[#f6f7f6] rounded-3xl min-h-[48px] flex py-2 overflow-hidden '>
                    {/* <Icon src={resize} width={18} height={18} className='ml-4 mt-[10px]' onClick={() => {
                        setSize(400)
                    }} /> */}
                    <textarea className='resize-none bg-[#f6f7f6] border-none outline-none py-2 pl-4 w-full overflow-hidden'
                        placeholder={step ? "お問い合わせの内容" : 'お返事のあて先'}
                        style={{
                            height: size
                        }}
                        onChange={handleChangeForm}
                        value={step ? formData.content : formData.author}
                    />
                    <span >
                        <Icon src={step ? EmailImg : pen} width={step ? 36 : 28} height={step ? 36 : 28} className={`${step ? 'mr-1' : 'mt-1 mr-2'}`}
                            onClick={step ? handleSendEmailForCotact : handleClickIcon}
                        />
                    </span>
                    {/* <div>
                        <span>아래</span>
                        <span>아미지</span>
                        <span>요시</span>
                    </div> */}
                </div>
            </AnimatedSection>
            <Alert
                message={`返信先は ${formData.author}です。`}
                confirm={'伝送'}
                reject={'閉じる'}
                textCenter
            />
            <SuccessAlert
                message={`伝送完了`}
                confirm={'閉じる'}
                textCenter
            />
            <ErrorAlert
                message={`エラーTTしばらくしてからもう一度お試しください。`}
                confirm={'閉じる'}
                textCenter
            />
            <ValidAlert
                message={`メールの形式が間違っています。`}
                confirm={'閉じる'}
                textCenter
            />
        </div >
    )
}

export default Landing6