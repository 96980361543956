import React from 'react';


const Spinner: React.FC = () => {


    return (
        <div className={`fixed inset-0 flex justify-center bg-gray2 w-screen h-screen z-[1000] opacity-30`}>
            <div
                className={`w-8 h-8 border-4 mt-[300px] border-pr2 border-t-transparent border-solid rounded-full animate-spin opacity-100`}
                role="status"
                style={{ borderTopColor: 'transparent' }}
            />
        </div>
    );
};

export default Spinner;