import axios from 'axios';
import ContentButton from 'components/button/ContentButton';
import MbWrapper from 'components/warpper/MbWrapper';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AnswerType, SentenceType } from 'types/quiz';
import ArrowButton from 'components/button/ArrowButton';
import Answer from 'pages/topik/Answer';
import Progress from 'components/bar/Progress';
import AnswerButton from 'components/button/AnswerButton';
import { QuizResult } from 'data/result';
import { getPointPerGrade } from 'utils/number';
import bottomBg from 'assets/imgs/background/girl.png';
import Spinner from 'components/spinner/Spinner';

const Sentences = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<{ data: SentenceType[] }>({ data: [] });
  const [current, setCurrent] = useState<SentenceType>();
  const [questionIdx, setQuestionIdx] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [userAnswer, setUserAnswer] = useState<number[]>([]);
  const [result, setResult] = useState<boolean>(false); // 결과상태 state
  const [currentAnswer, setCurrentAnswer] = useState<AnswerType[]>([]);
  const [answerText, setAnswerText] = useState<{ title: string; desc: string }>({
    title: '',
    desc: '',
  });
  const [showResult, setShowResult] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchGist = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://api.github.com/gists/${process.env.REACT_APP_GIST_ID}`);
        setData(JSON.parse(response.data.files[process.env.REACT_APP_GIST_SENTENCE as string].content));
        console.log(JSON.parse(response.data.files[process.env.REACT_APP_GIST_SENTENCE as string].content));
        setLoading(false);
      } catch (error) {
        // 글로벌 에러 세팅
        console.error('Error fetching the Gist:', error);
        setLoading(false);
      }
    };

    fetchGist();
  }, []);

  const handleClickPrev = () => {
    questionIdx !== 0 && setQuestionIdx(questionIdx - 1);
  };

  const handleClickNext = () => {
    current && current.list.length !== questionIdx + 1 && setQuestionIdx(questionIdx + 1);
  };

  const handleClickAnswer = (a: AnswerType, idx: number) => () => {
    let clone = [...userAnswer];
    clone[questionIdx] = idx;
    setUserAnswer(clone);

    // answer length 계산하기
    let current = [...currentAnswer];
    current[questionIdx] = a;
    setCurrentAnswer(current);
  };

  const handleClickShowResult = () => {
    const point = currentAnswer.filter((ele) => ele.isAnswer).length;
    const total = current?.list?.length || (0 && current?.list?.length);
    setAnswerText({
      title: `${total}個のうち ${point}を当てた！`,
      desc: QuizResult[getPointPerGrade(point, total)],
    });
    setShowResult(true);
  };

  // 결과 버튼 누르면
  const handleResult = () => {
    setResult(true);
  };

  // 다시풀기 버튼
  const handleResolve = () => {
    // 처음 화면으로 돌아가야함 === 1번문제로 가야됨

    setQuestionIdx(0);
    setUserAnswer([]);
    setCurrentAnswer([]);
    setResult(false);
    setShowResult(false);
  };

  if (showModal) {
    return (
      <MbWrapper bg="lg">
        {loading && <Spinner />}
        {showModal && (
          <div className="flex gap-[20px] justify-center flex-wrap w-fit">
            {data.data.map((ele) => (
              <div
                onClick={() => {
                  setCurrent(ele);
                  setShowModal(false);
                }}
                className="max-h-[100px]"
              >
                <ContentButton text={ele.tagJp} />
              </div>
            ))}
          </div>
        )}
        <img src={bottomBg} alt="이미지" className="absolute bottom-0 -z-10" />
      </MbWrapper>
    );
  } else {
    return (
      <MbWrapper bg="sm">
        {showResult ? (
          <Answer
            title={answerText.title}
            desc={answerText.desc}
            onReset={handleResolve}
            onSelectOther={() => navigate(-1)}
            selectOtherText="後ろへ"
            hasCommentary={currentAnswer.map(ele => ele.isAnswer)}
          />
        ) : (
          <div className="px-5">
            <Progress total={current?.list.length ?? 0} current={questionIdx} />

            <div className="flex items-center gap-1 font-medium line-normal justify-center">
              <p className="inline-block w-[24px] leading-[24px] text-[14px] font-semibold h-[24px] text-center bg-pr2 text-white rounded-full">
                {questionIdx + 1}
              </p>
            </div>

            <p className="font-semibold text-2xl leading-8 text-center mt-5">{current?.list[questionIdx].question}</p>

            <div className="mt-[30px]">
              <div className="flex flex-wrap gap-5 mt-5">
                {current?.list[questionIdx].answer.map((a, idx) => (
                  <AnswerButton disabled={showResult} selected={userAnswer[questionIdx] === idx} onClick={handleClickAnswer(a, idx)}>
                    {idx + 1}. {a.text}
                  </AnswerButton>
                ))}
              </div>
            </div>

            <div className="flex gap-5 justify-between mt-[60px]">
              <ArrowButton onClick={handleClickPrev} disabled={questionIdx === 0} way="left" />
              {questionIdx === (current?.list?.length ?? 0) - 1 ? (
                <button
                  className={`w-[150px] rounded-[20px] border-pr4 border-[1px] text-pr2 h-[70px] text-[20px] font-semibold flex-center ${!currentAnswer[questionIdx] ? 'bg-gray2' : 'bg-pr3'
                    }`}
                  disabled={typeof userAnswer[questionIdx] !== 'number' && !userAnswer[questionIdx]}
                  onClick={handleClickShowResult}
                >
                  확인
                </button>
              ) : (
                <ArrowButton
                  onClick={handleClickNext}
                  disabled={!(currentAnswer[questionIdx] && current?.list?.length && current.list.length - 1 !== questionIdx)}
                  way="right"
                />
              )}
            </div>
          </div>
        )}
      </MbWrapper>
    );
  }
};

export default Sentences;
