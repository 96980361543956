import Button from 'components/button/Button';
import AnswerCard, { AnswerCardProps } from 'components/card/AnswerCard';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface AnswerPageProps extends AnswerCardProps {
  onReset?: () => void;
  onSelectOther?: () => void;
  resetText?: string;
  selectOtherText?: string;
  hasCommentary?: boolean[];
  count?: number
}

const Answer = ({ title, desc, onReset, onSelectOther, resetText, selectOtherText, hasCommentary, count }: AnswerPageProps) => {
  const navigate = useNavigate();
  return (
    <div>
      <AnswerCard title={title} desc={desc} hasCommentary={hasCommentary} count={count} />
      <div className="flex justify-between mt-5">
        <Button onClick={onReset && onReset} className="text-gray bg-gray2">
          {resetText ?? 'もう一度'}
        </Button>
        {onSelectOther && (
          <Button onClick={onSelectOther} className="bg-pr2 text-white">
            {selectOtherText ?? '他の問題'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Answer;
