import { TopikReadingType } from "types/topik";
import img10 from 'assets/imgs/topik/topik1/r91_1_10.png'

export const ReadingQuizlist: TopikReadingType[] = [
    {
        q: '무엇에 대한 내용입니까?',
        answer: [
            {
                text: '시간',
                isAnswer: false
            },
            {
                text: '과일',
                isAnswer: true
            },
            {
                text: '운동',
                isAnswer: false
            },
            {
                text: '가족',
                isAnswer: false
            },
        ],
        question:
            `사과가 있습니다. 수박은 없습니다.`,
    },

    {
        q: '무엇에 대한 내용입니까?',
        answer: [
            {
                text: '나이',
                isAnswer: true
            },
            {
                text: '날짜',
                isAnswer: false
            },
            {
                text: '이름',
                isAnswer: false
            },
            {
                text: '나라',
                isAnswer: false
            },
        ],
        question:
            `저는 열다섯 살입니다. 언니는 열일곱 살입니다.`,
    },

    {
        q: '무엇에 대한 내용입니까?',
        answer: [
            {
                text: '취미',
                isAnswer: false
            },
            {
                text: '직업',
                isAnswer: true
            },
            {
                text: '약속',
                isAnswer: false
            },
            {
                text: '쇼핑',
                isAnswer: false
            },
        ],
        question:
            `수미 씨는 회사원입니다. 컴퓨터 회사에 다닙니다.`,
    },

    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '약국',
                isAnswer: false
            },
            {
                text: '공항',
                isAnswer: false
            },
            {
                text: '도서관',
                isAnswer: false
            },
            {
                text: '운동장',
                isAnswer: true
            },
        ],
        question:
            `친구를 만납니다.(      )에서 야구를 합니다`,
    },

    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오',
        answer: [
            {
                text: '도',
                isAnswer: false
            },
            {
                text: '는',
                isAnswer: false
            },
            {
                text: '하고',
                isAnswer: true
            },
            {
                text: '에게',
                isAnswer: false
            },
        ],
        question:
            `백화점에 갑니다. 바지(      ) 치마를 삽니다.`,
    },

    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '큽니다',
                isAnswer: false
            },
            {
                text: '쌉니다',
                isAnswer: false
            },
            {
                text: '가깝습니다',
                isAnswer: true
            },
            {
                text: '조용합니다',
                isAnswer: false
            },
        ],
        question:
            `병원이 집에서(      ). 걸어서 갑니다.`,
    },

    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '아주',
                isAnswer: true
            },
            {
                text: '아까',
                isAnswer: false
            },
            {
                text: '먼저',
                isAnswer: false
            },
            {
                text: '어서',
                isAnswer: false
            },
        ],
        question:
            `저는 케이크를(      ) 좋아합니다. 매일 먹습니다.`,
    },

    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '삽니다',
                isAnswer: false
            },
            {
                text: '줍니다',
                isAnswer: false
            },
            {
                text: '닫습니다',
                isAnswer: true
            },
            {
                text: '읽습니다',
                isAnswer: false
            },
        ],
        question:
            `비가 옵니다. 그래서 창문을(      ).`,
    },

    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오',
        answer: [
            {
                text: '쉽니다',
                isAnswer: true
            },
            {
                text: '됩니다',
                isAnswer: false
            },
            {
                text: '자릅니다',
                isAnswer: false
            },
            {
                text: '찾습니다',
                isAnswer: false
            },
        ],
        question:
            `오늘 많이 피곤합니다. 집에서 (      ).`,
    },

    {
        q: '다음을 읽고 맞지 않는 것을 고르십시오.',
        answer: [
            {
                text: '김밥입니다.',
                isAnswer: false
            },
            {
                text: '천오백 원입니다.',
                isAnswer: false
            },
            {
                text: '불고기 맛입니다.',
                isAnswer: false
            },
            {
                text: '십이월까지 팝니다.',
                isAnswer: true
            },
        ],
        isImgQ: true,
        question: img10
    },
]
