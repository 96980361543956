import AlertTemplate from "components/alert/Alert";
import Button from "components/button/Button";
import React, { useEffect, useState } from "react";

import 'style/alert.css';

type PromiseInfo = {
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
};

interface AlertPropsType {
    message: string;
    confirm?: string;
    reject?: string;
    className?: string;
    width?: number;
    height?: number;
    textCenter?: boolean;
    handleConfirm?: () => void;
}

const useAlert = (): [() => Promise<any>, React.MemoExoticComponent<(props: AlertPropsType) => JSX.Element>] => {
    const [modalState, setModalState] = useState<boolean>(false);
    const [promiseInfo, setPromiseInfo] = useState<PromiseInfo>({
        resolve: () => { },
        reject: () => { },
    });

    const showAlert = (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            setPromiseInfo({ resolve, reject });
            setModalState(true);
        });
    };


    const AlertComponent = ({
        message,
        confirm = '確認',
        reject,
        textCenter,
        className,
        width,
        height = 230,
        handleConfirm
    }: AlertPropsType) => {
        return (
            <AlertTemplate
                alertModalState={modalState}
                setAlertModalToggle={setModalState}
                {...{ className, width, height }}
            >
                <div className={`${textCenter && 'flex-center'} px-4 py-2 min-h-[120px]`}>
                    <div className={`modal-text pre-wrap`}>{message}</div>
                </div>

                <div className={"flex justify-center gap-5"}>
                    {reject && (
                        <button
                            type="button"
                            className='mt-3 text-center w-[50%] bg-gray2 text-gray  rounded-[10px] p-2 text-[14px]'
                            color="gray"
                            onClick={() => {
                                setModalState(false);
                            }}
                        >
                            {reject}
                        </button>
                    )}
                    <button
                        type="button"
                        className='mt-3 text-center bg-pr2 text-white rounded-[10px] w-[50%] p-2 text-[14px]'
                        onClick={() => {
                            if (handleConfirm) {
                                handleConfirm()
                            } else {
                                promiseInfo.resolve();
                                setModalState(false);
                            }

                        }}

                        autoFocus
                    >
                        {confirm}
                    </button>
                </div>
            </AlertTemplate>
        );
    };

    return [showAlert, React.memo(AlertComponent)];
};

export default useAlert;
