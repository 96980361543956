import React from 'react';
import backgroundImage from 'assets/imgs/background/blue.png';
import logo from 'assets/d-icon/logo.svg';

export type AnswerCardProps = {
  title: string;
  desc: string;
  hasCommentary?: boolean[];
  count?: number
};

const AnswerCard = ({ title, desc, hasCommentary, count }: AnswerCardProps) => {
  return (
    <div className="flex justify-center w-[320px] min-h-[320px]" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="flex flex-col gap-4  text-center justify-between font-semibold p-5 relative">
        {
          hasCommentary ? <h3 className="text-[20px] mt-2 text-white whitespace-pre-wrap">{title}</h3> :
            <h3 className="text-[36px] mt-2 text-white whitespace-pre-wrap">{title}</h3>
        }
        {hasCommentary && <div>
          <div className='bg-white p-2 rounded-[10px] flex justify-around text-[14px]'>
            {hasCommentary.map((ele, idx) => (
              <span className='flex-col flex'>
                {(count ? count * 10 : 0) + (idx) + 1}
                {ele ?
                  <span className='text-pr2'>O</span> :
                  <span className='text-pr2'>X</span>}
              </span>
            ))}
          </div>
        </div>}
        <div className="relative w-[280px] h-[120px] bg-white rounded-[20px] flex items-center flex-col justify-center whitespace-pre-wrap">
          <img src={logo} className="absolute top-[-20px] w-12" />
          <p>{desc}</p>
        </div>
      </div>

    </div>
  );
};

export default AnswerCard;
