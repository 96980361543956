import { word1_1, word1_2, word1_3 } from "./words1/word1"
import { word2_1 } from "./words1/word2"
import { word3_1, word3_2 } from "./words1/word3"
import { word4_1 } from "./words1/word4"
import { word5_1, word5_2 } from "./words1/word5"
import { word6_1, word6_2 } from "./words1/word6"
import { word7_1, word7_2, word7_3 } from "./words1/word7"
import { word8_1, word8_2, word8_3, word8_4 } from "./words1/word8"
import { word9_1, word9_2 } from "./words1/word9"
import { word10_1 } from "./words1/word10"
import { word11_1 } from "./words1/word11"
import { word12_1 } from "./words1/word12"
import { word13_1 } from "./words1/word13"
import { word14_1 } from "./words1/word14"
import { word_2_1_1, word_2_1_2, word_2_1_3, word_2_1_4 } from "./words2/word1"
import { word_2_2_1 } from "./words2/word2"
import { word_2_3_1, word_2_3_2, word_2_3_3 } from "./words2/word3"
import { word_2_4_1 } from "./words2/word4"
import { word_2_5_1, word_2_5_2 } from "./words2/word5"
import { word_2_6_1, word_2_6_2, word_2_6_3 } from "./words2/word6"
import { word_2_7_1, word_2_7_2, word_2_7_3, word_2_7_4 } from "./words2/word7"
import { word_2_8_1, word_2_8_2, word_2_8_3, word_2_8_4 } from "./words2/word8"
import { word_2_9_1, word_2_9_2, word_2_9_3, word_2_9_4 } from "./words2/word9"
import { word_2_10_1, word_2_10_2 } from "./words2/word10"
import { word_2_11_1 } from './words2/word11';
import { word_2_12_1 } from './words2/word12';
import { word_2_13_1 } from './words2/word13';
import { word_2_14_1, word_2_14_2 } from './words2/word14';

const word1 = word1_1.concat(word1_2, word1_3)
const word2 = word2_1
const word3 = word3_1.concat(word3_2)
const word4 = word4_1
const word5 = word5_1.concat(word5_2)
const word6 = word6_1.concat(word6_2)
const word7 = word7_1.concat(word7_2, word7_3)
const word8 = word8_1.concat(word8_2, word8_3, word8_4)
const word9 = word9_1.concat(word9_2)
const word10 = word10_1
const word11 = word11_1
const word12 = word12_1
const word13 = word13_1
const word14 = word14_1

const word_2_1 = word_2_1_1.concat(word_2_1_2, word_2_1_3, word_2_1_4)
const word_2_2 = word_2_2_1
const word_2_3 = word_2_3_1.concat(word_2_3_2, word_2_3_3)
const word_2_4 = word_2_4_1
const word_2_5 = word_2_5_1.concat(word_2_5_2)
const word_2_6 = word_2_6_1.concat(word_2_6_2, word_2_6_3)
const word_2_7 = word_2_7_1.concat(word_2_7_2, word_2_7_3, word_2_7_4)
const word_2_8 = word_2_8_1.concat(word_2_8_2, word_2_8_3, word_2_8_4)
const word_2_9 = word_2_9_1.concat(word_2_9_2, word_2_9_3, word_2_9_4)
const word_2_10 = word_2_10_1.concat(word_2_10_2)
const word_2_11 = word_2_11_1
const word_2_12 = word_2_12_1
const word_2_13 = word_2_13_1
const word_2_14 = word_2_14_1.concat(word_2_14_2)

export const word1DataList = [
    word1,
    word2,
    word3,
    word4,
    word5,
    word6,
    word7,
    word8,
    word9,
    word10,
    word11,
    word12,
    word13,
    word14
]


export const word2DataList = [
    word_2_1,
    word_2_2,
    word_2_3,
    word_2_4,
    word_2_5,
    word_2_6,
    word_2_7,
    word_2_8,
    word_2_9,
    word_2_10,
    word_2_11,
    word_2_12,
    word_2_13,
    word_2_14
]
