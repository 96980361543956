import { LayoutProps } from 'components/layouts/Layout'
import React from 'react'
import backgroundImage from 'assets/imgs/background/lg.png'
import backgroundImageSm from 'assets/imgs/background/sm.png'

interface MbWrapperProps extends LayoutProps {
    bg?: 'lg' | 'sm'
}

const MbWrapper = (props: MbWrapperProps) => {
    const { bg, children } = props;
    return (
        <div
            className='flex justify-center bg-image-repeat relative z-30 pb-5'
            style={{ backgroundImage: `url(${bg === 'lg' ? backgroundImage : backgroundImageSm})`, minHeight: 'calc(100vh - 64px)' }}
        >
            <div className='w-[360px] pt-4 flex justify-center px-2 h-full'>
                {children}
            </div>
        </div>
    )
}

export default MbWrapper