import { TopikListeningType } from "types/topik";
import t1_91_1 from 'assets/audio/t91_2/t2_91_21.mp3';
import t1_91_2 from 'assets/audio/t91_2/t2_91_22.mp3';
import t1_91_3 from 'assets/audio/t91_2/t2_91_23.mp3';
import t1_91_4 from 'assets/audio/t91_2/t2_91_24.mp3';
import t1_91_5 from 'assets/audio/t91_2/t2_91_25.mp3';
import t1_91_6 from 'assets/audio/t91_2/t2_91_26.mp3';
import t1_91_7 from 'assets/audio/t91_2/t2_91_27.mp3';
import t1_91_8 from 'assets/audio/t91_2/t2_91_28.mp3';
import t1_91_9 from 'assets/audio/t91_2/t2_91_29.mp3';
import t1_91_10 from 'assets/audio/t91_2/t2_91_30.mp3';

// 토픽 91 II 듣기 30
export const listening2Quizlist3: TopikListeningType[] = [
    {
        q: '다음을 듣고 물음에 답하시오. (21~22)',
        question: "남자의 중심 생각으로 가장 알맞은 것을 고르십시오.",
        audio: t1_91_1,
        answer: [
            {
                text: '회의 내용을 빠짐없이 기록해야 한다.',
                isAnswer: false
            },
            {
                text: '회의 때 낭비되는 종이를 줄이는 것이 좋다.',
                isAnswer: true
            },
            {
                text: '회의실을 지금보다 더 큰 장소로 바꿔야 한다.',
                isAnswer: false
            },
            {
                text: '발표 자료는 알아보기 쉽게 만드는 것이 좋다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오. (21~22)',
        question: "들은 내용과 같은 것을 고르십시오.",
        audio: t1_91_2,
        answer: [
            {
                text: '이번 회의는 발표 자료 없이 진행된다.',
                isAnswer: false
            },
            {
                text: '여자는 회의에서 쓸 자료를 복사할 예정이다.',
                isAnswer: false
            },
            {
                text: '남자는 회의 때 대형 화면을 사용한 적이 있다.',
                isAnswer: true
            },
            {
                text: '여자는 참석자들에게 참고할 자료를 이미 이메일로 보내 놓았다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (23~24)',
        question: '남자가 무엇을 하고 있는지 고르십시오.',
        audio: t1_91_3,
        answer: [
            {
                text: '가게의 위치를 확인하고 있다.',
                isAnswer: false
            },
            {
                text: '가게 운영에 대해 조언을 구하고 있다.',
                isAnswer: false
            },
            {
                text: '구매할 물건의 사용 방법을 물어보고 있다.',
                isAnswer: false
            },
            {
                text: '원하는 조건의 물건이 있는지 알아보고 있다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (23~24)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_4,
        answer: [
            {
                text: '여자의 가게에는 크기가 큰 냉장고가 없다.',
                isAnswer: false
            },
            {
                text: '남자는 카페에서 케이크를 팔지 않기로 했다.',
                isAnswer: false
            },
            {
                text: '남자의 카페에는 유리문이 달린 냉장고가 있다.',
                isAnswer: true
            },
            {
                text: '여자의 가게에서는 새로 나온 제품만 판매한다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (25~26)',
        question: '남자의 중심 생각으로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_5,
        answer: [
            {
                text: '마을 축제에 대한 주민들의 관심이 필요하다.',
                isAnswer: false
            },
            {
                text: '마을 특징을 활용한 것이 축제의 성공 비결이다.',
                isAnswer: true
            },
            {
                text: '축제에서 판매할 기념품을 새로 제작하는 것이 좋다.',
                isAnswer: false
            },
            {
                text: '축제를 기획할 때 성공 사례를 충분히 검토해야 한다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (25~26)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_6,
        answer: [
            {
                text: '이 마을을 아는 사람이 거의 없다.',
                isAnswer: false
            },
            {
                text: '이 마을에서 축제가 올해 처음으로 열렸다.',
                isAnswer: false
            },
            {
                text: '이 마을의 해바라기 들판은 최근에 만들어졌다.',
                isAnswer: false
            },
            {
                text: '이 마을의 특산품이 관광객에게 좋은 반응을 얻었다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (27~28)',
        question: '남자가 말하는 의도로 알맞은 것을 고르십시오.',
        audio: t1_91_7,
        answer: [
            {
                text: '이 과자의 맛이 달라진 것을 지적하려고',
                isAnswer: false
            },
            {
                text: '이 과자를 생산하게 된 이유를 알려 주려고',
                isAnswer: false
            },
            {
                text: '이 과자의 생산이 중단될 것이라는 소식을 전하려고',
                isAnswer: false
            },
            {
                text: '이 과자를 구하기 어려운 것에 대한 불만을 말하려고',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (27~28)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_8,
        answer: [
            {
                text: '이 회사는 작업 시간을 늘렸다.',
                isAnswer: true
            },
            {
                text: '남자는 이 과자를 구입한 적이 있다.',
                isAnswer: false
            },
            {
                text: '이 과자는 사람들의 관심을 끌지 못했다.',
                isAnswer: false
            },
            {
                text: '여자는 남자에게 이 과자에 대해 처음 들었다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (29~30)',
        question: '남자가 누구인지 고르십시오.',
        audio: t1_91_9,
        answer: [
            {
                text: '도로를 정비하는 사람',
                isAnswer: false
            },
            {
                text: '교통 상황을 촬영하는 사람',
                isAnswer: false
            },
            {
                text: '도로에 CCTV를 설치하는 사람',
                isAnswer: false
            },
            {
                text: '방송에서 교통 상황을 알려주는 사람',
                isAnswer: true

            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하십시오. (29~30)',
        question: '들은 내용과 같은 것을 고르십시오.',
        audio: t1_91_10,
        answer: [
            {
                text: '남자는 매일 오후에 근무를 시작한다.',
                isAnswer: false
            },
            {
                text: '남자는 이 일을 할 때 긴장하지 않는다.',
                isAnswer: false
            },
            {
                text: '남자는 CCTV를 분석하는 일을 하지 않는다.',
                isAnswer: false
            },
            {
                text: '남자의 일에는 시민들이 보낸 문자를 보는 것이 포함된다.',
                isAnswer: true
            },
        ],
    },
]
