export const ganadaApb = [
    { text: "가", value: 1 },
    { text: "나", value: 2 },
    { text: "다", value: 3 },
    { text: "라", value: 4 },
    { text: "마", value: 5 },
    { text: "바", value: 6 },
    { text: "사", value: 7 },
    { text: "아", value: 8 },
    { text: "자", value: 9 },
    { text: "차", value: 10 },
    { text: "카", value: 11 },
    { text: "타", value: 12 },
    { text: "파", value: 13 },
    { text: "하", value: 14 }
]

export const ganadara = [
    [
        { k: "가", j: "ガ" },
        { k: "나", j: "ナ" },
        { k: "다", j: "ダ" },
        { k: "라", j: "ラ" },
        { k: "마", j: "マ" },
        { k: "바", j: "バ" },
        { k: "사", j: "サ" },
        { k: "아", j: "ア" },
        { k: "자", j: "ザ" },
        { k: "차", j: "チャ" },
        { k: "카", j: "カ" },
        { k: "타", j: "タ" },
        { k: "파", j: "パ" },
        { k: "하", j: "ハ" }
    ],
    [
        { k: "거", j: "ゴ" },
        { k: "너", j: "ﾉ" },
        { k: "더", j: "ド" },
        { k: "러", j: "ロ" },
        { k: "머", j: "モ" },
        { k: "버", j: "ボ" },
        { k: "서", j: "ソ" },
        { k: "어", j: "オ" },
        { k: "저", j: "ゾ" },
        { k: "처", j: "チョ" },
        { k: "커", j: "コ" },
        { k: "터", j: "ト" },
        { k: "퍼", j: "ポ" },
        { k: "허", j: "ホ" },
    ],
    [
        { k: "고", j: "ゴ" },
        { k: "노", j: "ﾉ" },
        { k: "도", j: "ド" },
        { k: "로", j: "ロ" },
        { k: "모", j: "モ" },
        { k: "보", j: "ボ" },
        { k: "소", j: "ソ" },
        { k: "오", j: "オ" },
        { k: "조", j: "ゾ" },
        { k: "초", j: "チョ" },
        { k: "코", j: "コ" },
        { k: "토", j: "ト" },
        { k: "포", j: "ポ" },
        { k: "호", j: "ホ" },
    ],
    [
        { k: "구", j: "グ" },
        { k: "누", j: "ヌ" },
        { k: "두", j: "ドゥ" },
        { k: "루", j: "ル" },
        { k: "무", j: "ム" },
        { k: "부", j: "ブ" },
        { k: "수", j: "ス" },
        { k: "우", j: "ウ" },
        { k: "주", j: "ズ" },
        { k: "추", j: "ツ" },
        { k: "쿠", j: "ク" },
        { k: "투", j: "トゥ" },
        { k: "푸", j: "プ" },
        { k: "후", j: "フ" },
    ],
    [
        { k: "그", j: "グ" },
        { k: "느", j: "ヌ" },
        { k: "드", j: "ドゥ" },
        { k: "르", j: "ル" },
        { k: "므", j: "ム" },
        { k: "브", j: "ブ" },
        { k: "스", j: "ス" },
        { k: "으", j: "ウ" },
        { k: "즈", j: "ズ" },
        { k: "츠", j: "ツ" },
        { k: "크", j: "ク" },
        { k: "트", j: "トゥ" },
        { k: "프", j: "プ" },
        { k: "흐", j: "フ" },
    ],
    [
        { k: "기", j: "ギ" },
        { k: "니", j: "ニ" },
        { k: "디", j: "ディ" },
        { k: "리", j: "リ" },
        { k: "미", j: "ミ" },
        { k: "비", j: "ビ" },
        { k: "시", j: "シ" },
        { k: "이", j: "イ" },
        { k: "지", j: "ジ" },
        { k: "치", j: "チ" },
        { k: "키", j: "キ" },
        { k: "티", j: "テ" },
        { k: "피", j: "ピィ" },
        { k: "히", j: "ヒ" },
    ],
    [
        { k: "개", j: "ゲ" },
        { k: "내", j: "ネ" },
        { k: "대", j: "デ" },
        { k: "래", j: "レ" },
        { k: "매", j: "メ" },
        { k: "배", j: "ベ" },
        { k: "새", j: "セ" },
        { k: "애", j: "エ" },
        { k: "재", j: "ゼ" },
        { k: "채", j: "チェ" },
        { k: "캐", j: "ケ" },
        { k: "태", j: "テ" },
        { k: "패", j: "ペ" },
        { k: "해", j: "ヘ" },
    ],
    [
        { k: "게", j: "ゲ" },
        { k: "네", j: "ネ" },
        { k: "데", j: "デ" },
        { k: "레", j: "レ" },
        { k: "메", j: "メ" },
        { k: "베", j: "ベ" },
        { k: "세", j: "セ" },
        { k: "에", j: "エ" },
        { k: "제", j: "ゼ" },
        { k: "체", j: "チェ" },
        { k: "케", j: "ケ" },
        { k: "테", j: "テ" },
        { k: "페", j: "ペ" },
        { k: "헤", j: "ヘ" },
    ],
]

export const hanguelTable1 = {
    title: '가나다',
    data:
        [
            [{ kr: "가", jp: "ガ", en: 'ga' },
            { kr: "거", jp: "ゴ", en: 'geo' },
            { kr: "고", jp: "ゴ", en: 'go' },
            { kr: "구", jp: "グ", en: 'gu' },
            { kr: "그", jp: "グ", en: 'geu' },
            { kr: "기", jp: "ギ", en: 'gi' },
            { kr: "개", jp: "ゲ", en: 'gae' },
            { kr: "게", jp: "ゲ", en: 'ge' },],

            [{ kr: "나", jp: "ナ", en: "na" },
            { kr: "너", jp: "ノ", en: "neo" },
            { kr: "노", jp: "ノ", en: "no" },
            { kr: "누", jp: "ヌ", en: "nu" },
            { kr: "느", jp: "ヌ", en: "neu" },
            { kr: "니", jp: "二", en: "ni" },
            { kr: "내", jp: "ネ", en: "nae" },
            { kr: "네", jp: "ネ", en: "ne" }],

            [{ kr: "다", jp: "ダ", en: "da" },
            { kr: "더", jp: "ド", en: "deo" },
            { kr: "도", jp: "ド", en: "do" },
            { kr: "두", jp: "ドゥ", en: "du" },
            { kr: "드", jp: "ドゥ", en: "deu" },
            { kr: "디", jp: "ディ", en: "di" },
            { kr: "대", jp: "デ", en: "dae" },
            { kr: "데", jp: "デ", en: "de" }]
        ]
}


export const hanguelTable2 = {
    title: '라마바',
    data:
        [[{ kr: "라", jp: "ラ", en: "ra" },
        { kr: "러", jp: "レ", en: "reo" },
        { kr: "로", jp: "ロ", en: "ro" },
        { kr: "루", jp: "ル", en: "ru" },
        { kr: "르", jp: "ル", en: "reu" },
        { kr: "리", jp: "リ", en: "ri" },
        { kr: "래", jp: "レ", en: "rae" },
        { kr: "레", jp: "レ", en: "re" }],

        [{ kr: "마", jp: "マ", en: "ma" },
        { kr: "머", jp: "モ", en: "meo" },
        { kr: "모", jp: "モ", en: "mo" },
        { kr: "무", jp: "ム", en: "mu" },
        { kr: "므", jp: "ム", en: "meu" },
        { kr: "미", jp: "ミ", en: "mi" },
        { kr: "매", jp: "メ", en: "mae" },
        { kr: "메", jp: "メ", en: "me" }],

        [{ kr: "바", jp: "バ", en: "ba" },
        { kr: "버", jp: "ベ", en: "beo" },
        { kr: "보", jp: "ボ", en: "bo" },
        { kr: "부", jp: "ブ", en: "bu" },
        { kr: "브", jp: "ブ", en: "beu" },
        { kr: "비", jp: "ビ", en: "bi" },
        { kr: "배", jp: "ベ", en: "bae" },
        { kr: "베", jp: "ベ", en: "be" }]]
}

export const hanguelTable3 = {
    title: '사아자',
    data:
        [[{ kr: "사", jp: "サ", en: "sa" },
        { kr: "서", jp: "ソ", en: "seo" },
        { kr: "소", jp: "ソ", en: "so" },
        { kr: "수", jp: "ス", en: "su" },
        { kr: "스", jp: "ス", en: "seu" },
        { kr: "시", jp: "シ", en: "si" },
        { kr: "새", jp: "セ", en: "sae" },
        { kr: "세", jp: "セ", en: "se" }],

        [{ kr: "아", jp: "ア", en: "a" },
        { kr: "어", jp: "オ", en: "eo" },
        { kr: "오", jp: "オ", en: "o" },
        { kr: "우", jp: "ウ", en: "u" },
        { kr: "으", jp: "ウ", en: "eu" },
        { kr: "이", jp: "イ", en: "i" },
        { kr: "애", jp: "エ", en: "ae" },
        { kr: "에", jp: "エ", en: "e" }],

        [{ kr: "자", jp: "ザ", en: "ja" },
        { kr: "저", jp: "ゾ", en: "jeo" },
        { kr: "조", jp: "ゾ", en: "jo" },
        { kr: "주", jp: "ズ", en: "ju" },
        { kr: "즈", jp: "ズ", en: "jeu" },
        { kr: "지", jp: "ジ", en: "ji" },
        { kr: "재", jp: "ジェ", en: "jae" },
        { kr: "제", jp: "ジェ", en: "je" }]]
}

export const hanguelTable4 = {
    title: '차카타',
    data:
        [[{ kr: "차", jp: "チャ", en: "cha" },
        { kr: "처", jp: "チョ", en: "cheo" },
        { kr: "초", jp: "チョ", en: "cho" },
        { kr: "추", jp: "ツ", en: "chu" },
        { kr: "츠", jp: "チュ", en: "cheu" },
        { kr: "치", jp: "チ", en: "chi" },
        { kr: "채", jp: "チェ", en: "chae" },
        { kr: "체", jp: "チェ", en: "che" }],

        [{ kr: "카", jp: "カ", en: "ka" },
        { kr: "커", jp: "ケオ", en: "keo" },
        { kr: "코", jp: "コ", en: "ko" },
        { kr: "쿠", jp: "ク", en: "ku" },
        { kr: "크", jp: "ク", en: "keu" },
        { kr: "키", jp: "キ", en: "ki" },
        { kr: "캐", jp: "ケ", en: "kae" },
        { kr: "케", jp: "ケ", en: "ke" }],

        [{ kr: "타", jp: "タ", en: "ta" },
        { kr: "터", jp: "ト", en: "teo" },
        { kr: "토", jp: "ト", en: "to" },
        { kr: "투", jp: "トゥ", en: "tu" },
        { kr: "트", jp: "テュ", en: "teu" },
        { kr: "티", jp: "ティ", en: "ti" },
        { kr: "태", jp: "テ", en: "jae" },
        { kr: "테", jp: "テ", en: "je" }]],
}

export const hanguelTable5 = {
    title: '파하',
    data:
        [[{ kr: "파", jp: "パ", en: "pa" },
        { kr: "퍼", jp: "ペオ", en: "peo" },
        { kr: "포", jp: "ポ", en: "po" },
        { kr: "푸", jp: "プ", en: "pu" },
        { kr: "프", jp: "プ", en: "peu" },
        { kr: "피", jp: "ピ", en: "pi" },
        { kr: "패", jp: "ペ", en: "pae" },
        { kr: "페", jp: "ペ", en: "pe" }],

        [{ kr: "하", jp: "ハ", en: "ha" },
        { kr: "허", jp: "ヘオ", en: "heo" },
        { kr: "호", jp: "ホ", en: "ho" },
        { kr: "후", jp: "フ", en: "hu" },
        { kr: "흐", jp: "フ", en: "heu" },
        { kr: "히", jp: "ヒ", en: "hi" },
        { kr: "해", jp: "ヘ", en: "hae" },
        { kr: "헤", jp: "ヘ", en: "he" }]]
}