import React, { useEffect } from 'react'
import Header from './Header'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export type LayoutProps = {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const location = useLocation();
  useEffect(() => {
    // 페이지 이동을 추적하는 코드
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  // root URL일 경우, pb-10 클래스를 제외합니다.
  const Pb = ['/hanguel'].includes(location.pathname);
  const mainClassName = `w-full overflow-x-hidden ${Pb ? 'pb-10' : ''}`;

  return (
    <div className='w-full flex-center flex-col pt-[64px]'>
      <Header />
      <main className={mainClassName}>
        {props.children}
      </main>

    </div>
  )
}

export default Layout