export const word9_1 = [
    { "kr": "자", "mean": "さあ" },
    { "kr": "자기", "mean": "自己、自分" },
    { "kr": "자다", "mean": "寝る" },
    { "kr": "자동차", "mean": "自動車、車" },
    { "kr": "자동차회사", "mean": "自動車会社" },
    { "kr": "자료", "mean": "資料" },
    { "kr": "자르다", "mean": "切る" },
    { "kr": "자리", "mean": "座席、場所" },
    { "kr": "자신", "mean": "自身" },
    { "kr": "자연", "mean": "自然" },
    { "kr": "자유", "mean": "自由" },
    { "kr": "자장면", "mean": "ジャージャー麺" },
    { "kr": "자전거", "mean": "自転車" },
    { "kr": "자주", "mean": "たびたび、しょっちゅう" },
    { "kr": "작년", "mean": "昨年" },
    { "kr": "작다", "mean": "小さい" },
    { "kr": "작은아버지", "mean": "父の弟、おじ" },
    { "kr": "작은어머니", "mean": "父の弟の妻、おば" },
    { "kr": "잔", "mean": "～杯" },
    { "kr": "잔치", "mean": "パーティ、祝宴" },
    { "kr": "잘", "mean": "よく、上手に" },
    { "kr": "잘못", "mean": "間違って" },
    { "kr": "잘생기다", "mean": "ハンサムだ" },
    { "kr": "잘하다", "mean": "上手だ" },
    { "kr": "잠", "mean": "寝ること、睡眠" },
    { "kr": "잠깐", "mean": "しばらく、ちょっと" },
    { "kr": "잠시", "mean": "しばらく、ちょっと" },
    { "kr": "잠자다", "mean": "寝る、眠る" },
    { "kr": "잡다", "mean": "つかむ、握る" },
    { "kr": "잡수시다", "mean": "召し上がる" },
    { "kr": "잡지", "mean": "雑誌" },
    { "kr": "장", "mean": "～枚" },
    { "kr": "장갑", "mean": "手袋" },
    { "kr": "장마", "mean": "梅雨" },
    { "kr": "장마철", "mean": "梅雨時" },
    { "kr": "장미", "mean": "バラ" },
    { "kr": "장소", "mean": "場所" },
    { "kr": "장점", "mean": "長所" },
    { "kr": "재료", "mean": "材料" },
    { "kr": "재미없다", "mean": "面白くない" },
    { "kr": "재미있다", "mean": "面白い" },
    { "kr": "재킷", "mean": "ジャケット" },
    { "kr": "저", "mean": "あの" },
    { "kr": "저거", "mean": "あれ" },
    { "kr": "저것", "mean": "あれ" },
    { "kr": "저곳", "mean": "あそこ" },
    { "kr": "저기", "mean": "あそこ" },
    { "kr": "저녁", "mean": "夕方、夜、夕ご飯" },
    { "kr": "저녁식사", "mean": "夕食" },
    { "kr": "저렇게", "mean": "あのように" },
    { "kr": "저분", "mean": "あの方" },
    { "kr": "저쪽", "mean": "あちら、あの方" },
    { "kr": "저희", "mean": "私ども" },
    { "kr": "적", "mean": "的" },
    { "kr": "적다", "mean": "少ない、記す" },
    { "kr": "전", "mean": "全、前" },
    { "kr": "전공", "mean": "専攻" },
    { "kr": "전기자동차", "mean": "電気自動車" },
    { "kr": "전자사전", "mean": "電子辞書" },
    { "kr": "전하다", "mean": "伝える" },
    { "kr": "전혀", "mean": "まったく、全然" },
    { "kr": "전화", "mean": "電話" },
    { "kr": "전화기", "mean": "電話機" },
    { "kr": "전화번호", "mean": "電話番号" },
    { "kr": "절", "mean": "寺" },
    { "kr": "절대", "mean": "絶対" },
    { "kr": "젊다", "mean": "若い" },
    { "kr": "점", "mean": "点" },
    { "kr": "점수", "mean": "点数" },
    { "kr": "점심", "mean": "昼食、昼ごはん" },
    { "kr": "점심시간", "mean": "昼食時間" },
    { "kr": "점심식사", "mean": "昼食" },
    { "kr": "점원", "mean": "店員" },
    { "kr": "점퍼", "mean": "ジャンパー" },
    { "kr": "젓가락", "mean": "箸" },
    { "kr": "정거장", "mean": "停車場、駅" },
    { "kr": "정도", "mean": "程度" },
    { "kr": "정류장", "mean": "停留所、バス停" },
    { "kr": "정리하다", "mean": "整理する" },
    { "kr": "정말", "mean": "本当に" },
    { "kr": "정문", "mean": "正門" },
    { "kr": "정보", "mean": "情報" },
    { "kr": "정하다", "mean": "定める" },
    { "kr": "제가", "mean": "私が" },
    { "kr": "제목", "mean": "題名、タイトル" },
    { "kr": "제일", "mean": "いちばん、最も" },
    { "kr": "제주도", "mean": "済州島" },
    { "kr": "조금", "mean": "少し" },
    { "kr": "조사하다", "mean": "調査する" },
    { "kr": "조선", "mean": "朝鮮" },
    { "kr": "조선말", "mean": "朝鮮語" },
    { "kr": "조선어", "mean": "朝鮮語" },
    { "kr": "조심하다", "mean": "注意する" },
    { "kr": "조용하다", "mean": "静かだ" },
    { "kr": "조용히", "mean": "静かに" },
    { "kr": "조카", "mean": "甥（おい）" },
    { "kr": "졸업", "mean": "卒業" },
    { "kr": "졸업하다", "mean": "卒業する" },
    { "kr": "좀", "mean": "ちょっと、すこし" },
    { "kr": "좀더", "mean": "もう少し、もうちょっと" }
]

export const word9_2 = [
    { "kr": "좁다", "mean": "狭い" },
    { "kr": "종로", "mean": "鍾路" },
    { "kr": "종류", "mean": "種類" },
    { "kr": "종업원", "mean": "従業員" },
    { "kr": "종이", "mean": "紙" },
    { "kr": "종일", "mean": "終日、一日中" },
    { "kr": "좋다", "mean": "良い、好きだ" },
    { "kr": "좋아하다", "mean": "好む、好きだ" },
    { "kr": "죄송하다", "mean": "申し訳ない" },
    { "kr": "주", "mean": "週" },
    { "kr": "주다", "mean": "あげる、くれる" },
    { "kr": "주로", "mean": "主に" },
    { "kr": "주말", "mean": "週末" },
    { "kr": "주머니", "mean": "ポケット、小袋" },
    { "kr": "주무시다", "mean": "お休みになる" },
    { "kr": "주문하다", "mean": "注文する" },
    { "kr": "주변", "mean": "周辺、周り" },
    { "kr": "주부", "mean": "主婦" },
    { "kr": "주사", "mean": "注射" },
    { "kr": "주소", "mean": "住所" },
    { "kr": "주스", "mean": "ジュース" },
    { "kr": "주위", "mean": "周囲、周り" },
    { "kr": "주인", "mean": "主人、持ち主、マスター" },
    { "kr": "주차장", "mean": "駐車場" },
    { "kr": "주차하다", "mean": "駐車する" },
    { "kr": "주황색", "mean": "オレンジ色" },
    { "kr": "죽", "mean": "おかゆ" },
    { "kr": "죽다", "mean": "死ぬ" },
    { "kr": "준비", "mean": "用意、準備" },
    { "kr": "준비하다", "mean": "準備する" },
    { "kr": "줄", "mean": "列、線、ひも" },
    { "kr": "줄다", "mean": "減る" },
    { "kr": "중", "mean": "中" },
    { "kr": "중국", "mean": "中国" },
    { "kr": "중국어", "mean": "中国語" },
    { "kr": "중국집", "mean": "中華料理店" },
    { "kr": "중식", "mean": "中国料理" },
    { "kr": "중심", "mean": "中心" },
    { "kr": "중요", "mean": "重要" },
    { "kr": "중요하다", "mean": "重要だ" },
    { "kr": "중학교", "mean": "中学校" },
    { "kr": "중학생", "mean": "中学生" },
    { "kr": "즐거워하다", "mean": "楽しがる" },
    { "kr": "즐겁다", "mean": "楽しい" },
    { "kr": "즐기다", "mean": "楽しむ" },
    { "kr": "증세", "mean": "病状" },
    { "kr": "지각", "mean": "遅刻" },
    { "kr": "지갑", "mean": "財布" },
    { "kr": "지금", "mean": "今" },
    { "kr": "지나다", "mean": "過ぎる、経つ" },
    { "kr": "지난달", "mean": "先月" },
    { "kr": "지난번", "mean": "前回" },
    { "kr": "지난주", "mean": "先週" },
    { "kr": "지내다", "mean": "過ごす、暮らす" },
    { "kr": "지다", "mean": "負ける" },
    { "kr": "지도", "mean": "地図" },
    { "kr": "지방", "mean": "地方" },
    { "kr": "지우개", "mean": "消しゴム、黒板消し" },
    { "kr": "지키다", "mean": "守る" },
    { "kr": "지하", "mean": "地下" },
    { "kr": "지하도", "mean": "地下道" },
    { "kr": "지하철", "mean": "地下鉄" },
    { "kr": "지하철역", "mean": "地下鉄の駅" },
    { "kr": "직업", "mean": "職業" },
    { "kr": "직원", "mean": "職員" },
    { "kr": "직장", "mean": "職場" },
    { "kr": "직접", "mean": "直接" },
    { "kr": "진달래", "mean": "ツツジ" },
    { "kr": "질", "mean": "質" },
    { "kr": "질문", "mean": "質問" },
    { "kr": "질문하다", "mean": "質問する" },
    { "kr": "짐", "mean": "荷物" },
    { "kr": "집", "mean": "家、店" },
    { "kr": "짓다", "mean": "作る" },
    { "kr": "짜다", "mean": "組み立てる、（計画を）立てる" },
    { "kr": "짜리", "mean": "価値があるもの（○ウォン硬貨/紙幣/切手など）" },
    { "kr": "짧다", "mean": "短い" },
    { "kr": "쪽", "mean": "ページ" },
    { "kr": "쯤", "mean": "～ごろ" },
    { "kr": "찌개", "mean": "チゲ" },
    { "kr": "찍다", "mean": "突き刺す、（写真を）撮る、（印鑑を）押す、（物の端に液体を）つける" }
]
