import Icon from 'components/icon/Icon'
import React, { useEffect, useState } from 'react'
import hamburger from 'assets/d-icon/menu.svg'
import foot from 'assets/d-icon/foot.svg'
import close from 'assets/d-icon/close.svg'
import face from 'assets/d-icon/face.svg'
import { Link, useNavigate } from 'react-router-dom';

type HamburgerProps = {
    url: string;
    label: string;
}

const tabList: HamburgerProps[] = [
    {
        url: "",
        label: "ホーム"
    },
    {
        url: "/hanguel",
        label: "ハングル"
    },
    {
        url: "/quiz",
        label: "クイズ"
    },
    {
        url: "/topik",
        label: "TOPIK"
    },
    {
        url: "/wordsbook",
        label: "単語帳"
    },
    {
        url: "/archive",
        label: "ダウンロード"
    },
    {
        url: "/community",
        label: "コミュニティー"
    },
]

const Hamburger = () => {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (isOpen) {
            // 메뉴가 열리면 body의 스크롤을 막음
            document.body.style.overflow = 'hidden';
        } else {
            // 메뉴가 닫히면 body의 스크롤을 다시 활성화
            document.body.style.overflow = 'auto';
        }

        // 컴포넌트 언마운트 시 또는 상태가 변경될 때 clean-up
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleClickLink = (url: string) => {
        setIsOpen(false)
        navigate(url)
    }

    return (
        <div className='h-[30px]'>
            <Icon isSvg src={isOpen ? close : hamburger} onClick={() => setIsOpen(!isOpen)} width={30} height={30} />
            <div
                className={`fixed top-[60px] bg-white w-screen h-screen right-0 flex gap-[50px] flex-col items-center pt-20  ${!isOpen && 'hidden'
                    }`}
                style={{ zIndex: 100 }}
            >
                {
                    tabList.map(ele => (
                        <div className='text-[22px] font-semibold flex items-start gap-[10px] link cursor-pointer'
                            onClick={() => handleClickLink(ele.url)}
                        >
                            <img src={ele.url === '' ? face : foot} alt="icon" className='w-[30px] link-icon' />
                            {ele.label}
                        </div>

                    ))
                }
            </div>
        </div>
    )
}

export default Hamburger