import { TopikListeningType } from "types/topik";
import t1_91_1 from 'assets/audio/t91_1/topik_91_1_1.mp3';
import t1_91_2 from 'assets/audio/t91_1/topik_91_1_2.mp3';
import t1_91_3 from 'assets/audio/t91_1/topik_91_1_3.mp3';
import t1_91_4 from 'assets/audio/t91_1/topik_91_1_4.mp3';
import t1_91_5 from 'assets/audio/t91_1/topik_91_1_5.mp3';
import t1_91_6 from 'assets/audio/t91_1/topik_91_1_6.mp3';
import t1_91_7 from 'assets/audio/t91_1/topik_91_1_7.mp3';
import t1_91_8 from 'assets/audio/t91_1/topik_91_1_8.mp3';
import t1_91_9 from 'assets/audio/t91_1/topik_91_1_9.mp3';
import t1_91_10 from 'assets/audio/t91_1/topik_91_1_10.mp3';


// 토픽 91 I 듣기 1~10
export const listeningQuizlist: TopikListeningType[] = [
    {
        q: '다음을 듣고 물음에 맞는 대답을 고르십시오.',
        audio: t1_91_1,
        answer: [
            {
                text: '네, 학생이에요.',
                isAnswer: true
            },
            {
                text: '네, 학생이 없어요.',
                isAnswer: false
            },
            {
                text: '아니요, 학생이 와요.',
                isAnswer: false
            },
            {
                text: '아니요, 학생이 좋아요.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 맞는 대답을 고르십시오.',
        audio: t1_91_2,
        answer: [
            {
                text: '네, 과자가 비싸요.',
                isAnswer: false
            },
            {
                text: '네, 과자가 아니에요.',
                isAnswer: false
            },
            {
                text: '아니요, 과자를 먹어요.',
                isAnswer: false
            },
            {
                text: '아니요, 과자를 안 좋아해요.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 맞는 대답을 고르십시오.',
        audio: t1_91_3,
        answer: [
            {
                text: '매일 마셔요.',
                isAnswer: false
            },
            {
                text: '제가 마셔요.',
                isAnswer: false
            },
            {
                text: '우유를 마셔요.',
                isAnswer: true
            },
            {
                text: '집에서 마셔요.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 맞는 대답을 고르십시오.',
        audio: t1_91_4,
        answer: [
            {
                text: '내일 가요.',
                isAnswer: false
            },
            {
                text: '공원에 가요.',
                isAnswer: false
            },
            {
                text: '동생이 가요.',
                isAnswer: false
            },
            {
                text: '지하철로 가요.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 이어지는 말을 고르십시오.',
        audio: t1_91_5,
        answer: [
            {
                text: '고맙습니다.',
                isAnswer: true
            },
            {
                text: '반갑습니다.',
                isAnswer: false
            },
            {
                text: '실례합니다.',
                isAnswer: false
            },
            {
                text: '환영합니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 이어지는 말을 고르십시오.',
        audio: t1_91_6,
        answer: [
            {
                text: '네, 어서오세요.',
                isAnswer: false
            },
            {
                text: '네, 잘 다녀오세요.',
                isAnswer: false
            },
            {
                text: '네, 안녕히 주무세요.',
                isAnswer: false
            },
            {
                text: '네, 잠깐만 기다리세요.',
                isAnswer: true
            },
        ],
    },
    {
        q: '여기는 어디입니까?',
        audio: t1_91_7,
        answer: [
            {
                text: '극장',
                isAnswer: true
            },
            {
                text: '병원',
                isAnswer: false
            },
            {
                text: '은행',
                isAnswer: false
            },
            {
                text: '식당',
                isAnswer: false
            },
        ],
    },
    {
        q: '여기는 어디입니까?',
        audio: t1_91_8,
        answer: [
            {
                text: '미용실',
                isAnswer: false
            },
            {
                text: '정류장',
                isAnswer: true
            },
            {
                text: '우체국',
                isAnswer: false
            },
            {
                text: '사진관',
                isAnswer: false
            },
        ],
    },
    {
        q: '여기는 어디입니까?',
        audio: t1_91_9,
        answer: [
            {
                text: '꽃집',
                isAnswer: false
            },
            {
                text: '서점',
                isAnswer: true
            },
            {
                text: '약국',
                isAnswer: false
            },
            {
                text: '빵집',
                isAnswer: false
            },
        ],
    },
    {
        q: '여기는 어디입니까?',
        audio: t1_91_10,
        answer: [
            {
                text: '가구점',
                isAnswer: false
            },
            {
                text: '여행사',
                isAnswer: false
            },
            {
                text: '신발 가게',
                isAnswer: true
            },
            {
                text: '안경 가게',
                isAnswer: false
            },
        ],
    },
]
