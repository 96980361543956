import MbWrapper from "components/warpper/MbWrapper";
import { Link } from "react-router-dom";
import noResult from 'assets/imgs/background/no_data.png'

const NotFound = () => {
    return (
        <MbWrapper>
            <Link to="..">
                <li className="mt-[110px] flex flex-col items-center justify-center">
                    <img src={noResult} alt="데이터가 없습니다." />
                    <p className="font-bold text-[18px] text-[#333333] text-center opacity-60">
                        ページが読めませんでした<br />
                        URLが間違っている可能性がございます
                    </p>
                </li>
            </Link>
        </MbWrapper>
    )
};

export default NotFound;