import axios from "axios";
import Pagination from "components/pagination/Pagination";
import { useState, useEffect } from "react";
import { NoticeType } from "types/community";

import bg from 'assets/imgs/background/think.png'
import noResult from 'assets/imgs/background/no_data.png'



const Qna = () => {


    const [data, setData] = useState<{ Q: string; A: React.ReactNode; }[]>([
        {
            Q: "ヨシクラスは何ですか？",
            A: `ヨシのモットーは「韓国語を学ぶときは\nこうやって学べば簡単で面白そう」です。\nそして「お互いに、一緒に」します。\nお互いに学ぶという気持ちで頑張ります。`
        },
        {
            Q: "どんな勉強ができますか？",
            A: `簡単に学ぶ韓国語と韓国関連コンテンツを\nインスタグラムにアップロードしています。\n単語帳、問題解きはサイトに用意されています。\n韓国語の講義も準備中ですので、お楽しみに!`
        },
        {
            Q: "モバイルで見るのが不便です。",
            A: <span>{`モバイルでアプリのように使うことができます。`}<br />たった30秒 ! 方法は<a className="underline text-pr2 font-bold" href='https://bit.ly/3X3Sg2v' target="__blank">こちら</a></span>
        },
        {
            Q: "フィードバックをしたいです。",
            A: `皆さんのご意見はいつでも歓迎します。\nyoshi04class@gmail.com\nまでお問い合わせいただければ幸いです。`
        },
        {
            Q: "講義や学習本はありますか？",
            A: `両方とも準備中です。\n良い内容で準備して戻ってきます。\nありがとうございます。`
        },

    ])
    const [activeIndex, setActiveIndex] = useState<number | null>(null)




    const handleNoticeDetail = (idx: number) => {
        setActiveIndex(activeIndex === idx ? null : idx)

    }


    return (
        <div className='flex flex-col items-center relative'
            style={{ minHeight: 'calc(100vh - 64px)' }}

        >
            <ul className="w-[320px] pt-5">
                {
                    !data || data.length === 0 ? (
                        <li className="mt-[110px] flex flex-col items-center justify-center">
                            <img src={noResult} alt="데이터가 없습니다." />
                            <p className="font-semibold text-[18px] text-[#333333]">まだ内容がありません！</p>
                        </li>
                    ) : (
                        data.map((item, idx) => (
                            <li
                                onClick={() => handleNoticeDetail(idx)}
                                key={idx}
                            >
                                <div className="flex line-normal justify-between items-center h-16 border-b-[0.5px] border-[#D4DBE4] text-sm gap-2.5">
                                    <p className="font-bold text-pr2">Q .</p>
                                    <p className={`flex-1 font-semibold truncate cursor-pointer ${activeIndex === idx ? 'active' : ''}`}>{item.Q}</p>

                                </div>
                                <div className={` text-sm h-0 overflow-hidden whitespace-pre-wrap transition-[height] duration-500 ease-in-out ${activeIndex === idx ? 'min-h-[160px] h-auto' : ''}`}>
                                    <div className="p-[12px_20px] break-all relative text-[12px]">
                                        <span className="text-pr2 font-bold absolute left-0 text-[18px]">A .</span> {item.A}
                                    </div>
                                </div>
                            </li>
                        )
                        )
                    )
                }
            </ul>
        </div>
    )
}

export default Qna
