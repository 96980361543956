import { TopikReadingType } from "types/topik";

export const Reading2Quizlist5: TopikReadingType[] = [{
    q: `주어진 문장이 들어갈 곳으로 가장 알맞은 것을 고르십시오.
"이런 상소문들을 저자는 왕을 향한 깨우침의 죽비 소리로 비유하고 있다."`,
    answer: [
        {
            text: 'ㄱ',
            isAnswer: false
        },
        {
            text: 'ㄴ',
            isAnswer: false
        },
        {
            text: 'ㄷ',
            isAnswer: true
        },
        {
            text: 'ㄹ',
            isAnswer: false
        },
    ],
    question:
        `최근 역사학자 김경민 씨가 『응답하라, 조선』을 펴냈다.
(  ㄱ  ) 이 책은 왕과 신하의 소통을 다루고 있어 사람들의 관심을 끈다.
(  ㄴ  ) 그 소통의 내용 중에서 특히 왕의 노여움을 무릅쓰고 신하가 왕의 잘못을 지적한 상소문을 다룬 부분이 주목받고 있다.
(  ㄷ  ) 『응답하라, 조선』은 눈치를 살피며 윗사람의 잘못을 모르는 체 넘기곤 하는 현대인들을 깨우는 죽비인 것이다.
(  ㄹ  )`,
},
{
    q: `괄호 부분에 나타난 '미연'의 심정으로 가장 알맞은 것을 고르십시오.`,
    answer: [
        {
            text: '후련하다',
            isAnswer: false
        },
        {
            text: '불만스럽다',
            isAnswer: true
        },
        {
            text: '허전하다',
            isAnswer: false
        },
        {
            text: '자랑스럽다',
            isAnswer: false
        },
    ],
    question:
        `민욱이 주말에 친구 부부를 초대해도 되느냐고 물었을 때, 미연은 말없이 한참 뜸을 들였다.
그녀는 그날 민욱과 함께 아파트의 발코니 벽을 페인트 칠할 계획이었다.
(중략) 그들은 육 개월 전에 E시의 아파트를 사서 이사했다.
지은 지 이십 년도 더 된 낡은 아파트였지만, 누구의 도움도 받지 않고 그들 스스로 이룬 일이었다.
미연은 직접 발품을 팔아 수도부터 새시까지 새로 손을 보았다.
하지만 집수리는 대강 되었다고 해도, 미연이 생각해 둔 대로 인테리어가 완성되려면 아직 부족했다.
[ "손님 초대는 좀 이르지 않아? 
집 단장도 덜 되었는데……" ]
"성재가 한국에 왔대"
민욱이 무거운 목소리로 말했다.(중략)
민욱과 성재는 고등학교 동창이고, 그들을 통해 만난 미연과 연주도 십여년이 넘는 인연이었다.
꽤나 가까운 사이였지만 성재가 사업 실패로 한국을 떠나면서 연락이 끊겼다.
미연은 자신이 연주의 이름을 오랫동안 잊고 있었다는 사실을 깨달았다.
더 이상 뭐라고 할 말이 없었다.(중략)
토요일 오전에 미연은 두 딸아이를 데리고 마트에 가서 고기와 채소를 샀다.
누군가를 초대해 음식을 대접하는 것이 정말로 오랜만이었다.`,
},
{
    q: '윗글의 내용으로 알 수 있는 것을 고르십시오.',
    answer: [
        {
            text: '미연은 민욱과 성재를 통해 연주를 알게 되었다.',
            isAnswer: true
        },
        {
            text: '민욱은 주변 도움을 받아 E시의 새 아파트를 샀다.',
            isAnswer: false
        },
        {
            text: '미연은 육 개월 전에 산 아파트로 이사 갈 계획이다.',
            isAnswer: false
        },
        {
            text: '민욱은 인테리어를 완성한 후에 마트에 장을 보러 갔다.',
            isAnswer: false
        },
    ],
    question:
        `민욱이 주말에 친구 부부를 초대해도 되느냐고 물었을 때, 미연은 말없이 한참 뜸을 들였다.
그녀는 그날 민욱과 함께 아파트의 발코니 벽을 페인트 칠할 계획이었다.
(중략) 그들은 육 개월 전에 E시의 아파트를 사서 이사했다.
지은 지 이십 년도 더 된 낡은 아파트였지만, 누구의 도움도 받지 않고 그들 스스로 이룬 일이었다.
미연은 직접 발품을 팔아 수도부터 새시까지 새로 손을 보았다.
하지만 집수리는 대강 되었다고 해도, 미연이 생각해 둔 대로 인테리어가 완성되려면 아직 부족했다.
[ "손님 초대는 좀 이르지 않아? 
집 단장도 덜 되었는데……" ]
"성재가 한국에 왔대"
민욱이 무거운 목소리로 말했다.(중략)
민욱과 성재는 고등학교 동창이고, 그들을 통해 만난 미연과 연주도 십여년이 넘는 인연이었다.
꽤나 가까운 사이였지만 성재가 사업 실패로 한국을 떠나면서 연락이 끊겼다.
미연은 자신이 연주의 이름을 오랫동안 잊고 있었다는 사실을 깨달았다.
더 이상 뭐라고 할 말이 없었다.(중략)
토요일 오전에 미연은 두 딸아이를 데리고 마트에 가서 고기와 채소를 샀다.
누군가를 초대해 음식을 대접하는 것이 정말로 오랜만이었다.`,
},
{
    q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '알을 가지고 내려온',
            isAnswer: false
        },
        {
            text: '기존의 왕을 물리치고',
            isAnswer: false
        },
        {
            text: '수로에 이어 알에서 태어난',
            isAnswer: true
        },
        {
            text: '탄생을 축하하며 몹시 기뻐하던',
            isAnswer: false
        },
    ],
    question:
        `'수로왕 신화'는 왕이 알에서 탄생하는 여느 건국 신화와 유사하다.
고대에 아직 나라가 없던 낙동강 하류에 황금알 여섯 개가 하늘에서 내려왔다.
그중 맨 먼저 알을 깨고 나온 아이를 '수로'라 하였고 하늘의 뜻에 따라 수로는 지상의 첫 번째 왕이 되었다.
(      ) 다섯 아이 역시 각각 왕이 되었다.
수로왕 신화는 이렇게 한꺼번에 여섯 개 알이 나타나 거기서 태어난 이가 모두 왕이 된다는 점에서 다른 건국 신화와 큰 차이가 있다.
당시 세워진 여섯 나라는 강력한 왕권 국가가 아니었다.
황금알은 하늘로부터 부여받은 절대적 권위를 상징하는데 수로가 알에서 최초로 탄생했다는 부분은 수로왕이 중심이 되어 여섯 나라를 하나의 강력한 국가 '가야'로 통합하려 했다는 것을 말해 준다.`,
},
{
    q: '윗글의 주제로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '이 신화는 전형적인 건국 신화의 특성을 지니고 있다.',
            isAnswer: false
        },
        {
            text: '이 신화는 낙동강 일대의 유용한 지리적 정보를 담고 있다.',
            isAnswer: false
        },
        {
            text: '이 신화는 고대의 문학 작품으로서 훌륭한 가치를 지니고 있다.',
            isAnswer: false
        },
        {
            text: '이 신화는 강력한 왕권 국가를 이루려고 한 통합 의식을 반영한다.',
            isAnswer: true
        },
    ],
    question:
        `'수로왕 신화'는 왕이 알에서 탄생하는 여느 건국 신화와 유사하다.
고대에 아직 나라가 없던 낙동강 하류에 황금알 여섯 개가 하늘에서 내려왔다.
그중 맨 먼저 알을 깨고 나온 아이를 '수로'라 하였고 하늘의 뜻에 따라 수로는 지상의 첫 번째 왕이 되었다.
(      ) 다섯 아이 역시 각각 왕이 되었다.
수로왕 신화는 이렇게 한꺼번에 여섯 개 알이 나타나 거기서 태어난 이가 모두 왕이 된다는 점에서 다른 건국 신화와 큰 차이가 있다.
당시 세워진 여섯 나라는 강력한 왕권 국가가 아니었다.
황금알은 하늘로부터 부여받은 절대적 권위를 상징하는데 수로가 알에서 최초로 탄생했다는 부분은 수로왕이 중심이 되어 여섯 나라를 하나의 강력한 국가 '가야'로 통합하려 했다는 것을 말해 준다.`,
},
{
    q: '윗글에 나타난 필자의 태도로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '경제 범죄가 사회에 미치는 영향을 부정하고 있다.',
            isAnswer: false
        },
        {
            text: '경제 사범에 대한 처벌을 강화하도록 촉구하고 있다.',
            isAnswer: true
        },
        {
            text: '경제 사범의 처벌로 생길 결과에 대해 우려하고 있다.',
            isAnswer: false
        },
        {
            text: '경제 범죄의 다양한 수법을 객관적으로 분석하고 있다.',
            isAnswer: false
        },
    ],
    question:
        `주가 조작이나 공금 횡령 등의 경제 범죄는 사람에게 직접적인 상해를 가하는 흉악 범죄보다 범죄 정도가 낮다고 생각하기 쉽다.
그러나 경제 범죄의 수법이 날이 갈수록 다양해지고 지능화되어 사회에 미치는 충격과 피해가 막심하다.
최근 증권사 직원의 주가 조작으로 고객들이 천억 넘게 손해 본 사건만 해도 그렇다.
이러한 경제 범죄는 개인 손해를 넘어 국가경제와도 직결될 수 있다.
따라서 이를 가벼이 여겨서는 안 되며 관대하게 처벌해서도 안 된다.
건전한 경제 질서를 확립하기 위해 경제 사범을 얼벌할 필요가 있다.
'한탕 크게 해 먹고 때우면 된다'는 한탕주의가 만연하지 않도록 처벌 수준을 더 높여야 한다.
지금까지 미진했던 부당 이익 환수도 앞으로 잘 이루어져 경제 범죄가 재발되지 않도록 해야 할 것이다.`,
},
{
    q: '윗글의 내용과 같은 것을 고르십시오.',
    answer: [
        {
            text: '경제 범죄로 생긴 부당 이익을 문제없이 잘 환수해 왔다.',
            isAnswer: false
        },
        {
            text: '공금을 불법으로 가로채는 범죄는 경제 범죄에 포함된다.',
            isAnswer: true
        },
        {
            text: '개인이 저지른 주가 조작 범죄는 국가 경제와 큰 관련이 없다.',
            isAnswer: false
        },
        {
            text: '경제 범죄 수법이 교묘해졌지만 사회에 미치는 충격은 크지 않다.',
            isAnswer: false
        },
    ],
    question:
        `주가 조작이나 공금 횡령 등의 경제 범죄는 사람에게 직접적인 상해를 가하는 흉악 범죄보다 범죄 정도가 낮다고 생각하기 쉽다.
그러나 경제 범죄의 수법이 날이 갈수록 다양해지고 지능화되어 사회에 미치는 충격과 피해가 막심하다.
최근 증권사 직원의 주가 조작으로 고객들이 천억 넘게 손해 본 사건만 해도 그렇다.
이러한 경제 범죄는 개인 손해를 넘어 국가경제와도 직결될 수 있다.
따라서 이를 가벼이 여겨서는 안 되며 관대하게 처벌해서도 안 된다.
건전한 경제 질서를 확립하기 위해 경제 사범을 얼벌할 필요가 있다.
'한탕 크게 해 먹고 때우면 된다'는 한탕주의가 만연하지 않도록 처벌 수준을 더 높여야 한다.
지금까지 미진했던 부당 이익 환수도 앞으로 잘 이루어져 경제 범죄가 재발되지 않도록 해야 할 것이다.`,
},
{
    q: '윗 글을 쓴 목적으로 가장 알맞은 것을 고르십시오.',

    answer: [
        {
            text: '예술인의 자질에 대해 분석하려고',
            isAnswer: false
        },
        {
            text: '예술 발전의 어려움을 토로하려고',
            isAnswer: false
        },
        {
            text: '예술 작품의 창작 활동을 설명하려고',
            isAnswer: false
        },
        {
            text: '예술인 생활 보장의 필요성을 강조하려고',
            isAnswer: true
        },
    ],
    question:
        `예술인은 독창적인 문화를 창조하고 고유한 문화를 보촌하는 동시에 예술 활동을 업으로 삼아 수익을 내서 생활하는 사람이다. 
그런데 많은 예술인이 기본 생활이 불가능한 적은 수입 탓에 예술 활동을 포기한다. 
그 결과 예술인이 감소하며 고령화되는 현상이 나타나고 있다.
2011년에는 생활고로 한 작가가 사망하는 사건까지 일어났다. 
이 사건이 계기가 되어 2012년부터 예술인의 권리 보호를 위해 '예술인 복지법' 이 시행되었다.
그러나 이는 예술 현장의 실상에 맞지 않아 많은 예술인이 여전히 (      ).
이런 상황에서 올해 예술 활동을 증명하지 못해 지원을 못 받았던 예술인을 위해 예술인 복지법이 개정되었다. 
개정안은 이런 예술인도 일반 직업인과 같이 권리를 보호받을 수 있는 대상임을 명확히 하고 있다.
또 예술인이 불리한 처우를 받지 않도록 세부 조치를 마련하는 등 예술인의 고용 안정을 위한 여러 내용을 담고 있다. 
앞으로는 이를 바탕으로 유능한 예술인이 활동을 포기하지 않도록 해야 할 것이다.`,
},
{
    q: '(      )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
    answer: [
        {
            text: '안전을 중요시해야 한다',
            isAnswer: false
        },
        {
            text: '생계의 어려움을 겪고 있다',
            isAnswer: true
        },
        {
            text: '한 번에 큰돈을 모을 수 있다',
            isAnswer: false
        },
        {
            text: '창작에 관한 기밀을 지켜야 한다',
            isAnswer: false
        },
    ],
    question:
        `예술인은 독창적인 문화를 창조하고 고유한 문화를 보촌하는 동시에 예술 활동을 업으로 삼아 수익을 내서 생활하는 사람이다. 
그런데 많은 예술인이 기본 생활이 불가능한 적은 수입 탓에 예술 활동을 포기한다. 
그 결과 예술인이 감소하며 고령화되는 현상이 나타나고 있다.
2011년에는 생활고로 한 작가가 사망하는 사건까지 일어났다. 
이 사건이 계기가 되어 2012년부터 예술인의 권리 보호를 위해 '예술인 복지법' 이 시행되었다.
그러나 이는 예술 현장의 실상에 맞지 않아 많은 예술인이 여전히 (      ).
이런 상황에서 올해 예술 활동을 증명하지 못해 지원을 못 받았던 예술인을 위해 예술인 복지법이 개정되었다. 
개정안은 이런 예술인도 일반 직업인과 같이 권리를 보호받을 수 있는 대상임을 명확히 하고 있다.
또 예술인이 불리한 처우를 받지 않도록 세부 조치를 마련하는 등 예술인의 고용 안정을 위한 여러 내용을 담고 있다. 
앞으로는 이를 바탕으로 유능한 예술인이 활동을 포기하지 않도록 해야 할 것이다.`,
},
{
    q: '윗글의 내용과 같은 것을 고르십시오.',

    answer: [
        {
            text: '예술인 복지법은 한 번 신설된 후 개정된 적이 없다.',
            isAnswer: false
        },
        {
            text: '전국에서 예술 분야에 종사하는 사람들 수가 늘고 있다.',
            isAnswer: false
        },
        {
            text: '올해부터 예술인은 활동을 반드시 증명해야 지원을 받을 수 있다.',
            isAnswer: false
        },
        {
            text: '2012년의 예술인 복지법은 현장의 실상을 반영하는 데 한계가 있었다.',
            isAnswer: true
        },
    ],
    question:
        `예술인은 독창적인 문화를 창조하고 고유한 문화를 보촌하는 동시에 예술 활동을 업으로 삼아 수익을 내서 생활하는 사람이다. 
그런데 많은 예술인이 기본 생활이 불가능한 적은 수입 탓에 예술 활동을 포기한다. 
그 결과 예술인이 감소하며 고령화되는 현상이 나타나고 있다.
2011년에는 생활고로 한 작가가 사망하는 사건까지 일어났다. 
이 사건이 계기가 되어 2012년부터 예술인의 권리 보호를 위해 '예술인 복지법' 이 시행되었다.
그러나 이는 예술 현장의 실상에 맞지 않아 많은 예술인이 여전히 (      ).
이런 상황에서 올해 예술 활동을 증명하지 못해 지원을 못 받았던 예술인을 위해 예술인 복지법이 개정되었다. 
개정안은 이런 예술인도 일반 직업인과 같이 권리를 보호받을 수 있는 대상임을 명확히 하고 있다.
또 예술인이 불리한 처우를 받지 않도록 세부 조치를 마련하는 등 예술인의 고용 안정을 위한 여러 내용을 담고 있다. 
앞으로는 이를 바탕으로 유능한 예술인이 활동을 포기하지 않도록 해야 할 것이다.`,
}]