import { TopikListeningType } from "types/topik";
import t1_91_1 from 'assets/audio/t91_2/t2_91_1.mp3';
import t1_91_2 from 'assets/audio/t91_2/t2_91_2.mp3';
import t1_91_3 from 'assets/audio/t91_2/t2_91_3.mp3';
import t1_91_4 from 'assets/audio/t91_2/t2_91_4.mp3';
import t1_91_5 from 'assets/audio/t91_2/t2_91_5.mp3';
import t1_91_6 from 'assets/audio/t91_2/t2_91_6.mp3';
import t1_91_7 from 'assets/audio/t91_2/t2_91_7.mp3';
import t1_91_8 from 'assets/audio/t91_2/t2_91_8.mp3';
import t1_91_9 from 'assets/audio/t91_2/t2_91_9.mp3';
import t1_91_10 from 'assets/audio/t91_2/t2_91_10.mp3';

import img1 from 'assets/imgs/topik/topik2/l91_1_1.png'
import img2 from 'assets/imgs/topik/topik2/l91_1_2.png'
import img3 from 'assets/imgs/topik/topik2/l91_1_3.png'

// 토픽 91 II 듣기 10
export const listening2Quizlist: TopikListeningType[] = [
    {
        q: '다음을 듣고 가장 알맞은 그림 또는 그래프를 고르십시오.',
        audio: t1_91_1,
        isImgQ: true,
        question: img1,
        answer: [
            {
                text: '(1)',
                isAnswer: false
            },
            {
                text: '(2)',
                isAnswer: true
            },
            {
                text: '(3)',
                isAnswer: false
            },
            {
                text: '(4)',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 가장 알맞은 그림 또는 그래프를 고르십시오.',
        audio: t1_91_2,
        isImgQ: true,
        question: img2,
        answer: [
            {
                text: '(1)',
                isAnswer: true
            },
            {
                text: '(2)',
                isAnswer: false
            },
            {
                text: '(3)',
                isAnswer: false
            },
            {
                text: '(4)',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 가장 알맞은 그림 또는 그래프를 고르십시오.',
        audio: t1_91_3,
        isImgQ: true,
        question: img3,
        answer: [
            {
                text: '(1)',
                isAnswer: false
            },
            {
                text: '(2)',
                isAnswer: false
            },
            {
                text: '(3)',
                isAnswer: true
            },
            {
                text: '(4)',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 이어질 수 있는 말로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_4,
        answer: [
            {
                text: '그래? 안 나와서 걱정했어.',
                isAnswer: true
            },
            {
                text: '무슨 학원에 다니고 있어?',
                isAnswer: false
            },
            {
                text: '그래? 할머니 댁에 언제 가?',
                isAnswer: false
            },
            {
                text: '부모님과 함께 가기로 했거든.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 이어질 수 있는 말로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_5,
        answer: [
            {
                text: '그럼 거기로 다시 가 볼까요?',
                isAnswer: true
            },
            {
                text: '아직 한 곳밖에 안 가 봤는데요.',
                isAnswer: false
            },
            {
                text: '그럼 이 소파를 사는 게 어때요?',
                isAnswer: false
            },
            {
                text: '집 근처에 있는 소파 가게에서 샀어요.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 이어질 수 있는 말로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_6,
        answer: [
            {
                text: '면접 날짜를 다시 확인하고 알려 줘.',
                isAnswer: false
            },
            {
                text: '준비 많이 했으니까 잘할 수 있을 거야.',
                isAnswer: true
            },
            {
                text: '네, 면접 보기 전인데 안 떨린다니 대단하다.',
                isAnswer: false
            },
            {
                text: '생각하지 못한 질문을 받아서 정말 놀랐겠네.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 이어질 수 있는 말로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_7,
        answer: [
            {
                text: '맞아요. 이전 작품은 내용이 별로였어요.',
                isAnswer: false
            },
            {
                text: '그래요? 이 드라마 다 보면 다른 작품도 봐야겠어요.',
                isAnswer: true
            },
            {
                text: '맞아요. 이 드라마는 몇 번을 다시 봐도 재미있네요.',
                isAnswer: false
            },
            {
                text: '그래요? 이 작가가 처음으로 쓴 작품인 줄 몰랐어요.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 이어질 수 있는 말로 가장 알맞은 것을 고르십시오.',
        audio: t1_91_8,
        answer: [
            {
                text: '얼룩이 잘 지워진 것 같아요.',
                isAnswer: false
            },
            {
                text: '운동화를 깨끗하게 신으셨네요.',
                isAnswer: false
            },
            {
                text: '해 봐야 알겠지만 최대한 없애 볼게요.',
                isAnswer: true
            },
            {
                text: '집에 돌아가서 다시 한번 지워 보려고요.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 여자가 이어서 할 행동으로 가장 알맞은 것을 고르십시오. (9~10)',
        audio: t1_91_9,
        answer: [
            {
                text: '김밥을 산다.',
                isAnswer: true
            },
            {
                text: '기차를 탄다.',
                isAnswer: false
            },
            {
                text: '자리에 앉는다.',
                isAnswer: false
            },
            {
                text: '카페로 들어간다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 여자가 이어서 할 행동으로 가장 알맞은 것을 고르십시오. (9~10)',
        audio: t1_91_10,
        answer: [
            {
                text: '고객 대기실로 간다.',
                isAnswer: true
            },
            {
                text: '남자에게 열쇠를 준다.',
                isAnswer: false
            },
            {
                text: '차의 상태를 확인한다.',
                isAnswer: false
            },
            {
                text: '차를 수리하려고 예약한다.',
                isAnswer: false
            },
        ],
    },
]
