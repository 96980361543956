import Pagination from 'components/pagination/Pagination';
import Tab from 'components/tab/Tab';
import MbWrapper from 'components/warpper/MbWrapper';
import { ganadaApb } from 'data/hanguel';
import React, { useEffect, useState } from 'react';
import { TopikWordType } from 'types/topik';
import foot from 'assets/d-icon/foot.svg';
import logo from 'assets/d-icon/logo.svg';
import { romanize } from '@romanize/korean';
import Modal from 'components/modal/Modal';
import Card from 'components/card/Card';
import { word2DataList } from 'data/topik/word';

interface TopikWordCardProps extends TopikWordType {
  pr: string;
}

const TopikWordsList2 = () => {
  const [apb, setApb] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [current, setCurrent] = useState<false | TopikWordCardProps>(false);
  const [list, setList] = useState<TopikWordType[]>(word2DataList[apb - 1].slice(0, 6));

  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    setList(word2DataList[apb - 1].slice((currentPage - 1) * 6, (currentPage - 1) * 6 + 6));
  }, [currentPage]);

  const handleOpenWord = (target: TopikWordType) => () => {
    setCurrent({ ...target, pr: romanize(target.kr) });
    setIsClosing(false);
  };

  const handleCloseCardModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setCurrent(false);
      setIsClosing(false);
    }, 300); // 애니메이션 시간과 맞춰줍니다.
  };

  const handleClickTab = (value: number) => {
    setApb(value);
    setCurrentPage(1);
    setList(word2DataList[value - 1].slice((currentPage - 1) * 6, (currentPage - 1) * 6 + 6));
  };

  return (
    <MbWrapper>
      <div className="flex flex-col items-center">
        <div
          className="pb-4 text-[22px] font-semibold flex items-start gap-[10px] link cursor-pointer"
          // onClick={() => handleClickLink(ele.url)}
        >
          <img src={foot} alt="icon" className="w-[30px] link-icon" />
          TOPIK II 単語
          <p className="min-w-[110px]">
            {' '}
            <span className="inline-block px-2 text-pr2">{ganadaApb[apb - 1].text}</span>
            {word2DataList[apb - 1].length}단어
          </p>
        </div>
        <Tab list={ganadaApb} onClick={handleClickTab} />
        <ul className="flex flex-col gap-5 mt-10 h-[400px]">
          {list.map((ele) => (
            <li
              className="bg-pr5 cursor-pointer w-[320px] text-center px-5 font-semibold rounded-[100px] h-[50px]"
              onClick={handleOpenWord(ele)}
            >
              <span className="word-item">
                {ele.kr} / 意味: {ele.mean}
              </span>
            </li>
          ))}
        </ul>
        <Pagination totalItems={word2DataList[apb - 1].length} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      {current && (
        <Modal closeModal={handleCloseCardModal} isClosing={isClosing}>
          <Card>
            <div className="flex flex-col gap-6  text-center justify-between font-semibold pt-16 pb-4">
              <h3 className="text-[30px] text-white">{current.kr}</h3>
              <div className="w-[280px] h-[120px] bg-white rounded-[20px] flex items-center flex-col justify-center whitespace-pre-wrap">
                <img src={logo} className="absolute bottom-[110px]" />
                <p className="text-[14px] mb-2">
                  <span className="font-medium text-[14px] text-pr2">意味</span>: {current.mean}
                </p>
                <p className="font-normal text-[18px]">
                  <span className="font-medium text-[14px] text-pr2">発音</span>: [ {current.pr} ]
                </p>
              </div>
            </div>
          </Card>
        </Modal>
      )}
    </MbWrapper>
  );
};

export default TopikWordsList2;
