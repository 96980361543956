import React from 'react'
import { Link } from 'react-router-dom'
import backgroundImage from 'assets/imgs/background/lg.png'
import ContentButton from 'components/button/ContentButton';
import MbWrapper from 'components/warpper/MbWrapper';

type LinkDataProps = {
    url: string;
    text: string;
}

const Community = () => {
    const linkData: LinkDataProps[] = [
        {
            url: '/community/notice',
            text: 'お知らせ'
        },
        {
            url: '/community/event',
            text: 'イベント'
        },
        {
            url: '/community/qna',
            text: 'Q&A'
        }
    ]
    return (
        <MbWrapper bg='lg'>
            <div className='flex gap-[10px]'>
                {linkData.map(ele => (
                    <Link to={ele.url}>
                        <ContentButton text={ele.text} />
                    </Link>
                ))
                }
            </div>
        </MbWrapper>
    )
}

export default Community