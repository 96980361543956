import Tab from 'components/tab/Tab'
import {
    hanguelTable1, hanguelTable2, hanguelTable3, hanguelTable4, hanguelTable5
} from 'data/hanguel'
import React, { useState } from 'react'

const KoreanAlpahbet = () => {
    const tableList = [
        { text: hanguelTable1.title, value: 1 },
        { text: hanguelTable2.title, value: 2 },
        { text: hanguelTable3.title, value: 3 },
        { text: hanguelTable4.title, value: 4 },
        { text: hanguelTable5.title, value: 5 },
    ]

    const data = [
        hanguelTable1,
        hanguelTable2,
        hanguelTable3,
        hanguelTable4,
        hanguelTable5,
    ]

    const [current, setCurrent] = useState(hanguelTable1)

    const handleClickTab = (value: number) => {
        setCurrent(data[value - 1])
    }


    return (
        <div className='pt-3'>
            <Tab list={tableList} onClick={handleClickTab} />
            <div className=' flex-col bg-pr5 h-[331px] px-1 pb-0  rounded-[15px] w-[340px]'>
                <div className='flex justify-center mt-2'>
                    <h2 className='text-center text-[18px] pt-2 pb-[2px] text-pr2 border-b-[2px] font-bold border-b-pr2 w-fit'>{current.title}</h2>
                </div>
                <div className='pt-5 pb-3 h-[200px] flex-col flex-center  mt-6'>
                    {current.data.map(ele => (
                        <div className='flex'>
                            {ele.map(el => (
                                <div className='flex-center flex-col w-[42px] mb-5 text-center'>
                                    <span className='text-pr2 mb-[4px]  bg-gray2 text-[14px] w-5 h-5 text-center font-black rounded-full inline-block'>{el.kr}</span>
                                    <span className='text-[11px] font-black'>{el.jp}<br />{el.en}</span>
                                </div>
                            ))}
                        </div>
                    ))}

                </div>
                <p className='px-2 pt-3 text-[12px] font-semibold leading-5'> <b className='text-pr2 font-black'>「ㅓ」</b>は「お」の発音で口を大きく<b className='text-pr2 font-black'>「ㅡ」</b>は 日本の「す」の発音と似ている</p>
            </div>
        </div >

    )
}

export default KoreanAlpahbet