import React from 'react'



const SlideCard = (props: {
  title: string;
  thumbnail: string;
  description?: string;
  link: string;
  // content:{url:string;imgs:string[]}[];
}) => {
  const { title, thumbnail, description, link } = props;

  return (
    <div className='w-[220px] bg-white rounded-[20px] h-[308px] shadow-lg mx-5' >
      <div className=' mx-[2px] mt-[2px] flex-col justify-between'>
        <img className='w-full h-[216px] rounded-t-[20px]' src={thumbnail} />

        <div className='pt-[10px] flex flex-col justify-center'>
          <h3 className='text-[16px] font-semibold text-center line-normal'>
            {title}
          </h3>
          {/* <p className='text-black text-[12px] mt-[10px] h-9'>
            {description}
          </p> */}

        </div>
        <div className='px-[10px] pb-[10px]'>
          <button className='flex-center w-[200px] h-10'>
            <a href={link} target='_blank' className='mt-3 text-center bg-pr2 text-white rounded-[10px] w-full p-2 text-[14px]'>
              コンテンツを見に行く
            </a></button>
        </div>
      </div>
    </div>
  )
}

export default SlideCard