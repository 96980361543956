import axios from "axios";
import Pagination from "components/pagination/Pagination";
import { useState, useEffect } from "react";
import { NoticeType } from "types/community";
import noResult from 'assets/imgs/background/no_data.png'
import bg from 'assets/imgs/background/community.png'


const Notice = () => {


    const [data, setData] = useState<{ data: NoticeType[] }>({ data: [] })
    const [activeIndex, setActiveIndex] = useState<number | null>(null)
    const [currentPage, setCurrentPage] = useState<number>(1)



    useEffect(() => {
        const fetchGist = async () => {
            try {
                const response = await axios.get(`https://api.github.com/gists/${process.env.REACT_APP_GIST_ID}`);
                console.log(JSON.parse(response.data.files[process.env.REACT_APP_GIST_NOTICE as string].content))
                setData(JSON.parse(response.data.files[process.env.REACT_APP_GIST_NOTICE as string].content));
            } catch (error) {
                // 글로벌 에러 세팅
                console.error('Error fetching the Gist:', error);
            }
        };

        fetchGist();
    }, []);

    const handleNoticeDetail = (idx: number) => {
        setActiveIndex(activeIndex === idx ? null : idx)

    }

    return (
        <div className='min-h-[calc(100vh-64px)] flex flex-col items-center'>
            <img src={bg} alt="일러스트 배경" className="bg-[#E2EDFF] rounded-[16px]" />
            <ul className="w-[320px] pt-5">
                {
                    !data.data || data.data.length === 0 ? (
                        <li className="mt-[110px] flex flex-col items-center justify-center">
                            <img src={noResult} alt="데이터가 없습니다." />
                            <p className="font-semibold text-[18px] text-[#333333]">まだ内容がありません！</p>
                        </li>
                    ) : (
                        data.data.map((item, idx) => (
                            <li
                                onClick={() => handleNoticeDetail(idx)}
                                key={idx}
                            >
                                <div className="flex justify-between items-center h-12 border-b-[0.5px] border-[#D4DBE4] text-sm gap-2.5">
                                    <p className="font-medium">{idx + 1}</p>
                                    <p className={`flex-1 font-semibold truncate cursor-pointer ${activeIndex === idx ? 'active' : ''}`}>{item.title}</p>
                                    <p className="text-[#B1B9C7]">{item.date}</p>
                                </div>
                                <div className={` text-sm h-0 overflow-hidden transition-[height] duration-500 ease-in-out ${activeIndex === idx ? 'min-h-[240px] h-auto' : ''}`}>
                                    <div className="pt-5 p-[12px_20px] break-all">
                                        {item.desc}
                                    </div>
                                </div>
                            </li>
                        )
                        )
                    )
                }
            </ul>
            {!(!data.data || data.data.length === 0) && <Pagination totalItems={data.data.length} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
        </div>
    )
}

export default Notice
