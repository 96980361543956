export const word10_1 = [
    { "kr": "차", "mean": "車、茶" },
    { "kr": "차갑다", "mean": "冷たい" },
    { "kr": "차다", "mean": "蹴る" },
    { "kr": "차리다", "mean": "（食事の席を）準備する、整える、（気持ち、精神を）しっかりさせる" },
    { "kr": "착하다", "mean": "（人柄が）良い、善良だ、やさしい" },
    { "kr": "참", "mean": "本当に、非常に" },
    { "kr": "창문", "mean": "窓" },
    { "kr": "찾다", "mean": "探す、（銀行などでお金を）おろす、（人を）訪ねる" },
    { "kr": "찾아가다", "mean": "（人に会いに、用事を済ませに）行く、（落し物などを）引き取って持って行く" },
    { "kr": "찾아오다", "mean": "（人に会いに、用事を済ませに）来る、（落し物などを）引き取って持って来る" },
    { "kr": "채소", "mean": "野菜" },
    { "kr": "책", "mean": "本" },
    { "kr": "책방", "mean": "本屋" },
    { "kr": "책상", "mean": "机" },
    { "kr": "책임", "mean": "責任" },
    { "kr": "책장", "mean": "本棚" },
    { "kr": "처음", "mean": "最初、初めて" },
    { "kr": "처음 뵙겠습니다", "mean": "はじめまして" },
    { "kr": "천", "mean": "1000" },
    { "kr": "천천히", "mean": "ゆっくり" },
    { "kr": "철", "mean": "季節、旬" },
    { "kr": "첫", "mean": "初～、初めての～" },
    { "kr": "첫째", "mean": "最初の～、第１の～、１番目の～" },
    { "kr": "청바지", "mean": "（青い）ジーパン" },
    { "kr": "청소기", "mean": "掃除機" },
    { "kr": "청소하다", "mean": "掃除する" },
    { "kr": "초", "mean": "秒" },
    { "kr": "초대하다", "mean": "招待する" },
    { "kr": "초등학교", "mean": "初等学校（＝小学校）" },
    { "kr": "초등학생", "mean": "小学生" },
    { "kr": "초록색", "mean": "緑色" },
    { "kr": "초콜릿", "mean": "チョコレート" },
    { "kr": "촬영하다", "mean": "撮影する" },
    { "kr": "최고", "mean": "最高" },
    { "kr": "추다", "mean": "踊る" },
    { "kr": "추석", "mean": "チュソク（秋夕）、旧暦のお盆" },
    { "kr": "추억", "mean": "思い出" },
    { "kr": "축구", "mean": "サッカー" },
    { "kr": "축제", "mean": "祝祭、お祭り" },
    { "kr": "축하", "mean": "祝賀" },
    { "kr": "축하하다", "mean": "祝う" },
    { "kr": "축하합니다", "mean": "おめでとうございます" },
    { "kr": "출구", "mean": "出口" },
    { "kr": "출근하다", "mean": "出勤する" },
    { "kr": "출발", "mean": "出発" },
    { "kr": "출발하다", "mean": "出発する" },
    { "kr": "출장", "mean": "出張" },
    { "kr": "춤", "mean": "踊り、ダンス" },
    { "kr": "춤추다", "mean": "踊りを踊る" },
    { "kr": "춥다", "mean": "寒い" },
    { "kr": "취미", "mean": "趣味" },
    { "kr": "취소하다", "mean": "取り消す" },
    { "kr": "취직하다", "mean": "就職する" },
    { "kr": "층", "mean": "～階" },
    { "kr": "치과", "mean": "歯科" },
    { "kr": "치료하다", "mean": "治療する" },
    { "kr": "치마", "mean": "スカート、（韓服の）チマ" },
    { "kr": "치약", "mean": "歯磨き粉" },
    { "kr": "친구", "mean": "友達" },
    { "kr": "친절하다", "mean": "親切だ" },
    { "kr": "친척", "mean": "親戚" },
    { "kr": "친하다", "mean": "親しい" },
    { "kr": "칠", "mean": "7" },
    { "kr": "칠십", "mean": "70" },
    { "kr": "칠월", "mean": "７月" },
    { "kr": "칠판", "mean": "黒板" },
    { "kr": "침대", "mean": "ベッド、寝台" },
    { "kr": "칫솔", "mean": "歯ブラシ" }
]
