export const word11_1 = [
    { "kr": "카드", "mean": "カード" },
    { "kr": "카레", "mean": "カレー" },
    { "kr": "카메라", "mean": "カメラ" },
    { "kr": "카페", "mean": "カフェ、喫茶店" },
    { "kr": "칼", "mean": "刃物、刀、包丁、カッター" },
    { "kr": "캐나다", "mean": "カナダ" },
    { "kr": "커피", "mean": "コーヒー" },
    { "kr": "커피숍", "mean": "コーヒーショップ、喫茶店" },
    { "kr": "컴퓨터", "mean": "コンピューター、パソコン" },
    { "kr": "컵", "mean": "カップ" },
    { "kr": "케이크", "mean": "ケーキ" },
    { "kr": "켜다", "mean": "つける、（電源を）入れる" },
    { "kr": "코", "mean": "鼻" },
    { "kr": "코트", "mean": "コート" },
    { "kr": "코피", "mean": "鼻血" },
    { "kr": "콜라", "mean": "コーラ" },
    { "kr": "콧노래", "mean": "鼻歌" },
    { "kr": "콧물", "mean": "鼻水" },
    { "kr": "콩", "mean": "豆、大豆" },
    { "kr": "크기", "mean": "大きさ、サイズ" },
    { "kr": "크다", "mean": "大きい" },
    { "kr": "크리스마스", "mean": "クリスマス" },
    { "kr": "큰아버지", "mean": "父の（一番上の）兄、伯父" },
    { "kr": "큰어머니", "mean": "父の（一番上の）兄の妻、伯母" },
    { "kr": "키", "mean": "背、身長" },
    { "kr": "킬로그램", "mean": "キログラム" },
    { "kr": "킬로미터", "mean": "キロメートル" }
]
