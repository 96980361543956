export const word_2_2_1 = [
    { "kr": "나날이", "mean": "日に日に" },
    { "kr": "나들이", "mean": "おでかけ" },
    { "kr": "나름", "mean": "次第" },
    { "kr": "나머지", "mean": "残り、余り" },
    { "kr": "나물", "mean": "ナムル" },
    { "kr": "나뭇잎", "mean": "葉" },
    { "kr": "나서다", "mean": "進み出る、関与する" },
    { "kr": "나아가다", "mean": "進む" },
    { "kr": "나아지다", "mean": "よくなる" },
    { "kr": "나타내다", "mean": "現す" },
    { "kr": "낙서", "mean": "落書き" },
    { "kr": "낙엽", "mean": "落ち葉" },
    { "kr": "난로", "mean": "ストーブ、暖炉" },
    { "kr": "난방", "mean": "暖房" },
    { "kr": "날개", "mean": "翼、羽" },
    { "kr": "날다", "mean": "飛ぶ" },
    { "kr": "날로", "mean": "日ごとに" },
    { "kr": "날리다", "mean": "飛ばす" },
    { "kr": "날씬하다", "mean": "やせている" },
    { "kr": "날아가다", "mean": "飛ぶ" },
    { "kr": "날아다니다", "mean": "飛び回る" },
    { "kr": "날아오다", "mean": "飛んでくる" },
    { "kr": "날아오르다", "mean": "飛び立つ" },
    { "kr": "낡다", "mean": "古い" },
    { "kr": "남녀노소", "mean": "老若男女" },
    { "kr": "남녀평등", "mean": "男女平等" },
    { "kr": "남다", "mean": "残る、余る" },
    { "kr": "남부", "mean": "南部" },
    { "kr": "남성", "mean": "男性" },
    { "kr": "남성복", "mean": "男性服" },
    { "kr": "낫다", "mean": "治る" },
    { "kr": "낭비", "mean": "浪費、無駄遣い" },
    { "kr": "낯설다", "mean": "見慣れない" },
    { "kr": "낳다", "mean": "産む" },
    { "kr": "내내", "mean": "ずっと" },
    { "kr": "내려다보다", "mean": "見下ろす" },
    { "kr": "내복", "mean": "下着" },
    { "kr": "내부", "mean": "内部" },
    { "kr": "내성적", "mean": "内気な" },
    { "kr": "내용물", "mean": "中身、内容物" },
    { "kr": "내쫓다", "mean": "追い出す" },
    { "kr": "냇가", "mean": "小川" },
    { "kr": "냉방병", "mean": "冷房病" },
    { "kr": "너그럽다", "mean": "寛大だ、度量が大きい" },
    { "kr": "너무나", "mean": "あまりにも" },
    { "kr": "넉넉하다", "mean": "十分だ、豊かだ" },
    { "kr": "널다", "mean": "干す、風に当てる" },
    { "kr": "넓히다", "mean": "広げる、広くする" },
    { "kr": "넘기다", "mean": "渡す" },
    { "kr": "넘어가다", "mean": "移る" },
    { "kr": "넘치다", "mean": "あふれる" },
    { "kr": "네모나다", "mean": "四角い" },
    { "kr": "네트워크", "mean": "ネットワーク" },
    { "kr": "년대", "mean": "年代" },
    { "kr": "노동", "mean": "労働" },
    { "kr": "노동자", "mean": "労働者" },
    { "kr": "노력", "mean": "努力" },
    { "kr": "노선", "mean": "路線" },
    { "kr": "노약자", "mean": "お年寄り、体の不自由な方" },
    { "kr": "노인", "mean": "老人" },
    { "kr": "노처녀", "mean": "未婚で年をとった女性" },
    { "kr": "노총각", "mean": "未婚で年を取った男性" },
    { "kr": "노트북", "mean": "ノート型パソコン" },
    { "kr": "녹다", "mean": "溶ける" },
    { "kr": "녹음", "mean": "録音" },
    { "kr": "녹음기", "mean": "録音機" },
    { "kr": "논문", "mean": "論文" },
    { "kr": "놀랍다", "mean": "驚くべきだ、目覚ましい" },
    { "kr": "놀리다", "mean": "からかう" },
    { "kr": "놀이", "mean": "遊び" },
    { "kr": "놀이터", "mean": "公園、広場" },
    { "kr": "놈", "mean": "奴" },
    { "kr": "농담", "mean": "冗談" },
    { "kr": "농민", "mean": "農民、農夫" },
    { "kr": "농부", "mean": "農夫" },
    { "kr": "농사", "mean": "農業" },
    { "kr": "농약", "mean": "農薬" },
    { "kr": "농작물", "mean": "農作物" },
    { "kr": "농촌", "mean": "農村" },
    { "kr": "높이", "mean": "高さ" },
    { "kr": "높이다", "mean": "高める" },
    { "kr": "높임말", "mean": "敬語" },
    { "kr": "놓이다", "mean": "置かれる" },
    { "kr": "놓치다", "mean": "逃す、失う" },
    { "kr": "눈길", "mean": "視線" },
    { "kr": "눈높이", "mean": "目の高さ" },
    { "kr": "눈병", "mean": "眼病" },
    { "kr": "눈보라", "mean": "吹雪" },
    { "kr": "눈사태", "mean": "雪崩" },
    { "kr": "눈썹", "mean": "眉" },
    { "kr": "눈치", "mean": "気転、顔つき" },
    { "kr": "눌러쓰다", "mean": "深々とかぶる" },
    { "kr": "느긋하다", "mean": "のんびりしている、ゆったりしている" },
    { "kr": "느끼하다", "mean": "脂っこい" },
    { "kr": "늘리다", "mean": "増やす" },
    { "kr": "늘어나다", "mean": "伸びる、長くなる、増える" },
    { "kr": "늘어서다", "mean": "並ぶ" },
    { "kr": "늙다", "mean": "老いる" },
    { "kr": "늦추다", "mean": "遅らせる" }
]