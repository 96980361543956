import React, { useState } from 'react'
import Hiragana from '../haguel/components/Hiragana'
import Button from 'components/button/Button'
import Question from '../haguel/components/Question'
import MbWrapper from 'components/warpper/MbWrapper'
import Tab from 'components/tab/Tab'
import Nav from 'components/tab/Nav'
import KoreanAlpahbet from './components/KoreanAlpahbet'


const Hanguel = () => {
    const tabList = [
        { text: "ひらがな", value: 1 },
        { text: "ハングル", value: 2 },
    ]
    const [current, setCurrent] = useState<number>(1)

    const handleClickTab = (value: number) => {
        setCurrent(value)
    }

    return (
        <MbWrapper>
            <div>
                <Nav list={tabList} onClick={handleClickTab} />
                {current === 1 ? <Hiragana /> :
                    <KoreanAlpahbet />}
                <Question />
            </div>

        </MbWrapper>
    )
}

export default Hanguel 