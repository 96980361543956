import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import SlideCard from 'components/card/SlideCard';
import { slideData } from 'data/landing'
import { shuffleArray } from 'utils/array';

const Landing4 = () => {
    const [emblaRef] = useEmblaCarousel({ loop: true })



    return (
        <div className='bg-bg4 w-full min-h-[500px] flex flex-col items-center py-20'>
            <div className='w-full'>
                <div className="embla" ref={emblaRef}>

                    <div className="embla__container">
                        {
                            shuffleArray(slideData).map(ele => (
                                <SlideCard
                                    title={ele.title}
                                    thumbnail={ele.src}
                                    // description={ele.desc}
                                    link={ele.link}
                                />
                            ))
                        }
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Landing4