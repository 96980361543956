export const word_2_10_1 = [
    { "kr": "차라리", "mean": "むしろ" },
    { "kr": "차례", "mean": "順序、順番" },
    { "kr": "차분하다", "mean": "物静かだ" },
    { "kr": "차이", "mean": "差" },
    { "kr": "차이점", "mean": "違い、異なる点、差異点" },
    { "kr": "착각", "mean": "錯覚、勘違い" },
    { "kr": "찬물", "mean": "おひや" },
    { "kr": "찬성", "mean": "賛成" },
    { "kr": "참가", "mean": "参加" },
    { "kr": "참고", "mean": "参考" },
    { "kr": "참기름", "mean": "ゴマ油" },
    { "kr": "참다", "mean": "こらえる、我慢する" },
    { "kr": "참석", "mean": "参席、参加" },
    { "kr": "참여", "mean": "参与、立ち合い" },
    { "kr": "참치", "mean": "マグロ" },
    { "kr": "찻집", "mean": "お茶屋、喫茶店" },
    { "kr": "창", "mean": "窓" },
    { "kr": "창피", "mean": "恥" },
    { "kr": "찾아내다", "mean": "探し出す" },
    { "kr": "찾아다니다", "mean": "探し回る" },
    { "kr": "찾아보다", "mean": "調べる、探してみる" },
    { "kr": "채널", "mean": "チャンネル" },
    { "kr": "채식", "mean": "菜食" },
    { "kr": "채식주의자", "mean": "菜食主義者、ベジタリアン" },
    { "kr": "채용", "mean": "採用" },
    { "kr": "채우다", "mean": "補う" },
    { "kr": "채팅", "mean": "チャット" },
    { "kr": "책임지다", "mean": "責任を負う" },
    { "kr": "챙기다", "mean": "取りまとめる、用意する" },
    { "kr": "처녀", "mean": "処女" },
    { "kr": "처리", "mean": "処理" },
    { "kr": "처방", "mean": "処方" },
    { "kr": "처방전", "mean": "処方箋" },
    { "kr": "천국", "mean": "天国" },
    { "kr": "천사", "mean": "天使" },
    { "kr": "천장", "mean": "天井" },
    { "kr": "천재", "mean": "天才、天災" },
    { "kr": "철학", "mean": "哲学" },
    { "kr": "첫날", "mean": "初日" },
    { "kr": "첫눈", "mean": "初雪" },
    { "kr": "첫사랑", "mean": "初恋" },
    { "kr": "첫인상", "mean": "第一印象" },
    { "kr": "청구서", "mean": "請求書" },
    { "kr": "청년", "mean": "青年" },
    { "kr": "청소년", "mean": "青少年" },
    { "kr": "청첩장", "mean": "招待状" },
    { "kr": "청혼", "mean": "プロポーズ" },
    { "kr": "체격", "mean": "体格" },
    { "kr": "체온", "mean": "体温" },
    { "kr": "체육", "mean": "体育" },
    { "kr": "체육관", "mean": "体育館" },
    { "kr": "체조", "mean": "体操" },
    { "kr": "체중", "mean": "体重" },
    { "kr": "체험", "mean": "体験" },
    { "kr": "쳐다보다", "mean": "見上げる、見つめる、眺める" },
    { "kr": "초급", "mean": "初級" },
    { "kr": "초기", "mean": "初期" },
    { "kr": "초대장", "mean": "招待状" },
    { "kr": "초보자", "mean": "初心者" },
    { "kr": "초청장", "mean": "招待状" },
    { "kr": "총", "mean": "銃" },
    { "kr": "총무과", "mean": "総務課" },
    { "kr": "촬영장", "mean": "撮影所" },
    { "kr": "촬영지", "mean": "撮影地" },
    { "kr": "최근", "mean": "最近" },
    { "kr": "최대", "mean": "最大" },
    { "kr": "최선", "mean": "最善" },
    { "kr": "최신", "mean": "最新" },
    { "kr": "최저", "mean": "最低" },
    { "kr": "최종", "mean": "最終" },
    { "kr": "최초", "mean": "最初" },
    { "kr": "최후", "mean": "最後" },
    { "kr": "추가", "mean": "追加" },
    { "kr": "추위", "mean": "寒さ" },
    { "kr": "추천", "mean": "推薦" },
    { "kr": "추천서", "mean": "推薦状" },
    { "kr": "추첨", "mean": "抽選" },
    { "kr": "추측", "mean": "推測" },
    { "kr": "출국", "mean": "出国" },
    { "kr": "출근길", "mean": "出勤途中、出勤時" },
    { "kr": "출력", "mean": "出力" },
    { "kr": "출산", "mean": "出産" },
    { "kr": "출산율", "mean": "出産率" },
    { "kr": "출생", "mean": "出生" },
    { "kr": "출석", "mean": "出席" },
    { "kr": "출신", "mean": "出身" },
    { "kr": "출입", "mean": "出入り" },
    { "kr": "출입국", "mean": "出入国" },
    { "kr": "출퇴근", "mean": "出勤と退社" },
    { "kr": "출판사", "mean": "出版社" },
    { "kr": "충격", "mean": "衝撃" },
    { "kr": "충고", "mean": "忠告" },
    { "kr": "충돌", "mean": "衝突" },
    { "kr": "충분하다", "mean": "十分だ" },
    { "kr": "충분히", "mean": "十分に" },
    { "kr": "취업", "mean": "就職" },
    { "kr": "취업난", "mean": "就職難" },
    { "kr": "취침", "mean": "就寝" },
    { "kr": "취하다", "mean": "酔う" },
    { "kr": "측", "mean": "側" },
    { "kr": "치료제", "mean": "治療薬" }
]

export const word_2_10_2 = [
    { "kr": "치수", "mean": "寸法" },
    { "kr": "치우다", "mean": "片づける、掃除する" },
    { "kr": "치즈", "mean": "チーズ" },
    { "kr": "친어머니", "mean": "実母、実の母" },
    { "kr": "친환경", "mean": "環境に優しい" },
    { "kr": "침", "mean": "つば" },
    { "kr": "침실", "mean": "寝室" },
    { "kr": "칭찬", "mean": "称賛" }
]
