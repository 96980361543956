import { TopikReadingType } from "types/topik";
import img9 from 'assets/imgs/topik/topik2/r91_2_9.png'
import img10 from 'assets/imgs/topik/topik2/r91_2_10.png'

export const Reading2Quizlist: TopikReadingType[] = [
    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '등산하고 싶다',
                isAnswer: false
            },
            {
                text: '등산해도 된다',
                isAnswer: false
            },
            {
                text: '등산할 것 같다',
                isAnswer: false
            },
            {
                text: '등산한 적이 있다',
                isAnswer: true
            },
        ],
        question:
            `나는 오래전에 설악산을 (      ).`,
    },
    {
        q: '들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '이사한 지',
                isAnswer: false
            },
            {
                text: '이사하거든',
                isAnswer: false
            },
            {
                text: '이사하려면',
                isAnswer: false
            },
            {
                text: '이사하고 나서',
                isAnswer: true
            },
        ],
        question:
            `새집으로 (      ) 가구를 새로 샀다.`,
    },
    {
        q: '밑줄 친 부분과 의미가 가장 비슷한 것을 고르십시오.',
        answer: [
            {
                text: '돕기 위해서',
                isAnswer: true
            },
            {
                text: '돕는 대신에',
                isAnswer: false
            },
            {
                text: '돕기 무섭게',
                isAnswer: false
            },
            {
                text: '돕는 바람에',
                isAnswer: false
            },
        ],
        question:
            `어려운 이웃을 돕고자 매년 봉사 활동에 참여하고 있다.`,
    },
    {
        q: '밑줄 친 부분과 의미가 가장 비슷한 것을 고르십시오.',
        answer: [
            {
                text: '본 척했다',
                isAnswer: false
            },
            {
                text: '보기 나름이다',
                isAnswer: false
            },
            {
                text: '볼 수밖에 없었다',
                isAnswer: false
            },
            {
                text: '본 거나 마찬가지이다',
                isAnswer: true
            },
        ],
        question:
            `지난 3년 동안 영화를 한 편 봤으니 거의 안 본 셈이다.`,
    },
    {
        q: '다음은 무엇에 대한 글인지 고르십시오.',
        answer: [
            {
                text: '비누',
                isAnswer: false
            },
            {
                text: '칫솔',
                isAnswer: true
            },
            {
                text: '안경',
                isAnswer: false
            },
            {
                text: '수건',
                isAnswer: false
            },
        ],
        question:
            `입속 치아 사이사이를 깨끗이! 부드럽게 잘 닦여요~.`,
    },
    {
        q: '다음은 무엇에 대한 글인지 고르십시오.',
        answer: [
            {
                text: '은행',
                isAnswer: false
            },
            {
                text: '서점',
                isAnswer: false
            },
            {
                text: '유치원',
                isAnswer: true
            },
            {
                text: '우체국',
                isAnswer: false
            },
        ],
        question:
            `아이들의 예쁜 꿈과 희망을 키워 주는 곳 부모의 마음으로 보살핍니다.`,
    },
    {
        q: '다음은 무엇에 대한 글인지 고르십시오.',
        answer: [
            {
                text: '건강 관리',
                isAnswer: true
            },
            {
                text: '안전 운전',
                isAnswer: false
            },
            {
                text: '전화 예절',
                isAnswer: false
            },
            {
                text: '절약 습관',
                isAnswer: false
            },
        ],
        question:
            `계단으로 다니기! 하루 30분 운동하기! 우리 몸을 위해 실천하세요.`,
    },
    {
        q: '다음은 무엇에 대한 글인지 고르십시오.',
        answer: [
            {
                text: '관람 규칙',
                isAnswer: true
            },
            {
                text: '제품 소개',
                isAnswer: false
            },
            {
                text: '사용 방법',
                isAnswer: false
            },
            {
                text: '예약 문의',
                isAnswer: false
            },
        ],
        question:
            `공연장 내에서는 사진 촬영을 금지합니다.
휴대 전화는 전원을 끄거나 진동으로 해 주십시오.`,
    },
    {
        q: '다음 글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '성인과 학생의 버스 요금이 같다.',
                isAnswer: false
            },
            {
                text: '이 버스는 별빛공원에서 출발한다.',
                isAnswer: false
            },
            {
                text: '매일 오전에 이 버스를 탈 수 있다.',
                isAnswer: false
            },
            {
                text: '이 버스를 타려면 미리 신청해야 한다.',
                isAnswer: true
            },
        ],
        isImgQ: true,
        question: img9
    },
    {
        q: '다음 그래프의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '자가용으로 출퇴근한다고 응답한 직장인들의 비율이 줄었다.',
                isAnswer: false
            },
            {
                text: '걸어서 출퇴근하는 직장인은 2012년보다 2022년이 더 적었다.',
                isAnswer: true
            },
            {
                text: '2022년에는 지하철을 타고 출퇴근하는 직장인이 가장 많았다.',
                isAnswer: false
            },
            {
                text: '2012년에 직장인들은 출퇴근할 때 버스를 제일 많이 이용했다.',
                isAnswer: false
            },
        ],
        isImgQ: true,
        question: img10
    },
]