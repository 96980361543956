import Card from 'components/card/Card'
import MbWrapper from 'components/warpper/MbWrapper'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {}

const TopikWords = (props: Props) => {
    return (
        <MbWrapper bg='lg'>
            <div className='flex flex-col gap-6 mt-4'>
                <Card>
                    <div className='py-6 text-white flex flex-col items-center justify-between px-6 gap-4'>
                        <h2 className='font-semibold text-[24px]'>TOPIK I</h2>
                        <p>TOPIK1の単語帳では、基本単語を学習できます。</p>
                        <p className='font-semibold text-[20px]'>1600 単語</p>
                        <Link to='/wordsbook/I'>
                            <button className='bg-pr3 border-pr4 border-[1px] border-solid text-pr2 py-2 px-4 rounded-lg'>単語帳で</button>
                        </Link>
                    </div>
                </Card>
                <Card>
                    <div className='py-6 text-white flex flex-col items-center justify-between px-6 gap-4'>
                        <h2 className='font-semibold text-[24px]'>TOPIK II</h2>
                        <p>TOPIK2の単語帳では、より多様な単語を学習できます。</p>
                        <p className='font-semibold text-[20px]'>2600 単語</p>
                        <Link to='/wordsbook/II'>
                            <button className='bg-pr3 border-pr4 border-[1px] border-solid text-pr2 py-2 px-4 rounded-lg'>単語帳で</button>
                        </Link>
                    </div>
                </Card>
            </div>
        </MbWrapper>
    )
}

// 토픽1: 토픽1
// 설명: "토픽1의 단어장에서는 주제와 관련된 기본 단어를 학습할 수 있습니다."
// 토픽2: 토픽2
// 설명: "토픽2의 단어장에서는 더 깊이 있는 단어와 개념을 다룹니다."

export default TopikWords