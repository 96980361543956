import { DataType } from "types/item";

export const getRandomValueFromArray = (arr: DataType[]): DataType => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export const getRandomValuesFromArray = (arr: DataType[], count: number) => {
  // 배열 복사 (원본 배열을 변경하지 않기 위해)
  const arrayCopy = [...arr];
  // Fisher-Yates 알고리즘을 사용하여 배열 섞기
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }
  // 배열의 처음 `count` 개 요소 반환
  return arrayCopy.slice(0, count);
}

export const shuffleTwoArrays = (arr1: DataType[], arr2: DataType[]) => {
  // 두 배열 합치기
  const combinedArray = [...arr1, ...arr2];
  // Fisher-Yates 알고리즘을 사용하여 배열 셔플
  for (let i = combinedArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [combinedArray[i], combinedArray[j]] = [combinedArray[j], combinedArray[i]];
  }

  return combinedArray;
}

export const sortByLeadingNumber = (array: {
  name: string;
  [key: string]: any; // 다른 속성들은 어떤 것이든 상관없음
}[]) => {
  return array.sort((a, b) => {
    // 각 이름의 맨 앞 숫자를 추출
    const numA = parseInt(a.name.split('.')[0], 10);
    const numB = parseInt(b.name.split('.')[0], 10);

    // 숫자를 기준으로 비교
    return numA - numB;
  });
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    // 0부터 i(포함) 사이의 랜덤한 인덱스 선택
    const j = Math.floor(Math.random() * (i + 1));

    // 배열의 요소를 서로 교환
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
