export const TopikListeningResult: { A: string; B: string; C: string; D: string; } = {
    A: 'TOPIKマスター!最高!👍',
    B: 'よくできました!素敵です!',
    C: '頑張ったんですね! もう少し!',
    D: 'もう一度勉強してみましょう？\nがんばりましょう!'
}

export const hanguelResult: { A: string; B: string; C: string; D: string; } = {
    A: 'ハングルマスター!最高!👍',
    B: 'よくできました！素敵です!',
    C: '頑張ったんですね! もう少し1',
    D: 'もう一度勉強してみましょう？\nがんばりましょう!'
}

export const QuizResult: { A: string; B: string; C: string; D: string; } = {
    A: 'クイズの天才？!最高!👍',
    B: 'よくできました!素敵です!',
    C: '頑張ったんですね! もう少し!',
    D: 'もう一度勉強してみましょう？\nがんばりましょう!'
}
