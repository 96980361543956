import React, { useEffect, useState } from 'react';
import play from 'assets/d-icon/play.svg';
import stop from 'assets/d-icon/stop.svg';

type ProgressBarProps = {
    currentTime: number;
    duration: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPlayPause: () => void;
    isPlaying: boolean;
};

const AudioBar: React.FC<ProgressBarProps> = ({
    currentTime,
    duration,
    onChange,
    onPlayPause,
    isPlaying,
}) => {

    const [progressWidth, setProgressWidth] = useState(0);

    useEffect(() => {
        // current 값에 따라 프로그래스 바의 너비를 애니메이션으로 설정
        const progress = (currentTime / duration) * 100;
        setProgressWidth(progress);
    }, [currentTime, duration]);



    return (
        <div className="flex flex-center gap-5 mt-[10px]">
            <img src={isPlaying ? stop : play} className='cursor-pointer'
                // 멈춤 아이콘 수정
                onClick={onPlayPause}
            />
            <div className='flex items-center bg-pr5 h-[50px] px-5 rounded-[15px]'>
                <div className='relative w-[210px] flex items-center'>
                    <div className='absolute  bg-gray2 h-[10px] w-full rounded-[10px]'></div>
                    <div className='absolute  bg-pr2 h-[10px] rounded-[10px] z-10'
                        style={{ width: progressWidth > 90 ? `calc(${progressWidth}% - 2px)` : `calc(${progressWidth}% + 3px)` }}
                    //style={{ width: `${progressWidth}%` }}
                    />
                    <input
                        type="range"
                        className="z-30 absolute w-full h-2 rounded-lg appearance-none cursor-pointer"
                        style={{
                            outline: 'none',
                            background: 'transparent',
                            left: progressWidth < 5 ? '-2px' : '0'
                        }}
                        min="0"
                        max={duration || 0}
                        value={currentTime}
                        onChange={onChange}  // 슬라이더를 드래그해서 변경할 때 호출
                        step="0.1"
                    />
                </div>
            </div>
        </div>
    );
};

export default AudioBar;
