import { TopikListeningType } from "types/topik";
import t1_91_11 from 'assets/audio/t91_1/topik_91_1_11.mp3';
import t1_91_12 from 'assets/audio/t91_1/topik_91_1_12.mp3';
import t1_91_13 from 'assets/audio/t91_1/topik_91_1_13.mp3';
import t1_91_14 from 'assets/audio/t91_1/topik_91_1_14.mp3';
import t1_91_15 from 'assets/audio/t91_1/topik_91_1_15.mp3';
import t1_91_16 from 'assets/audio/t91_1/topik_91_1_16.mp3';
import t1_91_17 from 'assets/audio/t91_1/topik_91_1_17.mp3';
import t1_91_18 from 'assets/audio/t91_1/topik_91_1_18.mp3';
import t1_91_19 from 'assets/audio/t91_1/topik_91_1_19.mp3';
import t1_91_20 from 'assets/audio/t91_1/topik_91_1_20.mp3';
import img15 from 'assets/imgs/topik/topik1/l91_1_15.png'
import img16 from 'assets/imgs/topik/topik1/l91_1_16.png'


export const listeningQuizlist2: TopikListeningType[] = [
    {
        q: '다음은 무엇에 대해 말하고 있습니까?',
        audio: t1_91_11,
        answer: [
            {
                text: '값',
                isAnswer: false
            },
            {
                text: '맛',
                isAnswer: true
            },
            {
                text: '주말',
                isAnswer: false
            },
            {
                text: '시간',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음은 무엇에 대해 말하고 있습니까?',
        audio: t1_91_12,
        answer: [
            {
                text: '운동',
                isAnswer: false
            },
            {
                text: '요일',
                isAnswer: false
            },
            {
                text: '나라',
                isAnswer: false
            },
            {
                text: '장소',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음은 무엇에 대해 말하고 있습니까?',
        audio: t1_91_13,
        answer: [
            {
                text: '계획',
                isAnswer: false
            },
            {
                text: '날씨',
                isAnswer: true
            },
            {
                text: '약속',
                isAnswer: false
            },
            {
                text: '위치',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음은 무엇에 대해 말하고 있습니까?',
        audio: t1_91_14,
        answer: [
            {
                text: '고향',
                isAnswer: true
            },
            {
                text: '여행',
                isAnswer: false
            },
            {
                text: '취미',
                isAnswer: false
            },
            {
                text: '휴일',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 가장 알맞은 것을 고르십시오.',
        audio: t1_91_15,
        isImgQ: true,
        question: img15,
        answer: [
            {
                text: '(1)',
                isAnswer: true
            },
            {
                text: '(2)',
                isAnswer: false
            },
            {
                text: '(3)',
                isAnswer: false
            },
            {
                text: '(4)',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 가장 알맞은 것을 고르십시오.',
        audio: t1_91_16,
        isImgQ: true,
        question: img16,
        answer: [
            {
                text: '(1)',
                isAnswer: false
            },
            {
                text: '(2)',
                isAnswer: true
            },
            {
                text: '(3)',
                isAnswer: false
            },
            {
                text: '(4)',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 대화 내용과 같은 것을 고르십시오.',
        audio: t1_91_17,
        answer: [
            {
                text: '남자는 방학에 공부를 했습니다.',
                isAnswer: true
            },
            {
                text: '여자는 여행을 가지 못했습니다.',
                isAnswer: false
            },
            {
                text: '여자는 방학에 남자를 자주 만났습니다.',
                isAnswer: false
            },
            {
                text: '남자는 아르바이트를 한 적이 없습니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 대화 내용과 같은 것을 고르십시오.',
        audio: t1_91_18,
        answer: [
            {
                text: '남자는 김준 씨를 모릅니다.',
                isAnswer: false
            },
            {
                text: '여자는 김준 씨를 좋아합니다.',
                isAnswer: true
            },
            {
                text: '여자는 어제 김준 씨를 만났습니다.',
                isAnswer: false
            },
            {
                text: '남자는 김준 씨와 사진을 찍지 못했습니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 대화 내용과 같은 것을 고르십시오.',
        audio: t1_91_19,
        answer: [
            {
                text: '남자는 오늘 수업이 없습니다.',
                isAnswer: false
            },
            {
                text: '남자는 여자와 축구를 볼 겁니다.',
                isAnswer: true
            },
            {
                text: '여자는 오후에 남자의 집에 가려고 합니다.',
                isAnswer: false
            },
            {
                text: '여자는 축구를 보기 전에 저녁을 먹을 겁니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 대화 내용과 같은 것을 고르십시오.',
        audio: t1_91_20,
        answer: [
            {
                text: '남자는 주말에 바쁩니다.',
                isAnswer: false
            },
            {
                text: '여자는 오 년 동안 기타를 쳤습니다.',
                isAnswer: false
            },
            {
                text: '여자는 혼자 기타 연습을 했습니다.',
                isAnswer: true
            },
            {
                text: '남자는 여자에게 기타를 배우려고 합니다.',
                isAnswer: false
            },
        ],
    },
]