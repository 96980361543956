import React from 'react';

interface AnswerButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    selected?: boolean;
}

const AnswerButton: React.FC<AnswerButtonProps> = ({ selected, children, ...props }) => {
    return (
        <button
            {...props}
            className={`w-[320px] rounded-[100px] py-[14px] px-4 h-[50px] flex-center font-semibold transition-colors duration-300 ${selected ? 'bg-pr2 text-white' : 'bg-pr5'
                }`}
        >
            {children}
        </button>
    );
}

export default AnswerButton;