export const word_2_3_1 = [
    { "kr": "다가오다", "mean": "近づいてくる、接近する" },
    { "kr": "다녀가다", "mean": "寄っていく" },
    { "kr": "다람쥐", "mean": "リス" },
    { "kr": "다루다", "mean": "扱う" },
    { "kr": "다림질", "mean": "アイロンかけ" },
    { "kr": "다수", "mean": "多数" },
    { "kr": "다양하다", "mean": "多様だ" },
    { "kr": "다음날", "mean": "翌日" },
    { "kr": "다정하다", "mean": "思いやりがある、情深い、やさしい" },
    { "kr": "다투다", "mean": "もめる、言い争う" },
    { "kr": "다행", "mean": "幸い" },
    { "kr": "다행히", "mean": "幸い、運よく" },
    { "kr": "단계", "mean": "段階" },
    { "kr": "단골", "mean": "常連" },
    { "kr": "단기간", "mean": "短期間" },
    { "kr": "단단하다", "mean": "丈夫だ、しっかりしている" },
    { "kr": "단발머리", "mean": "おかっぱ" },
    { "kr": "단백질", "mean": "タンパク質" },
    { "kr": "단속", "mean": "取り締まり" },
    { "kr": "단순하다", "mean": "単純である" },
    { "kr": "단순히", "mean": "単純に" },
    { "kr": "단위", "mean": "単位" },
    { "kr": "단정하다", "mean": "断定する、端正だ" },
    { "kr": "단체", "mean": "団体" },
    { "kr": "단풍", "mean": "紅葉、もみじ" },
    { "kr": "달라지다", "mean": "変わる" },
    { "kr": "달려가다", "mean": "走る" },
    { "kr": "달려오다", "mean": "走ってくる" },
    { "kr": "달리", "mean": "他に、別に" },
    { "kr": "달리기", "mean": "ランニング" },
    { "kr": "달밤", "mean": "月夜" },
    { "kr": "달아나다", "mean": "逃げる" },
    { "kr": "달콤하다", "mean": "甘ったるい" },
    { "kr": "닭갈비", "mean": "タッカルビ" },
    { "kr": "담", "mean": "垣根" },
    { "kr": "담기다", "mean": "入れられる、盛られる" },
    { "kr": "담다", "mean": "盛る" },
    { "kr": "담당", "mean": "担当" },
    { "kr": "담당자", "mean": "担当者" },
    { "kr": "담백하다", "mean": "淡白だ" },
    { "kr": "담뱃불", "mean": "たばこの火" },
    { "kr": "담임", "mean": "担任" },
    { "kr": "답", "mean": "答え、返事" },
    { "kr": "답글", "mean": "返信" },
    { "kr": "답답하다", "mean": "はがゆい、重苦しい" },
    { "kr": "답변", "mean": "答弁" },
    { "kr": "당기다", "mean": "引く、引っ張る" },
    { "kr": "당분간", "mean": "当分の間" },
    { "kr": "당연하다", "mean": "当然である" },
    { "kr": "당연히", "mean": "当然" },
    { "kr": "당장", "mean": "即刻、今のところ" },
    { "kr": "당하다", "mean": "やられる" },
    { "kr": "당황하다", "mean": "慌てる" },
    { "kr": "대개", "mean": "大概、大抵" },
    { "kr": "대기업", "mean": "大企業" },
    { "kr": "대다", "mean": "当てる、触れる" },
    { "kr": "대단하다", "mean": "すごい、大したものだ" },
    { "kr": "대단히", "mean": "非常に" },
    { "kr": "대도시", "mean": "大都市" },
    { "kr": "대량", "mean": "大量" },
    { "kr": "대리", "mean": "代理" },
    { "kr": "대문", "mean": "門" },
    { "kr": "대신", "mean": "代わり" },
    { "kr": "대여", "mean": "貸与、レンタル" },
    { "kr": "대여료", "mean": "レンタル料" },
    { "kr": "대접", "mean": "もてなし、接待" },
    { "kr": "대중", "mean": "大衆" },
    { "kr": "대중교통", "mean": "公共交通機関" },
    { "kr": "대중목욕탕", "mean": "銭湯" },
    { "kr": "대책", "mean": "対策" },
    { "kr": "대청소", "mean": "大掃除" },
    { "kr": "대체로", "mean": "大体" },
    { "kr": "대출", "mean": "貸し付け、貸し出し" },
    { "kr": "대통령", "mean": "大統領" },
    { "kr": "대표", "mean": "代表" },
    { "kr": "대표적", "mean": "代表的" },
    { "kr": "대형", "mean": "大型" },
    { "kr": "댄스", "mean": "ダンス" },
    { "kr": "더구나", "mean": "その上、しかも、さらに" },
    { "kr": "더러워지다", "mean": "汚れる" },
    { "kr": "더욱", "mean": "もっと、よりいっそう" },
    { "kr": "더위", "mean": "暑さ" },
    { "kr": "더하다", "mean": "ひどくなる、加える" },
    { "kr": "덕", "mean": "おかげ" },
    { "kr": "던지다", "mean": "投げる" },
    { "kr": "덜", "mean": "より少なく、まだ～ない" },
    { "kr": "덜다", "mean": "減らす" },
    { "kr": "데다", "mean": "やけどする" },
    { "kr": "데려오다", "mean": "連れてくる" },
    { "kr": "데치다", "mean": "ゆでる、さっとゆでる" },
    { "kr": "도구", "mean": "道具" },
    { "kr": "도난", "mean": "盗難" },
    { "kr": "도대체", "mean": "一体全体" },
    { "kr": "도둑", "mean": "泥棒" },
    { "kr": "도망가다", "mean": "逃げる" },
    { "kr": "도서", "mean": "図書" },
    { "kr": "도시락", "mean": "弁当" },
    { "kr": "도장", "mean": "印鑑、実印" },
    { "kr": "도전", "mean": "挑戦" },
    { "kr": "도중", "mean": "途中" },
    { "kr": "독", "mean": "毒" }
]

export const word_2_3_2 = [
    { "kr": "독감", "mean": "インフルエンザ、ひどい風邪" },
    { "kr": "독립", "mean": "独立" },
    { "kr": "독방", "mean": "独房" },
    { "kr": "독신자", "mean": "独身者" },
    { "kr": "독특하다", "mean": "独特だ" },
    { "kr": "돌", "mean": "石" },
    { "kr": "돌다", "mean": "回る" },
    { "kr": "돌려받다", "mean": "返してもらう" },
    { "kr": "돌려보내다", "mean": "返す、帰らせる" },
    { "kr": "돌려주다", "mean": "返す" },
    { "kr": "돌리다", "mean": "回す" },
    { "kr": "돌보다", "mean": "面倒をみる、世話をする" },
    { "kr": "돌솥", "mean": "石鍋" },
    { "kr": "돌아다니다", "mean": "歩き回る" },
    { "kr": "돌아보다", "mean": "振り向いて見る" },
    { "kr": "돌아서다", "mean": "振り返る" },
    { "kr": "돌잔치", "mean": "一歳の誕生日" },
    { "kr": "동굴", "mean": "洞窟" },
    { "kr": "동료", "mean": "同僚" },
    { "kr": "동물원", "mean": "動物園" },
    { "kr": "동부", "mean": "東部" },
    { "kr": "동서양", "mean": "東洋と西洋" },
    { "kr": "동시", "mean": "同時" },
    { "kr": "동양화", "mean": "東洋画" },
    { "kr": "동영상", "mean": "動画" },
    { "kr": "동일하다", "mean": "同じである" },
    { "kr": "동작", "mean": "動作" },
    { "kr": "동창", "mean": "同窓" },
    { "kr": "동창회", "mean": "同窓会" },
    { "kr": "동해안", "mean": "東海岸" },
    { "kr": "동호회", "mean": "同好会" },
    { "kr": "동화", "mean": "童話" },
    { "kr": "동화책", "mean": "児童書" },
    { "kr": "되도록", "mean": "できるだけ" },
    { "kr": "되돌리다", "mean": "取り戻す" },
    { "kr": "되돌아보다", "mean": "振り返る" },
    { "kr": "되살아나다", "mean": "生き返る、蘇る" },
    { "kr": "두려움", "mean": "恐れ" },
    { "kr": "두려워하다", "mean": "恐れる" },
    { "kr": "두세", "mean": "二・三" },
    { "kr": "두통", "mean": "頭痛" },
    { "kr": "두통약", "mean": "頭痛薬" },
    { "kr": "둔하다", "mean": "鈍い" },
    { "kr": "둘러보다", "mean": "見回す" },
    { "kr": "둥글다", "mean": "丸い" },
    { "kr": "뒤떨어지다", "mean": "劣る、遅れる" },
    { "kr": "뒤집다", "mean": "裏返す" },
    { "kr": "뒤풀이", "mean": "打ち上げ" },
    { "kr": "뒷모습", "mean": "後ろ姿" },
    { "kr": "뒷문", "mean": "裏門" },
    { "kr": "뒷이야기", "mean": "裏話" },
    { "kr": "뒷자리", "mean": "後ろの席" },
    { "kr": "드디어", "mean": "いよいよ、ついに" },
    { "kr": "드라이", "mean": "ドライ" },
    { "kr": "드림", "mean": "ドリーム" },
    { "kr": "드물다", "mean": "まれだ" },
    { "kr": "든든하다", "mean": "心強い、頼もしい、しっかりしている" },
    { "kr": "들려주다", "mean": "聞かせてくれる" },
    { "kr": "들르다", "mean": "立ち寄る" },
    { "kr": "들리다", "mean": "聞こえる" },
    { "kr": "들어보다", "mean": "聞いてみる" },
    { "kr": "들어주다", "mean": "聞いてくれる" },
    { "kr": "들이다", "mean": "かける、費やす" },
    { "kr": "등록", "mean": "登録" },
    { "kr": "등록금", "mean": "登録金、授業料" },
    { "kr": "등록증", "mean": "登録証" },
    { "kr": "등불", "mean": "明かり、灯火" },
    { "kr": "디스크", "mean": "ディスク、ヘルニア" },
    { "kr": "디자이너", "mean": "デザイナー" },
    { "kr": "디지털", "mean": "デジタル" },
    { "kr": "따다", "mean": "もぎとる、摘む、得る" },
    { "kr": "따라다니다", "mean": "ついていく、つきまとう" },
    { "kr": "따라서", "mean": "従って" },
    { "kr": "따로따로", "mean": "別々、それぞれ" },
    { "kr": "따지다", "mean": "問い詰める" },
    { "kr": "딱", "mean": "ピッタリ" },
    { "kr": "딱딱하다", "mean": "固い" },
    { "kr": "딴", "mean": "べつの、他の" },
    { "kr": "땅", "mean": "土地" },
    { "kr": "땅콩", "mean": "ピーナッツ" },
    { "kr": "때때로", "mean": "時折" },
    { "kr": "때로", "mean": "時々" },
    { "kr": "떠오르다", "mean": "浮かぶ" },
    { "kr": "떨다", "mean": "震える、震わす" },
    { "kr": "떨리다", "mean": "震える、揺れる" },
    { "kr": "떨어뜨리다", "mean": "落とす、下落させる" },
    { "kr": "떼다", "mean": "取る、離す" },
    { "kr": "또박또박", "mean": "きちんと、はきはき" },
    { "kr": "또한", "mean": "また" },
    { "kr": "똑같이", "mean": "同じく" },
    { "kr": "똑똑하다", "mean": "利口だ、賢い、頭いい" },
    { "kr": "뚜렷하다", "mean": "はっきりしている" },
    { "kr": "뚫다", "mean": "穴を開ける" },
    { "kr": "뚱뚱하다", "mean": "太っている" },
    { "kr": "뛰어가다", "mean": "走る" },
    { "kr": "뛰어나다", "mean": "優れている" },
    { "kr": "뛰어내리다", "mean": "飛び降りる" },
    { "kr": "뛰어넘다", "mean": "飛び越える" },
    { "kr": "뛰어다니다", "mean": "走りまわる" },
]

export const word_2_3_3 = [
    { "kr": "뛰어오다", "mean": "走ってくる" },
    { "kr": "뛰쳐나가다", "mean": "飛び出す" },
    { "kr": "뜨거워지다", "mean": "熱くなる" },
    { "kr": "뜻밖에", "mean": "意外に" },
    { "kr": "띠", "mean": "干支" }
]