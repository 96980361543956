import React from 'react'
import xl from 'assets/imgs/background/xl.png'
import face from 'assets/d-icon/face.svg'
import paper from 'assets/imgs/background/paper.png'
import AnimatedSection from 'components/animation/AnimationSection';


const Landing2 = () => {
    return (
        <div className='bg-bg3 w-[360px]'>
            <div
                className='min-h-[500px] bg-illust'
                style={{ backgroundImage: `url(${xl})` }}
            >
                <div className='pt-[100px] flex-center flex-col'>
                    <div className='relative text-center rounded-[100px] p-[10px] bg-bg2 w-[320px]'>
                        <img src={face} className='absolute top-[-36px] right-3' />
                        <p className='text-white'>ヨシクラスはこんな理由で始めました</p>
                    </div>
                    <AnimatedSection
                        className='min-h-[360px] bg-illust pt-[70px] px-9'
                        style={{ backgroundImage: `url(${paper})` }}
                        inViewAni={{ opacity: 1, }}
                        initAni={{ opacity: 0, }}
                        duration={0.4}
                        delay={0.2}
                    >
                        <p className='leading-normal pt-4 whitespace-pre-wrap text-center text-[14px] font-semibold'>
                            {`日本が好きで日本に住んでみたいと\n思っています。\n日本語を勉強しながら逆に韓国語を勉強する\n日本人を助けたかったです。\n（日本語は下手です！）！\nだからもっと共感しながら\n勉強できますモットーは\n「韓国語を学ぶ時はこうやって学べば\n簡単で面白そう」\nお互いに学ぶという気持ちで頑張ります。`}
                        </p>
                    </AnimatedSection>
                </div>
            </div>
        </div>
    )
}

export default Landing2