import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import '../../style/alert.css';
import close from 'assets/d-icon/close.svg';
import logo from 'assets/d-icon/logo.svg';

export const AlertPortal = ({ children }: { children: ReactNode }) => {
    const el = document.getElementById("modal") as Element;

    return ReactDOM.createPortal(<>{children}</>, el);
};

export interface AlertTemplatePropsType {
    alertModalState: boolean;
    setAlertModalToggle: React.Dispatch<React.SetStateAction<boolean>>
    children: ReactNode;
    width?: number;
    height?: number;
    className?: string;
}



const AlertTemplate = (props: AlertTemplatePropsType) => {
    const {
        alertModalState,
        setAlertModalToggle,
        children,
        width,
        height,
        className,
    } = props;

    return (
        alertModalState ?
            <AlertPortal>
                <div className={`c__alert toggleOpen  ${className}`}>
                    <div
                        className="alert-container"
                        style={{ width: width, minHeight: height }}
                    >
                        <div className="flex justify-end">
                            <img src={close} className="w-4 cursor-pointer" onClick={() => setAlertModalToggle(false)} />
                        </div>
                        <div className={`alert-body relative`}>
                            <img src={logo} className="absolute w-12 top-[-60px] left-[120px]" />
                            {/* 상태에 따른 아이콘 띄워주기 */}
                            {children}
                        </div>
                    </div>
                </div>
            </AlertPortal> : <></>

    );
};

export default AlertTemplate;

AlertTemplate.defaultProps = {
    width: 320,
    height: 200,

};
