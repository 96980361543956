import { TopikListeningType } from "types/topik";
import t1_91_21 from 'assets/audio/t91_1/topik_91_1_21.mp3';
import t1_91_22 from 'assets/audio/t91_1/topik_91_1_22.mp3';
import t1_91_23 from 'assets/audio/t91_1/topik_91_1_23.mp3';
import t1_91_24 from 'assets/audio/t91_1/topik_91_1_24.mp3';
import t1_91_25 from 'assets/audio/t91_1/topik_91_1_25.mp3';
import t1_91_26 from 'assets/audio/t91_1/topik_91_1_26.mp3';
import t1_91_27 from 'assets/audio/t91_1/topik_91_1_27.mp3';
import t1_91_28 from 'assets/audio/t91_1/topik_91_1_28.mp3';
import t1_91_29 from 'assets/audio/t91_1/topik_91_1_29.mp3';
import t1_91_30 from 'assets/audio/t91_1/topik_91_1_30.mp3';


// 토픽 91 I 듣기 1~10
export const listeningQuizlist3: TopikListeningType[] = [
    {
        q: '다음을 듣고 대화 내용과 같은 것을 고르십시오.',
        audio: t1_91_21,
        answer: [
            {
                text: '남자는 새 휴대 전화를 받았습니다.',
                isAnswer: false
            },
            {
                text: '남자는 휴대 전화를 찾으러 왔습니다.',
                isAnswer: false
            },
            {
                text: '남자의 휴대 전화는 고칠수 없습니다.',
                isAnswer: false
            },
            {
                text: '남자의 휴대 전화는 소리가 들리지 않습니다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 여자의 중심 생각을 고르십시오.',
        audio: t1_91_22,
        answer: [
            {
                text: '이번 시험을 잘 보고 싶습니다.',
                isAnswer: false
            },
            {
                text: '친구와 같이 공부하고 싶습니다.',
                isAnswer: false
            },
            {
                text: '아침 일찍 일어나는 것이 좋습니다.',
                isAnswer: true
            },
            {
                text: '피곤할 때 더 많이 자는 것이 좋습니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 여자의 중심 생각을 고르십시오.',
        audio: t1_91_23,
        answer: [
            {
                text: '냉면을 만들어 먹고 싶습니다.',
                isAnswer: false
            },
            {
                text: '음식을 바로 주문해야 합니다.',
                isAnswer: false
            },
            {
                text: '냉면이 빨리 나오면 좋겠습니다.',
                isAnswer: true
            },
            {
                text: '음식을 배달해서 먹는 것이 좋습니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 여자의 중심 생각을 고르십시오.',
        audio: t1_91_24,
        answer: [
            {
                text: '수첩을 찾으러 가고 싶습니다.',
                isAnswer: true
            },
            {
                text: '수업 시간에 늦으면 안 됩니다.',
                isAnswer: false
            },
            {
                text: '수첩을 새로 사는 것이 좋습니다.',
                isAnswer: false
            },
            {
                text: '중요한 일은 천천히 해야 합니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오.(25~26)',
        question:`여자가 왜 이 이야기를 하고 있는지 고르십시오.`,
        audio: t1_91_25,
        answer: [
            {
                text: '마트의 위치를 가르쳐 주려고',
                isAnswer: false
            },
            {
                text: '마트의 할인 상품을 소개하려고',
                isAnswer: false
            },
            {
                text: '마트를 이용할 수 있는 시간을 안내하려고',
                isAnswer: true
            },
            {
                text: '마트가 새로 문을 여는 것을 알려주려고',
                isAnswer: false
            },
        ],
       
    },
    {
        q: '다음을 듣고 물음에 답하시오.(25~26)',
        question:`들은 내용과 같은 것을 고르십시오.`,
        audio: t1_91_26,
        answer: [
            {
                text: '이 마트는 오늘 이용할 수 없습니다.',
                isAnswer: false
            },
            {
                text: '이 마트에서는 채소를 팔지 않습니다.',
                isAnswer: false
            },
            {
                text: '이 마트는 매일 오전 열 시에 시작합니다.',
                isAnswer: false
            },
            {
                text: '이 마트는 주말에 평일보다 늦게 문을 닫습니다.',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오.(27~28)',
        question:`두 사람이 무엇에 대해 이야기를 하고 있는지 고르십시오.`,
        audio: t1_91_27,
        answer: [
            {
                text: '미술관이 있는 장소',
                isAnswer: false
            },
            {
                text: '미술관에서 일하는 사람',
                isAnswer: false
            },
            {
                text: '미술관에 갈 수 있는 날',
                isAnswer: false
            },
            {
                text: '미술관에서 할 수 있는 일',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오.(27~28)',
        question:`들은 내용과 같은 것을 고르십시오.`,
        audio: t1_91_28,
        answer: [
            {
                text: '남자는 이 미술관에 가 본 적이 있습니다.',
                isAnswer: true
            },
            {
                text: '여자는 이 미술관에서 인형을 만들었습니다.',
                isAnswer: false
            },
            {
                text: '여자는 이 미술관에서 산 옷을 입고 있습니다.',
                isAnswer: false
            },
            {
                text: '남자는 이 미술관에서 산 필통을 가지고 있습니다.',
                isAnswer: false
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오.(29~30)',
        question:`여자가 수영을 배우게 된 이유를 고르십시오.`,
        audio: t1_91_29,
        answer: [
            {
                text: '수영을 가르치고 싶어서',
                isAnswer: false
            },
            {
                text: '수영이 건강에 도움이 돼서',
                isAnswer: false
            },
            {
                text: '아버지가 유명한 수영 선수라서',
                isAnswer: false
            },
            {
                text: '어렸을 때 본 선수처럼 되고 싶어서',
                isAnswer: true
            },
        ],
    },
    {
        q: '다음을 듣고 물음에 답하시오.(29~30)',
        question:`들은 내용과 같은 것을 고르십시오.`,
        audio: t1_91_30,
        answer: [
            {
                text: '여자는 작년에 수영 대회에 나갔습니다.',
                isAnswer: false
            },
            {
                text: '여자는 중학생 때 수영을 시작했습니다.',
                isAnswer: false
            },
            {
                text: '여자는 다음 달 수영 대회에 참가할 겁니다.',
                isAnswer: true
            },
            {
                text: '여자는 수영 대회에서 상을 못 받았습니다.',
                isAnswer: false
            },
        ],
    },
]
