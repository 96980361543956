export const word7_1 = [
    { "kr": "사", "mean": "4" },
    { "kr": "사거리", "mean": "四つ角" },
    { "kr": "사계절", "mean": "四季" },
    { "kr": "사고", "mean": "事故" },
    { "kr": "사과", "mean": "りんご" },
    { "kr": "사다", "mean": "買う" },
    { "kr": "사람", "mean": "人" },
    { "kr": "사랑", "mean": "愛" },
    { "kr": "사랑하다", "mean": "愛する" },
    { "kr": "사례", "mean": "事例" },
    { "kr": "사무실", "mean": "事務室" },
    { "kr": "사물", "mean": "物事" },
    { "kr": "사십", "mean": "40" },
    { "kr": "사업가", "mean": "事業家" },
    { "kr": "사용하다", "mean": "使用する" },
    { "kr": "사원", "mean": "社員" },
    { "kr": "사월", "mean": "４月" },
    { "kr": "사이", "mean": "間" },
    { "kr": "사이다", "mean": "サイダー" },
    { "kr": "사이즈", "mean": "サイズ" },
    { "kr": "사인하다", "mean": "サインする" },
    { "kr": "사장", "mean": "社長" },
    { "kr": "사전", "mean": "事典、辞典、辞書" },
    { "kr": "사진", "mean": "写真" },
    { "kr": "사진기", "mean": "写真機、カメラ" },
    { "kr": "사촌", "mean": "いとこ" },
    { "kr": "사탕", "mean": "飴、キャンディー" },
    { "kr": "사흘", "mean": "３日、３日間" },
    { "kr": "산", "mean": "山" },
    { "kr": "산책", "mean": "散策、散歩" },
    { "kr": "살", "mean": "～歳" },
    { "kr": "살다", "mean": "生きる、暮らす、住む" },
    { "kr": "삼", "mean": "3" },
    { "kr": "삼거리", "mean": "三つ角、三叉路" },
    { "kr": "삼계탕", "mean": "サムゲタン（蓼鶏湯）" },
    { "kr": "삼십", "mean": "30" },
    { "kr": "삼월", "mean": "３月" },
    { "kr": "삼촌", "mean": "（父方の）おじ" },
    { "kr": "상관", "mean": "関係" },
    { "kr": "상자", "mean": "箱、ボックス" },
    { "kr": "상처", "mean": "傷" },
    { "kr": "상품", "mean": "商品" },
    { "kr": "새", "mean": "新しい、鳥" },
    { "kr": "새로", "mean": "新しく" },
    { "kr": "새벽", "mean": "深夜、未明、明け方" },
    { "kr": "새우", "mean": "えび" },
    { "kr": "색", "mean": "色" },
    { "kr": "색깔", "mean": "色" },
    { "kr": "샌드위치", "mean": "サンドウィッチ" },
    { "kr": "생각", "mean": "考え、思想" },
    { "kr": "생각되다", "mean": "考えられる、思われる" },
    { "kr": "생각하다", "mean": "考える" },
    { "kr": "생기다", "mean": "生じる" },
    { "kr": "생선", "mean": "魚（料理）" },
    { "kr": "생신", "mean": "（目上の人の）誕生日" },
    { "kr": "생일", "mean": "誕生日" },
    { "kr": "생활", "mean": "生活" },
    { "kr": "샤워하다", "mean": "シャワーする" },
    { "kr": "샴푸", "mean": "シャンプー" },
    { "kr": "서다", "mean": "立つ、（時計の針などが）止まる" },
    { "kr": "서로", "mean": "互いに、共に" },
    { "kr": "서류", "mean": "書類" },
    { "kr": "서른", "mean": "30" },
    { "kr": "서비스", "mean": "サービス" },
    { "kr": "서양", "mean": "西洋" },
    { "kr": "서울", "mean": "ソウル" },
    { "kr": "서울역", "mean": "ソウル駅" },
    { "kr": "서점", "mean": "書店、本屋" },
    { "kr": "서쪽", "mean": "西、西側" },
    { "kr": "선물", "mean": "プレゼント" },
    { "kr": "선배", "mean": "先輩" },
    { "kr": "선생님", "mean": "先生" },
    { "kr": "선수", "mean": "選手" },
    { "kr": "선택하다", "mean": "選択する、選ぶ" },
    { "kr": "선풍기", "mean": "扇風機" },
    { "kr": "설거지", "mean": "皿洗い" },
    { "kr": "설날", "mean": "（旧正月の）元旦" },
    { "kr": "설렁탕", "mean": "ソルロンタン" },
    { "kr": "설명", "mean": "説明" },
    { "kr": "설명하다", "mean": "説明する" },
    { "kr": "설악산", "mean": "雪岳山" },
    { "kr": "설탕", "mean": "砂糖" },
    { "kr": "섬", "mean": "島" },
    { "kr": "성", "mean": "姓、名字" },
    { "kr": "성격", "mean": "性格" },
    { "kr": "성함", "mean": "お名前" },
    { "kr": "세계", "mean": "世界" },
    { "kr": "세수하다", "mean": "洗顔する" },
    { "kr": "세우다", "mean": "たてる" },
    { "kr": "세일하다", "mean": "セールする" },
    { "kr": "세탁기", "mean": "洗濯機" },
    { "kr": "세탁소", "mean": "クリーニング屋" },
    { "kr": "센터", "mean": "センター" },
    { "kr": "센티미터", "mean": "センチメートル" },
    { "kr": "셋", "mean": "3" },
    { "kr": "셋째", "mean": "第３の～、３番目の～" },
    { "kr": "소", "mean": "牛" },
    { "kr": "소개", "mean": "紹介" },
    { "kr": "소개하다", "mean": "紹介する" },
    { "kr": "소고기", "mean": "牛肉" },
    { "kr": "소금", "mean": "塩" }
]


export const word7_2 = [
    { "kr": "소리", "mean": "音、声" },
    { "kr": "소설", "mean": "小説" },
    { "kr": "소설가", "mean": "小説家" },
    { "kr": "소식", "mean": "消息、便り" },
    { "kr": "소파", "mean": "ソファー" },
    { "kr": "소포", "mean": "小包" },
    { "kr": "소풍", "mean": "遠足" },
    { "kr": "소화제", "mean": "消化剤" },
    { "kr": "속", "mean": "中" },
    { "kr": "손", "mean": "手" },
    { "kr": "손가락", "mean": "手の指" },
    { "kr": "손님", "mean": "お客さま" },
    { "kr": "손수건", "mean": "ハンカチ" },
    { "kr": "송이", "mean": "（花などの）房" },
    { "kr": "쇼핑", "mean": "ショッピング" },
    { "kr": "수건", "mean": "タオル、手ぬぐい" },
    { "kr": "수도", "mean": "首都" },
    { "kr": "수돗물", "mean": "水道水" },
    { "kr": "수박", "mean": "すいか" },
    { "kr": "수술하다", "mean": "手術する" },
    { "kr": "수업", "mean": "授業" },
    { "kr": "수영", "mean": "水泳" },
    { "kr": "수영복", "mean": "水着" },
    { "kr": "수영장", "mean": "プール" },
    { "kr": "수요일", "mean": "水曜日" },
    { "kr": "수저", "mean": "箸とスプーン" },
    { "kr": "수첩", "mean": "手帳" },
    { "kr": "수학", "mean": "数学" },
    { "kr": "숙제", "mean": "宿題" },
    { "kr": "순서", "mean": "順序、順番" },
    { "kr": "숟가락", "mean": "スプーン" },
    { "kr": "술", "mean": "酒" },
    { "kr": "쉬다", "mean": "休む" },
    { "kr": "쉰", "mean": "50" },
    { "kr": "쉽다", "mean": "簡単だ" },
    { "kr": "슈퍼마켓", "mean": "スーパーマーケット" },
    { "kr": "스물", "mean": "20" },
    { "kr": "스웨터", "mean": "セーター" },
    { "kr": "스케이트", "mean": "スケート" },
    { "kr": "스키", "mean": "スキー" },
    { "kr": "스키장", "mean": "スキー場" },
    { "kr": "스타", "mean": "スター" },
    { "kr": "스타킹", "mean": "ストッキング" },
    { "kr": "스트레스", "mean": "ストレス" },
    { "kr": "스페인어", "mean": "スペイン語" },
    { "kr": "스포츠", "mean": "スポーツ" },
    { "kr": "슬퍼하다", "mean": "悲しむ" },
    { "kr": "슬프다", "mean": "悲しい" },
    { "kr": "습관", "mean": "習慣、くせ" },
    { "kr": "시", "mean": "市、時" },
    { "kr": "시간", "mean": "時間" },
    { "kr": "시간표", "mean": "時間表、時刻表" },
    { "kr": "시계", "mean": "時計" },
    { "kr": "시골", "mean": "いなか" },
    { "kr": "시끄럽다", "mean": "うるさい" },
    { "kr": "시내", "mean": "市内、小川" },
    { "kr": "시다", "mean": "酸っぱい" },
    { "kr": "시디", "mean": "CD" },
    { "kr": "시민", "mean": "市民" },
    { "kr": "시설", "mean": "施設" },
    { "kr": "시외", "mean": "市外" },
    { "kr": "시원하다", "mean": "涼しい、爽快だ" },
    { "kr": "시월", "mean": "１０月" },
    { "kr": "시작", "mean": "始まり" },
    { "kr": "시작되다", "mean": "始まる" },
    { "kr": "시작하다", "mean": "始める" },
    { "kr": "시장", "mean": "市場" },
    { "kr": "시청", "mean": "市役所" },
    { "kr": "시키다", "mean": "～させる、注文する" },
    { "kr": "시험", "mean": "試験" },
    { "kr": "식당", "mean": "食堂" },
    { "kr": "식사", "mean": "食事" },
    { "kr": "식탁", "mean": "食卓" },
    { "kr": "신", "mean": "新、神" },
    { "kr": "신다", "mean": "履く" },
    { "kr": "신문", "mean": "新聞" },
    { "kr": "신발", "mean": "履物、靴" },
    { "kr": "신청서", "mean": "申請書" },
    { "kr": "신청하다", "mean": "申請する" },
    { "kr": "실례", "mean": "失礼" },
    { "kr": "실례하다", "mean": "失礼する" },
    { "kr": "실수", "mean": "失敗、ミス" },
    { "kr": "싫다", "mean": "嫌いだ" },
    { "kr": "싫어하다", "mean": "嫌う" },
    { "kr": "심하다", "mean": "ひどい、（度が）過ぎる" },
    { "kr": "십", "mean": "10" },
    { "kr": "십이월", "mean": "１２月" },
    { "kr": "십일월", "mean": "１１月" },
    { "kr": "싱겁다", "mean": "（味が）薄い" },
    { "kr": "싶다", "mean": "～したい" },
    { "kr": "싸다", "mean": "包む、安い" },
    { "kr": "싸우다", "mean": "けんかする、争う" },
    { "kr": "쌀", "mean": "米" },
    { "kr": "쌀밥", "mean": "米飯、ごはん" },
    { "kr": "쌓이다", "mean": "積もる" },
    { "kr": "썰다", "mean": "切る" },
    { "kr": "쓰기", "mean": "書くこと、書き取り" },
    { "kr": "쓰다", "mean": "書く、使う、めがねをかける" },
    { "kr": "쓰레기", "mean": "ごみ" },
    { "kr": "쓰이다", "mean": "使われる、使える" }
]

export const word7_3 = [
    { "kr": "씨", "mean": "～氏" },
    { "kr": "씩", "mean": "～ずつ" },
    { "kr": "씹다", "mean": "噛む" },
    { "kr": "씻다", "mean": "洗う" }
]