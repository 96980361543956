import React from 'react'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const Button = (props: ButtonProps) => {
  return (
    <button {...props}
      className={`w-[150px] h-[50px] rounded-[10px] text-[18px] font-semibold ${props.className}`}
    >{props.children}</button>
  )
}

export default Button
