import { listening2Quizlist } from "./topik91_1";
import { listening2Quizlist2 } from "./topik91_2";
import { listening2Quizlist3 } from "./topik91_3";
import { listening2Quizlist4 } from "./topik91_4";
import { listening2Quizlist5 } from "./topik91_5";

export const listeningII93 = [
    listening2Quizlist,
    listening2Quizlist2,
    listening2Quizlist3,
    listening2Quizlist4,
    listening2Quizlist5,
]