import { TopikReadingType } from "types/topik";
import img33 from 'assets/imgs/topik/topik1/r91_1_33.png'

export const ReadingQuizlist4: TopikReadingType[] = [
    {
        q: '( ㄱ )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '간 것 같습니다',
                isAnswer: false
            },
            {
                text: '갈 수 없습니다',
                isAnswer: false
            },
            {
                text: '가면 안 됩니다',
                isAnswer: false
            },
            {
                text: '가기로 했습니다',
                isAnswer: true
            },
        ],
        question:
            `지난주에 새 식당이 문을 열었습니다. 
식당에는 여러 모양의 멋있는 가구들이 있습니다.
이 가구들은 모두 사장님이 만든 것인데 손님이 살 수도 있습니다.
저는 어제 동생과 그 식당에 처음 갔습니다.
우린 꽃 모양 테이블에 앉아서 음식을 먹었습니다.
분위기도 좋고 음식도 맛있어서 
우리는 다음에 또 (  ㄱ  ).`,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '저는 이 식당에 오랜만에 갔습니다.',
                isAnswer: false
            },
            {
                text: '저는 이 식당의 가구를 만들었습니다.',
                isAnswer: false
            },
            {
                text: '이 식당에서는 테이블을 살 수 있습니다.',
                isAnswer: true
            },
            {
                text: '이 식당의 가구는 모두 같은 모양입니다.',
                isAnswer: false
            },
        ],
        question:
            `지난주에 새 식당이 문을 열었습니다. 
식당에는 여러 모양의 멋있는 가구들이 있습니다.
이 가구들은 모두 사장님이 만든 것인데 손님이 살 수도 있습니다.
저는 어제 동생과 그 식당에 처음 갔습니다.
우린 꽃 모양 테이블에 앉아서 음식을 먹었습니다.
분위기도 좋고 음식도 맛있어서 
우리는 다음에 또 (  ㄱ  ).`,
    },

    {
        q: '왜 윗글을 썼는지 맞는 것을 고르십시오.',
        answer: [
            {
                text: '다른 청바지로 바꾸려고',
                isAnswer: false
            },
            {
                text: '청바지 주문을 취소하려고',
                isAnswer: false
            },
            {
                text: '청바지의 값을 확인하려고',
                isAnswer: false
            },
            {
                text: '청바지 도착 날짜를 물어보려고',
                isAnswer: true
            },
        ],
        isImgQ: true,
        question: img33,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '저는 목요일에 여행을 갑니다.',
                isAnswer: false
            },
            {
                text: '저는 오늘 이 청바지를 샀습니다.',
                isAnswer: false
            },
            {
                text: '저는 인터넷 쇼핑몰에서 이 옷을 샀습니다.',
                isAnswer: true
            },
            {
                text: '저는 서비스 센터에 전화한 적이 없습니다.',
                isAnswer: false
            },
        ],
        isImgQ: true,
        question: img33,
    },

    {
        q: '( ㄱ )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '참가비가 싸서',
                isAnswer: false
            },
            {
                text: '프로그램이 다양해서',
                isAnswer: true
            },
            {
                text: '집에서 할 수 있어서',
                isAnswer: false
            },
            {
                text: '주말에 갈 수 있어서',
                isAnswer: false
            },
        ],
        question:
            `매월 마지막 주 수요일에 인주시청 앞에서는 '책을 읽읍시다!'라는 행사를 합니다.
이 행사는 (  ㄱ  ) 사람들에게 인기가 많습니다.
새로 나온 책을 구경할 수도 있고 자기가 읽은 책을 다른 사람에게 싸게 팔 수도 있습니다.
유명한 작가와 인사를 나누는 프로그램도 있습니다.
행사에 다녀와서 느낀 것을 홈페이지에 쓰면 선물도 받을 수 있습니다.`,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '이 행사에 있는 책은 모두 오래된 책입니다.',
                isAnswer: false
            },
            {
                text: '이 행사는 인주시청에서 한 달 동안 합니다.',
                isAnswer: false
            },
            {
                text: '이 행사에서는 유명한 작가를 만날 수 있습니다.',
                isAnswer: true
            },
            {
                text: '이 행사에 참가한 모든 사람에게는 선물을 줍니다.',
                isAnswer: false
            },
        ],
        question:
            `매월 마지막 주 수요일에 인주시청 앞에서는 '책을 읽읍시다!'라는 행사를 합니다.
이 행사는 (  ㄱ  ) 사람들에게 인기가 많습니다.
새로 나온 책을 구경할 수도 있고 자기가 읽은 책을 다른 사람에게 싸게 팔 수도 있습니다.
유명한 작가와 인사를 나누는 프로그램도 있습니다.
행사에 다녀와서 느낀 것을 홈페이지에 쓰면 선물도 받을 수 있습니다.`,
    },

    {
        q: '( ㄱ )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '빛과 소리가 나니까',
                isAnswer: true
            },
            {
                text: '여러 색깔로 변하니까',
                isAnswer: false
            },
            {
                text: '예쁜 그림이 있으니까',
                isAnswer: false
            },
            {
                text: '크기와 무게가 적당하니까',
                isAnswer: false
            },
        ],
        question:
            `최근 특별한 어린이 우산이 나왔습니다. 이 우산을 펴면 위쪽에서 빛이 납니다.
그리고 손잡이의 버튼을 누르면 노랫소리가 나옵니다.
이렇게 (  ㄱ  ) 아이들이 좋아합니다.
보통 우산을 쓰면 아이가 잘 안 보이는데 이 우산을 쓰면 아이가 어디에 있는지 쉽게 알 수 있습니다.
또 튼튼해서 오래 쓸 수 있습니다.
그래서 다른 우산보다 비싸지만 인기가 있습니다.`,
    },

    {
        q: '윗글의 내용과 같은 것을 고르십시오.',
        answer: [
            {
                text: '이 우산은 약해서 고장이 잘 납니다.',
                isAnswer: false
            },
            {
                text: '이 우산은 손잡이 쪽에서 빛이 납니다.',
                isAnswer: false
            },
            {
                text: '이 우산의 가격은 다른 우산과 비슷합니다.',
                isAnswer: false
            },
            {
                text: '이 우산을 쓰면 아이의 위치를 확인하기 쉽습니다.',
                isAnswer: true
            },
        ],
        question:
            `최근 특별한 어린이 우산이 나왔습니다. 이 우산을 펴면 위쪽에서 빛이 납니다.
그리고 손잡이의 버튼을 누르면 노랫소리가 나옵니다.
이렇게 (  ㄱ  ) 아이들이 좋아합니다.
보통 우산을 쓰면 아이가 잘 안 보이는데 이 우산을 쓰면 아이가 어디에 있는지 쉽게 알 수 있습니다.
또 튼튼해서 오래 쓸 수 있습니다.
그래서 다른 우산보다 비싸지만 인기가 있습니다.`,
    },

    {
        q: '( ㄱ )에 들어갈 말로 가장 알맞은 것을 고르십시오.',
        answer: [
            {
                text: '선물을 받아 보셨습니다',
                isAnswer: false
            },
            {
                text: '이사를 가시게 되었습니다',
                isAnswer: true
            },
            {
                text: '아이를 데리러 오셨습니다',
                isAnswer: false
            },
            {
                text: '저를 도와주려고 하셨습니다',
                isAnswer: false
            },
        ],
        question:
            `우리 가족은 일 년 전에 한국에 왔습니다.
저와 아내는 회사에 다니면서 아이를 돌보는 것이 힘들었습니다.
이것을 알고 옆집에 사는 할머니가 아이를 자주 돌봐 주셨습니다. 아이는 할머니를 좋아했습니다.
그런데 할머니가 갑자기 (  ㄱ  ). 아이는 많이 울었습니다.
할머니와 헤어지는 날에 아이는 "제가 생각날 때 보세요."라고 하면서 직접 그린 그림을 선물했습니다.
그 그림에는 할머니와 아이가 있었습니다.
할머니는 눈물을 흘리셨습니다.`,
    },

    {
        q: '윗글의 내용으로 알 수 있는 것을 고르십시오.',
        answer: [
            {
                text: '아이는 우리 부부와 헤어져서 슬펐습니다.',
                isAnswer: false
            },
            {
                text: '아이는 할머니와 함께 있는 것이 좋았습니다.',
                isAnswer: true
            },
            {
                text: '할머니는 아이와 같은 집에 살아서 편했습니다.',
                isAnswer: false
            },
            {
                text: '할머니는 아이의 선물을 받고 기분이 나빴습니다.',
                isAnswer: false
            },
        ],
        question:
            `우리 가족은 일 년 전에 한국에 왔습니다.
저와 아내는 회사에 다니면서 아이를 돌보는 것이 힘들었습니다.
이것을 알고 옆집에 사는 할머니가 아이를 자주 돌봐 주셨습니다. 아이는 할머니를 좋아했습니다.
그런데 할머니가 갑자기 (  ㄱ  ). 아이는 많이 울었습니다.
할머니와 헤어지는 날에 아이는 "제가 생각날 때 보세요."라고 하면서 직접 그린 그림을 선물했습니다.
그 그림에는 할머니와 아이가 있었습니다.
할머니는 눈물을 흘리셨습니다.`,
    }]