export const word_2_6_1 = [
    { "kr": "바가지", "mean": "ひょうたん" },
    { "kr": "바구니", "mean": "かご" },
    { "kr": "바깥", "mean": "外、屋外、表" },
    { "kr": "바느질", "mean": "針仕事" },
    { "kr": "바늘", "mean": "針" },
    { "kr": "바닥", "mean": "底、床" },
    { "kr": "바닥나다", "mean": "底をつく" },
    { "kr": "바닷물", "mean": "海水" },
    { "kr": "바둑", "mean": "囲碁" },
    { "kr": "바라보다", "mean": "見渡す、眺める" },
    { "kr": "바보", "mean": "馬鹿" },
    { "kr": "바위", "mean": "岩" },
    { "kr": "바이러스", "mean": "ウイルス" },
    { "kr": "바퀴", "mean": "車輪" },
    { "kr": "바탕", "mean": "基礎、土台、素地" },
    { "kr": "박사", "mean": "博士" },
    { "kr": "박스", "mean": "ボックス" },
    { "kr": "반가워하다", "mean": "懐かしがる" },
    { "kr": "반납", "mean": "返却" },
    { "kr": "반납일", "mean": "返却日" },
    { "kr": "반대", "mean": "反対" },
    { "kr": "반대말", "mean": "反対語" },
    { "kr": "반대쪽", "mean": "反対側" },
    { "kr": "반드시", "mean": "必ず" },
    { "kr": "반말", "mean": "タメ口" },
    { "kr": "반복", "mean": "反復、リピート" },
    { "kr": "반성", "mean": "反省" },
    { "kr": "반응", "mean": "反応" },
    { "kr": "반짝반짝", "mean": "ぴかぴか" },
    { "kr": "반짝이다", "mean": "きらめく、輝く" },
    { "kr": "반품", "mean": "返品" },
    { "kr": "반하다", "mean": "惚れる" },
    { "kr": "받아들이다", "mean": "受け入れる" },
    { "kr": "발걸음", "mean": "足どり" },
    { "kr": "발견", "mean": "発見" },
    { "kr": "발급", "mean": "発給" },
    { "kr": "발달", "mean": "発達" },
    { "kr": "발레", "mean": "バレエ" },
    { "kr": "발명", "mean": "発明" },
    { "kr": "발목", "mean": "足首" },
    { "kr": "발생", "mean": "発生" },
    { "kr": "발자국", "mean": "足跡" },
    { "kr": "발표자", "mean": "発表者" },
    { "kr": "발표회", "mean": "発表会" },
    { "kr": "밝히다", "mean": "明らかにする" },
    { "kr": "밟다", "mean": "踏む" },
    { "kr": "밤새", "mean": "夜の間" },
    { "kr": "밤새우다", "mean": "徹夜する" },
    { "kr": "밥그릇", "mean": "ご飯茶碗" },
    { "kr": "밥맛", "mean": "ご飯の味、食欲" },
    { "kr": "밥상", "mean": "お膳" },
    { "kr": "방금", "mean": "たった今" },
    { "kr": "방문", "mean": "訪問" },
    { "kr": "방송사", "mean": "放送会社、放送社" },
    { "kr": "방식", "mean": "方式" },
    { "kr": "방청객", "mean": "傍聴人" },
    { "kr": "방해", "mean": "妨害、じゃま" },
    { "kr": "방향", "mean": "方向" },
    { "kr": "밭", "mean": "畑" },
    { "kr": "배경", "mean": "背景" },
    { "kr": "배꼽", "mean": "へそ" },
    { "kr": "배낭", "mean": "リュックサック" },
    { "kr": "배낭여행", "mean": "バックパック旅行" },
    { "kr": "배려", "mean": "配慮" },
    { "kr": "배우자", "mean": "配偶者" },
    { "kr": "배추", "mean": "白菜" },
    { "kr": "배터리", "mean": "バッテリー" },
    { "kr": "뱀", "mean": "蛇" },
    { "kr": "뱉다", "mean": "吐きだす" },
    { "kr": "버릇", "mean": "癖、礼儀作法" },
    { "kr": "버릇없다", "mean": "行儀が悪い、わがままだ" },
    { "kr": "버섯", "mean": "きのこ" },
    { "kr": "버튼", "mean": "ボタン" },
    { "kr": "번역", "mean": "翻訳" },
    { "kr": "번호표", "mean": "番号表" },
    { "kr": "벌금", "mean": "罰金" },
    { "kr": "벌리다", "mean": "あける、広げる" },
    { "kr": "벌집", "mean": "蜂の巣" },
    { "kr": "범위", "mean": "範囲" },
    { "kr": "범인", "mean": "犯人" },
    { "kr": "범죄", "mean": "犯罪" },
    { "kr": "법", "mean": "法律、法" },
    { "kr": "법원", "mean": "裁判所" },
    { "kr": "벗기다", "mean": "脱がせる、取れる" },
    { "kr": "벗어나다", "mean": "抜け出す、それる" },
    { "kr": "베개", "mean": "枕" },
    { "kr": "벨", "mean": "ベル" },
    { "kr": "벨트", "mean": "ベルト" },
    { "kr": "벼", "mean": "稲" },
    { "kr": "벼락", "mean": "雷" },
    { "kr": "벼룩시장", "mean": "フリーマーケット" },
    { "kr": "벽걸이", "mean": "壁掛け" },
    { "kr": "벽돌", "mean": "煉瓦" },
    { "kr": "변경", "mean": "変更" },
    { "kr": "변기", "mean": "トイレ" },
    { "kr": "변명", "mean": "弁明、言い訳" },
    { "kr": "변비", "mean": "便秘" },
    { "kr": "변하다", "mean": "変わる" },
    { "kr": "변화", "mean": "変化" },
    { "kr": "별명", "mean": "ニックネーム、あだ名、別名" },
    { "kr": "별일", "mean": "変わったこと、別ごと" }
]

export const word_2_6_2 = [
    { "kr": "병문안", "mean": "お見舞い" },
    { "kr": "보고", "mean": "報告" },
    { "kr": "보고서", "mean": "レポート、報告書" },
    { "kr": "보고회", "mean": "報告会" },
    { "kr": "보관", "mean": "保管" },
    { "kr": "보관실", "mean": "保管室" },
    { "kr": "보기", "mean": "見本、例" },
    { "kr": "보너스", "mean": "ボーナス" },
    { "kr": "보람", "mean": "やりがい、甲斐" },
    { "kr": "보름", "mean": "陰暦の十五日" },
    { "kr": "보름달", "mean": "満月、十五夜の月" },
    { "kr": "보물", "mean": "宝物" },
    { "kr": "보살피다", "mean": "面倒を見る" },
    { "kr": "보약", "mean": "強壮剤、補薬" },
    { "kr": "보증금", "mean": "保証金、敷金" },
    { "kr": "보충", "mean": "補充" },
    { "kr": "보험", "mean": "保険" },
    { "kr": "보험금", "mean": "保険金" },
    { "kr": "보험료", "mean": "保険料" },
    { "kr": "보호", "mean": "保護" },
    { "kr": "복", "mean": "福" },
    { "kr": "복권", "mean": "宝くじ" },
    { "kr": "복날", "mean": "ポンナル、滋養食を食べる日" },
    { "kr": "복도", "mean": "廊下" },
    { "kr": "복사", "mean": "複写、コピー" },
    { "kr": "복사기", "mean": "コピー機" },
    { "kr": "복습", "mean": "復習" },
    { "kr": "복통", "mean": "腹痛" },
    { "kr": "본론", "mean": "本論" },
    { "kr": "본문", "mean": "本文" },
    { "kr": "볼거리", "mean": "見どころ" },
    { "kr": "볼륨", "mean": "ボリューム" },
    { "kr": "볼링", "mean": "ボーリング" },
    { "kr": "볼링장", "mean": "ボーリング場" },
    { "kr": "볼일", "mean": "用事" },
    { "kr": "봉사", "mean": "ボランティア、奉仕" },
    { "kr": "봉사자", "mean": "奉仕者" },
    { "kr": "부끄러워하다", "mean": "恥ずかしがる" },
    { "kr": "부끄럽다", "mean": "恥ずかしい" },
    { "kr": "부담", "mean": "負担、負い目" },
    { "kr": "부딪치다", "mean": "ぶつかる" },
    { "kr": "부딪히다", "mean": "ぶつかる、直面する" },
    { "kr": "부러워하다", "mean": "うらやむ" },
    { "kr": "부러지다", "mean": "折れる" },
    { "kr": "부럽다", "mean": "うらやましい" },
    { "kr": "부분", "mean": "部分" },
    { "kr": "부사", "mean": "副詞" },
    { "kr": "부상", "mean": "負傷、けが" },
    { "kr": "부상자", "mean": "負傷者" },
    { "kr": "부서", "mean": "部署" },
    { "kr": "부서지다", "mean": "壊れる" },
    { "kr": "부자", "mean": "金持ち" },
    { "kr": "부작용", "mean": "副作用" },
    { "kr": "부잣집", "mean": "金持ちの家" },
    { "kr": "부정적", "mean": "否定的" },
    { "kr": "부족", "mean": "不足" },
    { "kr": "부주의", "mean": "不注意" },
    { "kr": "부지런히", "mean": "熱心に" },
    { "kr": "부채", "mean": "うちわ、扇子" },
    { "kr": "부케", "mean": "ブーケ" },
    { "kr": "부피", "mean": "大きさ" },
    { "kr": "분량", "mean": "分量" },
    { "kr": "분리", "mean": "分離" },
    { "kr": "분리수거", "mean": "分別収集" },
    { "kr": "분명하다", "mean": "明らかだ、はっきりしている" },
    { "kr": "분명히", "mean": "明確に" },
    { "kr": "분실", "mean": "紛失" },
    { "kr": "분실물", "mean": "忘れ物" },
    { "kr": "분홍색", "mean": "ピンク色" },
    { "kr": "불가능", "mean": "不可能" },
    { "kr": "불가능하다", "mean": "不可能である" },
    { "kr": "불경기", "mean": "不景気" },
    { "kr": "불교", "mean": "仏教" },
    { "kr": "불규칙", "mean": "不規則" },
    { "kr": "불꽃놀이", "mean": "花火遊び、花火見物" },
    { "kr": "불량", "mean": "不良" },
    { "kr": "불만", "mean": "不満" },
    { "kr": "불면증", "mean": "不眠症" },
    { "kr": "불법", "mean": "不法、違法" },
    { "kr": "불빛", "mean": "炎、あかり" },
    { "kr": "불쌍하다", "mean": "哀れだ" },
    { "kr": "불안", "mean": "不安" },
    { "kr": "불안감", "mean": "不安感" },
    { "kr": "불어나다", "mean": "増える、増す" },
    { "kr": "불친절", "mean": "不親切" },
    { "kr": "불태우다", "mean": "燃やす" },
    { "kr": "불편", "mean": "不便" },
    { "kr": "불평", "mean": "不平" },
    { "kr": "불필요", "mean": "不要、不必要" },
    { "kr": "불행", "mean": "不幸" },
    { "kr": "붉다", "mean": "赤い" },
    { "kr": "붐비다", "mean": "込み合う" },
    { "kr": "붕어빵", "mean": "たい焼き" },
    { "kr": "붙잡다", "mean": "捕まえる" },
    { "kr": "붙잡히다", "mean": "捕まる" },
    { "kr": "뷔페", "mean": "ビュッフェ" },
    { "kr": "브랜드", "mean": "ブランド" },
    { "kr": "브레이크", "mean": "ブレーキ" },
    { "kr": "블라인드", "mean": "ブラインド" },
    { "kr": "블로그", "mean": "ブログ" },
    { "kr": "비교", "mean": "比較" }
]

export const word_2_6_3 = [
    { "kr": "비교적", "mean": "比較的" },
    { "kr": "비닐", "mean": "ビニール" },
    { "kr": "비다", "mean": "空いている" },
    { "kr": "비디오카메라", "mean": "ビデオカメラ" },
    { "kr": "비록", "mean": "たとえ～でも" },
    { "kr": "비만", "mean": "肥満" },
    { "kr": "비바람", "mean": "雨風" },
    { "kr": "비비다", "mean": "こする、揉む、混ぜる" },
    { "kr": "비빔냉면", "mean": "ビビン冷麺" },
    { "kr": "비상약", "mean": "常備薬、非常用の薬" },
    { "kr": "비용", "mean": "費用" },
    { "kr": "비우다", "mean": "空にする、空ける" },
    { "kr": "비자", "mean": "ビザ" },
    { "kr": "비추다", "mean": "照らす" },
    { "kr": "비타민", "mean": "ビタミン" },
    { "kr": "비하다", "mean": "比べる" },
    { "kr": "빗길", "mean": "雨の道" },
    { "kr": "빚", "mean": "借金" },
    { "kr": "빛", "mean": "光" },
    { "kr": "빠뜨리다", "mean": "落とす" },
    { "kr": "빠지다", "mean": "落ちる、抜ける" },
    { "kr": "빠짐없이", "mean": "もれなく、抜かりなく" },
    { "kr": "빨개지다", "mean": "赤くなる" },
    { "kr": "빼앗기다", "mean": "奪われる" },
    { "kr": "빼앗다", "mean": "奪う" },
    { "kr": "뺨", "mean": "頬" },
    { "kr": "뻔하다", "mean": "確かだ、自明だ" },
    { "kr": "뻗다", "mean": "伸びる" },
    { "kr": "뽑다", "mean": "抜く、選ぶ" },
    { "kr": "뿌리", "mean": "根" },
    { "kr": "뿌리다", "mean": "振りまく" }
]
