export const word_2_1_1 = [
    { "kr": "가계부", "mean": "家計簿" },
    { "kr": "가구점", "mean": "家具店" },
    { "kr": "가까이", "mean": "近く" },
    { "kr": "가꾸다", "mean": "栽培する" },
    { "kr": "가난", "mean": "貧乏" },
    { "kr": "가늘다", "mean": "細い" },
    { "kr": "가능", "mean": "可能" },
    { "kr": "가능성", "mean": "可能性" },
    { "kr": "가능하다", "mean": "可能だ" },
    { "kr": "가득", "mean": "いっぱい" },
    { "kr": "가라앉다", "mean": "沈む" },
    { "kr": "가렵다", "mean": "かゆい" },
    { "kr": "가루비누", "mean": "粉石鹸" },
    { "kr": "가리다", "mean": "選ぶ" },
    { "kr": "가리키다", "mean": "示す" },
    { "kr": "가만히", "mean": "じっと、おとなしく" },
    { "kr": "가뭄", "mean": "干ばつ、日照り" },
    { "kr": "가사", "mean": "家事" },
    { "kr": "가상", "mean": "仮想" },
    { "kr": "가스", "mean": "ガス" },
    { "kr": "가습기", "mean": "加湿器" },
    { "kr": "가입", "mean": "加入" },
    { "kr": "가전제품", "mean": "家電製品" },
    { "kr": "가정", "mean": "家庭" },
    { "kr": "가족적", "mean": "家族的" },
    { "kr": "가축", "mean": "家畜" },
    { "kr": "가치", "mean": "価値" },
    { "kr": "각각", "mean": "別々に、それぞれ" },
    { "kr": "각자", "mean": "各自" },
    { "kr": "각종", "mean": "各種" },
    { "kr": "간격", "mean": "間隔" },
    { "kr": "간절히", "mean": "切に" },
    { "kr": "간접적", "mean": "間接的" },
    { "kr": "간판", "mean": "看板" },
    { "kr": "간호", "mean": "看護" },
    { "kr": "갈다", "mean": "替える" },
    { "kr": "갈등", "mean": "葛藤" },
    { "kr": "갈수록", "mean": "ますます" },
    { "kr": "갈아입다", "mean": "着替える" },
    { "kr": "감각", "mean": "感覚" },
    { "kr": "감기다", "mean": "巻かれる" },
    { "kr": "감독", "mean": "監督" },
    { "kr": "감동적", "mean": "感動的" },
    { "kr": "감상", "mean": "鑑賞" },
    { "kr": "감소", "mean": "減少" },
    { "kr": "감정", "mean": "感情" },
    { "kr": "감탄", "mean": "感嘆、感歎" },
    { "kr": "강물", "mean": "川水" },
    { "kr": "강변", "mean": "川沿い、川辺" },
    { "kr": "강사", "mean": "講師" },
    { "kr": "강수량", "mean": "降水量" },
    { "kr": "강연", "mean": "講演" },
    { "kr": "강우량", "mean": "降雨量" },
    { "kr": "강의", "mean": "講義" },
    { "kr": "강의실", "mean": "講義室" },
    { "kr": "강조", "mean": "強調" },
    { "kr": "강하다", "mean": "強い" },
    { "kr": "갖추다", "mean": "整える、準備する" },
    { "kr": "갚다", "mean": "返す、報いる" },
    { "kr": "개강", "mean": "開講" },
    { "kr": "개구리", "mean": "カエル" },
    { "kr": "개구쟁이", "mean": "いたずらっ子、やんちゃ" },
    { "kr": "개다", "mean": "晴れる" },
    { "kr": "개막식", "mean": "開幕式、開会式" },
    { "kr": "개발", "mean": "開発" },
    { "kr": "개방", "mean": "開放" },
    { "kr": "개방적", "mean": "開放的" },
    { "kr": "개선", "mean": "改善" },
    { "kr": "개성", "mean": "個性" },
    { "kr": "개성적", "mean": "個性的" },
    { "kr": "개인적", "mean": "個人的" },
    { "kr": "개최", "mean": "開催" },
    { "kr": "객관적", "mean": "客観的" },
    { "kr": "객실", "mean": "客室" },
    { "kr": "갸름하다", "mean": "細長い" },
    { "kr": "걔", "mean": "その子" },
    { "kr": "거래", "mean": "取引" },
    { "kr": "거미", "mean": "蜘蛛" },
    { "kr": "거북이", "mean": "カメ" },
    { "kr": "거스름돈", "mean": "お釣り" },
    { "kr": "거절", "mean": "拒絶" },
    { "kr": "거짓", "mean": "嘘、偽り" },
    { "kr": "거치다", "mean": "こすれる" },
    { "kr": "거품", "mean": "泡" },
    { "kr": "걱정거리", "mean": "心配事" },
    { "kr": "건강식", "mean": "健康食" },
    { "kr": "건강식품", "mean": "健康食品" },
    { "kr": "건너", "mean": "向こう" },
    { "kr": "건네다", "mean": "手渡す、（声を）かける" },
    { "kr": "건더기", "mean": "具、カップ麺の加薬" },
    { "kr": "건망증", "mean": "健忘症、物忘れ" },
    { "kr": "건설", "mean": "建設" },
    { "kr": "건전지", "mean": "乾電池" },
    { "kr": "건조", "mean": "乾燥" },
    { "kr": "건축", "mean": "建築" },
    { "kr": "건축가", "mean": "建築家" },
    { "kr": "걸레", "mean": "雑巾" },
    { "kr": "걸레질", "mean": "雑巾がけ" },
    { "kr": "걸음", "mean": "歩み" },
    { "kr": "검다", "mean": "黒い" }
]

export const word_2_1_2 = [
    { "kr": "검사", "mean": "検査" },
    { "kr": "검색", "mean": "検索" },
    { "kr": "검색어", "mean": "検索語、検索単語" },
    { "kr": "겁", "mean": "恐れ、怖さ" },
    { "kr": "겉", "mean": "表面、外面" },
    { "kr": "겉모습", "mean": "見かけ" },
    { "kr": "게다가", "mean": "その上、それに" },
    { "kr": "게시판", "mean": "掲示板" },
    { "kr": "게으르다", "mean": "怠ける、怠惰だ" },
    { "kr": "게임기", "mean": "ゲーム機" },
    { "kr": "겨우", "mean": "やっと、ようやく" },
    { "kr": "격려", "mean": "激励" },
    { "kr": "겪다", "mean": "経験する、経る" },
    { "kr": "견디다", "mean": "耐える" },
    { "kr": "결과적", "mean": "結果的" },
    { "kr": "결국", "mean": "結局" },
    { "kr": "결론", "mean": "結論" },
    { "kr": "결말", "mean": "結末" },
    { "kr": "결석", "mean": "欠席" },
    { "kr": "결승전", "mean": "決勝戦" },
    { "kr": "결심", "mean": "決心" },
    { "kr": "결코", "mean": "決して" },
    { "kr": "결혼기념일", "mean": "結婚記念日" },
    { "kr": "결혼식장", "mean": "結婚式場" },
    { "kr": "겸손", "mean": "謙遜" },
    { "kr": "겹치다", "mean": "重なる" },
    { "kr": "경고", "mean": "警告" },
    { "kr": "경력", "mean": "経歴" },
    { "kr": "경비", "mean": "警備" },
    { "kr": "경비원", "mean": "警備員" },
    { "kr": "경영", "mean": "経営" },
    { "kr": "경영자", "mean": "経営者" },
    { "kr": "경영학", "mean": "経営学" },
    { "kr": "경우", "mean": "場合" },
    { "kr": "경쟁", "mean": "競争" },
    { "kr": "경쟁력", "mean": "競争力" },
    { "kr": "경제", "mean": "経済" },
    { "kr": "경제력", "mean": "経済力" },
    { "kr": "경제적", "mean": "経済的" },
    { "kr": "경제학", "mean": "経済学" },
    { "kr": "계산", "mean": "計算、会計" },
    { "kr": "계산대", "mean": "レジ" },
    { "kr": "계산서", "mean": "計算書" },
    { "kr": "계약", "mean": "契約" },
    { "kr": "계약금", "mean": "契約金" },
    { "kr": "계약서", "mean": "契約書" },
    { "kr": "계좌", "mean": "口座" },
    { "kr": "계층", "mean": "階層" },
    { "kr": "계획성", "mean": "計画性" },
    { "kr": "계획적", "mean": "計画的" },
    { "kr": "고개", "mean": "頭、峠" },
    { "kr": "고객", "mean": "顧客" },
    { "kr": "고구마", "mean": "さつまいも" },
    { "kr": "고궁", "mean": "昔の韓国の宮殿" },
    { "kr": "고급", "mean": "高級" },
    { "kr": "고래", "mean": "クジラ" },
    { "kr": "고마움", "mean": "ありがたみ" },
    { "kr": "고무신", "mean": "ゴム靴" },
    { "kr": "고무줄", "mean": "輪ゴム" },
    { "kr": "고민", "mean": "悩み" },
    { "kr": "고백", "mean": "告白" },
    { "kr": "고생", "mean": "苦労" },
    { "kr": "고소하다", "mean": "香ばしい" },
    { "kr": "고속", "mean": "高速" },
    { "kr": "고속도로", "mean": "高速道路" },
    { "kr": "고아원", "mean": "孤児院" },
    { "kr": "고유하다", "mean": "固有である" },
    { "kr": "고지서", "mean": "告知書" },
    { "kr": "고집", "mean": "固執" },
    { "kr": "고춧가루", "mean": "唐辛子粉" },
    { "kr": "고층", "mean": "高層" },
    { "kr": "고통", "mean": "苦痛" },
    { "kr": "곤란", "mean": "困難" },
    { "kr": "골고루", "mean": "均等に" },
    { "kr": "골목길", "mean": "路地" },
    { "kr": "골치가 아프다", "mean": "非常に面倒だ" },
    { "kr": "곰", "mean": "クマ" },
    { "kr": "곱슬머리", "mean": "くせ毛、天然パーマ" },
    { "kr": "곳곳", "mean": "あちこち、至るところ" },
    { "kr": "공공요금", "mean": "公共料金" },
    { "kr": "공공장소", "mean": "公共の場所" },
    { "kr": "공동", "mean": "共同" },
    { "kr": "공동생활", "mean": "共同生活" },
    { "kr": "공사", "mean": "工事" },
    { "kr": "공손하다", "mean": "礼儀正しい" },
    { "kr": "공연장", "mean": "公演会場" },
    { "kr": "공장", "mean": "工場" },
    { "kr": "공주", "mean": "お姫様" },
    { "kr": "공통", "mean": "共通" },
    { "kr": "공통점", "mean": "共通点" },
    { "kr": "공포", "mean": "恐怖" },
    { "kr": "공해", "mean": "公害" },
    { "kr": "과로", "mean": "過労" },
    { "kr": "과목", "mean": "科目" },
    { "kr": "과소비", "mean": "浪費" },
    { "kr": "과속", "mean": "スピード違反" },
    { "kr": "과식", "mean": "食べ過ぎ" },
    { "kr": "과연", "mean": "果たして" },
    { "kr": "과외", "mean": "家庭教師レッスン" },
    { "kr": "과음", "mean": "飲み過ぎ" }
]

export const word_2_1_3 = [
    { "kr": "과장", "mean": "課長" },
    { "kr": "과장하다", "mean": "誇張する" },
    { "kr": "과정", "mean": "過程" },
    { "kr": "과제", "mean": "課題" },
    { "kr": "과학자", "mean": "科学者" },
    { "kr": "과학적", "mean": "科学的" },
    { "kr": "관객", "mean": "観客" },
    { "kr": "관계없다", "mean": "関係ない" },
    { "kr": "관계자", "mean": "関係者" },
    { "kr": "관광", "mean": "観光" },
    { "kr": "관광객", "mean": "観光客" },
    { "kr": "관광지", "mean": "観光地" },
    { "kr": "관람", "mean": "観覧" },
    { "kr": "관람객", "mean": "観覧客" },
    { "kr": "관련", "mean": "関連" },
    { "kr": "관련성", "mean": "関連性" },
    { "kr": "관리", "mean": "管理" },
    { "kr": "관리실", "mean": "管理室" },
    { "kr": "관용어", "mean": "慣用句" },
    { "kr": "관찰", "mean": "観察" },
    { "kr": "광장", "mean": "広場" },
    { "kr": "괜히", "mean": "訳もなく" },
    { "kr": "괴로움", "mean": "辛さ" },
    { "kr": "괴로워하다", "mean": "煩う(わずら)" },
    { "kr": "괴롭다", "mean": "辛い" },
    { "kr": "괴롭히다", "mean": "苦しめる、いじめる" },
    { "kr": "굉장하다", "mean": "凄い" },
    { "kr": "굉장히", "mean": "ものすごく" },
    { "kr": "교사", "mean": "教師" },
    { "kr": "교외", "mean": "郊外" },
    { "kr": "교육", "mean": "教育" },
    { "kr": "교육자", "mean": "教育者" },
    { "kr": "교육학", "mean": "教育学" },
    { "kr": "교재", "mean": "教材" },
    { "kr": "교통수단", "mean": "交通手段" },
    { "kr": "교통편", "mean": "交通手段" },
    { "kr": "교포", "mean": "同胞" },
    { "kr": "교환", "mean": "交換" },
    { "kr": "구두쇠", "mean": "ケチ" },
    { "kr": "구르다", "mean": "転がる" },
    { "kr": "구매", "mean": "購買" },
    { "kr": "구멍", "mean": "穴" },
    { "kr": "구별", "mean": "区別" },
    { "kr": "구분", "mean": "区分" },
    { "kr": "구성", "mean": "構成" },
    { "kr": "구이", "mean": "焼き物" },
    { "kr": "구인", "mean": "求人" },
    { "kr": "구입", "mean": "購入" },
    { "kr": "구조", "mean": "構造" },
    { "kr": "구조대", "mean": "救助隊" },
    { "kr": "구체적", "mean": "具体的" },
    { "kr": "국가", "mean": "国家" },
    { "kr": "국그릇", "mean": "汁椀" },
    { "kr": "국내선", "mean": "国内線" },
    { "kr": "국문학", "mean": "国文学" },
    { "kr": "국물", "mean": "汁、スープ" },
    { "kr": "국민", "mean": "国民" },
    { "kr": "국악", "mean": "伝統音楽" },
    { "kr": "국외", "mean": "国外" },
    { "kr": "국제결혼", "mean": "国際結婚" },
    { "kr": "국제선", "mean": "国際線" },
    { "kr": "국화", "mean": "菊" },
    { "kr": "군대", "mean": "軍隊" },
    { "kr": "군데", "mean": "所" },
    { "kr": "굵다", "mean": "太い" },
    { "kr": "궁금증", "mean": "気がかり" },
    { "kr": "궁금하다", "mean": "気になる" },
    { "kr": "권하다", "mean": "すすめる" },
    { "kr": "귀걸이", "mean": "イヤリング" },
    { "kr": "귀국", "mean": "帰国" },
    { "kr": "귀신", "mean": "幽霊、お化け" },
    { "kr": "귀중하다", "mean": "貴重だ" },
    { "kr": "귀찮다", "mean": "面倒くさい" },
    { "kr": "귀하다", "mean": "尊い、高貴だ、立派だ" },
    { "kr": "규칙적", "mean": "規則的" },
    { "kr": "그네", "mean": "ブランコ" },
    { "kr": "그녀", "mean": "彼女" },
    { "kr": "그늘", "mean": "日陰" },
    { "kr": "그대로", "mean": "そのまま" },
    { "kr": "그래도", "mean": "それでも" },
    { "kr": "그래야", "mean": "そうしてこそ" },
    { "kr": "그래프", "mean": "グラフ" },
    { "kr": "그러니", "mean": "だから" },
    { "kr": "그러다가", "mean": "そうこうするうちに" },
    { "kr": "그러므로", "mean": "それゆえ" },
    { "kr": "그러자", "mean": "すると" },
    { "kr": "그러하다", "mean": "そのようだ" },
    { "kr": "그루", "mean": "～株、～本" },
    { "kr": "그룹", "mean": "グループ" },
    { "kr": "그리", "mean": "それほど" },
    { "kr": "그리워하다", "mean": "なつかしがる" },
    { "kr": "그리하여", "mean": "そうして" },
    { "kr": "그림책", "mean": "絵本" },
    { "kr": "그립다", "mean": "懐かしい、恋しい" },
    { "kr": "그만두다", "mean": "やめる" },
    { "kr": "그만큼", "mean": "それくらい" },
    { "kr": "그저", "mean": "ただ" },
    { "kr": "근데", "mean": "ところで" },
    { "kr": "근로자", "mean": "勤労者" },
    { "kr": "근무", "mean": "勤務" }
]

export const word_2_1_4 = [
    { "kr": "근육", "mean": "筋肉" },
    { "kr": "글쎄", "mean": "まあ" },
    { "kr": "글쓴이", "mean": "著者" },
    { "kr": "글씨", "mean": "文字" },
    { "kr": "글자", "mean": "文字" },
    { "kr": "긁다", "mean": "掻く(かく）、ひっかく" },
    { "kr": "금", "mean": "金" },
    { "kr": "금메달", "mean": "金メダル" },
    { "kr": "금액", "mean": "金額" },
    { "kr": "금지", "mean": "禁止" },
    { "kr": "급히", "mean": "急に" },
    { "kr": "긍정적", "mean": "肯定的、前向き" },
    { "kr": "기계", "mean": "機械" },
    { "kr": "기념", "mean": "記念" },
    { "kr": "기념관", "mean": "記念館" },
    { "kr": "기념일", "mean": "記念日" },
    { "kr": "기념품", "mean": "記念品" },
    { "kr": "기능", "mean": "機能" },
    { "kr": "기다", "mean": "這う" },
    { "kr": "기대", "mean": "期待" },
    { "kr": "기도", "mean": "祈祷" },
    { "kr": "기둥", "mean": "柱" },
    { "kr": "기록", "mean": "記録" },
    { "kr": "기본", "mean": "基本" },
    { "kr": "기본적", "mean": "基本的" },
    { "kr": "기부", "mean": "寄付" },
    { "kr": "기쁨", "mean": "喜び" },
    { "kr": "기술", "mean": "技術" },
    { "kr": "기술자", "mean": "技術者" },
    { "kr": "기억", "mean": "記憶" },
    { "kr": "기억나다", "mean": "思い出す" },
    { "kr": "기억력", "mean": "記憶力" },
    { "kr": "기업", "mean": "企業" },
    { "kr": "기업인", "mean": "起業家" },
    { "kr": "기운", "mean": "元気、気運" },
    { "kr": "기울이다", "mean": "傾ける" },
    { "kr": "기준", "mean": "基準" },
    { "kr": "기차역", "mean": "駅" },
    { "kr": "기차표", "mean": "列車のチケット" },
    { "kr": "기초", "mean": "基礎" },
    { "kr": "기행문", "mean": "紀行文" },
    { "kr": "기호", "mean": "記号" },
    { "kr": "기혼", "mean": "既婚" },
    { "kr": "기회", "mean": "機会、チャンス" },
    { "kr": "기후", "mean": "気候" },
    { "kr": "긴장감", "mean": "緊張感" },
    { "kr": "길가", "mean": "道端" },
    { "kr": "길거리", "mean": "通り、路上" },
    { "kr": "길이", "mean": "長さ" },
    { "kr": "김장", "mean": "冬にキムチを漬け込む行事" },
    { "kr": "깊다", "mean": "深い" },
    { "kr": "깊이", "mean": "深さ、深く" },
    { "kr": "까다", "mean": "皮をむく" },
    { "kr": "까다롭다", "mean": "気難しい" },
    { "kr": "깔다", "mean": "敷く" },
    { "kr": "깜빡", "mean": "うっかり" },
    { "kr": "깨끗이", "mean": "きれいに" },
    { "kr": "깨닫다", "mean": "悟る" },
    { "kr": "깨뜨리다", "mean": "割る" },
    { "kr": "깨소금", "mean": "ゴマ塩" },
    { "kr": "깨우다", "mean": "起こす" },
    { "kr": "깻잎", "mean": "エゴマの葉" },
    { "kr": "꺼지다", "mean": "消える" },
    { "kr": "껍질", "mean": "皮" },
    { "kr": "꼬리", "mean": "しっぽ" },
    { "kr": "꼬박꼬박", "mean": "欠かさずきちんと" },
    { "kr": "꼭대기", "mean": "てっぺん、頂上" },
    { "kr": "꼴찌", "mean": "びり、最下位" },
    { "kr": "꼼꼼하다", "mean": "几帳面だ" },
    { "kr": "꼼꼼히", "mean": "几帳面に" },
    { "kr": "꽃병", "mean": "花瓶" },
    { "kr": "꽤", "mean": "かなり、なかなか" },
    { "kr": "꾸미다", "mean": "飾る" },
    { "kr": "꾸준히", "mean": "着実に" },
    { "kr": "꾸짖다", "mean": "叱る" },
    { "kr": "꿀", "mean": "はちみつ" },
    { "kr": "꿀벌", "mean": "ミツバチ" },
    { "kr": "꿈꾸다", "mean": "夢見る" },
    { "kr": "끈", "mean": "紐" },
    { "kr": "끊다", "mean": "切る" },
    { "kr": "끊어지다", "mean": "切れる" },
    { "kr": "끊임없다", "mean": "絶え間無い" },
    { "kr": "끌다", "mean": "引く" },
    { "kr": "끝내", "mean": "最後まで、ついに" },
    { "kr": "끼어들다", "mean": "割り込む" }
]