export const word_2_4_1 = [
    { "kr": "램프", "mean": "ランプ" },
    { "kr": "레몬", "mean": "レモン" },
    { "kr": "레포츠", "mean": "レジャースポーツ" },
    { "kr": "렌즈", "mean": "レンズ" },
    { "kr": "렌터카", "mean": "レンタカー" },
    { "kr": "로또", "mean": "ロット、宝くじ" },
    { "kr": "로봇", "mean": "ロボット" },
    { "kr": "로비", "mean": "ロビー" },
    { "kr": "룸메이트", "mean": "ルームメイト" },
    { "kr": "리듬", "mean": "リズム" },
    { "kr": "리모컨", "mean": "リモコン" }
]
