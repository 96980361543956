export const word_2_5_1 = [
    { "kr": "마감일", "mean": "締め切り日" },
    { "kr": "마네킹", "mean": "マネキン" },
    { "kr": "마당", "mean": "庭" },
    { "kr": "마디", "mean": "節" },
    { "kr": "마라톤", "mean": "マラソン" },
    { "kr": "마련하다", "mean": "設ける" },
    { "kr": "마루", "mean": "板の間" },
    { "kr": "마무리", "mean": "仕上げ" },
    { "kr": "마술", "mean": "手品" },
    { "kr": "마스크", "mean": "マスク" },
    { "kr": "마약", "mean": "麻薬" },
    { "kr": "마을", "mean": "村" },
    { "kr": "마음껏", "mean": "心おきなく、思いっきり" },
    { "kr": "마음대로", "mean": "自分勝手に、思い通りに" },
    { "kr": "마음먹다", "mean": "決心する" },
    { "kr": "마음씨", "mean": "心、気立て" },
    { "kr": "마주보다", "mean": "向き合う" },
    { "kr": "마주치다", "mean": "ぶつかる、目が合う" },
    { "kr": "마중", "mean": "出迎え" },
    { "kr": "마찬가지", "mean": "同様" },
    { "kr": "마치", "mean": "まるで、あたかも、ちょうど" },
    { "kr": "마침", "mean": "ちょうど、たまたま" },
    { "kr": "마침내", "mean": "遂に" },
    { "kr": "마케팅", "mean": "マーケティング" },
    { "kr": "마트", "mean": "マート" },
    { "kr": "막", "mean": "めちゃくちゃに、やたらに" },
    { "kr": "막내", "mean": "末っ子" },
    { "kr": "막다", "mean": "ふさぐ、さえぎる" },
    { "kr": "막대기", "mean": "棒" },
    { "kr": "막차", "mean": "終電" },
    { "kr": "만남", "mean": "出会い" },
    { "kr": "만년필", "mean": "万年筆" },
    { "kr": "만약", "mean": "もしも、万が一" },
    { "kr": "만족", "mean": "満足" },
    { "kr": "만족감", "mean": "満足感" },
    { "kr": "만화책", "mean": "漫画" },
    { "kr": "맏딸", "mean": "長女" },
    { "kr": "맏아들", "mean": "長男" },
    { "kr": "말다툼", "mean": "口喧嘩、口論" },
    { "kr": "말리다", "mean": "引き止める、乾かす" },
    { "kr": "말투", "mean": "話し方、言葉使い" },
    { "kr": "맛보다", "mean": "味見する" },
    { "kr": "망가뜨리다", "mean": "壊す、ダメにする" },
    { "kr": "망가지다", "mean": "壊れる、駄目になる" },
    { "kr": "망설이다", "mean": "ためらう、躊躇する" },
    { "kr": "망하다", "mean": "滅ぶ" },
    { "kr": "맞벌이", "mean": "共稼ぎ" },
    { "kr": "맞이", "mean": "迎え" },
    { "kr": "맞추다", "mean": "合わせる、仕立てる" },
    { "kr": "맡기다", "mean": "任せる" },
    { "kr": "맡다", "mean": "引き受ける" },
    { "kr": "매년", "mean": "毎年" },
    { "kr": "매달", "mean": "毎月" },
    { "kr": "매력", "mean": "魅力" },
    { "kr": "매력적", "mean": "魅力的" },
    { "kr": "매연", "mean": "煤煙" },
    { "kr": "매운탕", "mean": "メウンタン、魚のアラ鍋" },
    { "kr": "매점", "mean": "売店" },
    { "kr": "매진", "mean": "売り切れ" },
    { "kr": "매체", "mean": "媒体" },
    { "kr": "매콤하다", "mean": "やや辛い、ピリ辛い" },
    { "kr": "매표소", "mean": "チケット売り場" },
    { "kr": "맨발", "mean": "素足" },
    { "kr": "맨손", "mean": "素手" },
    { "kr": "맹인", "mean": "盲人" },
    { "kr": "맺다", "mean": "結ぶ" },
    { "kr": "머리카락", "mean": "髪、髪の毛" },
    { "kr": "머무르다", "mean": "とどまる" },
    { "kr": "머지않다", "mean": "遠くない" },
    { "kr": "먹을거리", "mean": "食べ物" },
    { "kr": "먹이", "mean": "えさ" },
    { "kr": "먹이다", "mean": "食べさせる" },
    { "kr": "먼지", "mean": "ちり、ほこり" },
    { "kr": "멀리", "mean": "遠く" },
    { "kr": "멀티미디어실", "mean": "マルチメディア室" },
    { "kr": "멈추다", "mean": "止まる" },
    { "kr": "멋지다", "mean": "素敵だ" },
    { "kr": "멍", "mean": "あざ" },
    { "kr": "메일", "mean": "メール" },
    { "kr": "멜로디", "mean": "メロディー" },
    { "kr": "멜론", "mean": "メロン" },
    { "kr": "며느리", "mean": "嫁" },
    { "kr": "면", "mean": "面、麺" },
    { "kr": "면세점", "mean": "免税店" },
    { "kr": "면접", "mean": "面接" },
    { "kr": "면접관", "mean": "面接官" },
    { "kr": "면접시험", "mean": "面接試験" },
    { "kr": "면허증", "mean": "免許証" },
    { "kr": "명랑", "mean": "明朗" },
    { "kr": "명랑하다", "mean": "朗らかだ、明るい" },
    { "kr": "명사", "mean": "名詞" },
    { "kr": "명소", "mean": "名所" },
    { "kr": "명품", "mean": "ブランド品、名品" },
    { "kr": "몇몇", "mean": "いくつかの、若干" },
    { "kr": "모국어", "mean": "母国語" },
    { "kr": "모기", "mean": "蚊" },
    { "kr": "모델", "mean": "モデル" },
    { "kr": "모래", "mean": "砂" },
    { "kr": "모범생", "mean": "模範生" },
    { "kr": "모습", "mean": "姿" },
    { "kr": "모음", "mean": "母音" }
]

export const word_2_5_2 = [
    { "kr": "모자라다", "mean": "足りない" },
    { "kr": "모집", "mean": "募集" },
    { "kr": "모험심", "mean": "冒険心" },
    { "kr": "모형", "mean": "模型" },
    { "kr": "목마르다", "mean": "喉が渇く" },
    { "kr": "목사", "mean": "牧師" },
    { "kr": "목숨", "mean": "命" },
    { "kr": "목욕탕", "mean": "銭湯" },
    { "kr": "목적지", "mean": "目的地" },
    { "kr": "목표", "mean": "目標" },
    { "kr": "몰라보다", "mean": "わからない、見違える" },
    { "kr": "몰래", "mean": "こっそり" },
    { "kr": "몰려들다", "mean": "押し寄せる" },
    { "kr": "몰리다", "mean": "寄り集まる" },
    { "kr": "몸무게", "mean": "体重" },
    { "kr": "몸짓", "mean": "身振り、ジェスチャー" },
    { "kr": "몹시", "mean": "とても、たいへん、ひどく" },
    { "kr": "못생기다", "mean": "ぶさいくだ、醜い" },
    { "kr": "무게", "mean": "重さ" },
    { "kr": "무관심", "mean": "無関心" },
    { "kr": "무너지다", "mean": "崩れる" },
    { "kr": "무늬", "mean": "柄、模様" },
    { "kr": "무대", "mean": "舞台、ステージ" },
    { "kr": "무더위", "mean": "蒸し暑さ" },
    { "kr": "무덤", "mean": "墓" },
    { "kr": "무덥다", "mean": "蒸し暑い" },
    { "kr": "무렵", "mean": "頃" },
    { "kr": "무사히", "mean": "無事に" },
    { "kr": "무서워하다", "mean": "怖がる" },
    { "kr": "무선", "mean": "無線" },
    { "kr": "무소식", "mean": "便りのないこと" },
    { "kr": "무시", "mean": "無視" },
    { "kr": "무인", "mean": "無人" },
    { "kr": "무조건", "mean": "無条件" },
    { "kr": "무지개", "mean": "虹" },
    { "kr": "무통장 입금", "mean": "電子振込、ATM入金" },
    { "kr": "묵다", "mean": "泊まる" },
    { "kr": "묶다", "mean": "束ねる、縛る" },
    { "kr": "문구", "mean": "文具、文房具" },
    { "kr": "문명", "mean": "文明" },
    { "kr": "문법", "mean": "文法" },
    { "kr": "문병", "mean": "お見舞い" },
    { "kr": "문서", "mean": "文書" },
    { "kr": "문의", "mean": "問い合わせ" },
    { "kr": "문자", "mean": "文字、SMS、ショートメッセージ" },
    { "kr": "문제점", "mean": "問題点" },
    { "kr": "문학", "mean": "文学" },
    { "kr": "문화생활", "mean": "文化的な生活" },
    { "kr": "문화유산", "mean": "文化遺産" },
    { "kr": "문화적", "mean": "文化的" },
    { "kr": "묻히다", "mean": "つける、まぶす" },
    { "kr": "물가", "mean": "物価" },
    { "kr": "물고기", "mean": "魚" },
    { "kr": "물냉면", "mean": "水冷麺" },
    { "kr": "물다", "mean": "咬む、口にくわえる" },
    { "kr": "물러서다", "mean": "後退する" },
    { "kr": "물려주다", "mean": "譲る、譲り渡す" },
    { "kr": "물리다", "mean": "飽きる、噛まれる" },
    { "kr": "물방울", "mean": "水玉" },
    { "kr": "물약", "mean": "飲み薬、水薬" },
    { "kr": "물음", "mean": "問い" },
    { "kr": "물질", "mean": "物質" },
    { "kr": "물품", "mean": "物品" },
    { "kr": "뮤지컬", "mean": "ミュージカル" },
    { "kr": "미끄러지다", "mean": "滑る" },
    { "kr": "미끄럽다", "mean": "つるつるしている、滑りやすい" },
    { "kr": "미루다", "mean": "延ばす" },
    { "kr": "미소", "mean": "微笑み" },
    { "kr": "미역", "mean": "ワカメ" },
    { "kr": "미역국", "mean": "ワカメスープ" },
    { "kr": "미용사", "mean": "美容師" },
    { "kr": "미인", "mean": "美人" },
    { "kr": "미치다", "mean": "及ぶ、及ぼす" },
    { "kr": "미팅", "mean": "合コン" },
    { "kr": "미혼", "mean": "未婚" },
    { "kr": "민박", "mean": "民宿、民泊" },
    { "kr": "민속", "mean": "民俗" },
    { "kr": "민속놀이", "mean": "民俗遊び" },
    { "kr": "민요", "mean": "民謡" },
    { "kr": "민족", "mean": "民族" },
    { "kr": "믿다", "mean": "信じる" },
    { "kr": "믿음", "mean": "信頼、信仰" },
    { "kr": "믿음직스럽다", "mean": "頼もしい、信頼できる" },
    { "kr": "믿음직하다", "mean": "頼もしい" },
    { "kr": "밀다", "mean": "押す" },
    { "kr": "밀려오다", "mean": "押し寄せてくる" },
    { "kr": "밀리미터", "mean": "ミリメートル" },
    { "kr": "밉다", "mean": "憎い、醜い" },
    { "kr": "및", "mean": "および" },
    { "kr": "밑줄", "mean": "下線" }
]